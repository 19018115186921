import React, { useState, useEffect, Component, useRef } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView, Picker} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import ModalInfoRegister from '../components/ModalInfoRegister';
import CountryPicker from 'react-native-country-picker-modal'
import ModalActivityIndicator from '../components/ModalActivityIndicator';
import axios from 'axios';
import RNPickerSelect from '../components/react-native-picker-select-max';
import BoutonEtude from '../components/BoutonEtude';

const ModifInfo2Screen = props => {

  const {iduser, nom, prenom, sexe, happy, national} = props.route.params;

  console.log(props)

  const URL = 'https://www.useradventure.net/'
  const URL_ville =  "https://vicopo.selfbuild.fr/cherche/"
  const EXT = ".json"

  useEffect(() => {
    fetch(`${URL}json_get_react_data_user.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
      .then((response) => response.json())
      .then(response => {setDataUser(response);   
        setPays(response[0].Nationalite);
        setCountryCode(response[0].Country);
        setPortable(response[0].Num_portable);
        setNumRue(response[0].Num_rue);
        setRue(response[0].Rue);
        setCompAdress(response[0].Complement_adresse);
        setCodePostal(response[0].Code_postal);
        setVille(response[0].Ville);
        setinitValue(response[0].Ville);
        setModalVisible3(true)
        axios.get(`${URL_ville}`+response[0].Code_postal).then(function(response){
            response.data.cities.map((item, index) => {
              data.push({ value: item.city,"label":item.city})
             // setData([...data,{ key: index++,"label":item.city}])
              console.log("data",data)
            })
           
             })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .finally(() =>  setModalVisible3(false))
        if(response[0].Num_portable != '' && response[0].Num_rue != '' && response[0].Rue !='' && response[0].Code_postal !='' && response[0].Ville !=''){
          setdisabledcolor('#FFB217')
          setdisabled(true)
          }
      })
      .catch((error) => console.error(error))
      
  }, []);

  const [dataUser, setDataUser] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalVisible3, setModalVisible3] = useState(false);
  const [countryCode, setCountryCode] = useState('')
  const [country, setCountry] = useState(null)
  const [withCountryNameButton, setWithCountryNameButton] = useState(true)
  const [withFlag, setWithFlag] = useState(true)
  const [withEmoji, setWithEmoji] = useState(true)
  const [withFilter, setWithFilter] = useState(true)
  const [withAlphaFilter, setWithAlphaFilter] = useState(true)
  const [withCallingCode, setWithCallingCode] = useState(false)
  const [data, setData] = useState([]);
  console.log(dataUser)

  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);

  const [isvisible, setisvisible] = useState(false);
  var width = Dimensions.get('window').width;
  const [initValue, setinitValue] = useState('Selectionner')
  const [Portable, setPortable] = useState('');
  const [NumRue, setNumRue] = useState('');
  const [Rue, setRue] = useState('');
  const [CompAdress, setCompAdress] = useState('');
  const [CodePostal, setCodePostal] = useState('');
  const [Ville, setVille] = useState('');
  const [Pays, setPays] = useState('');
  

  const onValid =()=>{
    if(disabled){
      fetch(`${URL}json_get_react_update_infosup_user.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({iduser: iduser, phone: Portable, numrue: NumRue, rue: Rue, postal: CodePostal, ville: Ville, adressup: CompAdress, pays: Pays})
      })
        .then((response) => response.json())
        .catch((error) => console.error(error))
        .finally(() => props.navigation.navigate('Compte', {iduser, Nom: nom, Prenom: prenom, Sexe: String(sexe), Nationalite : national, Date_de_naissance: happy, Num_portable: Portable, Num_rue:NumRue, Rue:Rue, Code_postal: CodePostal, Ville: Ville}));
  
    };
      
  }

  const ChangePortable = (text) => {
    setPortable(text)
    if(text != '' && NumRue != '' && Rue !='' && CodePostal !='' && Ville !='' && Pays !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(text)
  }

  const ChangeNumRue = (text) => {
    setNumRue(text)
    if(text != '' && Portable != '' && Rue !='' && CodePostal !='' && Ville !='' && Pays !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(text)
  }

  const ChangeRue = (text) => {
    setRue(text)
    if(text != '' && Portable != '' && NumRue !='' && CodePostal !='' && Ville !='' && Pays !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(text)
  }

  const ChangeCompAdress = (text) => {
    setCompAdress(text)
  }

  const ChangeCodePostal = (text) => {
    setCodePostal(text)
    setData([])
    if(text != '' && Portable != '' && Rue !='' && NumRue !='' && Ville !='' && Pays !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(text)
  }

  let index = 0;

 const ChangePostal = () => {
   data.length = 0
  setModalVisible3(true)
  axios.get(`${URL_ville}${CodePostal}`).then(function(response){
      response.data.cities.map((item, index) => {
        data.push({ value: item.city,"label":item.city})
       // setData([...data,{ key: index++,"label":item.city}])
        console.log("data",data)
      })
     
       })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(() =>  setModalVisible3(false))
 
    }

  const ChangeVille = (option) => {
  //  setVille(option.label)
   // setinitValue(option.label)
    if(option != '' && Portable != '' && Rue !='' && NumRue !='' && CodePostal !='' && Pays !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    //console.log(text)
  }

  const onSelect = (country) => {
    setCountryCode(country.cca2)
    setPays(country.name)
    setCountry(country)
    if(country.name != '' && Portable != '' && Rue !='' && NumRue !='' && CodePostal !='' && Ville !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(country.name)
  }
  
  const ref_input2 = useRef();
  const ref_input3 = useRef();
  const ref_input4 = useRef();
  const ref_input5 = useRef();
  const ref_input6 = useRef();
  

  
  
  
        return (
          
               <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
           <View  style={{ flex:1 }}>
          <View style={{height: Platform.OS === 'web' ? 70 : 110 , backgroundColor: '#00808B', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
          <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
        <View style={styles.button}>
        <Button  buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="transparent" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>Modifier mes informations personnelles</Text>
        </View>
        <View style={styles.button}>
        <Button buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline"color="transparent" size={36} />  } />
        </View>
        </LinearGradient>
        </View>
          <ScrollView>
            <View style={styles.Container}>
             <Text style={styles.text1}>Informations personnelles 2/2</Text>
             <Text style={styles.text2}>Ces informations nous permettrons d'une part de favoriser l'organisation de certaines études et également de vous transmettre vos gains par voie postale (chèque).</Text>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Téléphone portable*</Text>
                <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Portable} onChangeText={ChangePortable} returnKeyType = { Platform . OS  ===  'ios' ? 'done' : 'next' }   keyboardType = "phone-pad"  onSubmitEditing={() => ref_input2.current.focus()} blurOnSubmit={false}/>
              </View>   
              <View style={styles.sectionContainer}>
              <Text style={styles.text3}>Votre adresse</Text>
                <Text style={styles.sectionTitle}>N° de rue*</Text>
                <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={NumRue} onChangeText={ChangeNumRue} keyboardType="numbers-and-punctuation" returnKeyType = { "next" } onSubmitEditing={() => ref_input3.current.focus()} ref={ref_input2} />
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Rue*</Text>
                <TextInput style={styles.input} placeholder="ex: rue des étoiles" placeholderTextColor="grey" value={Rue} onChangeText={ChangeRue} returnKeyType = { "next" } onSubmitEditing={() => ref_input4.current.focus()} ref={ref_input3}/>
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Complément d'adresse</Text>
                <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={CompAdress} onChangeText={ChangeCompAdress} returnKeyType = { "next" } onSubmitEditing={() => ref_input5.current.focus()} ref={ref_input4}/>
              </View>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Code postal*</Text>
                <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={CodePostal} onChangeText={ChangeCodePostal} onEndEditing={ChangePostal} keyboardType="numbers-and-punctuation" returnKeyType = { "next" } ref={ref_input5}/>
              </View>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Ville<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
                <View style={styles.ViewInput}>
                {Platform.OS == "ios" ?
               
               <RNPickerSelect
                style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
           onValueChange={(itemValue) => ChangeVille(itemValue)}
           value={initValue}
           items={data}
       /> : 
               <Picker
       selectedValue={initValue}
       style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
       onValueChange={(itemValue) => ChangeVille(itemValue)}
     >
     <Picker.Item label="Selectionner" value={null} />
       {data.map((item, i) =>{ return <Picker.Item label={item.label} value={item.value} />})}
     </Picker>}
                 </View>   
              </View>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Pays*</Text>
                <View style={styles.ViewInput}>
                          <CountryPicker 
                  {...{
                    countryCode,
                    withFilter,
                    withFlag,
                    withCountryNameButton,
                    withAlphaFilter,
                    withCallingCode,
                    withEmoji,
                    onSelect,
                    placeholder:"Selectionner",
        
                  } }

                /></View>
              </View>
            </View> 
            <ModalInfoRegister isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} />
              {Platform.OS === 'ios' ?<View style={{  height:200 }}></View>: undefined}
          </ScrollView>
          <View style={{ flexDirection: "column-reverse", height:80, backgroundColor:'#00808B'}}>
   <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,justifyContent: 'center'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
          <BoutonEtude onPress={() => props.navigation.goBack()} text={'Précédent'} textColor={'#00808B'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={onValid} text={'Valider'} textColor={'white'} boutonColor={'#FFB217'}></BoutonEtude>
        </View>
        <ModalActivityIndicator isVisible={modalVisible3} props={props}/>
</LinearGradient>
</View>  
</View>
</KeyboardAvoidingView>
          
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 22,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text2: {
        fontSize: 14,
        color: 'black',
        paddingBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      button:{
          marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      Container: {
        margin: 10,
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center',
        backgroundColor:'white'
      },

    });

export default ModifInfo2Screen;
