import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
//import DatePicker from 'react-native-datepicker';
import { isLoading } from 'expo-font';
import ModalInfoEtude from '../components/ModalInfoEtude';
import ModalQuit1 from '../components/ModalQuit1';
import { List } from 'react-native-paper';
import moment from 'moment';
import 'moment/locale/fr';

const Rdv4Screen = props => {

  const {iduser, Idetude, flat, etude, Points, Shield, Img_Packaging, rdv, heurerdv, rdvNew, heurerdvNew, Duree} = props.route.params;

  const URL = 'https://www.useradventure.net/'

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const flatlist = Number(flat) + 1;

  console.log(props)

  const valid = () => {
  fetch(`${URL}json_get_react_update_rdv.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iduser: iduser, idetude: Idetude, rdv: rdvNew, heurerdv: heurerdvNew})
  })
    .then((response) => response.json())
    .catch((error) => console.error(error))
    .finally(() =>fetch(`${URL}json_get_react_update_agenda_${heurerdv}.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ idetude: Idetude, rdv: rdv, heurerdv: '1'})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() => fetch(`${URL}json_get_react_update_agenda_${heurerdvNew}.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ idetude: Idetude, rdv: rdvNew, heurerdv: '0'})
      })
        .then((response) => response.json())
        .catch((error) => console.error(error))
        .finally(() => props.navigation.navigate('Home',{ flat: flatlist}))));

};
  
  var width = (Dimensions.get('window').width);
    
        return (
          <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 ,borderBottomColor:'#00808B',borderBottomWidth:0, backgroundColor: "#00343B", elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color='white' size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color='white' size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
          <View style={styles.Container}>
            <View style={{ justifyContent: 'center', alignItems:'center'}}>
                <Text style={styles.textT1}>Vous êtes sur le point de modifier la date et l'heure de votre test</Text>
                <View style={{  paddingHorizontal:10}}>
                <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 150,
                        width:width,
                        resizeMode: 'contain',
                      }} source={{ uri: Img_Packaging }} />
                      </View>  
                      <Text style={styles.textT1}>{heurerdvNew} le {moment(rdvNew).format('Do MMMM')}</Text>
                      <Text style={styles.textT1}>Vous êtes sûr?</Text>
                    
              </View>      
              <View style={{ alignItems:'center', marginTop:30}}>
              <Text style={styles.section3}>Valider <Text style={styles.section1}>pour confirmer votre changement</Text></Text>
              <Text style={styles.section1}>ou</Text>
              <Text style={styles.section4}>Précedent <Text style={styles.section1}>pour modifier votre horaire de test</Text></Text>
              <Text style={styles.section2}>Après confirmation du responsable du test, votre rendez-vous apparaitra dans vos missions en cours</Text>
              </View>
               </View> 
               <ModalInfoEtude isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points} shield1={Shield} Duree={Duree} etude={etude}/>
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80,borderTopColor:'#00808B',borderTopWidth:0, backgroundColor:"#00343B"}}>
        <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.goBack()} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:"#00343B", fontSize:20, fontWeight:'bold' }} >Précédent</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={valid} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Valider</Text>
        </TouchableOpacity>
        </View>
        </View>
        </View>
        );
        
        
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: 40,
        paddingHorizontal: 0,
        fontSize: 22,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10,
       paddingHorizontal:30,
       textAlign: 'center'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text11: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text111: {
        fontSize: 34,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text22: {
        fontSize: 20,
        color: 'black',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:15,
        marginBottom:0,
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text33: {
        fontSize: 16,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text4: {
        fontSize: 26,
        color: 'green',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
          marginTop: 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      inputgreen: {
        flex:8,
        height: 50,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      rondgreen: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      section1: {
        fontSize: 16,
        color: 'black',
        fontWeight:'normal',
        marginBottom:10,
        textAlign:'center'
      },
      section2: {
        fontSize: 16,
        color: 'black',
        fontWeight:'bold',
        marginVertical:20,
        textAlign:'center'
      },
      section3: {
        fontSize: 16,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section4: {
        fontSize: 16,
        color: "#00343B",
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },

    });

export default Rdv4Screen;
