import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer, useCallback } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, Text, TextInput,FlatList, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, VirtualizedList, Alert, RefreshControl} from 'react-native';
import { List } from 'react-native-paper';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox, Image as Img} from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import moment from 'moment';
import 'moment/locale/fr';
import coffreOr from "../assets/u86.png";
import coffre from "../assets/u144.png";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalRecompense from '../components/ModalRecompense';

function PointScreen(props) {

  const {majData, token, iduser, Points, Shield, item1, item2, item3, item4, item5, item6 } = props.route.params;

  const [masterDataSource, setMasterDataSource] = useState([]);
  const URL = 'https://www.useradventure.net/'
  const [modalVisible2, setModalVisible2] = useState(false);
  const [Images, setImages] = useState('');
  const [IDrecompense, setIDrecompense] = useState('');
  const [PointsObjet, setPointsObjet] = useState('');
  const [ShieldObjet, setShieldObjet] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [isLoading, setLoading] = useState(true);

  var width = Dimensions.get('window').width;

  useEffect(() => {
    MAJflatlist()

    const unsubscribe = props.navigation.addListener('focus', () => {
       
      fetch(`${URL}json_get_react_saveID.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
    .then((response) => response.json())
    .then((response) =>{
      props.navigation.setParams({Shield: response[0].Shield, Points: response[0].Points, item1: response[0].Item_1, item2: response[0].Item_2, item3: response[0].Item_3, item4: response[0].Item_4, item5: response[0].Item_5, item6: response[0].Item_6, Manualite: response[0].Manualite, Langue_maternelle: response[0].Langue_maternelle, Enfants: response[0].Enfants, Situation: response[0].Situation, Profession: response[0].Profession, Poste: response[0].Poste, Autre_info: response[0].Autre_info, Num_rue: response[0].Num_rue, Rue: response[0].Rue, Code_postal: response[0].Code_postal, Ville: response[0].Ville })
    console.log('DataUser',response) 
    fetch(`${URL}json_get_react_level.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({pointsuser: response[0].Points})
    })
      .then((response2) => response2.json())
      .then(response2 => { props.navigation.setParams({level: response2[0].Level})
      setLoading(false)
        console.log('Level',response2[0].Level)})
    })
    });

   
  
    return () => {
      unsubscribe;
    };
  
  }, [props.navigation]);

  


    const MAJflatlist = () => {
 fetch(`${URL}json_get_react_recompense.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
      .then((response) => response.json())
      .then(response => {
        console.log('response',response)
      setMasterDataSource(response)
            })
      .catch((error) => console.error(error))
          }


          const onRefresh = React.useCallback(() => {
            setRefreshing(true);
            setTimeout(() => {
              MAJflatlist()
              setRefreshing(false)}, 2000)
              
          }, []);


const ListHeader = () => {
  //View to set in Header
  return (
    <View style={{ marginHorizontal:10, justifyContent:'center', marginTop:10}}>
      <Text style={{color:'#003333', fontSize:14}}>
      Vous êtes actuellement à <Text style={{color:'#003333',fontWeight:'bold', fontSize:14}}>{Points}</Text> points et <Text style={{color:'#003333',fontWeight:'bold', fontSize:14}}>{Shield}</Text> shields, augmente ton nombre de points et de shields pour dévérouiller les récompenses!
      </Text>
    </View>
  );
};


const valid = (text) => {
  fetch(`${URL}json_get_react_input_recompense.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iduser: iduser, idObjet: text})
  })
    .then((response) => response.json())
    .then(response => {
      console.log('response',response)
      fetch(`${URL}json_get_react_input_fin_etude.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({iduser: iduser, points: Number(Points), shield: Number(Shield)-Number(ShieldObjet) })
      })
        .then((response) => response.json())
        .then(() => {
          props.navigation.setParams({Points:  Number(Points), Shield: Number(Shield)-Number(ShieldObjet)})
         
          
      MAJflatlist()
      setModalVisible2(false)
     })
          })
    .catch((error) => console.error(error))
      
}
    

  
    return (isLoading ? <ActivityIndicator/> :
      <View style={{ flex: 1,backgroundColor:'transparent'}}>
       <FlatList
          data={masterDataSource}
          backgroundColor='#DCDCDC'
          refreshControl={<RefreshControl refreshing={refreshing}  onRefresh={onRefresh} /> }  
          ListHeaderComponent={ListHeader}
          renderItem={({ item , index}) =>
          {
                     
          return( 
            <View key={index} style={{height:70, paddingHorizontal:10, marginHorizontal:10, marginTop:10, backgroundColor:'white', borderRadius:3, elevation: 10  ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 5, width:5 }}}>
            <View style={{ flexDirection: "row", flex:4, justifyContent: 'center', alignItems: "center"}}>
            <View style={{ flexDirection: "row", flex:2, justifyContent: 'flex-start', alignItems: "center"}}> 
            <View style={{ flexDirection: "row", flex:1, justifyContent: width > 1200 ? 'center' :'flex-start', alignItems: "center"}}> 
            {item.ID_Achat === null ? <Image  source={item.Shields >0 ? coffreOr : coffre}  style={{ width: 45, height: 45, margin:10, transform: [{ rotate: '-30deg' }] }}  PlaceholderContent={<ActivityIndicator />}/> :<Image  source={{uri:item.Image_item}}  style={{ width: 45, height: 45, margin:10, transform: [{ rotate: '-30deg' }] }}  PlaceholderContent={<ActivityIndicator />}/>   } 
            </View>
            {width > 1200 ? <View style={{ flexDirection: "row", flex:1, justifyContent: 'flex-start', alignItems: "center"}}/> :undefined }
            <View style={{ flexDirection: "row", flex:1, justifyContent: 'flex-start', alignItems: "center"}}> 
             {Number(item.Points)>0 ? <Text style={{color:'#003333',fontWeight:'bold', fontSize:20}}>{Number(item.Points).toLocaleString()} points</Text> : undefined}
             {Number(item.Shields)>0 ?  <View style={{flex:1, flexDirection: "row", alignItems:'flex-end' }}>
                 <Text style={{color:'#FFB217',fontWeight:'bold', fontSize:20}}>{Number(item.Shields).toLocaleString()}</Text>
                 <Image style={{height:22, width:22, marginBottom:0, marginLeft:5, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                 </View>: undefined}
             </View>
             </View>
             <View style={{ flexDirection: "row", flex:2, justifyContent: 'flex-end', alignItems: "center"}}>  
             {width > 1200 ? <View style={{ flexDirection: "row", flex:1, justifyContent: 'flex-start', alignItems: "center"}}/> :undefined }
              <View style={{ flexDirection: "row", flex:width > 1200 ? 1: 0.3, justifyContent: 'flex-start', alignItems: "center"}}/> 
             <View style={{ flexDirection: "row", flex:1, justifyContent: 'center', alignItems: "center"}}> 
             {item.ID_Achat === null ? Number(Points) >= Number(item.Points) && Number(Shield) >= Number(item.Shields)?  <TouchableOpacity onPress={()=>{setImages(item.Image_item),setIDrecompense(item.ID), setPointsObjet(item.Points), setShieldObjet(item.Shields), setModalVisible2(true)}}  style={{ flexDirection: "row",alignItems:'flex-end', justifyContent:'center', backgroundColor:item.Shields >0 ?'#FFB217':'#00808B', paddingVertical:5, borderRadius:2, width:110}}>
                 <Text style={{color:'white', fontWeight:'bold',padding:5}}>{item.Shields >0 ? 'Acheter' : 'Récupérer' }</Text>
                 </TouchableOpacity>:
                <MaterialCommunityIcons  name='lock-outline' color={'#003333'} size={44}/> : <MaterialCommunityIcons  name='check' color={'#00808B'} size={44}/> 
             }
             </View>
             </View>
             </View>
            </View>
               
          )}}
          //Setting the number of column
       
          keyExtractor={(item, index) => index}
          />
        
        <ModalRecompense isVisible={modalVisible2} onSubmit={() => valid(IDrecompense) }  onQuit={() => {setModalVisible2(false) }} isImage={Images}  props={props}/>
      </View>
    );
  }

  function ShieldScreen(props) {


  const {majData, token, iduser, Points, Shield, item1, item2, item3, item4, item5, item6 } = props.route.params;

  const URL = 'https://www.useradventure.net/'

  const [isLoading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [Totaldata, setTotaldata] = useState([]);
  const [Etat2, setEtat2] = useState(0);
  const [Don, setDon] = useState(0);
  const [Etat, setEtat] = useState(0);

  if (majData) {
    if(Etat2 != majData){
      fetch(`${URL}json_get_react_Historique_shields.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({iduser: iduser})
      })
        .then((response) => response.json())
        .then(response => {if(response.length != 0){
                            setDataSource(response);  
                             setDon(response[0].Don); 
                            console.log(response)  ;
        }
        })
        .catch((error) => console.error(error))
        .finally(() =>  fetch(`${URL}json_get_react_Total_shields.php`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',  // It can be used to overcome cors errors
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({iduser: iduser})
        })
          .then((response) => response.json())
          .then(response => {setTotaldata(response);   
            console.log(Totaldata);
          })
          .catch((error) => console.error(error))
          .finally(() => setLoading(false)))
            
                
  setEtat2(majData)
    }   
  }

  useEffect(() => {
   
    const unsubscribe = props.navigation.addListener('focus', () => {
         
      fetch(`${URL}json_get_react_saveID.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
    .then((response) => response.json())
    .then((response) =>{
      console.log('tt',response)
      props.navigation.setParams({Shield: response[0].Shield, Points: response[0].Points, item1: response[0].Item_1, item2: response[0].Item_2, item3: response[0].Item_3, item4: response[0].Item_4, item5: response[0].Item_5, item6: response[0].Item_6 })
      Historique()
      Totalshields()

    } )
    });
  
    return () => {
      unsubscribe;
    };
  
  }, [props.navigation]);

  
  
 const Historique = () => {
    //setheight_origin(Dimensions.get('window').height)
    fetch(`${URL}json_get_react_Historique_shields.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
      .then((response) => response.json())
      .then(response => {if(response.length != 0){
                        setDataSource(response);  
                        setDon(response[0].Don);
                          console.log(response)  ;
      }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const Totalshields = () => {
    fetch(`${URL}json_get_react_Total_shields.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
      .then((response) => response.json())
      .then(response => {setTotaldata(response);   
        console.log(Totaldata);
         setLoading(false)
      })
      .catch((error) => console.error(error))
     
  };


  const styles = StyleSheet.create({
    
    Title: {
      paddingTop: Platform.OS === 'web' ? 0 : 40,
      paddingHorizontal: 24,
      fontSize: 24,
      fontWeight: 'bold',
      color: 'white',
     backgroundColor:'transparent'
    },
    button:{
        marginTop: 40,
        marginLeft:10,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor:'transparent'
    },
    button1: {
        height:50,
        marginVertical:10,
        borderRadius:5,
        justifyContent: 'center',
      alignItems: "center",
      backgroundColor: '#003333',
       elevation: 10  ,
       shadowColor: 'black', 
       shadowOpacity: 0.3, 
    shadowOffset: { height: 10 }
      },
    buttonDelete1: {
      backgroundColor:'transparent'
    },
    buttonStyle: {
      backgroundColor: '#003333',
      height:50,
      margin:20
    },
    buttonStyleIOS: {
      backgroundColor: '#003333',
      height:50,
      marginLeft: -40,
      marginRight:20,
      marginVertical:20
    },
    buttonsize: {fontSize: 24, },
    textPoints: {
      paddingHorizontal:10,
      color: '#00808B',
      fontWeight:'bold',
      fontSize:20
     },
     textShield: {
      paddingHorizontal:10,
      color: '#00808B',
      fontWeight:'bold',
      fontSize:40
     },
     labelShield: {
      paddingHorizontal:10,
      color: '#00808B',
      fontWeight:'bold',
      fontSize:20,
      textAlign:'center'
     },
     totalPoints: {
      paddingHorizontal:10,
      color: '#003333',
      fontWeight:'bold',
      fontSize:26
     },
  })


      if (Shield != 0) {
        if(Etat==0){
          setEtat(1)
        }          
      }

      const backgroundColor = Etat  == 0 ? '#DCDCDC' :  '#003333';
    
        return (isLoading ? <ActivityIndicator/> :
          <View style={{ flex:1}}>
        
         <ScrollView>
          <View style={{ height: 100,flexDirection: "row", backgroundColor:'transparent',marginTop:20}}>
                <View style={{flex:1, justifyContent: 'center',alignItems: 'center'}}>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width:150, height:150, borderWidth:3, borderColor:'#FFB217', borderRadius:75, marginBottom:-65, backgroundColor:'white'}}>
                  <View style={{ height:130,justifyContent: 'center', alignItems:'center'}}>
                  <View style={{ flexDirection: "row"}}>
                 <Text style={styles.textShield}>{Shield}</Text> 
                  <Image style={{height:35, width:35, resizeMode: 'contain'}} source={require("../assets/bouclier_or.png")} /> 
                  </View>
                  </View>
                </View>
                  </View>
                 </View>
                 <View style={{flex:1, justifyContent: 'center',alignItems: 'center'}}>
                  <View style={{ flexDirection: "row"}}>
                  <View style={{ width:150, height:150, borderWidth:3, borderColor:'red', borderRadius:75, marginBottom:-65, backgroundColor:'white'}}>
                  <View style={{ height:130,justifyContent: 'center', alignItems:'center'}}>
                  <View style={{ flexDirection: "row"}}>
                  <Text style={styles.textShield}>{Don}</Text> 
                    <Image style={{height:35, width:35, resizeMode: 'contain'}} source={require("../assets/bouclier_or.png")} /> 
                  </View>
                 </View>
                 </View>
                 </View>
                 </View>
              </View>
          <View style={{ height: 100,flexDirection: "row", backgroundColor:'transparent'}}>
                <View style={{flex:1, justifyContent: 'center',alignItems: 'center', padding:10 }}>
                  <Image style={{height:'100%',width:'100%', resizeMode: 'contain'}} source={require("../assets/porte-feuille.png")}/>
                </View>
                 <View style={{flex:1, justifyContent: 'center',alignItems: 'center', padding:10 }}>
                  <Image style={{height:'100%',width:'100%', resizeMode: 'contain'}} source={require("../assets/boite-coeur.png")}/>
                </View>
              </View>
              <View style={{ flexDirection: "row", backgroundColor:'transparent', paddingBottom:15}}>
                <View style={{flex:1, justifyContent: 'center',alignItems: 'center',paddingHorizontal:30}}>
                    <Text style={styles.labelShield}>Cagnotte actuelle</Text>
                 </View>
                 <View style={{flex:1, justifyContent: 'center',alignItems: 'center',paddingHorizontal:30}}>
                    <Text style={styles.labelShield}>Dons du mois</Text>
                 </View>
              </View>
              <View style={{marginHorizontal:24 }}>
                <Text style={{marginVertical:10 }}>1 shield = 1 euro</Text>
                <Text style={{marginVertical:10 }}>Le paiement de la cagnotte est réalisé à partir de 15 euros par voie postale. Si vous n'avez pas atteint ce montant, pas de panique, il sera conservé pour le mois suivant.</Text>
                <Text style={{marginVertical:10 }}>Le montant de votre cagnotte ainsi que les dons seront payés automatiquement le 5 du mois suivant.</Text>
             
          <TouchableOpacity style={{height:50, marginVertical:10,borderRadius:5, justifyContent: 'center', alignItems: "center", backgroundColor: backgroundColor, elevation: 10, shadowColor: 'black', shadowOpacity: 0.3, shadowOffset: { height: 10 }}} onPress={() => {if(Etat  == 0) {Alert.alert("Information","Désolé, vous ne possédez pas encore de shield pour faire un don.")} else {props.navigation.navigate('Shields1', {majData: majData, iduser: iduser, Shield: Shield})}}}>
            <Text style={{  fontSize: 20,color:'white',fontWeight:'bold'}}>Faire un don</Text>
        </TouchableOpacity>
      <List.Accordion
        title="Historique des paiements et dons"
        theme={{colors: {placeholder: 'white', primary: '#00808B', underlineColor: 'transparent', background: 'transparent' } }}
       >
          <View style={{ flexDirection: "row", paddingTop: 0,height:50,  marginVertical:2}}>
              <View style={{ flex: 2, backgroundColor:'transparent', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, justifyContent: 'center', alignItems:'center'}}>
                <Text style={{color:'#FFB217', fontWeight:'bold',padding:5, fontSize:24}}>Mois</Text>
              </View>
              <View style={{flex: 2, justifyContent: 'center'}}>
                  <Text style={{color:'#FFB217', fontWeight:'bold',padding:5, fontSize:24}}>Paiement</Text>
               </View>
               <View style={{flex: 1, justifyContent: 'center'}}>
                  <Text style={{color:'#FFB217', fontWeight:'bold',padding:5, fontSize:24}}>Don</Text>
               </View>
            </View>
            {Totaldata.map((item, i) => (
            <View style={{ flexDirection: "row", paddingTop: 0,height:50, marginHorizontal:0, marginVertical:2}}>
              <View style={{ flex: 2, backgroundColor:'#003333', justifyContent: 'center', alignItems:'center'}}>
                <Text style={{color:'white', fontWeight:'bold',padding:5}}>Total</Text>
              </View>
              <View style={{flex: 2, justifyContent: 'center'}}>
                  <Text style={styles.totalPoints}>{item.TotalCash}</Text>
               </View>
               <View style={{flex: 1, justifyContent: 'center'}}>
                  <Text style={styles.totalPoints}>{item.TotalDon}</Text>
               </View>
            </View>
               ))}
         {isLoading ? <ActivityIndicator/> : (
          <FlatList
          data={dataSource}
          extraData={Etat2}
          backgroundColor='transparent'
          renderItem={({ item, index }) =>
          {
                     
          return( 
            <View key={index} style={{ flexDirection: "row", paddingTop: 0,height:50, marginHorizontal:0, marginVertical:2}}>
              <View style={{ flex: 2, backgroundColor:'#00808B', justifyContent: 'center', alignItems:'center'}}>
                <Text style={{color:'white', fontWeight:'bold',padding:5}}>{moment(item.YEAR+item.MOIS, "YYYYMM").format('MMMM YYYY')}</Text>
              </View>
              <View style={{flex: 2, justifyContent: 'center'}}>
                  <Text style={styles.textPoints}>{item.Cash}</Text>
               </View>
               <View style={{flex: 1, justifyContent: 'center'}}>
                  <Text style={styles.textPoints}>{item.Don}</Text>
               </View>
            </View>
               
        
          )}}
          //Setting the number of column
       
          keyExtractor={(item, index) => index}
          />
          )}
      </List.Accordion>
      </View>
      </ScrollView>
      </View>
        );
        
       
        }


const Tab = createMaterialTopTabNavigator();

const CashScreen =  props => {

  
  return (
    <>
    
     <View style={{flex:1,backgroundColor: 'transparent',}}>           
      
     <View style={{ height: Platform.OS === 'web' ? 70 : 110 , backgroundColor: '#00808B', elevation: 10  ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
          <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>Récompenses</Text>
        </View>
        </LinearGradient>
        </View>
      <Tab.Navigator screenOptions={{
       tabBarIndicatorStyle: { backgroundColor: '#FFB217', height:5 },
        tabBarStyle: { backgroundColor: 'transparent'},
        tabBarLabelStyle: {  color: '#003333'},
      }}
    >
        <Tab.Screen name="Shields" component={ShieldScreen} initialParams={props.route.params}  options={{
      unmountOnBlur: true}}/>
        <Tab.Screen name="Points" component={PointScreen} initialParams={props.route.params}  options={{
      unmountOnBlur: true}}/>
      </Tab.Navigator>
      </View>
   </>
  );
}
const styles = StyleSheet.create({
    
  Title: {
    paddingTop: Platform.OS === 'web' ? 0 : 40,
    paddingHorizontal: 24,
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
   backgroundColor:'transparent'
  },
  button:{
      marginTop: 40,
      marginLeft:10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:'transparent'
  },
  button1: {
      height:50,
      marginVertical:10,
      borderRadius:5,
      justifyContent: 'center',
    alignItems: "center",
    backgroundColor: '#003333',
     elevation: 10  ,
     shadowColor: 'black', 
     shadowOpacity: 0.3, 
  shadowOffset: { height: 10 }
    },
  buttonDelete1: {
    backgroundColor:'transparent'
  },
  buttonStyle: {
    backgroundColor: '#003333',
    height:50,
    margin:20
  },
  buttonStyleIOS: {
    backgroundColor: '#003333',
    height:50,
    marginLeft: -40,
    marginRight:20,
    marginVertical:20
  },
  buttonsize: {fontSize: 24, },
  textPoints: {
    paddingHorizontal:10,
    color: '#00808B',
    fontWeight:'bold',
    fontSize:20
   },
   textShield: {
    paddingHorizontal:10,
    color: '#00808B',
    fontWeight:'bold',
    fontSize:40
   },
   labelShield: {
    paddingHorizontal:10,
    color: '#00808B',
    fontWeight:'bold',
    fontSize:20,
    textAlign:'center'
   },
   totalPoints: {
    paddingHorizontal:10,
    color: '#003333',
    fontWeight:'bold',
    fontSize:26
   },
})

export default CashScreen;