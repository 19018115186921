import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal, ActivityIndicator} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const ModalActivityIndicator =  ({ isVisible, onSubmit, onQuit, props }) => { 

return(
  <Modal
  animationType="fade"
  transparent={true}
  visible={isVisible}
 
>
<View style={styles.centeredView}>
<ActivityIndicator size="large" color='#FFB217' />
</View>
</Modal>


);




}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
 
   

  });

  export default ModalActivityIndicator;