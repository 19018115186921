import React, { useState, useEffect, Component, useRef } from 'react';
import { NavigationContainer, useFocusEffect, useCallback, useIsFocused } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, ImageBackground, Platform, Animated, Alert, BackHandler, RefreshControl} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox, Image as Img} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import ModalFilterHome from '../components/ModalFilterHome';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import moment from 'moment';
import 'moment/locale/fr';
import { ProgressBar } from 'react-native-paper';
import * as NavigationBar from 'expo-navigation-bar';


Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const HomeScreen = props => {

    const {deeplink, token, flat, iduser, level, Cadeau, ImageCadeau, MSG, levelold, pointsmax, Points, Shield, Classe, Pseudo, item1, item2, item3, item4, item5, item6 } = props.route.params;

    console.log(props.route.params)
    var D1 = [];
    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification] = useState(false);
    const notificationListener = useRef();
    const responseListener = useRef();
    const [refreshing, setRefreshing] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);
    const [Etat, setEtat] = useState(0);
    const [Etat2, setEtat2] = useState(0);
    const [Etat3, setEtat3] = useState(0);
    const [Etat4, setEtat4] = useState(0);
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [dataUser, setDataUser] = useState([]);
    const [filteredDataSource, setFilteredDataSource] = useState([]);
    const [extraDataSource, setextraDataSource] = useState([]);
    const [masterDataSource, setMasterDataSource] = useState([]);
    const [isLoadingUser, setLoadingUser] = useState(true);
    const [flatlist, setflatlist] = useState(flat);
    const [height_origin, setheight_origin] = useState();
    const URL = 'https://www.useradventure.net/'
    const [count, setCount] = useState(0);
    
   
    useEffect(() => {
     
      const unsubscribe = props.navigation.addListener('focus', () => {
       
        fetch(`${URL}json_get_react_saveID.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({iduser: iduser})
      })
      .then((response) => response.json())
      .then((response) =>{setDataUser(response),
        props.navigation.setParams({Shield: response[0].Shield, Points: response[0].Points, item1: response[0].Item_1, item2: response[0].Item_2, item3: response[0].Item_3, item4: response[0].Item_4, item5: response[0].Item_5, item6: response[0].Item_6, Manualite: response[0].Manualite, Langue_maternelle: response[0].Langue_maternelle, Enfants: response[0].Enfants, Situation: response[0].Situation, Profession: response[0].Profession, Poste: response[0].Poste, Autre_info: response[0].Autre_info, Num_rue: response[0].Num_rue, Rue: response[0].Rue, Code_postal: response[0].Code_postal, Ville: response[0].Ville })
      console.log('DataUser',response) 
      fetch(`${URL}json_get_react_level.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({pointsuser: response[0].Points})
      })
        .then((response2) => response2.json())
        .then(response2 => { props.navigation.setParams({level: response2[0].Level})
          console.log('Level',response2[0].Level)})
      })
      });

     
      setheight_origin(Dimensions.get('window').height)
      if (Platform.OS === 'android') {
      NavigationBar.setBackgroundColorAsync('#DCDCDC')
      }
        MAJflatlist()


        if(deeplink){
          fetch(`${URL}json_get_react_idetude.php`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',  // It can be used to overcome cors errors
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({idetude: deeplink, iduser: iduser})
          })
            .then((response) => response.json())
            .then(response => {
              console.log('flatlist',response)  
              if(response[0].Type_Etude === "1" || response[0].Type_Etude === "2"){
               props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : response[0].ID_Etude, etude : response[0].Nom_Etude,Points : response[0].Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree, Ecomplet : response}, screen : "Etude"})
              }else if(response[0].Type_Etude === "3"){
                 props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : response[0].ID_Etude, etude : response[0].Nom_Etude,Points : response[0].Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Presentiel1"})
             }  else if(response[0].Type_Etude === "4"){
                 props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : response[0].ID_Etude, etude : response[0].Nom_Etude,Points : response[0].Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Q_3: response[0].Q_3,Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Rdv1"})
             }
             }) 
             setNotification(false)

        }



        if (Platform.OS != 'web') {
        registerForPushNotificationsAsync().then(token => setExpoPushToken(token));
  
        // This listener is fired whenever a notification is received while the app is foregrounded
        notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
          console.log('notif',notification)
          setNotification(notification);
        });
    
        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        const subscription = Notifications.addNotificationResponseReceivedListener(response => {
          const url = response.notification.request.content.data.ID_Etude;
         // alert(url)
          fetch(`${URL}json_get_react_idetude.php`, {
               method: 'post',
               headers: {
                 'Accept': 'application/json',  // It can be used to overcome cors errors
                 'Content-Type': 'application/json'
               },
               body: JSON.stringify({idetude: url, iduser: iduser})
             })
               .then((response) => response.json())
               .then(response => {
                 console.log('flatlist',response)  
                 if(response[0].Type_Etude === "1" || response[0].Type_Etude === "2"){
                  props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : response[0].ID_Etude, etude : response[0].Nom_Etude,Points : response[0].Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree, Ecomplet : response}, screen : "Etude"})
                 }else if(response[0].Type_Etude === "3"){
                    props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : response[0].ID_Etude, etude : response[0].Nom_Etude,Points : response[0].Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Presentiel1"})
                }  else if(response[0].Type_Etude === "4"){
                    props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : response[0].ID_Etude, etude : response[0].Nom_Etude,Points : response[0].Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Q_3: response[0].Q_3,Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Rdv1"})
                }
                }) 
                setNotification(false)
 
               
        });

       
    

        return () => {
          subscription.remove()
          Notifications.removeNotificationSubscription(notificationListener.current);
          unsubscribe;
        };
      }

    
    }, [props.navigation]);

      async function registerForPushNotificationsAsync() {
        let token;
        if (Constants.isDevice) {
          const { status: existingStatus } = await Notifications.getPermissionsAsync();
          let finalStatus = existingStatus;
          if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
          }
          if (finalStatus !== 'granted') {
            alert('Failed to get push token for push notification!');
            return;
          }
          token = (await Notifications.getExpoPushTokenAsync()).data;
          console.log(token);
          fetch(`${URL}json_get_react_input_EPT.php`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',  // It can be used to overcome cors errors
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({iduser: iduser, ExponentPushToken: token})
          })
            .then((response) => response.json())
        } else {
          alert('Must use physical device for Push Notifications');
        }
      
        if (Platform.OS === 'android') {
          Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
          });
        }
      
        return token;
      }

     
      const URL_items = 'https://www.useradventure.net/Items/'
      const barprog = Points == 0 ? 0 : Number(((Points)/pointsmax).toFixed(2));
      const Etude = [
      {
        "Destinataires": "",
        "ID": "1",
        "ID_Etude": "1",
        "Nom_Etude": "Finaliser mon compte",
        "Points": "100",
        "Shield": "0",
        "Type_Etude": "en ligne",
      }]
  
      if (token == '0') {
        if(Etat==0){
          setEtat(1)
        }          
      }

      const MAJflatlist = () => {
     
        fetch(`${URL}json_get_react_etude.php`, {
     method: 'post',
     headers: {
       'Accept': 'application/json',  // It can be used to overcome cors errors
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({iduser: iduser})
   })
     .then((response) => response.json())
     .then(response => {
       console.log('iduser',iduser)
       console.log('response',response)
     setMasterDataSource(response)
     setFilteredDataSource(response)
      

           })
     .catch((error) => console.error(error))
     .finally(() => setLoading(false))
   
 }
 
 const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      MAJflatlist()
      setchecked1(false), setchecked2(false),setchecked3(false), setchecked4(false), setchecked5(false)
      setRefreshing(false)}, 2000)
      
  }, []);

  if(Etat2 != flat){    
    MAJflatlist()

 setEtat2(flat)
    
  }   



const [checked1, setchecked1] = useState(false);
const [checked2, setchecked2] = useState(false);
const [checked3, setchecked3] = useState(false);
const [checked4, setchecked4] = useState(false);
const [checked5, setchecked5] = useState(false);

console.log(checked1)
console.log(checked2)

const searchFilterFunction = () => {
    var Data1;
    var Data2;
    var Data3;
    var Data4;
    var Data5;
    if (!checked1) { Data1="";}
    if (!checked2) { Data2="";}
    if (!checked3) { Data3="";}
    if (!checked4) { Data4="";}
    if (!checked5) { Data5="";}

    if (checked1) {
         Data1 = masterDataSource.filter(function (item) {
          return item.Type_Etude.indexOf("en ligne") > -1;
        });
        
      } 
      if (checked2) {  
        Data2 = masterDataSource.filter(function (item) {
          return item.Type_Etude.indexOf("en presentiel") > -1;
        });
       
      }
      if (checked3) {  
        Data3 = masterDataSource.filter(function (item) {
          return item.Type_Etude.indexOf("Rdv") > -1;
        });
       
      }
      if (checked4) {  
        Data4 = masterDataSource.filter(function (item) {
          return item.Type_Etude.indexOf("en ligne") > -1;
        });
        
      }
      if (checked5) {  
        Data5 = masterDataSource.filter(function (item) {
          return item.Type_Etude.indexOf("postule") > -1;
        });
        
      }
      setFilteredDataSource([...Data1,...Data2,...Data3,...Data4,...Data5])
       if (!checked1 && !checked2 && !checked3 && !checked4 && !checked5) {
          setFilteredDataSource(masterDataSource)
        }
        
  };

  var width = Dimensions.get('window').width;
  var height = Dimensions.get('window').height;

  var x = height - 400;

 
  const marginTop = Platform.OS === 'ios'? 6 : 8;
  const marginBottom = Platform.OS === 'ios'? 5 : 4;
  const marginBottom2 = Platform.OS != 'android'? 10 : 0;
  const backgroundColorFilter = checked1 || checked2 || checked3 || checked4 || checked5 > 0 ? "#FFB217" : "transparent";

       

    return (
     
      <View style={{ flex:1}}>  
      
        <ModalFilterHome isVisible={modalVisible2} ischecked1={checked1} ischecked2={checked2}  ischecked3={checked3} ischecked4={checked4} ischecked5={checked5} onCheck1={() => { if(!checked1) {setchecked1(true)} else {setchecked1(false)} }} onCheck2={() => { if(!checked2) {setchecked2(true)} else {setchecked2(false)} }} onCheck3={() => { if(!checked3) {setchecked3(true)} else {setchecked3(false)} }} onCheck4={() => { if(!checked4) {setchecked4(true)} else {setchecked4(false)} }} onCheck5={() => { if(!checked5) {setchecked5(true)} else {setchecked5(false)} }}  onCancel={() => { setModalVisible2(false), setchecked1(false), setchecked2(false),setchecked3(false), setchecked4(false), setchecked5(false), setFilteredDataSource(masterDataSource)}} onSubmit={() => { setModalVisible2(false),searchFilterFunction() }} props={props}/>
         <View style={{flex:Platform.OS === 'web' ? 3 : 4,  backgroundColor: 'white',marginBottom:marginBottom2}} >
           <View style={{flex:1, backgroundColor: '#00808B', elevation: 20  ,shadowColor: 'black', shadowOpacity: 0.4,  shadowRadius: 5, shadowOffset: { height: 8, width:1 }}}>
            <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, paddingTop:30 }}>
             <View style={{flex:5,flexDirection: "row", backgroundColor:'transparent'}}>
          
               {Etat  == 0 ?
            
             <View style={{flex:6, justifyContent: 'flex-end',alignItems: 'center', padding:10  }}>
             <ImageBackground style={{  width: '100%', height: '100%' }}  imageStyle={{resizeMode: 'contain' }} source={require("../assets/u62.png")}>
               <View style={{flex:1,backgroundColor:'transparent', justifyContent: 'center',alignItems: 'center', paddingLeft:15}}>
                 <Text style={styles.textStyle}>?</Text>
                 </View>
              </ImageBackground>
          
         </View>
             : 
                   <View style={{flex:6, justifyContent: 'flex-end',alignItems: 'center', padding:10 }}>
                   <ImageBackground  style={{  width: '100%', height: '100%' }}  imageStyle={{resizeMode: 'contain' }} source={{ uri: `${URL_items}${item1}.png` }}>
                   <ImageBackground style={{  width: '100%', height: '100%' }}  imageStyle={{resizeMode: 'contain' }} source={{ uri: `${URL_items}${item2}.png` }} >
                    <ImageBackground style={{  width: '100%', height: '100%' }}  imageStyle={{resizeMode: 'contain' }} source={{ uri: `${URL_items}${item3}.png` }} >
                    <ImageBackground style={{  width: '100%', height: '100%' }}  imageStyle={{resizeMode: 'contain' }} source={{ uri: `${URL_items}${item4}.png` }} >
                    <ImageBackground style={{  width: '100%', height: '100%' }}  imageStyle={{resizeMode: 'contain' }} source={{ uri: `${URL_items}${item5}.png` }} >
                    <ImageBackground style={{  width: '100%', height: '100%' }}  imageStyle={{resizeMode: 'contain' }} source={{ uri: `${URL_items}${item6}.png` }} />
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                   </ImageBackground>
                
               </View>}
               <View style={{flex:10, justifyContent:'center'}}>
               
                 <View style={{ justifyContent: 'flex-start', alignItems: 'flex-end',marginTop:0, marginRight: 10}}>
                { Platform.OS != 'web' ?
                   <TouchableOpacity onPress={() => {props.navigation.setParams({MSG: "0"}), props.navigation.navigate("Pigeon",{iduser, Pseudo, Classe, item1, item2, item3, item4, item5, item6, flat, Points, Shield})}} style={{ height:60, width: 60, borderRadius:35, backgroundColor:'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } , elevation: 10}}>
                     <View style={{ flex:1,alignItems: 'center',marginTop:0,  }}>

                       <Image style={{marginTop:2, height:70, width:50, resizeMode: 'stretch'}} source={require("../assets/pigeon_bulle.png")} />
                       {MSG == 0  ?   undefined:<View style={{position:"absolute",justifyContent: 'center',alignItems: 'center',backgroundColor:'red',height:23, width:23,borderRadius:12, bottom:-5, right:-5}}>
                         <Text style={{color:'white',fontWeight:'bold'}}>{MSG}</Text>
                       </View>  }
                       {Cadeau == 0 ? undefined:<View style={{position:"absolute", justifyContent: 'center',alignItems: 'center',backgroundColor:'#FFB217',height:23, width:23,borderRadius:12, Top:-5, right:-5}}>
                       <MaterialCommunityIcons  name='gift' color={'white'} size={15}  />
                       </View>}
                     </View>
                  </TouchableOpacity> : undefined }
                 </View>
                
                 <View style={{ flexDirection: "row", marginTop:-20}}>
                   <Text style={styles.textShield}>{Shield}</Text>
                   <Image style={{height:35, width:35, resizeMode: 'stretch', marginLeft:10}} source={require("../assets/bouclier_or.png")} /> 
                 </View>
                 <View style={{ flexDirection: "row",justifyContent: 'space-between', alignItems: 'center',marginRight:10}}>
                   <Text style={styles.levelUser}>LVL {level}</Text>
                   <Text style={{color:'white', fontWeight:'bold',fontSize:14}}>{Classe.charAt(0).toUpperCase() + Classe.substring(1).toLowerCase()} {Pseudo}</Text>
                 </View>
                 <ProgressBar progress={barprog} color={'#FFB217'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginRight:10, marginTop:5 }} />
                   <Text style={styles.pointsUser}>{Points}/{pointsmax} pts</Text>
                </View>
             </View>
             <View style={{flex:1, flexDirection: "column-reverse", }}>
             {Platform.OS != 'web' ?   <View style={{ flexDirection: "row", justifyContent: 'flex-end'}}>
               <View style={{ justifyContent: 'flex-end', marginLeft:10}}>
                 <Text style={{ color:'white', fontWeight:'bold', fontSize:26 }}>MISSIONS</Text>
               </View>
               <View style={{flex:1,  alignItems: 'flex-end'}}>
               {Etat  == 0 ? undefined :
                  <TouchableOpacity  onPress={() => { setModalVisible2(true) }} style={{ justifyContent: 'center', backgroundColor:backgroundColorFilter,  borderColor:'white', borderWidth:1, borderRadius:5, marginRight:10}}>  
                   <View style={{ flexDirection: "row", alignItems:'center', paddingHorizontal:10}}>
                      <Text style={{  paddingHorizontal: 5, fontWeight:'bold', color:'white'}}>Filtrer</Text>
                      <MaterialCommunityIcons  name='filter' color={'white'} size={24}  />
                    </View>
                 </TouchableOpacity>}
               </View>
             </View>: undefined}
             </View>
             </LinearGradient>
           </View>
         </View>
         {Etat  == 0 ?
         <>
        <View style={{flex:7,  backgroundColor:'#DCDCDC'}}>
          <FlatList
          style={{flex:2}}
           data={Etude}
           backgroundColor='transparent'
           renderItem={({ item }) => (
           <TouchableOpacity style={{ flex: 1, flexDirection: 'column', margin: 6, marginTop:marginTop, borderColor: 'pink', borderWidth: 0, borderRadius: 5, marginBottom: marginBottom,elevation: 12, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } }} onPress={() => props.navigation.navigate("Accueil", { params: {Points : item.Points, Shield : item.Shield, etude : item.Nom_Etude, iduser : iduser  }, screen : "FinalCompte1"})}>
             <View style={{ flexDirection: "row", paddingTop: 0,height:120}}>
               <View style={{ flex: 1, backgroundColor:'#003333', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, justifyContent: 'center', alignItems:'center'}}>
               {item.Type_Etude == 'en ligne' ? 
                <Image style={{height:50, width:50, resizeMode: 'contain'}} source={require("../assets/ecran_blanc.png")} />:
                <Image style={{height:50, width:50, resizeMode: 'contain'}} source={require("../assets/image_presentiel.png")} /> }
                 <Text style={{color:'white', fontWeight:'bold',padding:5}}>A réaliser</Text>
               </View>
               <View style={{flex: 2, margin:15}}>
                 <View style={{flex:1,justifyContent: 'center'}}>
                  <Text style={styles.titleGrid}>{item.Nom_Etude}</Text>
                </View>
                <View style={{ flexDirection: "row", paddingTop: 0}}>
                   <Text style={styles.textPoints}>{item.Points}</Text>
                   <Text style={styles.Points}>pts</Text>
                </View>
             </View>
             </View>          
           </TouchableOpacity>
           )}
           //Setting the number of column
        
           keyExtractor={(item, index) => index}
           /> 
            <View style={{flex:2,justifyContent: 'flex-start', alignItems: 'center',  backgroundColor:'#DCDCDC'}}>
             <MaterialCommunityIcons  name='lock-outline' color={'#003333'} size={94}  />
           <Text style={styles.lock}>Merci de réaliser votre première mission ci-dessus afin de débloquer les études disponibles et l'ensemble des fonctionnalités de l'application</Text>
          </View> 
           </View>
           </>
        :

        isLoading ? <ActivityIndicator/> : (
        <View style={{flex:7,  backgroundColor:'#DCDCDC'}}>
        {Platform.OS === 'web' ?   <View style={{ flexDirection: "row", justifyContent: 'flex-end',marginTop:5}}>
               <View style={{ justifyContent: 'flex-end', marginLeft:10}}>
                 <Text style={{ color:'#00808B', fontWeight:'bold', fontSize:26 }}>MISSIONS</Text>
               </View>
               <View style={{flex:1,  alignItems: 'flex-end'}}>
               {Etat  == 0 ? undefined :
                  <TouchableOpacity  onPress={() => { setModalVisible2(true) }} style={{ justifyContent: 'center', backgroundColor:backgroundColorFilter,  borderColor:'#00808B', borderWidth:1, borderRadius:5, marginRight:10}}>  
                   <View style={{ flexDirection: "row", alignItems:'center', paddingHorizontal:10}}>
                      <Text style={{  paddingHorizontal: 5, fontWeight:'bold', color:'#00808B'}}>Filtrer</Text>
                      <MaterialCommunityIcons  name='filter' color={'#00808B'} size={24}  />
                    </View>
                 </TouchableOpacity>}
               </View>
              
             </View>: undefined}
         <FlatList
         data={filteredDataSource}
         refreshControl={<RefreshControl refreshing={refreshing}  onRefresh={onRefresh} /> }  
         extraData={Etat2}
         backgroundColor='transparent'
         renderItem={({ item }) => 
          

         <TouchableOpacity style={{ flex: 1, flexDirection: 'column', margin: 6, marginTop:marginTop, borderColor: 'pink', borderWidth: 0, borderRadius: 5, marginBottom: marginBottom,elevation: 12, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }}} onPress={() => 
           fetch(`${URL}json_get_react_idetude.php`, {
             method: 'post',
             headers: {
               'Accept': 'application/json',  // It can be used to overcome cors errors
               'Content-Type': 'application/json'
             },
             body: JSON.stringify({idetude: item.ID_Etude, iduser: iduser})
           })
             .then((response) => response.json())
             .then(response => {setDataSource(response)
               console.log('flatlist',response)  
               if(response[0].Type_Etude === "1" || response[0].Type_Etude === "2"){
                props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : item.ID_Etude, etude : item.Nom_Etude,Points : item.Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree, Ecomplet : response}, screen : "Etude"})
               }else if(response[0].Type_Etude === "3"){
                  props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : item.ID_Etude, etude : item.Nom_Etude,Points : item.Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Presentiel1"})
              }  else if(response[0].Type_Etude === "4"){
                  props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : item.ID_Etude, etude : item.Nom_Etude,Points : item.Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Q_3: response[0].Q_3,Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Rdv1"})
              }
              })

        }>
           <View style={{ flexDirection: "row", paddingRight:10, paddingVertical:5, height:90}}>
            
             <View style={{ flex: 1,flexDirection: "row", borderTopLeftRadius: 5, borderBottomLeftRadius: 5, alignItems:'center'}}>
              <Image  source={ item.Type_Etude === '1'|| item.Type_Etude === "2" ? require("../assets/u1105.png"): require("../assets/u1111.png")}  style={{ width: 50, height: 50, margin:10, transform: [{ rotate: '-30deg' }] }}  PlaceholderContent={<ActivityIndicator />}/>     
              <View>
              <Text style={styles.titleGrid}>{item.Type_Etude === '1' ? 'QUESTIONNAIRE EN LIGNE' : item.Type_Etude === '2'? 'QUESTIONNAIRE EN LIGNE': item.Type_Etude === '3' ? 'TEST UTILISATEUR EN PRESENTIEL': undefined}</Text>
              <Text adjustsFontSizeToFit>{item.Nom_Etude}</Text>
              {item.Type_Etude === '4' ? <Text style={styles.subtitle}>Test planifié le {moment(item.Q2).format('Do/MM/YYYY')} à {item.Q3}</Text> : undefined}
              </View>
              </View>
             <View style={{flex:0.6}}></View>
             <View style={{flex:1, flexDirection: width > 600 ? "row" : "column", alignItems:'center' ,justifyContent: 'center'}}>
              { width > 600 ?  <>
              {item.Shield === '0' ?  <View style={{ flex:1}}/> :
              <View style={{flex:1, flexDirection: "row", alignItems:'flex-end' }}>
                 <Text style={styles.textShield}>{item.Shield}</Text>
                 <Image style={{height:22, width:22, marginBottom:5, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                 </View>}
                 <View style={{ flex:1, flexDirection: "row"}}>
                 <Text style={styles.textPoints}>{item.Points}</Text>
                 <Text style={styles.Points}>pts</Text>
                 </View>
                 </> :
                 <View style={{flex:1, flexDirection: "row" }}>
                 {item.Shield === '0' ? <View style={{ flex:1}}/>:
              <View style={{flex:1, flexDirection: "row", alignItems:'flex-end' }}>
                 <Text style={styles.textShield}>{item.Shield}</Text>
                 <Image style={{height:22, width:22, marginBottom:5, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                 </View>}
                 <View style={{ flex:1, flexDirection: "row", alignItems:'flex-end'}}>
                 <Text style={styles.textPoints}>{item.Points}</Text>
                 <Text style={styles.Points}>pts</Text>
                 </View>
                 </View>
                 }
                 
                 <View style={{ flex:1}}>
                 {item.Type_Etude === '4'?  <View style={{ flexDirection: "row",alignItems:'flex-end', paddingHorizontal:20, paddingVertical:5, borderRadius:2}}>
                 <MaterialCommunityIcons name="check" color='#00808B' size={36} />
                 <Text style={{color:'#00808B', fontWeight:'bold',padding:5}}>Merci d'avoir postulé!</Text>
                 </View>:
                 <View style={{ height:35, alignItems:'center' ,justifyContent: 'center', backgroundColor:item.Type_Etude === '1' || item.Type_Etude === '3'? '#FFB217' : '#00808B', paddingHorizontal:20, paddingVertical:5, borderRadius:2}}>
                 <Text style={{color:'white', fontWeight:'bold',padding:5, fontSize:12, textAlign:'center'}}>{item.Type_Etude === '1' ? 'Réaliser' : item.Type_Etude === '2'? 'Postuler': item.Type_Etude === '3' ? 'Prendre RDV': undefined}</Text>
                 </View>}
                 </View>
             
             
           </View>
           </View>          
         </TouchableOpacity>
       } 
         //Setting the number of column
      
         keyExtractor={(item, index) => index}
         /> 
         {Platform.OS === 'web' ?  <View style={{position:'absolute', bottom:20, right:20}}>
         <TouchableOpacity onPress={() => {props.navigation.setParams({MSG: "0"}), props.navigation.navigate("Pigeon",{iduser, Pseudo, Classe, item1, item2, item3, item4, item5, item6, flat, Points, Shield})}} style={{ height:60, width: 60, borderRadius:35, backgroundColor:'white' ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }, elevation: 10}}>
                     <View style={{ flex:1,alignItems: 'center',marginTop:0,  }}>

                       <Image style={{marginTop:2, height:70, width:50, resizeMode: 'stretch'}} source={require("../assets/pigeon_bulle.png")} />
                       {MSG == 0  ?   undefined:<View style={{position:"absolute",justifyContent: 'center',alignItems: 'center',backgroundColor:'red',height:23, width:23,borderRadius:12, bottom:-5, right:-5}}>
                         <Text style={{color:'white',fontWeight:'bold'}}>{MSG}</Text>
                       </View>  }
                       {Cadeau == 0 ? undefined:<View style={{position:"absolute", justifyContent: 'center',alignItems: 'center',backgroundColor:'#FFB217',height:23, width:23,borderRadius:12, Top:-5, right:-5}}>
                       <MaterialCommunityIcons  name='gift' color={'white'} size={15}  />
                       </View>}
                     </View>
                  </TouchableOpacity>
        </View>: undefined}
         </View>  
        )}   
     
     </View>
   );
 }

 const styles = StyleSheet.create({
   container: {
       flex: 1,
       justifyContent: 'center',
       backgroundColor:'#DCDCDC'
     },
     containerIOS: {
       flex: 1,
       justifyContent: 'center',
       backgroundColor: 'white',
     },
   imageThumbnail: {
       justifyContent: 'center',
       alignItems: 'center',
       height: 120,
       margin: 0 ,
     },
     imageRelative: {
       height: 300,
       width: '100%',
       resizeMode: 'contain',
   
     },
     textShield: {
      color: '#FFB217',
      fontWeight:'bold',
      fontSize:30
     },
     textPoints: {
       color: '#00808B',
       fontWeight:'bold',
       fontSize:30
      },
      levelUser: {
       color: 'white',
       fontWeight:'bold',
       fontSize:24,
      },
      pointsUser: {
       paddingRight: 10,
       paddingVertical:7,
       color: 'white',
       fontWeight:'bold',
       fontSize:24
      },
      Points: {
       paddingTop: 15,
       color: '#00808B',
       fontWeight:'bold',
       fontSize:14
      },
      lock: {
       paddingTop: 15,
       color: '#003333',
       fontSize:17,
       paddingHorizontal:20,
       textAlign:'center',
      },
     titleGrid: {
      fontWeight:"bold",
      fontSize: Math.max(14,10)
      },
      tinyLogo: {
       width: 20,
       height: 20,
     },
     Title: {
       paddingTop: 40,
       paddingHorizontal: 10,
       fontSize: 24,
       fontWeight: '600',
       color: 'black',
      backgroundColor:'transparent'
     },
     button:{
       justifyContent: 'flex-end',
       alignItems: 'flex-end',
       backgroundColor:'transparent'
     },
     buttonDelete1: {
       backgroundColor:'transparent'
     },
     textStyle: {
       color: 'white',
       textAlign: 'center',
       fontSize: 70
        },
        subtitle: {
         color: '#00808B',
         fontSize: 14
          },

 });


export default HomeScreen
