import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const ModalInfoEtude =  ({ isVisible, onSubmit, Point1, shield1, etude, Duree }) => { 

return(

            <Modal
        animationType="fade"
        transparent={true}
        visible={isVisible}
    
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={{flexDirection: "row", alignItems:'center', marginBottom:10}}>
            <MaterialCommunityIcons name="information-outline" color='#00808B' size={46} />
            <Text style={styles.textStyle1}>Information</Text>
            </View>
            <Text style={styles.modalText}>L'étude que vous réalisez actuellement porte sur le <Text style={styles.modalTextbold}>{etude}</Text>. Nous vous demandons répondre à l'ensemble des questions en fonction de votre propre avis (il n'y a pas de bonnes ou de mauvaises réponses).</Text>
            <Text style={styles.modalText}>Durée: Environ <Text style={styles.modalTextbold}>{Duree} Minutes</Text></Text>
            <Text style={styles.modalText}>Votre gain : <Text style={styles.modalTextbold}>{shield1} Shields</Text> et <Text style={styles.modalTextbold}>{Point1} Points</Text></Text>
            <TouchableOpacity
              style={{ backgroundColor: "transparent" }}
              onPress={(value) => onSubmit(value)}
            >
              <Text style={styles.textStyle}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      

            );




}

const styles = StyleSheet.create({
    
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 0,
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 5,
      padding: 35,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5
     },
    textStyle: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    fontSize: 20
     },
     textStyle1: {
      color: '#00808B',
      fontWeight: "bold",
      textAlign: "right",
      marginHorizontal:10,
      fontSize: 24
       },
     modalText: {
     marginBottom: 15
     },
     modalTextbold: {
      marginBottom: 15,
      fontWeight:'bold'
      },
    
  

  });

  export default ModalInfoEtude;