import React, { useState, useEffect, Component } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform,Modal, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalInfo1 from '../components/ModalInfo1';
import ModalQuit1 from '../components/ModalQuit1';

const FinalCompte1Screen = props => {

  const { iduser, Points, Shield, etude } = props.route.params;

  console.log(props.route.params)

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);

  var width = Dimensions.get('window').width;
  
 
        return (
            <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center' , backgroundColor: '#003333', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={30} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={30} />  } />
        </View>
        </View>
        </View>
          
          <ScrollView>
              <View style={styles.body}>
                <Text style={styles.TitleScroll}>Quel aventurier êtes vous ?</Text>
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                  <ImageBackground style={{ height:150, width:100, resizeMode: 'stretch',}} source={require("../assets/bonhomme_jaune.png")} >
                  <Text style={styles.textStyle}>?</Text>
                  </ImageBackground>
                </View>
                <Text style={styles.text3}>Merci d'avoir rejoint nos rangs pour cette belle aventure :)</Text>
                <Text style={styles.text3}>Nous avons besoins de plus d'informations sur vous afin de vous inscrire à des études correspondants à votre profil.</Text>
                <Text style={styles.text3}>Merci de renseiger ce premier questionnaire.</Text>
                <Text style={styles.text3}>Bonus: ça vous permettra de vous familiariser avec notre questionnaire en ligne, elle est pas belle la vie ?</Text>
                <Text style={styles.text1}>Durée :</Text>
                <Text style={styles.text2}>Environ 5 minutes</Text>
                <Text style={styles.text1}>Votre récompense :</Text>
                <Text style={styles.text2}>+ {Points} pts</Text>
              </View>
              <ModalInfo1 isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
          <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} Point1={Points} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#003333'}}>
<View style={{ flexDirection: "row",justifyContent: 'center',marginHorizontal:10, }}>
       
        <TouchableOpacity onPress={() => props.navigation.navigate("FinalCompte2", {iduser, Points, Shield, etude})} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Commencer</Text>
        </TouchableOpacity>
        </View>
</View>
            </View>
        );
        
      
        }

    const styles = StyleSheet.create({
      body: {
        flex:1,
        marginHorizontal:20,
      },    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      TitleScroll: {
        paddingTop: 20,
        paddingBottom:10,
        paddingHorizontal: 0,
        fontSize: 28,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
      },
      modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 5,
        padding: 35,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
       },
      textStyle: {
      color: 'white',
      paddingTop:10,
      paddingLeft:10,
      textAlign: 'center',
      fontSize: 80
       },
       textStyle1: {
        color: '#00808B',
        fontWeight: "bold",
        textAlign: "right",
        marginHorizontal:10,
        fontSize: 24
         },
       modalText: {
       marginBottom: 15
       },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 20,
        color: 'black',
        marginVertical:5,
       backgroundColor:'transparent'

      },
      text3: {
        fontSize: 16,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        margin: 10,
      },
     

    });

export default FinalCompte1Screen;
