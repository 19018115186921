import React, { useState, useEffect, Component, useRef, useCallback } from 'react';
import { StyleSheet, View, Text, TextInput, Image, TouchableOpacity, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView, Alert} from 'react-native';
import {Button, CheckBox, Slider, Image as Img} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from '../components/react-native-simple-radio-button-max';
import ModalInfoEtude from '../components/ModalInfoEtude';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar } from 'react-native-paper';
import BoutonEtude from '../components/BoutonEtude';
import { WebView } from 'react-native-webview';
import { WebView as WebView1 } from 'react-native-web-webview';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import { DraxProvider, DraxView ,DraxList} from 'react-native-drax';

const EtudeScreen = props => {

  const {flat, iduser, PointsUser, ShieldUser, Idetude, Points, Shield, etude, num_Q, Q, Q_1, Q_2, Q_3, Q_4, Q_5, Q_6, Q_7, Q_8, Q_9, Q_10, Q_type, Img_Packaging, Q_description, Duree, Ecomplet} = props.route.params;

  
  const URL = 'https://www.useradventure.net/'
  const [source, setSource] = useState();
  const [database, setDatabase] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [Etat, setEtat] = useState(0);
  const [Load, setLoad] = useState(true);
  const [H, setH] = useState(1);
  const [I, setI] = useState(250);
  const [value11, setValue11] = useState(50);
  const flatlist = Number(flat) + 1;
  const totalPoints = Number(PointsUser) + Number(Points);
  const totalShield = Number(ShieldUser) + Number(Shield);
  const [level, setlevel] = useState('');
  const [levelold, setlevelold] = useState('');
  const [pointsmax, setPointsmax] = useState('');
  const [Cadeau, setCadeau] = useState("0");
  const [ImageCadeau, setImageCadeau] = useState('');
  const [valueBplus, setValueBplus] = useState(-1);
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
  const [type, setType] = useState()
  const [Img, setImg] = useState();
  const [Data, setData] = useState();
  const [question, setQuestion] = useState();
  const [Numero, setNumero] = useState(0);
  const [Save, setSave] = useState([]);
  const [ordre, setordre] = useState();
  const [Mail1, setMail1] = useState('');
  const [Mail2, setMail2] = useState('');
  const [Mail3, setMail3] = useState('');


  useEffect(() => {
   console.log('Ecomplet',Ecomplet)
   setSource(Ecomplet)
   setImg(Img_Packaging)
   setData(Q_1)
   setType(Q_type)
   setQuestion(Q)
   setLoading(true)
    }, []);

  
 
  if(Numero  == Ecomplet.length - 1 ){

  fetch(`${URL}json_get_react_level.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({pointsuser: PointsUser})
  })
    .then((response2) => response2.json())
    .then(response2 => {setlevelold(response2[0].Level)
      console.log('Level',response2[0].Level)
  
  fetch(`${URL}json_get_react_level.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({pointsuser: totalPoints})
    })
      .then((response) => response.json())
      .then(response => {setlevel(response[0].Level)
                         setPointsmax(response[0].pts_max)
                         setImageCadeau(response[0].Cadeau)
                         if(response2[0].Level != response[0].Level) {
                           if (response[0].Cadeau != ''){
                           setCadeau(1)
                        
                              }
                           }  })  })


                          }

  


//variables slider
const changeMoins = () => {
  if(value11 > 0){
setValue11(value11 - 1)
  }
  setdisabledcolor('#00808B')
    setdisabled(true)
}

const changePlus = () => {
  if(value11 < 100){
    setValue11(value11 + 1)
  }
  setdisabledcolor('#00808B')
    setdisabled(true)
  }

   
                        

//variables positif
  const [P1, setP1] = useState('');
  const [P2, setP2] = useState('');
  const [P3, setP3] = useState('');

  const [OpenP2, setOpenP2] = useState(false);
  const [OpenP3, setOpenP3] = useState(false);

  const changeP1 = (text) => {
    setP1(text)
    setOpenP2(true)
    if(Number(text.length)+Number(P2.length)+Number(P3.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

  const changeP2 = (text) => {
    setP2(text)
    setOpenP3(true)
    if(Number(text.length)+Number(P1.length)+Number(P3.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

  const changeP3 = (text) => {
    setP3(text)
    if(Number(text.length)+Number(P2.length)+Number(P1.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

//variables négatif
  const [N1, setN1] = useState('');
  const [N2, setN2] = useState('');
  const [N3, setN3] = useState('');

  const [OpenN2, setOpenN2] = useState(false);
  const [OpenN3, setOpenN3] = useState(false);

  const changeN1 = (text) => {
    setN1(text)
    setOpenN2(true)
    if(Number(text.length)+Number(N2.length)+Number(N3.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

  const changeN2 = (text) => {
    setN2(text)
    setOpenN3(true)
    if(Number(text.length)+Number(N1.length)+Number(N3.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

  const changeN3 = (text) => {
    setN3(text)
    if(Number(text.length)+Number(N2.length)+Number(N1.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}
  
  //variables bascule
  const [value, setValue] = useState(-1);

  const ChangeBascule = (text) => {
    setValue(text)
   if(text != -1){
     setdisabledcolor('#00808B')
     setdisabled(true)
   } else {
     setdisabledcolor('#DCDCDC')
     setdisabled(false)
   }
 }

 const ChangeBasculeplus = (text) => {
  setValueBplus(text)
  console.log('value',text)
 if(text != -1){
   setdisabledcolor('#00808B')
   setdisabled(true)
 } else {
   setdisabledcolor('#DCDCDC')
   setdisabled(false)
 }
}

    
  
  const onValide = () => {
if(Numero == 0){
    setData(source[Numero+1].Q_1)
   setQuestion(source[Numero+1].Q)
   setType(source[Numero+1].Q_type)
   setImg(source[Numero+1].Img_Packaging)
   setNumero(Numero+1)
   setdisabledcolor('#DCDCDC')
    setdisabled(false)}else{
   
    if(disabled){
      setdisabled(false)
      setdisabledcolor('#DCDCDC')
      if (bouton != "Valider"){
      var data =''
      if (type == "1") {
        var payload = {
         "iduser": iduser,
       "idetude": Idetude,
       "numR": Numero,
       "etape": 0,
       "r1": Nom,
        };
        data =  payload  ;
        }else if(type == "2")  {
         var payload = {
           "iduser": iduser,
           "idetude": Idetude,
           "numR": Numero,
           "etape": 0,
           "r1": valueBplus,
          };
          data =  payload  ;
        }else if(type == "3")  {
          var payload = {
            "iduser": iduser,
            "idetude": Idetude,
            "numR": Numero,
            "etape": 0,
            "r1":  JSON.stringify(database)
           };
           data = payload  ;
        }else if(type == "4")  {
         var payload = {
           "iduser": iduser,
           "idetude": Idetude,
           "numR": Numero,
           "etape": 0,
           "r1": '',
          };
          data =  payload ;
        }else if(type == "5")  {
          var payload = {
            "iduser": iduser,
            "idetude": Idetude,
            "numR": Numero,
            "etape": 0,
            "r1":  JSON.stringify(Data)
           };
           data = payload  ;
          }else if(type == "6")  {
            var payload = {
              "iduser": iduser,
              "idetude": Idetude,
              "numR": Numero,
              "etape": 0,
              "r1": Mail1,
              "r2": Mail2,
              "r3": Mail3,
             };
             data = payload  ;
            }
      console.log(source.length)
  
       
     
    var parse_obj = [...Save]
    parse_obj.splice(Numero, 1, data);
  
   console.log('str',parse_obj)
if(source.length != Numero + 1){

   setData(source[Numero+1].Q_1)
   setQuestion(source[Numero+1].Q)
   setType(source[Numero+1].Q_type)
   setImg(source[Numero+1].Img_Packaging)
   setNumero(Numero+1)
   setValue11(50);
   setValueBplus(-1);
   setNom('')
   setSave(parse_obj)
   setdisabledcolor('#DCDCDC')
    setdisabled(false)
    }else{
      setSave(parse_obj)
      setNumero(Numero+1)
      setdisabledcolor('#00808B')
      setdisabled(true)

  }  }else{
      if(Cadeau == 1){
        const message = `
  J'ai un nouveau cadeau pour vous.
  Encore merci pour votre aide`

        fetch(`${URL}json_get_react_input_cadeau.php`, {
                                method: 'post',
                                headers: {
                                  'Accept': 'application/json',  // It can be used to overcome cors errors
                                  'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({iduser: iduser, message: message, type: 'cadeau', image: ImageCadeau, qui: 'P'})
                              })
                                .then((response) => response.json())
                                .catch((error) => console.error(error))
      }
      fetch(`${URL}json_get_react_input_resultat_jsonformat.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Save)
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() =>
      fetch(`${URL}json_get_react_input_fin_etude.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser, points: totalPoints, shield: totalShield })
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() => props.navigation.navigate('Home',{flat: flatlist, Points : totalPoints, Shield: totalShield, level: level, pointsmax: pointsmax, ImageCadeau: ImageCadeau, Cadeau: Cadeau}),props.navigation.navigate('Don',{ params: { Shield: totalShield, level: level, pointsmax: pointsmax}, screen : "Cash"}),props.navigation.navigate('Scanner',{ params: { Points : totalPoints,Shield: totalShield, level: level, pointsmax: pointsmax}, screen : "Scan"}),props.navigation.navigate('Profil', {params:{ level: level },screen:'Compte'}))
        
      )} 
        
  }
      
    
  }
}


const onPrevious = () => {

  console.log(source.length)
 setData(source[Numero-1].Q_1)
 setQuestion(source[Numero-1].Q)
 setType(source[Numero-1].Q_type)
 setImg(source[Numero-1].Img_Packaging)
  setNumero(Numero-1)
  setNom(Save[Numero-2].r1)
  setValueBplus(Save[Numero-2].r1)
  setValue11(Save[Numero-2].r1);

  setdisabledcolor('#00808B')
  setdisabled(true)
}

const toggle = (item,index) => {
  let alReadyCar = false;
  const newData = Data;
  console.log(newData)
  newData.forEach(items => {
    if( item.checked == true)
    {
        item.checked = false;
        alReadyCar = true
    }
})

 if(!alReadyCar){
   item.checked = true;
    
}
 
 setData(newData)
  const database = [];
  console.log('data',newData.filter(x => x.checked).length)
  newData.forEach(function(item, index, array) {
   
    if(item.checked){
      database.push({"checked":index})
    }
    console.log('database',database)
    setDatabase(newData.filter(x => x.checked))
  })

  if(newData.filter(x => x.checked).length != 0){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
  
};

  const [Nom, setNom] = useState('');
  const ChangeNom = (text) => {
   setNom(text)
    if(text != ''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
  }

  const webview = useRef();

  const handleWebViewNavigationStateChange = (newNavState) => {
    
    const { url } = newNavState;
    console.log('url',url)
    if (!url) return;

   
    if (url.includes(Data[0].label)) {
      setdisabledcolor('#00808B')
      setdisabled(true)
    }

  };

  const [src, setSrc] = useState();

  
  const hoverHandler = () => {
    console.log("onMouseEnter")
  }
  const outHandler = (obj) => {
    
  console.log(document.getElementsByTagName('iframe')[0].src)
  }

  const backgroundColor = type == 0 ? "#00343B" : "#FFB217";
  var width = Dimensions.get('window').width;
  var heightWebView = Dimensions.get('window').height - 190;
  const barprog = (Numero/Ecomplet.length).toFixed(2);
  const bouton = Numero  == Ecomplet.length ? "Valider" : "Suivant";
  const injectedJS = `const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); true`;
 

  const ref_input2 = useRef();
const ref_input3 = useRef();
const ref_input4 = useRef();

  const [icon1, seticon1] = useState('close-circle');
  const [colorIcon1, setcolorIcon1] = useState('transparent');
  const [icon2, seticon2] = useState('close-circle');
  const [colorIcon2, setcolorIcon2] = useState('transparent');
  const [icon3, seticon3] = useState('close-circle');
  const [colorIcon3, setcolorIcon3] = useState('transparent');

  const verifMail1 = (text) => {
    setMail1(text)
    var position = text.indexOf("@");
    var reste = text.substr(-position);
    var posit = reste.indexOf(".");
    //alert(posit)
    if(position !== -1 && posit !== -1 ){
     seticon1('check-circle');
    setcolorIcon1('green')
   } else {
     seticon1('close-circle');
    setcolorIcon1('red');
   }
   if(position !== -1 && posit !== -1 && colorIcon2 == 'green' && colorIcon3 == 'green'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
   };
  
   const verifMail2 = (text) => {
    setMail2(text)
    var position = text.indexOf("@");
    var reste = text.substr(-position);
    var posit = reste.indexOf(".");
    //alert(posit)
    if(position !== -1 && posit !== -1 ){
     seticon2('check-circle');
    setcolorIcon2('green')
   } else {
     seticon2('close-circle');
    setcolorIcon2('red');
   }
   if(position !== -1 && posit !== -1 && colorIcon1 == 'green' && colorIcon3 == 'green'){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
   };
  
   const verifMail3 = (text) => {
    setMail3(text)
    var position = text.indexOf("@");
    var reste = text.substr(-position);
    var posit = reste.indexOf(".");
    //alert(posit)
    if(position !== -1 && posit !== -1 ){
     seticon3('check-circle');
    setcolorIcon3('green')
   } else {
     seticon3('close-circle');
    setcolorIcon3('red');
   }
   if(position !== -1 && posit !== -1 && colorIcon2 == 'green' && colorIcon1 == 'green'){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
   };

        return (
          isLoading && (<>
          <View style={{ flex:1}}>
                <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      style={{flex:1}}
    >
         <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center', backgroundColor: backgroundColor, elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.3,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title} numberOfLines={1}>{etude}</Text>
        {type == "0" ? undefined : 
        <ProgressBar progress={barprog} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginRight:0, marginTop:5 }} />
          }
        </View>
        <View style={styles.button}>
        <Button onPress={() => {setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
         {
          Numero  == Ecomplet.length  ?   
          <View style={styles.Container}>
          <View style={{ justifyContent: 'center', alignItems:'center'}}>
              <Text style={styles.textT1}>Félicitation</Text>
              <Text style={styles.text3}>Vous venez de gagner</Text>
              <View style={{ width:250, height:250, borderWidth:3, borderColor:'#FFB217', borderRadius:125, marginBottom:-65, backgroundColor:'white'}}>
                <View style={{ height:190,justifyContent: 'center', alignItems:'center'}}>
                {Shield == 0 ? undefined : 
                <View style={{ flexDirection: "row", marginVertical:5}}>
                    <Text style={styles.text111}>+{Shield} </Text>
                    <Image style={{height:50, width:50, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                  </View>}
                  <Text style={styles.text111}>+{Points} pts</Text>
                </View>
              </View>
              <Image style={{ height:90,width:'100%', resizeMode: 'contain'}} source={require("../assets/coffre.png")} />
            </View>      
            <View style={{ alignItems:'center', marginTop:30}}>
            <Text style={styles.section3}>Valider <Text style={styles.section1}>pour finaliser le questionnaire et revenir au tableau de bord</Text></Text>
            <Text style={styles.section1}>ou</Text>
            <Text style={styles.section4}>Précedent <Text style={styles.section1}>pour modifier vos réponses</Text></Text>
            <Text style={styles.section2}>Attention, une fois le questionnaire validé, vous ne pourrez plus modifier vos réponses.</Text>
            </View>
             </View> 
             :
            
         ( () => {  
                   switch (type) { //Introduction
                    case '0':return (
                      <>
                     <View style={{ margin:10}}>
                      <Text style={styles.text1}>{question}</Text>
                      {/* <Text style={styles.text3}>{Q_1}</Text> */}
                      <View style={{  paddingHorizontal:10}}>
                      { Img =="" ? undefined:
                      <>
                       <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: H,
                        width:'100%',
                        resizeMode: 'contain',
                        marginBottom:10
                      }} 
                      source={{ uri: Img }}
                      onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                      />  
                      <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: I}}>
                          <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                        </View>
                      </>
                      }
                      </View>  
                       <Text style={styles.text33}>{Q_description}</Text>
                      <Text style={styles.text1}>Durée :</Text>
                      <View style={{ marginVertical:5}}>
                      <Text style={styles.text11}>Environ {Duree} minutes</Text>
                      </View>
                      <Text style={styles.text1}>Votre récompense :</Text>
                      <View style={{ flexDirection: "row", marginTop:5}}>
                    <Text style={styles.text11}>+{Shield} </Text>
                    <Image style={{height:35, width:35, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                      <Text style={styles.text11}>   +{Points} pts</Text>
                  </View> 
                    
                      </View>
                      </>
                    )
                    case '1':return ( // question ouverte
                      <View style={{ margin:10}}>
                    <Text style={styles.text1}>{Numero}. {question}</Text>
                    { Img =="" ? undefined:
                      <>
                       <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: H,
                        width:'100%',
                        resizeMode: 'contain',
                        marginBottom:10
                      }} 
                      source={{ uri: Img }}
                      onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                      />  
                      <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: I}}>
                          <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                        </View>
                      </>
                      }
                      <TextInput style={styles.input} placeholder="Entrez votre réponse" placeholderTextColor="grey" value={Nom} onChangeText={(text) => ChangeNom(text)} />
                     </View> 
                    )
                    case "2":   return (  // radio
                      <View style={{ margin:10}}>
                      <Text style={styles.text1}>{Numero}. {question}</Text>
                      { Img =="" ? undefined:
                      <>
                       <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: H,
                        width:'100%',
                        resizeMode: 'contain',
                        marginBottom:10
                      }} 
                      source={{ uri: Img }}
                      onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                      />  
                      <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: I}}>
                          <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                        </View>
                      </>
                      }
                     
                      {
                        Data.map((item, i) => (
                          <TouchableOpacity onPress={()=>{ChangeBasculeplus(i)}}>
                          <View style={{flexDirection:'row', alignItems:'center', height:50, marginHorizontal:0}}>
            
                          <RadioButton labelHorizontal={true} key={i} >
                          
                            <RadioButtonInput
                              obj={item}
                              index={i}
                              isSelected={valueBplus === i}
                              onPress={()=>{ChangeBasculeplus(i)}}
                              borderWidth={1}
                              buttonInnerColor={'#00808B'}
                              buttonOuterColor={'#00808B'}
                              buttonSize={20}
                              buttonOuterSize={30}
                              buttonStyle={{}}
                              buttonWrapStyle={{marginLeft: 0, paddingVertical:0}}
                            />
                           
                          </RadioButton>
                          <Text style={{ fontSize: 22, color:'#303030', marginBottom:5, marginStart:10}}>{item.label}</Text>
                            </View>
                          </TouchableOpacity>
                        ))
                      }   
                    
                      </View>
                    )
                    case "3": return ( // checkbox
                      <View style={{ margin:10}}>
                      <Text style={styles.text1}>{Numero}. {question}</Text>
                      { Img =="" ? undefined:
                      <>
                       <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: H,
                        width:'100%',
                        resizeMode: 'contain',
                        marginBottom:10
                      }} 
                      source={{ uri: Img }}
                      onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                      />  
                      <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: I}}>
                          <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                        </View>
                      </>
                      }
                       { Data.map((item, i) => (
                       
                         <CheckBox
                          title={item.label}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={item.checked}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => toggle(item, i)}
                        />
                         
                       ))}
                        </View>
                        );
                        case "4": return (// webview
                          Platform.OS != 'web' ?
                          <View style={{height: heightWebView}}>
                        
        <WebView
            ref={webview}
            style={styles.webview}
            source={{ uri: question }}
            injectedJavaScript={injectedJS}
            onNavigationStateChange={(newNavState)=>handleWebViewNavigationStateChange(newNavState)}
            />
      </View> :  
      <View style={{height: heightWebView}}>
      <WebView1
            ref={webview}
            style={styles.webview}
            source={{ uri: question }}
            injectedJavaScript={injectedJS}
            onMessage={(newNavState)=>handleWebViewNavigationStateChange(newNavState)}
            onNavigationStateChange={(newNavState)=>handleWebViewNavigationStateChange(newNavState)}
            />
      </View>
     

     
                        );
                        case "5":  return (  <>
                    <View style={{ margin:10}}>
                    <Text style={styles.text1}>{Numero}. {question}</Text>
                    {/* { Q_1  ? <Text style={styles.text1}>{Q_1}</Text> : undefined } */}
                      { Img =="" ? undefined:
                      <>
                       <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: H,
                        width:'100%',
                        resizeMode: 'contain',
                      }} 
                      source={{ uri: Img }}
                      onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                      />  
                      <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: I}}>
                          <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                        </View>
                      </>
                      }
                      <DraxProvider>
                        <View style={styles.container}>
                          <DraxList
                            data={Data}
                            renderItemContent={({ item }) => (
                              <View style={styles.alphaItem}>
                                <Text style={styles.alphaText}>{item.label}</Text>
                              </View>
                            )}
                            //reorderable={Etat}
                            onItemDragStart={() => {
                                  console.log(`hello`);
                                  
                              }}

                              onItemDragEnd={() => {
                                  console.log(`fin`);
                              }}
                              onItemDragPositionChange={() => {
                                  console.log(`change`);
                                  setdisabledcolor('#00808B')
                                  setdisabled(true)
                                  
                              }}

                            onItemReorder={({ fromIndex, toIndex }) => {
 
                              const newData = Data.slice();
                              newData.splice(toIndex, 0, newData.splice(fromIndex, 1)[0]);
                              setData(newData);
                              setordre(!ordre)
                              console.log(newData)
                            }}
                            keyExtractor={(item, index) => item.key}
                          />
                        </View>
                      </DraxProvider>
                        </View>
                      </>
                      );

                case "6":  return (  <>
                     
                     <View style={{ margin:10}}>
                   <Text style={styles.text1}>Invitation 1</Text>
                   <Text style={styles.text222}>{Q_1}</Text>
                   <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                   <TextInput style={styles.input_invit} placeholder="Mail1@pigeon.com" placeholderTextColor="grey" value={Mail1} keyboardType={'email-address'} onChangeText={(text) => verifMail1(text)} returnKeyType = { "next" } onSubmitEditing={() => ref_input2.current.focus()} blurOnSubmit={false}/>
                   <MaterialCommunityIcons   name={icon1} size={40} color={colorIcon1}/>
                   </View>
                   <Text style={styles.text1}>Invitation 2</Text>
                   <Text style={styles.text222}>{Q_2}</Text>
                   <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                   <TextInput style={styles.input_invit} placeholder="Mail2@pigeon.com" placeholderTextColor="grey" value={Mail2} keyboardType={'email-address'} onChangeText={(text) => verifMail2(text)} returnKeyType = { "next" } onSubmitEditing={() => ref_input3.current.focus()} ref={ref_input2}/>
                   <MaterialCommunityIcons   name={icon2} size={40} color={colorIcon2}/>
                   </View>
                   <Text style={styles.text1}>Invitation 3</Text>
                   <Text style={styles.text222}>{Q_3}</Text>
                   <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                   <TextInput style={styles.input_invit} placeholder="Mail3@pigeon.com" placeholderTextColor="grey" value={Mail3} keyboardType={'email-address'} onChangeText={(text) => verifMail3(text)}     ref={ref_input3}/>
                   <MaterialCommunityIcons   name={icon3} size={40} color={colorIcon3}/>
                   </View>
                  </View> 
                  
                   </>
                   );
              
                    }})()}
          </ScrollView>
          <ModalInfoEtude isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points} shield1={Shield} Duree={Duree} etude={etude}/>
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {props.navigation.navigate('Home')}} props={props}/>
          {type == 0 ?   
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:backgroundColor}}>
        <View style={{ flexDirection: "row",justifyContent: 'center',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => onValide()} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: "#FFB217",shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Commencer</Text>
        </TouchableOpacity>
        </View>
        </View> 
        : 
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:backgroundColor}}>
        <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <BoutonEtude onPress={() => {onPrevious()}} text={'Précédent'} textColor={'#FFB217'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={()=> {onValide()}}   text={bouton} textColor={'white'} boutonColor={disabledcolor}></BoutonEtude>
        </View>
        </View>
        }
        </KeyboardAvoidingView>
        </View>
        </>)
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 22,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      container: {
        flex: 1,
        justifyContent: 'center',
        paddingTop: Constants.statusBarHeight,
        backgroundColor: '#ecf0f1',
        padding: 8,
      },
      webview: {
        flex: 1
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 22,
        marginTop: 5,
        marginBottom: 15,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text11: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text111: {
        fontSize: 34,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text22: {
        fontSize: 20,
        color: 'black',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text222: {
        fontSize: 20,
        color: 'black',
       backgroundColor:'transparent',
       paddingTop:10
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text4: {
        fontSize: 26,
        color: 'green',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      input_invit: {
        flex: 9,
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        backgroundColor:'white'
      },
      Container: {
        margin: 10,
      },
      inputgreen: {
        flex:8,
        height: 50,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      rondgreen: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
        backgroundColor:'white'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
        backgroundColor:'white'
      },
      section1: {
        fontSize: 16,
        color: 'black',
        fontWeight:'normal',
        marginBottom:10,
        textAlign:'center'
      },
      section2: {
        fontSize: 16,
        color: 'black',
        fontWeight:'bold',
        marginVertical:20,
        textAlign:'center'
      },
      section3: {
        fontSize: 16,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section4: {
        fontSize: 16,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section33: {
        fontSize: 22,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      alphaItem: {
        backgroundColor: '#00808B',
        borderRadius: 8,
        margin: 4,
        padding: 4,
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        
      },
      alphaText: {
        fontSize: 30,
        color:'white'
      },
    });

export default EtudeScreen;
 {/* <View
  
        onMouseEnter={hoverHandler}
        onMouseLeave={outHandler}
      >
      <Text>{src}</Text>
     
      <iframe src={question} style={{height: heightWebView}} ></iframe>
     
      </View> */}