import React, { useState, useEffect, Component, useRef } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator,KeyboardAvoidingView, Platform, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, Tooltip} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalInfo1 from '../components/ModalInfo1';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar, Snackbar } from 'react-native-paper';
import BoutonEtude from '../components/BoutonEtude';

const FinalCompte11Screen = props => {

  const {iduser, Points, Shield, etude, classe, bodyset, visageset, slipset, cheveuxset, casqueset, armeset , NumRue, Rue, CodePostal, Ville} = props.route.params;

  const URL = 'https://www.useradventure.net/'

  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);

  const [body, setbody] = useState(bodyset);
  const [visage, setvisage] = useState(visageset);
  const [slip, setslip] = useState(slipset);
  const [cheveux, setcheveux] = useState(cheveuxset);
  const [casque, setcasque] = useState(casqueset);
  const [arme, setarme] = useState(armeset);

  const [icon4, seticon4] = useState('close-circle');
  const [colorIcon4, setcolorIcon4] = useState('transparent');

  const [dataSource, setDataSource] = useState([]);

  const [Pseudo, setPseudo] = useState('');

  const ChangePseudo = (text) => {
    setPseudo(text)
    if(text == '' || text.length <= '2'){
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
      seticon4('close-circle');
      setcolorIcon4('red');
    }else{
    fetch(`${URL}json_get_react_find_pseudo.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({pseudo: text})
    })
    .then((response) => response.json())
        .then(response => {  
          if(response == ''){
          setdisabledcolor('#00808B')
          setdisabled(true)    
          seticon4('check-circle');
          setcolorIcon4('green');
          }else{
     // tooltipRef.current.toggleTooltip();
      onToggleSnackBar()
     setdisabledcolor('#DCDCDC')
        setdisabled(false)
        seticon4('close-circle');
        setcolorIcon4('red');
      }
        })
    console.log(text)
    
  }}

  const valide = () => {
    if(disabled) {
    fetch(`${URL}json_get_react_input_avatar.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser, item1: body, item2: visage, item3: slip, item4: cheveux, item5: casque, item6: arme})
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
        props.navigation.navigate('FinalCompte12', {iduser, Points, Shield, etude, classe, Pseudo: Pseudo, body: body, visage:visage, slip: slip, cheveux: cheveux, casque: casque, arme: arme, NumRue, Rue, CodePostal, Ville}) ;
    
      })
      .catch((error) => console.error(error))
     
    }
  };

  var width = Dimensions.get('window').width;
  const tooltipRef = useRef(null);
  const [visible, setVisible] = React.useState(false);

  const onToggleSnackBar = () => setVisible(true);

  const onDismissSnackBar = () => setVisible(false);
  
        return (
          <View style={{ flex:1}}>
              <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        <ProgressBar progress={0.8} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
            <View style={styles.Container}>
             <Text style={styles.textT1}>Votre pseudo</Text>
             <Text style={styles.text3}>10. Donnez vous un petit nom afin de garantir votre anonymat</Text>
              <View style={styles.sectionContainer}>
              <View style={{ height: 350, backgroundColor:'transparent',alignItems: 'center'}}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${body}.png` }}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${visage}.png` }}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${slip}.png` }}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${cheveux}.png` }}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${casque}.png` }}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${arme}.png` }}>
                  
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground> 
                    <Snackbar
                  visible={visible}
                  onDismiss={onDismissSnackBar}
                  action={{
                    label: 'OK',
                    onPress: () => {
                      onDismissSnackBar()
                    },
                  }}>
                  Pseudo déjà utilisé
                </Snackbar>
                  </View>
                </View>
                <View style={styles.sectionContainer}> 
                  <View style={{flexDirection:'row',flex:10, alignItems:'center'}}>
                <TextInput style={styles.input} placeholder="Entrez un pseudo (3 caracteres min)" placeholderTextColor="grey" value={Pseudo} onChangeText={ChangePseudo} maxLength={12}/>
                 <Tooltip
                  ref={tooltipRef}
                  popover={<Text>Pseudo déjà utilisé</Text>}
                  overlayColor={'rgba(0,0,0,0.5)'}
                  withPointer={true}
                  highlightColor={'blue'}
                  backgroundColor={'red'}
                />
                <MaterialCommunityIcons   name={icon4} size={40} color={colorIcon4}/> 
              
                </View>
              </View>
            </View> 
              <ModalInfo1 isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} Point1={Points} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
              {Platform.OS === 'ios' ?<View style={{  height:300 }}></View>: undefined}
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
          <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
          <BoutonEtude onPress={() => props.navigation.goBack()} text={'Précédent'} textColor={'#FFB217'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={valide}   text={'Suivant'} textColor={'white'} boutonColor={disabledcolor}></BoutonEtude>
        </View>
        </View>
        </KeyboardAvoidingView>
        </View>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      levelUser: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       
      },
      text2: {
        fontSize: 14,
        color: '#00808B',
        paddingBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      textjaune: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFB217',
        marginLeft:10,       
       backgroundColor:'transparent'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        flex:9,
        backgroundColor:'white'
      },
      Container: {
        margin: 10,
      },
      textGrid: {
        fontSize: 50,
        color: 'transparent',
        
      }

    });

export default FinalCompte11Screen;
