import React, { useState, useEffect, Component, useRef } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform,KeyboardAvoidingView, ImageBackground, Picker} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalInfo1 from '../components/ModalInfo1';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar } from 'react-native-paper';
import CountryPicker from 'react-native-country-picker-modal'
import RNPickerSelect from '../components/react-native-picker-select-max';
import DateTimePicker from '../components/react-native-web-datePicker';
import DatePicker from 'react-native-datepicker';
import BoutonEtude from '../components/BoutonEtude';

const FinalCompte2Screen = props => {

  const {iduser, Points, Shield, etude } = props.route.params;
 
  const URL = 'https://www.useradventure.net/'
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [initValue, setinitValue] = useState('Selectionner')


  useEffect(() => {
    fetch(`${URL}json_get_react_data_user.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
      .then((response) => response.json())
      .then(response => {setDataUser(response);   
                        setNom(response[0].Nom) ;
                        setPrenom(response[0].Prenom) ;
                        setDate(response[0].Date_de_naissance);
                        setNational(response[0].Nationalite);
                        setCountryCode(response[0].Country);
                        setGenre(Number(response[0].Sexe))
                        setinitValue(response[0].Sexe)
                        if(response[0].Nom != '' && response[0].Prenom != '' && response[0].Sexe !='' && response[0].Nationalite !='' && response[0].Date_de_naissance !=''){
                        setdisabledcolor('#00808B')
                        setdisabled(true)
                        }
      })
      .catch((error) => console.error(error))
     // .finally(() => setLoadingUser(false));
  }, []);

  const [dataUser, setDataUser] = useState([]);
  console.log(dataUser)

  
  var width = Dimensions.get('window').width;

  const [countryCode, setCountryCode] = useState('')
  const [country, setCountry] = useState(null)
  const [withCountryNameButton, setWithCountryNameButton] = useState(true)
  const [withFlag, setWithFlag] = useState(true)
  const [withEmoji, setWithEmoji] = useState(true)
  const [withFilter, setWithFilter] = useState(true)
  const [withAlphaFilter, setWithAlphaFilter] = useState(true)
  const [withCallingCode, setWithCallingCode] = useState(false)
  

  const [Nom, setNom] = useState('');
  const [Prenom, setPrenom] = useState('');
  const [Genre, setGenre] = useState('');
  const [National, setNational] = useState('');
  const [date, setDate] = useState('');

  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
 
  const onValid =()=>{
    if(disabled){
      fetch(`${URL}json_get_react_update_infoverif_user.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({iduser: iduser, nom: Nom, prenom: Prenom, sexe: Genre, happy: date, national: National})
      })
        .then((response) => response.json())
        .catch((error) => console.error(error))
        .finally(() => props.navigation.navigate('FinalCompte3', {iduser, Points, Shield, etude}));
  
    };
      
  }

  const ChangeNom = (text) => {
    setNom(text)
    if(text != '' && Prenom != '' && Genre !='Selectionner' && National !='' && date !=''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(text)
  }
  const ChangePrenom = (text) => {
    setPrenom(text)
    if(Nom != '' && text != '' && Genre !='Selectionner' && National !='' && date !=''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }

    console.log(Prenom)
  }

  const ChangeGenre = (option) => {
    console.log(option)
    setGenre(option)
    setinitValue(option)
    if(Nom != '' && Prenom != '' && option !='Selectionner' && National !='' && date !=''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  }

  const onSelect = (country) => {
    setCountryCode(country.cca2)
    setNational(country.name)
    setCountry(country)
    if(Nom != '' && Prenom != '' && Genre !='Selectionner' && country.name !='' && date !=''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(country.name)
  }



  const ChangeDate = (text) => {
    console.log(text)
    setDate(text)
    if(Nom != '' && Prenom != '' && Genre !='Selectionner' && National !='' && text !=''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
  }


  let index = 1;
  const data = [
      { value: index++, label: 'Homme' },
      { value: index++, label: 'Femme' },
      { value: index++, label: `Je m'identifie comme un homme` },
      { value: index++, label: `Je m'identifie comme un femme` },
      { value: index++, label: `Je ne m'identifie à aucun genre` }
  ];

  const ref_input2 = useRef();
  const ref_input3 = useRef();
  
        return (
          <View style={{ flex:1}}>
                    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
         <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center' , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }  }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        <ProgressBar progress={0.1} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
            <View style={styles.Container}>
             <Text style={styles.text1}>Informations personnelles 1/2</Text>
             <Text style={styles.text3}>1. Pouvez vous valider les informations ci dessous ?</Text>
             <Text style={styles.text33}>(*) champs obligatoires</Text>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Nom<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
                <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Nom} onChangeText={ChangeNom} returnKeyType = { "next" } onSubmitEditing={() => ref_input2.current.focus()} blurOnSubmit={false}/>
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Prénom<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
                <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Prenom} onChangeText={ChangePrenom} returnKeyType = { "next" }  ref={ref_input2} />
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Genre<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
                <View style={styles.ViewInput}>
                {Platform.OS == "ios" ?
               
               <RNPickerSelect
                style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
           onValueChange={(itemValue) => ChangeGenre(itemValue)}
           value={Genre}
           items={data}
       /> : 
               <Picker
       selectedValue={initValue}
       style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
       onValueChange={(itemValue) => ChangeGenre(itemValue)}
     >
     <Picker.Item label="Selectionner" value={null} />
       {data.map((item, i) =>{ return <Picker.Item label={item.label} value={item.value} />})}
     </Picker>}
                 </View>   
               <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Nationalité<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
                <View style={styles.ViewInput}>
                          <CountryPicker 
                  {...{
                    countryCode,
                    withFilter,
                    withFlag,
                    withCountryNameButton,
                    withAlphaFilter,
                    withCallingCode,
                    withEmoji,
                    onSelect,
                    placeholder:"Selectionner",
        
                  } }

                /></View>
             
              </View>
           
             
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Date de naissance<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
                {Platform.OS === 'web' ? 
                <View style={{borderColor: '#00808B',borderWidth: 2,borderRadius:10,paddingHorizontal: 10,marginVertical: 10,backgroundColor:'white'}}>
                <DateTimePicker type={"date"} placeholder="Date de naissance*" value={date} onChange={(event)=>ChangeDate(event.target.value)} style={{borderWidth: 0,fontSize:18,borderRadius:10, height:50,backgroundColor:'white'}} />
                </View>
      :  <DatePicker
          style={{
            height: 50,
            width: (width)/2,
            borderColor: '#00808B',
            borderWidth: 2,
            borderRadius:10,
            paddingHorizontal: 10,
            marginVertical: 10,
            backgroundColor:'white'
          }}
          date={new Date(date).getDate()+'/'+(new Date(date).getMonth() + 1)+'/'+new Date(date).getFullYear()} //initial date from state
          mode="date" //The enum of date, datetime and time
          locale={'fr'}
          placeholder="Selectionner"
          androidMode="spinner"
          format="DD/MM/YYYY"
          minDate="01/01/1900"
          maxDate="01/01/2100"
          confirmBtnText="Confirmer"
          cancelBtnText="Annuler"
          iconSource={require("../assets/calendrier.png")}
          customStyles={{
            dateIcon: {
              //display: 'none',
              position: 'relative',
              left: 0,
              top: 4,
              marginLeft: 0,
            },
            dateInput: {
              marginTop: 8,
              borderWidth: 0,
              alignItems:'flex-start',
              justifyContent: 'center',
            },
            dateText: {
              fontSize:18
            },
            placeholderText: {
              fontSize:16,
              color:'black'
            },
          }}
          onDateChange={ChangeDate}
        />}
              </View>
              </View>  
            </View> 
              <ModalInfo1 isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} Point1={Points} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
           
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <BoutonEtude onPress={() => props.navigation.goBack()} text={'Précédent'} textColor={'#FFB217'} boutonColor={'white'}></BoutonEtude>
        <BoutonEtude onPress={onValid} text={'Suivant'} textColor={'white'} boutonColor={disabledcolor}></BoutonEtude>
        </View>
</View>
</KeyboardAvoidingView>
             </View>
        );
        
      
        }
  // {Platform.OS === 'ios' ?<View style={{  height:300 }}></View>: undefined}
    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
        color: '#00808B',
        paddingVertical:0,
       backgroundColor:'transparent',
       textAlign:'right'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      Container: {
        margin: 10,
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center',
        backgroundColor:'white'
      },
     

    });

export default FinalCompte2Screen;
