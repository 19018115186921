import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import BoutonEtude from '../components/BoutonEtude';
import { isLoading } from 'expo-font';
import ModalInfoEtude from '../components/ModalInfoEtude';
import ModalQuit1 from '../components/ModalQuit1';
import { List } from 'react-native-paper';
import moment from 'moment';
import 'moment/locale/fr';

const Presentiel2Screen = props => {

  const {flat: flat, iduser, Idetude, etude, Points, Shield, Img_Packaging, Q, Q_1, Duree} = props.route.params;

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);

  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [length, setlength] = useState('');
  const [rdv, setrdv] = useState('');
  const [heurerdv, setheurerdv] = useState('');
  const [disabled, setdisabled] = useState(false);
  console.log(props)

  const URL = 'https://www.useradventure.net/'
  
  useEffect(() => {
    fetch(`${URL}json_get_react_agenda.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({idetude: Idetude})
    })
      .then((response) => response.json())
      .then(response => {setFilteredDataSource(response);
        setlength(response.length)
        console.log(response)
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
      
  }, []);
  

  

  var width_origin = Dimensions.get('window').width;
var height_origin = Dimensions.get('window').height

if(width_origin > 1200){
  var width = ((Dimensions.get('window').width/2)-80)/5;
  var height = Dimensions.get('window').height
}else{
  var width = ((Dimensions.get('window').width)-80)/5;
  var height = Dimensions.get('window').height
}
   ;
    
        return (
          <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center' ,borderBottomColor:'#00808B',borderBottomWidth:1, backgroundColor: 'white', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color='#00808B' size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color='#00808B' size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
         {isLoading ? <ActivityIndicator/> : (
                     <View style={styles.Container}>
                       <Text style={styles.text1}>Date et heure du test</Text>
                       <Text style={styles.text3}>Choisissez une date entre le {moment(filteredDataSource[0].Date).format('Do MMMM')} et {moment(filteredDataSource[length-1].Date).format('Do MMMM YYYY')}</Text>
                        <List.AccordionGroup>
        {filteredDataSource.map((item, index) => (
            <View key={index} style={{justifyContent: 'center',  backgroundColor: 'white', borderRadius:5, marginBottom:10}}>       
             <List.Accordion theme={{colors: {placeholder: 'white', primary: '#00808B', underlineColor: 'transparent', background: 'transparent' } }}
             titleStyle={{textAlign:'center', fontWeight:'bold', marginLeft:30}}
              title={moment(item.Date).format('dddd Do MMMM')}
              id={String(index)}
             >
             <View style={{alignItems:width_origin > 1200? 'center': undefined}}>
                <View style={{flexDirection:'row', justifyContent:'space-around', marginBottom:width_origin > 1200? 15 : 5, marginHorizontal:10, width:width_origin > 1200? width_origin/2: undefined}}>
                  { item.H8 == '1' ?  
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('8h')}} style={{backgroundColor:heurerdv == '8h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >8h</Text>
                  </TouchableOpacity>
                  :
                  <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >8h</Text>
                  </View>
                  }
                   { item.H9 == '1' ?  
                   <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('9h')}} style={{backgroundColor:heurerdv == '9h' && rdv== item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >9h</Text>
                  </TouchableOpacity>
                   :
                   <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                   <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >9h</Text>
                   </View>
                   }
                    { item.H10 == '1' ? 
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('10h')}} style={{backgroundColor:heurerdv == '10h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >10h</Text>
                  </TouchableOpacity>
                   :
                   <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                   <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >10h</Text>
                   </View>
                   }
                   { item.H11 == '1' ? 
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('11h')}} style={{backgroundColor:heurerdv == '11h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >11h</Text>
                  </TouchableOpacity>
                  :
                  <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >11h</Text>
                  </View>
                  }
                  { item.H12 == '1' ? 
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('12h')}} style={{backgroundColor:heurerdv == '12h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >12h</Text>
                  </TouchableOpacity>
                  :
                  <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >12h</Text>
                  </View>
                  }
                </View>
                </View>
                <View style={{alignItems:width_origin > 1200? 'center': undefined}}>
                <View style={{flexDirection:'row', justifyContent:'space-around',marginBottom:20, marginHorizontal:10,width:width_origin > 1200? width_origin/2: undefined}}>
                  { item.H14 == '1' ? 
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('14h')}} style={{backgroundColor:heurerdv == '14h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >14h</Text>
                  </TouchableOpacity>
                   :
                   <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                   <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >14h</Text>
                   </View>
                   }
                  { item.H15 == '1' ? 
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('15h')}} style={{backgroundColor:heurerdv == '15h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >15h</Text>
                  </TouchableOpacity>
                  :
                  <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >15h</Text>
                  </View>
                  }
                   { item.H16 == '1' ? 
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('16h')}} style={{backgroundColor:heurerdv == '16h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >16h</Text>
                  </TouchableOpacity>
                  :
                  <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >16h</Text>
                  </View>
                   }
                    { item.H17 == '1' ? 
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('17h')}} style={{backgroundColor:heurerdv == '17h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >17h</Text>
                  </TouchableOpacity>
                  :
                  <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >17h</Text>
                  </View>
                   }
                    { item.H18 == '1' ? 
                  <TouchableOpacity onPress={() => {setrdv(item.Date) 
                  setdisabled(true)
                  setheurerdv('18h')}} style={{backgroundColor:heurerdv == '18h' && rdv == item.Date ? '#FFB217': '#00808B',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >18h</Text>
                  </TouchableOpacity>
                  :
                  <View style={{backgroundColor:'#DCDCDC',width:width, height:width_origin > 1200? width/2: width, borderRadius:5, justifyContent:'center', alignItems:'center'}}>
                  <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >18h</Text>
                  </View>
                   }
                </View>
                </View>
             </List.Accordion>
            
            </View>  ))}
             </List.AccordionGroup>
                      </View>
                  )}
               <ModalInfoEtude isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points} shield1={Shield} Duree={Duree} etude={etude}/>
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse"}}>
        <View style={{ flexDirection: "row",justifyContent: 'space-between',paddingHorizontal:10, height:80,borderTopColor:'#00808B',alignItems: 'center',borderTopWidth:1, backgroundColor:'white' }}>
        <BoutonEtude onPress={() => props.navigation.goBack()} text={'Précédent'} textColor={'white'} boutonColor={'#FFB217'}></BoutonEtude>
        <BoutonEtude onPress={() =>{ if(disabled){props.navigation.navigate('Presentiel3',{flat: flat, Idetude: Idetude, iduser: iduser, etude:etude, Points:Points, Shield: Shield, Img_Packaging: Img_Packaging, rdv: rdv, heurerdv: heurerdv, Q: Q, Q_1: Q_1, Duree: Duree })} }} text={'Suivant'} textColor={'white'} boutonColor={disabled  ? '#00808B': '#DCDCDC'}></BoutonEtude>
        </View> 
        {rdv != '' ? <View style={{ flexDirection: "row", alignItems:'center' , backgroundColor:'#FFB217', height:40,paddingHorizontal:20, justifyContent:'center'}}>
          <Text style={styles.text5}>Date sélectionnée :</Text>
            <Text style={styles.text4}>{ rdv == '' ? '' :' le ' + moment(rdv).format('dddd Do MMMM') + ' à ' + heurerdv}</Text>
        </View> : undefined}
        </View>
        </View>
        );
        
        
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 22,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text11: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text111: {
        fontSize: 34,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text22: {
        fontSize: 20,
        color: 'black',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:15,
        marginBottom:10,
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text4: {
        fontSize: 14,
        color: 'white',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text5: {
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white',
        paddingVertical:5,
        marginBottom:0,
       backgroundColor:'transparent',
      
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      inputgreen: {
        flex:8,
        height: 50,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      rondgreen: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      section1: {
        fontSize: 16,
        color: 'black',
        fontWeight:'normal',
        marginBottom:10,
        textAlign:'center'
      },
      section2: {
        fontSize: 16,
        color: 'black',
        fontWeight:'bold',
        marginVertical:20,
        textAlign:'center'
      },
      section3: {
        fontSize: 16,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section4: {
        fontSize: 16,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },

    });

export default Presentiel2Screen;
