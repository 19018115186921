import React, { useState, useEffect, Component, useRef } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator,KeyboardAvoidingView, Platform, ImageBackground, Picker} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalInfo1 from '../components/ModalInfo1';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar } from 'react-native-paper';
import RNPickerSelect from '../components/react-native-picker-select-max';
import BoutonEtude from '../components/BoutonEtude';

const FinalCompte13Screen = props => {

  const {iduser, Points, Shield, etude, classe, Pseudo, situation, manualite, langue, enfants, body, visage, slip, cheveux, casque, arme , NumRue, Rue, CodePostal, Ville} = props.route.params;

  const URL = 'https://www.useradventure.net/'

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);

  console.log('rr',props.route.params)
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);

  const [initValue, setinitValue] = useState('Selectionner')
  const [value2, setValue2] = useState('');
  const [value, setValue] = useState('');

  let index = 0;
  const data = [
      { value: 'Professionnel de santé', label: 'Professionnel de santé' },
      { value: 'Agriculteur', label: 'Agriculteur' },
      { value: `Artisan`, label: `Artisan` },
      { value: `Commerçant`, label: `Commerçant` },
      { value: `Chef d'entreprise`, label: `Chef d'entreprise` },
      { value: `Cadre et profession Intellectuelle supérieure`, label: `Cadre et profession Intellectuelle supérieure` },
      { value: `Professions Intermédiaires`, label: `Professions Intermédiaires` },
      { value: `Employé`, label: `Employé` },
      { value: `Ouvrier`, label: `Ouvrier` },
      { value: `Etudiant`, label: `Etudiant` },
      { value: `Inactif ou chômeur n'ayant jamais travaillé`, label: `Inactif ou chômeur n'ayant jamais travaillé` },

  ];

  const ChangeGenre = (option) => {
    //setGenre(option.key)
    setinitValue(option)
    if(option !='Selectionner' && value !='' ){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  }
 
  const onChangeText = (text) => {
    setValue(text)
    if(text != '' && initValue != 'Selectionner' ){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(text)
  }

  const onChangeText2 = (text) => {
    setValue2(text)
   
  }
  const valid = () =>{
    if(disabled) {
    fetch(`${URL}json_get_react_update_infosup2_user.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser, manualite: manualite, langue: langue, enfants: enfants, situation: situation , Profession: initValue , Poste: value, Autre_info: value2})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() =>  props.navigation.navigate('FinalCompte14', {iduser, Points, Shield, etude, classe, Pseudo, body: body, visage:visage, slip: slip, cheveux: cheveux, casque: casque, arme: arme, manualite: manualite, langue: langue, enfants: enfants, situation: situation , Profession: initValue , Poste: value, Autre_info: value2 , NumRue, Rue, CodePostal, Ville}));
  }
  }
  

  const ref_input3 = useRef();
  const ref_input2 = useRef();

        return (
          <View style={{ flex:1}}>
              <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        <ProgressBar progress={0.92} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
            <View style={styles.Container}>
             <Text style={styles.textT1}>Un peu plus sur vous 2/2</Text>
             <Text style={styles.text33}>(*) champs obligatoires</Text>
             <Text style={styles.text3}>15. Votre profession*</Text>
                        <View style={styles.sectionContainer}>
                        <View style={styles.ViewInput}>
                        {Platform.OS == "ios" ?
               
               <RNPickerSelect
                style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
           onValueChange={(itemValue) => ChangeGenre(itemValue)}
           value={initValue}
           items={data}
       /> : 
               <Picker
       selectedValue={initValue}
       style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
       onValueChange={(itemValue) => ChangeGenre(itemValue)}
     >
     <Picker.Item label="Selectionner" value={null} key={0} />
       {data.map((item, i) =>{ return <Picker.Item label={item.label} value={item.value} key={i}/>})}
     </Picker>}
                 </View>   
                        </View>
                        <View style={styles.sectionContainer}>
                        <Text style={styles.sectionTitle}>Intulé du poste*</Text>
                        <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={value} onChangeText={text => onChangeText(text)} returnKeyType = { "next" } onSubmitEditing={() => ref_input3.current.focus()} ref={ref_input2}/>
                        </View>

                        <View style={styles.sectionContainer}>
                        <Text style={styles.text3}>16. Avez-vous d'autres éléments à nous confier?</Text>
                        <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={value2} onChangeText={text => onChangeText2(text)} returnKeyType = { "next" } ref={ref_input3}/>
                        </View>
               </View> 
              <ModalInfo1 isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} Point1={Points} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
          <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
          <BoutonEtude onPress={() => props.navigation.goBack()} text={'Précédent'} textColor={'#FFB217'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={valid}   text={'Suivant'} textColor={'white'} boutonColor={disabledcolor}></BoutonEtude>
        </View>
        </View>
        </KeyboardAvoidingView>
        </View>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      levelUser: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       
      },
      text2: {
        fontSize: 14,
        color: '#00808B',
        paddingBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
       
        color: '#00808B',
        paddingVertical:0,
       backgroundColor:'transparent',
       textAlign:'right'
      },
      textjaune: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFB217',
        marginLeft:10,       
       backgroundColor:'transparent'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        backgroundColor:'white'
      },
      Container: {
        margin: 10,
      },
      textGrid: {
        fontSize: 50,
        color: 'transparent',
        
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center',
        backgroundColor:'white'
      },

    });

export default FinalCompte13Screen;
