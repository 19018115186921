import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Animated, ActivityIndicator, Platform, ImageBackground, Alert} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox, Slider} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import { Rating, AirbnbRating } from 'react-native-elements';
import { ProgressBar } from 'react-native-paper';
import ModalInfoAvis from '../components/ModalInfoAvis';
import ModalQuitAvis from '../components/ModalQuitAvis';

const Avis2Screen = props => {

  const {Code, Name, Quantity, Points, PointsUser, iduser, Pseudo, person, rating, Temps, Frequence, Remarque, flat } = props.route.params;

  console.log('a2',props.route.params)

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [value1, setValue] = useState(50);
  const [value2, setValue2] = useState(50);
  const [value3, setValue3] = useState(50);

  var width = Dimensions.get('window').width;
  
  const changeMoins = () => {
    if(value1 > 0){
  setValue(value1 - 1)
    }
  }

  const changePlus = () => {
    if(value1 < 100){
      setValue(value1 + 1)
    }
    }

    const changeMoins2 = () => {
      if(value2 > 0){
    setValue2(value2 - 1)
      }
    }
  
    const changePlus2 = () => {
      if(value2 < 100){
        setValue2(value2 + 1)
      }
      }

      const changeMoins3 = () => {
        if(value3 > 0){
      setValue3(value3 - 1)
        }
      }
    
      const changePlus3 = () => {
        if(value3 < 100){
          setValue3(value3 + 1)
        }
        }
        return (
            <>
          <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title} numberOfLines={1}>Avis {Name} {Quantity}</Text>
        <ProgressBar progress={0.2} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
          <View style={styles.Container}>
          <Text style={styles.text1}>Avis détaillés</Text>
             <Text style={styles.text2}>Donnez une note sur 100 à chaque caractéristiques du produit</Text>
             <Text style={styles.text3}>1. Facilité d'utilisation *</Text> 
             <Text style={styles.text4}>{value1}</Text>
             <View style={{ flexDirection: "row",justifyContent: 'space-between',marginBottom:20}}> 
              <TouchableOpacity onPress={changeMoins}  style={{width: 50, height: 50, borderRadius:5,borderWidth:3,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:32, fontWeight:'bold' }}>-</Text>
        </TouchableOpacity>
        <View style={{ flex:1, justifyContent: 'center', paddingHorizontal:10 }}>
          <Slider
          maximumValue={100}
          minimumValue={0}
            value={value1}
            onValueChange={(text) => setValue(Math.round(text))}
            trackStyle={{ height: 3, backgroundColor: '#00808B' }}
            thumbStyle={{ height: 40, width: 40, backgroundColor: 'white', borderWidth: 3, borderColor: '#00808B' }}
            minimumTrackTintColor='#00808B'
          />
        </View>
        <TouchableOpacity onPress={changePlus} style={{width: 50, height:50, borderRadius:5,borderWidth:3,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:32, fontWeight:'bold' }}>+</Text>
        </TouchableOpacity>
              </View>

              <Text style={styles.text3}>2. Efficacité du produit *</Text> 
             <Text style={styles.text4}>{value2}</Text>
             <View style={{ flexDirection: "row",justifyContent: 'space-between',marginBottom:20}}> 
              <TouchableOpacity onPress={changeMoins2}  style={{width: 50, height: 50, borderRadius:5,borderWidth:3,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:32, fontWeight:'bold' }}>-</Text>
        </TouchableOpacity>
        <View style={{ flex:1, justifyContent: 'center', paddingHorizontal:10 }}>
          <Slider
          maximumValue={100}
          minimumValue={0}
            value={value2}
            onValueChange={(text) => setValue2(Math.round(text))}
            trackStyle={{ height: 3, backgroundColor: '#00808B' }}
            thumbStyle={{ height: 40, width: 40, backgroundColor: 'white', borderWidth: 3, borderColor: '#00808B' }}
            minimumTrackTintColor='#00808B'
          />
        </View>
        <TouchableOpacity onPress={changePlus2}  style={{width: 50, height:50, borderRadius:5,borderWidth:3,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:32, fontWeight:'bold' }}>+</Text>
        </TouchableOpacity>
              </View>

              <Text style={styles.text3}>3. Lisibilité et clarté de la notice d'utilisation *</Text> 
             <Text style={styles.text4}>{value3}</Text>
             <View style={{ flexDirection: "row",justifyContent: 'space-between',marginBottom:20}}> 
              <TouchableOpacity onPress={changeMoins3}  style={{width: 50, height: 50, borderRadius:5,borderWidth:3,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:32, fontWeight:'bold' }}>-</Text>
        </TouchableOpacity>
        <View style={{ flex:1, justifyContent: 'center', paddingHorizontal:10 }}>
          <Slider
          maximumValue={100}
          minimumValue={0}
            value={value3}
            onValueChange={(text) => setValue3(Math.round(text))}
            trackStyle={{ height: 3, backgroundColor: '#00808B' }}
            thumbStyle={{ height: 40, width: 40, backgroundColor: 'white', borderWidth: 3, borderColor: '#00808B' }}
            minimumTrackTintColor='#00808B'
          />
        </View>
        <TouchableOpacity onPress={changePlus3}  style={{width: 50, height:50, borderRadius:5,borderWidth:3,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:32, fontWeight:'bold' }}>+</Text>
        </TouchableOpacity>
              </View>
       </View>
       <ModalInfoAvis isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
       <ModalQuitAvis isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Scan')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.navigate('Avis1')} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#FFB217', fontSize:20, fontWeight:'bold' }} >Précédent</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => props.navigation.navigate('Avis3',{Code: Code, flat: flat, Name: Name, Quantity: Quantity, Points: Points, PointsUser: PointsUser, iduser: iduser, Pseudo: Pseudo, person:person, rating:rating, Temps:Temps, Frequence:Frequence, Remarque:Remarque, value1:value1, value2:value2, value3:value3 })} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Suivant</Text>
        </TouchableOpacity>
        </View>
</View>
         </View>
             </>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text2: {
        fontSize: 18,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 17,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4: {
        fontSize: 26,
        color: '#00808B',
        fontWeight: 'bold',
        paddingVertical:10,
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 150,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center'
      },

    });

export default Avis2Screen;
