import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal, ImageBackground, KeyboardAvoidingView, Platform} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const ModalQueryP =  ({ isVisible, onSubmit, onQuit, onQuery,isQuestion, props }) => { 

  

return(
  <Modal
  animationType="fade"
  transparent={true}
  visible={isVisible}
 
>
<KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
<View style={styles.centeredView}>
<View style={styles.modalView}>
  <View style={{flexDirection: "row", alignItems:'center', marginBottom:10}}>
  <Text style={styles.textStyle1}>Envoyer un message</Text>
  </View>
  <Text style={styles.modalText}>Vous rencontrez un problème particulier ou vous avez une question importante à poser ? Ecrivez-votre message et pigeon voyageur se charge du reste.</Text>
  <View style={{alignItems:'center', marginBottom:20,}}>
  <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={isQuestion} onChangeText={(text)=> onQuery(text)} multiline={true}   />
  </View>
  <View style={{flexDirection: "row", justifyContent:'center'}}>
  <TouchableOpacity
    style={{ height:40,margin:10,padding:5,  borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} 
    onPress={(value) => onQuit(value)}
  >
    <Text style={styles.textStyle}>Annuler</Text>
  </TouchableOpacity>
  <TouchableOpacity
    style={{ height:40,margin:10,padding:5,  borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} 
    onPress={(value) => onSubmit(value)}
  >
    <Text style={styles.textStyle}>Envoyer</Text>
  </TouchableOpacity>
  </View>
</View>
</View>
</KeyboardAvoidingView>
</Modal>


            );




}

const styles = StyleSheet.create({
     
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
  textStyle: {
  color: 'white',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:0,
    fontSize: 24
     },
   modalText: {
   marginBottom: 15
   },
   modalTextbold: {
    marginBottom: 15,
    fontWeight:'bold'
    },
    input: {
      
      height: 150,
      minWidth: Dimensions.get('window').width-60,
      borderColor: '#00808B',
      borderWidth: 2,
      borderRadius:10,
      padding: 10,
      fontSize:18,
      backgroundColor:'white'
    },

  });

  export default ModalQueryP;