import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal, ImageBackground, } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {Camera } from 'expo-camera';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';

const ModalCamera =  ({ isVisible, onSubmit, onCancel }) => { 

  const camera = useRef();

const takePicture = async ()=>{
  if (camera) {
    await camera.current.takePictureAsync({ quality : 1 , fixOrientation:true, forceUpOrientation:true, skipProcessing:true}).then((data) => { 
      onSubmit(data)
      
  })
  }
}

  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [flashmode, setflashmode] = useState(Camera.Constants.FlashMode.off);
  const [Flash, setFlash] = useState("flash-off");
  var screenWidth = Dimensions.get('window').width;


  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

return(
 
  <Modal
animationType="slide"
transparent={false}
visible={isVisible}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<View style={styles.container}>
<View style={styles.button2}>
  <Button onPress={() => onCancel()} buttonStyle={styles.buttonDelete1}
              icon={<MaterialCommunityIcons name="chevron-left" color="white" size={46} /> }   />
     </View>
      <Camera ref={camera} style={{justifyContent: 'flex-end',width: screenWidth,height: screenWidth * 1.33}} type={type} flashMode={flashmode} autoFocus={Camera.Constants.AutoFocus.on} >
      </Camera> 
      
      <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.button50}
            onPress={() => {
              setType(
                type === Camera.Constants.Type.back
                  ? Camera.Constants.Type.front
                  : Camera.Constants.Type.back
              );
            }}>
           <MaterialCommunityIcons name="rotate-3d-variant" color="white" size={36} />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={takePicture}>
           <MaterialCommunityIcons name="camera" color="white" size={56} />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button50}
            onPress={() => {
              setflashmode(
                flashmode === Camera.Constants.FlashMode.on
                  ? Camera.Constants.FlashMode.off
                  : Camera.Constants.FlashMode.on
              );
              setFlash(
                Flash === "flash"
                  ? "flash-off"
                  : "flash"
              );

            }}>
            <MaterialCommunityIcons name={Flash} color="white" size={36} />
          </TouchableOpacity>
          
        </View>
    </View>
</Modal>


            );




}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center', 
    backgroundColor:'black'
  },
  camera: {
    flex: 1
  },
  buttonContainer: {
    flexDirection: 'row',
    height:120,
    backgroundColor:'transparent',
    justifyContent: 'center',
    alignItems: "center",
  },
  button: {
    height:80,
    width:80,
    margin:10,
    borderRadius:40,
    borderWidth:1,
    borderColor:'white',
    justifyContent: 'center',
    alignItems: "center",
    backgroundColor: '#003333',
   elevation: 10  ,
   shadowColor: 'black', 
   shadowOpacity: 0.3, 
   shadowOffset: { height: 10 }
  },
  button50: {
    height:50,
    width:50,
    margin:30,
    borderRadius:25,
    borderWidth:1,
    borderColor:'white',
    justifyContent: 'center',
    alignItems: "center",
    backgroundColor: '#003333',
   elevation: 10  ,
   shadowColor: 'black', 
   shadowOpacity: 0.3, 
   shadowOffset: { height: 10 }
  },
  text: {
    fontSize: 18,
    color: 'white',
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
  textStyle: {
  color: '#00808B',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
   marginBottom: 15
   },
   modalTextbold: {
    marginBottom: 15,
    fontWeight:'bold'
    },
    buttonDelete1: {
      backgroundColor:'transparent',
     
    },
    button2: {
      height:80,
      width:80,
      margin:10,
      borderRadius:40,
      borderWidth:1,
      justifyContent: 'center',
      alignItems: "center",
     elevation: 10  ,
     shadowColor: 'black', 
     shadowOpacity: 0.3, 
     shadowOffset: { height: 10 }
    },

  });

  export default ModalCamera;