import React, { useState, useEffect, Component } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalInfo1 from '../components/ModalInfo1';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar } from 'react-native-paper';
import BoutonEtude from '../components/BoutonEtude';

const FinalCompte8Screen = props => {

  const {iduser, Points, Shield, etude, classe, bodyset, visageset, slipset , NumRue, Rue, CodePostal, Ville} = props.route.params;

  const URL = 'https://www.useradventure.net/'

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [body, setbody] = useState(bodyset);
  const [visage, setvisage] = useState(visageset);
  const [slip, setslip] = useState(slipset);
  const [cheveux, setcheveux] = useState('cheveux1');

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    fetch(`${URL}json_get_react_items.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({categorie: 'cheveux',niv: '0', iduser: iduser })
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
                      
    
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  var width = Dimensions.get('window').width;
  
        return (
          <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }}}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        <ProgressBar progress={0.6} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
            <View style={styles.Container}>
             <Text style={styles.textT1}>Votre aventurier 4/6</Text>
             <Text style={styles.text3}>7. Choisissez votre coiffure</Text>
              <View style={styles.sectionContainer}>
              <View style={{ height: 350, backgroundColor:'transparent',alignItems: 'center'}}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${body}.png` }}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${visage}.png` }}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${slip}.png` }}>
                    <ImageBackground style={{height:'100%',width:350}} imageStyle={{resizeMode: 'contain'}} source={{ uri: `${URL}Items/${cheveux}.png` }}>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                  </View>
                </View>
                {isLoading  ?   <ActivityIndicator size="large" color='#FFB217' />:<FlatList
                style={{paddingBottom:10}}
          data={dataSource}
          backgroundColor='transparent'
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => { setcheveux(item.Item) }}  style={[ item.Item === cheveux ? { flex: 1, flexDirection: 'column', margin: 3, borderColor: '#00808B', borderWidth: 3, borderRadius: 8, marginBottom: 7,elevation: 12, backgroundColor: item.color_icone ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } } : { flex: 1, flexDirection: 'column', margin: 6, borderColor: '#00808B', borderWidth: 0, borderRadius: 8, marginBottom: 10,elevation: 12, backgroundColor: item.color_icone,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }}]} >
              <View style={{ alignItems: 'center'}}>
                <Image style={{height:Platform.OS === 'web' ? (((width-80)/5)/2) :(width-80)/5,width:(width-150)/5, resizeMode: 'contain'}} source={{ uri: item.icone_item }}/>
              </View>
          </TouchableOpacity>
          )}
          //Setting the number of column
          numColumns={5}
          keyExtractor={(item, index) => index}
          /> }
            </View> 
              <ModalInfo1 isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} Point1={Points} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
          <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
          <BoutonEtude onPress={() => props.navigation.goBack()} text={'Précédent'} textColor={'#FFB217'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={() => props.navigation.navigate('FinalCompte9', {iduser, Points, Shield, etude, classe, cheveuxset:cheveux, slipset:slip, visageset:visage, bodyset:body  , NumRue, Rue, CodePostal, Ville})}  text={'Suivant'} textColor={'white'} boutonColor={'#00808B'}></BoutonEtude>
        </View>
        </View>
        </View>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      levelUser: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       
      },
      text2: {
        fontSize: 14,
        color: '#00808B',
        paddingBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      textjaune: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFB217',
        marginLeft:10,       
       backgroundColor:'transparent'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        margin: 10,
      },
      textGrid: {
        fontSize: 50,
        color: 'transparent',
        
      }

    });

export default FinalCompte8Screen;
