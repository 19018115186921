import React from 'react'
import { NavigationContainer, useRoute, useFocusEffect } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets, CardStyleInterpolators } from '@react-navigation/stack';
import Register1Screen from './Register1Screen';
import Register2Screen from './Register2Screen';

const RegisterScreen = props => {

    const { Navigator, Screen } = createStackNavigator();
    
    return(
        <Navigator initialRouteName="Register1" screenOptions={{cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS}}>
          <Screen name = "Register1" component ={Register1Screen} options={{headerShown: false}}/>   
          <Screen name= "Register2" component={Register2Screen} options={{headerShown: false}}/>
        </Navigator>
      );
    }

export default RegisterScreen

