import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import BoutonEtude from '../components/BoutonEtude';
import { isLoading } from 'expo-font';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar } from 'react-native-paper';
import ModalInfoEtude from '../components/ModalInfoEtude';

const Presentiel1Screen = props => {

  const {flat, iduser, PointsUser, ShieldUser, Idetude, Points, Shield, etude, num_Q, Q, Q_1, Q_2, Q_3, Q_4, Q_5, Q_6, Q_7, Q_8, Q_9, Q_10, Q_type, Img_Packaging, Q_description, Duree} = props.route.params;

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);

  //const totalPoints = Number(PointsUser) + Number(Points);
  //const totalShield = Number(ShieldUser) + Number(Shield);
   

  var width = Dimensions.get('window').width;
  

        return (
          
          <View style={{ flex:1}}>
            <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center' ,borderBottomColor:'#00808B',borderBottomWidth:1, backgroundColor: 'white', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color='#00808B' size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        {/* {Q_type == '0' ? undefined : 
        <ProgressBar progress={barprog} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginRight:10, marginTop:5 }} />
          } */}
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color='#00808B' size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
        
          <View style={styles.Container}>
                      <Text style={styles.text1}>{Q}</Text>
                      <Text style={styles.text3}>{Q_1}</Text>
                      <View style={{  paddingHorizontal:10}}>
                      <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 250,
                        width:'100%',
                        resizeMode: 'contain',
                      }} source={{ uri: Img_Packaging }} />
                      </View>  
                      <Text style={styles.text33}>{Q_description}</Text>
                      <Text style={styles.text1}>Durée :</Text>
                      <View style={{ marginVertical:5}}>
                      <Text style={styles.text11}>Environ {Duree} minutes</Text>
                      </View>
                      <Text style={styles.text1}>Votre récompense après le test :</Text>
                      <View style={{ flexDirection: "row", marginTop:5}}>
                    <Text style={styles.text11}>+{Shield} </Text>
                    <Image style={{height:35, width:35, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                      <Text style={styles.text11}>   +{Points} pts</Text>
                  </View>
                      </View>
                  
              <ModalInfoEtude isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points} shield1={Shield} Duree={Duree} etude={etude}/>
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center',borderTopColor:'#00808B',borderTopWidth:1, height:80, backgroundColor:'white'}}>
        <View style={{ flexDirection: "row",justifyContent: 'center',marginHorizontal:10, }}>
        <BoutonEtude onPress={() => props.navigation.navigate('Presentiel2',{flat: flat, Idetude: Idetude, iduser: iduser, etude:etude, Points:Points, Shield: Shield, Img_Packaging: Img_Packaging, Q: Q, Q_1: Q_1, Duree: Duree})} text={'Je postule'} textColor={'white'} boutonColor={'#00808B'}></BoutonEtude>
        </View>
        </View> 
        </View>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 22,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text11: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text111: {
        fontSize: 34,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text22: {
        fontSize: 20,
        color: 'black',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text4: {
        fontSize: 26,
        color: 'green',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      inputgreen: {
        flex:8,
        height: 50,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      rondgreen: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      section1: {
        fontSize: 16,
        color: 'black',
        fontWeight:'normal',
        marginBottom:10,
        textAlign:'center'
      },
      section2: {
        fontSize: 16,
        color: 'black',
        fontWeight:'bold',
        marginVertical:20,
        textAlign:'center'
      },
      section3: {
        fontSize: 16,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section4: {
        fontSize: 16,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },

    });

export default Presentiel1Screen;
