import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const ModalQuit1 =  ({ isVisible, onSubmit, onQuit, props }) => { 

return(
  <Modal
  animationType="fade"
  transparent={true}
  visible={isVisible}
 
>
<View style={styles.centeredView}>
<View style={styles.modalView}>
  <View style={{flexDirection: "row", alignItems:'center', marginBottom:10}}>
  <MaterialCommunityIcons name="alert-circle-outline" color='#00808B' size={46} />
  <Text style={styles.textStyle1}>Attention</Text>
  </View>
  <Text style={styles.modalText}>Vous êtes sur le point de <Text style={styles.modalTextbold}>quitter l'avis consommateur</Text>. Si c'est le cas, votre progression ne sera pas sauvegarder.</Text>
  <View style={{flexDirection: "row", justifyContent:'flex-end'}}>
  <TouchableOpacity
    style={{ backgroundColor: "transparent", marginHorizontal:20 }}
    onPress={(value) => onQuit(value)}
  >
    <Text style={styles.textStyle}>QUITTER</Text>
  </TouchableOpacity>
  <TouchableOpacity
    style={{ backgroundColor: "transparent" }}
    onPress={(value) => onSubmit(value)}
  >
    <Text style={styles.textStyle}>RESTER</Text>
  </TouchableOpacity>
  </View>
</View>
</View>
</Modal>


            );




}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
  textStyle: {
  color: '#00808B',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
   marginBottom: 15
   },
   modalTextbold: {
    marginBottom: 15,
    fontWeight:'bold'
    },
   

  });

  export default ModalQuit1;