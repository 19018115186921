import React, { useState, useEffect, Component, useRef  } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity,Pressable, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView, Alert} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import ModalInfoRegister from '../components/ModalInfoRegister';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

const Register2Screen = props  => {

  const { Nom, Prenom, Genre, National, date, countryCode } = props.route.params;

  const URL = 'https://www.useradventure.net/'


  var iChars = "~`!#$%^&*+=-[]\\';,./{}|\":<>?@";
  var width = Dimensions.get('window').width;
  var height = Dimensions.get('window').height
  
  const [Mail, setMail] = useState('');
  const [icon1, seticon1] = useState('close-circle');
  const [colorIcon1, setcolorIcon1] = useState('transparent');
  const [icon2, seticon2] = useState('close-circle');
  const [colorIcon2, setcolorIcon2] = useState('transparent');
  const [icon3, seticon3] = useState('close-circle');
  const [colorIcon3, setcolorIcon3] = useState('transparent');
  const [icon4, seticon4] = useState('close-circle');
  const [colorIcon4, setcolorIcon4] = useState('transparent');
  const [CMail, setCMail] = useState('');
  const [Mdp, setMdp] = useState('');
  const [CMdp, setCMdp] = useState('');
  const [security, setsecurity] = useState(true);
  const [securityC, setsecurityC] = useState(true);
  
  const [car8, set8car] = useState(false);
  const [Scar, setScar] = useState(false);
  const [Maj, setMaj] = useState(false);
  const [Minu, setMinu] = useState(false);
  const [Chif, setChif] = useState(false);

  const [iconPass, seticonPass] = useState('eye-off-outline');
  const [iconCPass, seticonCPass] = useState('eye-off-outline');
  const [modalVisible, setModalVisible] = useState(false);
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
  const [checked, setchecked] = useState(false)
  const [checked2, setchecked2] = useState(false)
  const [height_origin, setheight_origin] = useState();

  useEffect(() => {
    setheight_origin(Dimensions.get('window').height)
    }, []);

  const changechecked = (text) => {
    setchecked(text)
    if(text && checked2 && icon2 == 'check-circle' && icon3 == 'check-circle' && icon4 == 'check-circle'){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    }else {
      setdisabledcolor('#DCDCDC');
      setdisabled(false);
      } 
    
  }

  const changechecked2 = (text) => {
    setchecked2(text)
    if(text && checked && icon2 == 'check-circle' && icon3 == 'check-circle' && icon4 == 'check-circle'){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    }else {
      setdisabledcolor('#DCDCDC');
      setdisabled(false);
      } 
    
  }

  const verifMail = () => {
   var position = Mail.indexOf("@");
   var reste = Mail.substr(-position);
   var posit = reste.indexOf(".");
   //alert(posit)
   if(position !== -1 && posit !== -1 ){
    seticon1('check-circle');
   setcolorIcon1('green')
   if(checked && checked2 && icon2 == 'check-circle' && icon3 == 'check-circle' && icon4 == 'check-circle'){
    setdisabledcolor('#FFB217')
    setdisabled(true)}
  }else {
    seticon1('close-circle');
   setcolorIcon1('red');
   setdisabledcolor('#DCDCDC');
      setdisabled(false);
    } 
  
  
 if(CMail != ''){
  if(CMail.localeCompare(Mail, 'en', { sensitivity: 'base' })==0){
    seticon2('check-circle');
   setcolorIcon2('green');
   
  } else {
    seticon2('close-circle');
   setcolorIcon2('red');
   setdisabledcolor('#DCDCDC');
      setdisabled(false);
  }
  }
  };

  const verifCMail = () => {
   // setCMail(text)
     
    if(CMail.localeCompare(Mail, 'en', { sensitivity: 'base' })==0){
      seticon2('check-circle');
     setcolorIcon2('green');
      if(checked && checked2 && icon1 == 'check-circle'  && icon3 == 'check-circle' && icon4 == 'check-circle'){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    }
    } else {
      seticon2('close-circle');
     setcolorIcon2('red');
     setdisabledcolor('#DCDCDC');
      setdisabled(false);
    }
   
  };

  const verifMdp = () => {
   // setMdp(text)

    let Maj = false;
    let car8 = false;
    let Scar =false;
    let Minu = false;
    let Chif = false;
    var long = Mdp.length ;

    if (long >= 8){car8 = true}else{car8 = false}

    for (var i = 0; i < long; i++)
    {if (iChars.indexOf(Mdp.charAt(i)) !== -1 )
      {
        Scar = true
      break;}
      else {
      Scar = false
      }
    }
    
    for (var i = 0; i < long; i++)
    {if (Mdp.charAt(i) === Mdp.charAt(i).toUpperCase() )
      {  
        if (!isNaN(Mdp.charAt(i) * 1)) 
        {Maj = false
         
         }
        else
        {if (iChars.indexOf(Mdp.charAt(i)) !== -1 )
          
            {Maj = false
             
             }
            else
            { Maj = true
            break;
            }
           
        }
      }
      else {
         Maj = false
      }

    }

      for (var i = 0; i < long; i++)
    {if (Mdp.charAt(i) === Mdp.charAt(i).toLowerCase() )
      {  
        if (!isNaN(Mdp.charAt(i) * 1))
        {Minu = false
        
         }
        else
        {if (iChars.indexOf(Mdp.charAt(i)) !== -1 )
          
          {Minu = false
           
           }
          else
          { Minu = true
          break;
          }
         
      }
      }
      else {
        Minu = false
       
      }
    }

      for (var i = 0; i < long; i++)
      { if (!isNaN(Mdp.charAt(i) * 1))
          { Chif = true
          break;
          }
        else {
        Chif = false
        }
      }
  
  if(car8 && Scar && Maj && Minu && Chif) 
  {  setsecurity(true)
  seticon3('check-circle');
  setcolorIcon3('green');
  seticonPass('eye-off-outline')
if(checked && checked2 && icon1 == 'check-circle' && icon2 == 'check-circle'  && icon4 == 'check-circle'){
  setdisabledcolor('#FFB217')
  setdisabled(true)
}
} else {
seticon3('close-circle');
setcolorIcon3('red');
setdisabledcolor('#DCDCDC');
      setdisabled(false);
}
console.log(car8 , Scar, Maj ,Minu ,Chif)

  };

  const verifCMdp = () => {
   //setCMdp(text)
    if(CMdp === Mdp){
      seticon4('check-circle');
     setcolorIcon4('green');
  if(checked && checked2 && icon1 == 'check-circle' && icon2 == 'check-circle' && icon3 == 'check-circle'){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    }
    } else {
      seticon4('close-circle');
      setcolorIcon4('red');
      setdisabledcolor('#DCDCDC');
      setdisabled(false);
    }
    
  };


  
  const visiblePass = () => {
if(iconPass === 'eye-off-outline'){
  seticonPass('eye-outline')
  setsecurity(false)
} else {
  seticonPass('eye-off-outline')
  setsecurity(true)
}

  }

  const visibleCPass = () => {
    if(iconCPass === 'eye-off-outline'){
      seticonCPass('eye-outline')
      setsecurityC(false)
    } else {
      seticonCPass('eye-off-outline')
      setsecurityC(true)
    }
    
      }
  
  const valide = () => {
    if(disabled){

   fetch(`${URL}json_get_react_find_mail.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_name:Mail   })
    })
      .then((response) => response.json())
      .then(response => { 
        if(response == ''){
    fetch(`${URL}json_get_react_register.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name:Nom,
                            surname:Prenom,
                            genre:Genre,
                            pays:National,
                            bday:date,
                            mail:Mail,
                            password:Mdp,
                            country:countryCode
                          })
    })
      .then((response) => response.json())
      .then(() => {
        alert(
          `Félicitations, vous venez de créer votre compte !,
          Un mail de confirmation vient de vous être envoyé sur votre adresse mail ${Mail}. Merci de cliquer sur le lien afin de confirmer votre inscription.`
        )
    
      })
      .catch((error) => console.error(error))
      .finally(() => props.navigation.navigate('Main'));

    } else {
      alert(`l'adresse mail que vous avez choisi existe déjà`)
    }})

}else{
  if(!checked){
    alert("Vous n'avez pas validé les conditions générales, Merci de vérifier !")
  }else{
    alert("Mail ou mot de passe incorrect, Merci de vérifier !")
  }
  
}}

const ref_input2 = useRef();
const ref_input3 = useRef();
const ref_input4 = useRef();



        return (
            <>
             <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
   
   <View style={{  height: Platform.OS === 'web' ? 70 : 110 , backgroundColor: '#00808B', elevation: 10  ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 }}}>
        <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
      <View style={{flex:2 }}>
        <Text style={styles.Title}>Créer un compte</Text>
        </View>
        </LinearGradient>
        </View>
       
          <ScrollView>
            <View style={styles.Container}>
             <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 100 }} >
             <View style={{flex:1, justifyContent: 'center',alignItems: 'center', }}>
                  <Image style={{height:100,width:'100%', resizeMode: 'contain'}} source={require("../assets/pigeon_right.png")}/>
                </View>
                <View style={{flex:2,justifyContent: 'center', alignItems:'center'}}>
                    <Text style={styles.textPigeon}>Rejoignez l'aventure en seulement deux étapes !</Text>
                 </View>
             </View>
             <Text style={styles.text1}>Etape 2/2</Text>
             <Text style={styles.text2}>Toutes les informations sont obligatoires</Text>
             <Text style={styles.text3}>Votre adresse mail</Text>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Mail</Text>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                    <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Mail} keyboardType={'email-address'} onChangeText={text => setMail(text)}  onBlur={text => verifMail(text)} returnKeyType = { "next" } onSubmitEditing={() => ref_input2.current.focus()} blurOnSubmit={false}/>
                    <MaterialCommunityIcons   name={icon1} size={40} color={colorIcon1}/>
                  </View>
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Comfirmer votre mail</Text>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                    <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={CMail} keyboardType={'email-address'} onChangeText={text => setCMail(text)} onBlur={text => verifCMail(text)} returnKeyType = { "next" } onSubmitEditing={() => ref_input3.current.focus()}   ref={ref_input2}/>
                    <MaterialCommunityIcons   name={icon2} size={40} color={colorIcon2}/>
                  </View>
              </View>   
              <Text style={styles.text3}>Votre mot de passe</Text>
              <Text style={styles.text2}>Votre mot de passe doit contenir au minimum <Text style={styles.text4}>8 caractères</Text> et contenir au minimum : <Text style={styles.text4}>1 majuscule, 1 minuscule, 1 chiffre et 1 caractère special</Text>

</Text>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Mot de passe</Text>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', marginVertical: 10}}>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', height: 50,borderColor: '#00808B', borderWidth: 2, borderRadius:10, flex:9, paddingRight:5,backgroundColor:'white'}}>
                    <TextInput style={{paddingHorizontal: 10,fontSize:18, flex:8, paddingVertical:10}} placeholder="" placeholderTextColor="grey" value={Mdp}  onChangeText={text => setMdp(text)} onBlur={text => verifMdp(text)} secureTextEntry={security} returnKeyType = { "next" } onSubmitEditing={() => ref_input4.current.focus()}  ref={ref_input3}/>
                    <MaterialCommunityIcons onPress={visiblePass}  name={iconPass} size={30} color='darkgrey'/>
                    </View>
                    <MaterialCommunityIcons   name={icon3} size={40} color={colorIcon3}/>
                  </View>
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Confimer votre mot de passe</Text>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', marginVertical: 10}}>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', height: 50,borderColor: '#00808B', borderWidth: 2, borderRadius:10, flex:9, paddingRight:5, backgroundColor:'white'}}>
                    <TextInput style={{paddingHorizontal: 10,fontSize:18, flex:8, paddingVertical:10}} placeholder="" placeholderTextColor="grey" value={CMdp} onChangeText={text => setCMdp(text)} onBlur={text => verifCMdp(text)} secureTextEntry={securityC}   ref={ref_input4}/>
                    <MaterialCommunityIcons onPress={visibleCPass}  name={iconCPass} size={30} color='darkgrey'/>
                    </View>
                    <MaterialCommunityIcons   name={icon4} size={40} color={colorIcon4}/>
                  </View>
              </View>
              <View style={styles.sectionContainer}>
              <CheckBox
              title={<Text style={{fontSize:14, fontWeight:'normal', textAlign:'justify', marginRight:45}}>J'ai lu et j'accepte les <Text style={styles.text5} onPress={()=> WebBrowser.openBrowserAsync('https://www.useradventure.net/Conditions_Generales.pdf')}>Conditions Générales d'Utilisation</Text>, la politique de <Text style={styles.text5} onPress={()=>WebBrowser.openBrowserAsync('https://www.useradventure.net/Protection_des_donnees.pdf')}>Confidentialité et Respect des Données Personnelles</Text> ainsi que les <Text style={styles.text5} onPress={()=>WebBrowser.openBrowserAsync('https://www.useradventure.net/Contrat_testeurs.pdf')}>Conditions du contrat testeur</Text></Text>}
              textStyle={{fontSize:14, fontWeight:'normal', textAlign:'justify', marginRight:45}}
              containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0, marginStart:0}}
              checked={checked}
              checkedColor='#00808B'
              uncheckedColor='#00808B'
              size={36}
              onPress={() => { if(!checked) {changechecked(true)} else {changechecked(false)} }}
            />
              <CheckBox
              title={<Text style={{fontSize:14, fontWeight:'normal', textAlign:'justify', marginRight:45}}>En cochant cette case, vous acceptez que les informations que vous avez fournies font l’objet d’un traitement informatisé destiné au recrutement des participants aux études. Symplicity UserAdventure est responsable du traitement de vos données personnelles. Vous pouvez exprimer votre souhait de revenir sur votre consentement ou exercer vos droits d’accès, de rectification, d’effacement, d’opposition, de limitation ou de portabilité directement auprès de Symplicity UserAdventure à l’adresse suivante : Useradventure. Les données sollicitées sont nécessaires au traitement de votre demande.</Text>}
              textStyle={{fontSize:14, fontWeight:'normal', textAlign:'justify', marginRight:45}}
              containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0, marginStart:0}}
              checked={checked2}
              checkedColor='#00808B'
              uncheckedColor='#00808B'
              size={36}
              onPress={() => { if(!checked2) {changechecked2(true)} else {changechecked2(false)} }}
            />
            <Text style={styles.text2}>Pour en savoir plus, retrouvez ici notre politique de protection des données personnelles.</Text>
            </View> 
            
            </View> 
            <ModalInfoRegister isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} />
            {/* {Platform.OS === 'ios' ?<View style={{  height:300 }}></View>: undefined} */}
          </ScrollView>
          <View style={{ flexDirection: "column-reverse", height:80, backgroundColor:'#00808B'}}>
   <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,justifyContent: 'center'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.navigate('Register1')} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:20, fontWeight:'bold' }} >Précédent</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={valide} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: disabledcolor,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Valider</Text>
        </TouchableOpacity>
        </View>
</LinearGradient>
</View>

</KeyboardAvoidingView>
            </>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 14,
        color: '#00808B',
       backgroundColor:'transparent', 
       textAlign:'justify',
       marginRight: 45,
       marginBottom:10
      },
      text4: {
        fontSize: 14,
        color: '#00808B',
        fontWeight: 'bold',
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text5: {
        fontSize: 14,
        color: '#00808B',
       backgroundColor:'transparent', 
       textAlign:'justify',
       marginRight: 0,
       marginBottom:10
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        flex:9,
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      
      Container: {
        margin: 10,
      },
     

    });

export default Register2Screen;

