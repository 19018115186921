import React, { useState, useEffect, Component } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalInfo1 from '../components/ModalInfo1';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar } from 'react-native-paper';
import BoutonEtude from '../components/BoutonEtude';

const FinalCompte4Screen = props => {


  const {iduser, Points, Shield, etude, NumRue, Rue, CodePostal, Ville } = props.route.params;
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [classe, setClasse] = useState('');
  console.log(classe)

  var width = Dimensions.get('window').width;
  

  const onValid1 =()=>{
    setClasse('chevalier')
    setdisabled(true)
    setdisabledcolor('#00808B')
  }

  const onValid2 =()=>{
    setClasse('archer')
    setdisabled(true)
    setdisabledcolor('#00808B')
  }

  const onValid3 =()=>{
    setClasse('paladin')
    setdisabled(true)
    setdisabledcolor('#00808B')
  }

  const onValid =()=>{
    if(disabled){
      props.navigation.navigate('FinalCompte5', {iduser, Points, Shield, etude, classe, NumRue, Rue, CodePostal, Ville})
    }
  }

        return (
          <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        <ProgressBar progress={0.3} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
            <View style={styles.Container}>
             <Text style={styles.textT1}>Votre aventurier</Text>
             <Text style={styles.text3}>3. Choisissez votre classe d'aventurier</Text>
             <Text style={styles.text2}>Votre classe nous permettra de connaitre votre préférence concernant le type d'étude.</Text>
              <View style={styles.sectionContainer}>
              <TouchableOpacity onPress={onValid1} style={[ classe === 'chevalier' ? { height: 150,marginVertical:5, justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }} : { height: 150,marginVertical:5, justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }}]} >
              <View style={{ height: width/3,flexDirection: "row", backgroundColor:'transparent'}}>
                <View style={{flex:1,justifyContent: 'center',alignItems: 'center', }}>
                <Image style={{ height:80,width:'100%', resizeMode: 'contain'}} source={require("../assets/epee.png")} />
              </View>
                <View style={{flex:2, justifyContent:'center'}}>
                  <View style={{ flexDirection: "row", alignItems:'flex-end', paddingBottom:5}}>
                    <Text style={[ classe === 'chevalier' ? styles.text1white:styles.text1]}>Chevalier</Text>
                    <Text style={styles.textjaune}>l'action avant tout</Text>
                  </View>
                  <View style={{ }}>
                    <Text style={[ classe === 'chevalier' ? styles.text2white:styles.text2]}>le chevalier a une préférence pour les études en présentiel</Text>
                  </View>
                  <View style={{ flexDirection: "row",}}>
                    <View style={{ width:80, justifyContent: 'center'}}>
                    <Text style={[ classe === 'chevalier' ? styles.levelUserwhite:styles.levelUser]}>Présentiel</Text>
                    </View>
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                  </View>
                  <View style={{ flexDirection: "row",}}>
                  <View style={{ width:80, justifyContent: 'center'}}>
                    <Text style={[ classe === 'chevalier' ? styles.levelUserwhite:styles.levelUser]}>En ligne</Text>
                    </View>
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                  </View>
                    
                 </View>
              </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={onValid2} style={[ classe === 'archer' ? { height: 150,marginVertical:5, justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }} : { height: 150,marginVertical:5, justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }}]} >
              <View style={{ height: width/3,flexDirection: "row", backgroundColor:'transparent'}}>
                <View style={{flex:1,justifyContent: 'center',alignItems: 'center', }}>
                <Image style={{ height:80,width:'100%', resizeMode: 'contain'}} source={require("../assets/arc.png")} />
              </View>
                <View style={{flex:2, justifyContent:'center'}}>
                <View style={{ flexDirection: "row", alignItems:'flex-end', paddingBottom:5}}>
                    <Text style={[ classe === 'archer' ? styles.text1white:styles.text1]}>Archer</Text>
                    <Text style={styles.textjaune}>la distance c'est le santé</Text>
                  </View>
                  <View style={{ }}>
                    <Text style={[ classe === 'archer' ? styles.text2white:styles.text2]}>l'archer a une préférence pour les études en ligne</Text>
                  </View>
                  <View style={{ flexDirection: "row",}}>
                  <View style={{ width:80, justifyContent: 'center'}}>
                    <Text style={[ classe === 'archer' ? styles.levelUserwhite:styles.levelUser]}>Présentiel</Text>
                    </View>
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                  </View>
                  <View style={{ flexDirection: "row",}}>
                  <View style={{ width:80, justifyContent: 'center'}}>
                    <Text style={[ classe === 'archer' ? styles.levelUserwhite:styles.levelUser]}>En ligne</Text>
                    </View>
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                  </View>
                    
                 </View>
              </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={onValid3} style={[ classe === 'paladin' ? { height: 150,marginVertical:5, justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }} : { height: 150,marginVertical:5, justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }}]} >
              <View style={{ height: width/3,flexDirection: "row", backgroundColor:'transparent'}}>
                <View style={{flex:1,justifyContent: 'center',alignItems: 'center', }}>
                <Image style={{ height:90,width:'100%', resizeMode: 'contain'}} source={require("../assets/marteau.png")} />
              </View>
                <View style={{flex:2, justifyContent:'center'}}>
                <View style={{ flexDirection: "row", alignItems:'flex-end', paddingBottom:5}}>
                    <Text style={[ classe === 'paladin' ? styles.text1white:styles.text1]}>Paladin</Text>
                    <Text style={styles.textjaune}>sur tous les terrains</Text>
                  </View>
                  <View style={{ }}>
                    <Text style={[ classe === 'paladin' ? styles.text2white:styles.text2]}>le paladin aime tous les types d'études</Text>
                  </View>
                  <View style={{ flexDirection: "row",}}>
                  <View style={{ width:80, justifyContent: 'center'}}>
                    <Text style={[ classe === 'paladin' ? styles.levelUserwhite:styles.levelUser]}>Présentiel</Text>
                    </View>
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                  </View>
                  <View style={{ flexDirection: "row",}}>
                  <View style={{ width:80, justifyContent: 'center'}}>
                    <Text style={[ classe === 'paladin' ? styles.levelUserwhite:styles.levelUser]}>En ligne</Text>
                    </View>
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                    <Image style={{height:30, width:30, resizeMode: 'stretch',marginHorizontal:2}} source={require("../assets/coeur.png")} /> 
                  </View>
                    
                 </View>
              </View>
        </TouchableOpacity>
              </View>
            </View> 
              <ModalInfo1 isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} Point1={Points} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
          <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <BoutonEtude onPress={() => props.navigation.goBack()} text={'Précédent'} textColor={'#FFB217'} boutonColor={'white'}></BoutonEtude>
        <BoutonEtude onPress={onValid} text={'Suivant'} textColor={'white'} boutonColor={disabledcolor}></BoutonEtude>
        </View>
        </View>
        </View>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      levelUser: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      levelUserwhite: {
        fontSize: 12,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
      },
      text1white: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
      },
      text2: {
        fontSize: 14,
        color: '#00808B',
        paddingBottom:10,
       backgroundColor:'transparent'
      },
      text2white: {
        fontSize: 14,
        color: 'white',
        paddingBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text3white: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'white',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      textjaune: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFB217',
        marginLeft:10,       
       backgroundColor:'transparent'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        margin: 10,
      },
     

    });

export default FinalCompte4Screen;
