import React, { useState, useEffect, Component, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView, Picker, DatePickerIOS} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import CountryPicker from 'react-native-country-picker-modal'
import ModalInfoRegister from '../components/ModalInfoRegister';
import DateTimePicker from '../components/react-native-web-datePicker';
import DatePicker from 'react-native-datepicker';
import RNPickerSelect from '../components/react-native-picker-select-max';

const Register1Screen = props => {

  var width = Dimensions.get('window').width;
  

  const [countryCode, setCountryCode] = useState('')
  const [country, setCountry] = useState(null)
  const [withCountryNameButton, setWithCountryNameButton] = useState(true)
  const [withFlag, setWithFlag] = useState(true)
  const [withEmoji, setWithEmoji] = useState(true)
  const [withFilter, setWithFilter] = useState(true)
  const [withAlphaFilter, setWithAlphaFilter] = useState(true)
  const [withCallingCode, setWithCallingCode] = useState(false)
  const [height_origin, setheight_origin] = useState();

  const [initValue, setinitValue] = useState('Selectionner')

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth()+1; //January is 0!
  var yyyy = today.getFullYear();
 if(dd<10){
        dd='0'+dd
    } 
    if(mm<10){
        mm='0'+mm
    } 
today = yyyy+'-'+mm+'-'+dd;

  
  const [Nom, setNom] = useState('');
  const [Prenom, setPrenom] = useState('');
  const [Genre, setGenre] = useState('');
  const [National, setNational] = useState('');
  const [date, setDate] = useState(today);
  const [startDate, setStartDate] = useState(today);
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [chosenDate, setChosenDate] = useState(new Date());

  useEffect(() => {
    setheight_origin(Dimensions.get('window').height)
    }, []);
 
  const onValid =()=>{
    if(disabled){
      props.navigation.navigate('Register2', { Nom, Prenom, Genre, National, date, countryCode })
    }
  }


  const ChangeNom = (text) => {
    setNom(text)
    if(text != '' && Prenom != '' && Genre !='' && National !='' && date !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(text)
  }
  const ChangePrenom = (text) => {
    setPrenom(text)
    if(Nom != '' && text != '' && Genre !='' && National !='' && date !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }

    console.log(Prenom)
  }

  const ChangeGenre = (option) => {
    console.log(option)
    setGenre(option)
    setinitValue(option)
    if(Nom != '' && Prenom != '' && option !='' && National !='' && date !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  }

  const onSelect = (country) => {
    setCountryCode(country.cca2)
    setNational(country.name)
    setCountry(country)
    if(Nom != '' && Prenom != '' && Genre !='' && country.name !='' && date !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(country.name)
  }

  const ChangeDate = (text) => {
    setDate(text)
    if(Nom != '' && Prenom != '' && Genre !='' && National !='' && text !=''){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    console.log(date)
  }


        const ref_input2 = useRef();

        return (
            <>
            <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
    
          <View style={{  height: Platform.OS === 'web' ? 70 : 110 , backgroundColor: '#00808B', elevation: 10  ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 }}}>
        <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
      <View style={{flex:2 }}>
        <Text style={styles.Title}>Créer un compte</Text>
        </View>
        </LinearGradient>
        </View>
      
          <ScrollView>
            <View style={styles.Container}>
             <View style={{flex:3, flexDirection: "row",  height: 100 }} >
             <View style={{flex:1, justifyContent: 'center',alignItems: 'center' }}>
                  <Image style={{height:100,width:'100%', resizeMode: 'contain'}} source={require("../assets/pigeon_right.png")}/>
                </View>
                <View style={{flex:2,justifyContent: 'center', alignItems:'center'}}>
                    <Text style={styles.textPigeon}>Rejoignez l'aventure en seulement deux étapes !</Text>
                 </View>
             </View>
             <Text style={styles.text1}>Etape 1/2</Text>
             <Text style={styles.text2}>Toutes les informations sont obligatoires</Text>
             <Text style={styles.text3}>Vos informations personnelles</Text>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Nom</Text>
                <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Nom} onChangeText={ChangeNom} returnKeyType = { "next" } onSubmitEditing={() => ref_input2.current.focus()} blurOnSubmit={false}/>
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Prénom</Text>
                <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Prenom} onChangeText={ChangePrenom}   ref={ref_input2}/>
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Genre</Text>
                <View style={styles.ViewInput}>
                {Platform.OS == "ios" ?
               
                <RNPickerSelect
                 style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
            onValueChange={(itemValue, itemIndex) => ChangeGenre(itemValue)}
            selectedValue={initValue}
            items={[
                { label: `Homme`, value: '1' },
                { label: `Femme`, value: '2' },
                { label: `Je ne m'identifie à aucun genre`, value: '5' },
            ]}
        /> : 
                <Picker
        selectedValue={initValue}
        style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
        onValueChange={(itemValue, itemIndex) => ChangeGenre(itemValue)}
      >
          <Picker.Item label="Genre*" value="0" />
          <Picker.Item label="Homme" value="1" />
          <Picker.Item label="Femme" value="2" />
          <Picker.Item label="Je ne m'identifie à aucun genre" value="5" />
      </Picker>}
                 </View>   
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Nationalité</Text>
                <View style={styles.ViewInput}>
                          <CountryPicker 
                  {...{
                    countryCode,
                    withFilter,
                    withFlag,
                    withCountryNameButton,
                    withAlphaFilter,
                    withCallingCode,
                    withEmoji,
                    onSelect,
                    placeholder:"Selectionner",
        
                  } }

                /></View>
              </View>
               
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Date de naissance</Text>
{Platform.OS === 'web' ? 
                <View style={{borderColor: '#00808B',borderWidth: 2,borderRadius:10,paddingHorizontal: 10,marginVertical: 10,backgroundColor:'white'}}>
                <DateTimePicker type={"date"} placeholder="Date de naissance*" value={date} onChange={(event)=>ChangeDate(event.target.value)} style={{borderWidth: 0,fontSize:18,borderRadius:10, height:50,backgroundColor:'white'}} />
                </View>
      :  <DatePicker
          style={{
            height: 50,
            width: (width)/2,
            borderColor: '#00808B',
            borderWidth: 2,
            borderRadius:10,
            paddingHorizontal: 10,
            marginVertical: 10,
            backgroundColor:'white'
          }}
          date={date} //initial date from state
          mode="date" //The enum of date, datetime and time
          locale={'fr'}
          placeholder="Selectionner"
          androidMode="spinner"
          format="YYYY-MM-DD"
          minDate="1900-01-01"
          maxDate="2100-01-01"
          confirmBtnText="Confirmer"
          cancelBtnText="Annuler"
          iconSource={require("../assets/calendrier.png")}
          customStyles={{
            dateIcon: {
              //display: 'none',
              position: 'relative',
              left: 0,
              top: 4,
              marginLeft: 0,
            },
            dateInput: {
              marginTop: 8,
              borderWidth: 0,
              alignItems:'flex-start',
              justifyContent: 'center',
            },
            dateText: {
              fontSize:18
            },
            placeholderText: {
              fontSize:16,
              color:'black'
            },
          }}
          onDateChange={ChangeDate}
        />}
               
            </View>
            </View> 
            {/* {Platform.OS === 'ios' ?<View style={{  height:300 }}></View>: undefined} */}
            <ModalInfoRegister isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} />
          </ScrollView>
          <View style={{ flexDirection: "column-reverse", height:80, backgroundColor:'#00808B'}}>
   <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,justifyContent: 'center'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.navigate('Main')} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:20, fontWeight:'bold' }} >Annuler</Text>
        </TouchableOpacity>
        <TouchableOpacity  onPress={onValid}   style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: disabledcolor,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Suivant</Text>
        </TouchableOpacity>
        </View>
</LinearGradient>
</View>

</KeyboardAvoidingView>
            </>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center',
        backgroundColor:'white'
      },
      Container: {
        margin: 10,
      },
     

    });

export default Register1Screen;
