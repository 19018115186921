import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground,Alert} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
//import DatePicker from 'react-native-datepicker';
import { isLoading } from 'expo-font';
import { List } from 'react-native-paper';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from '../components/react-native-simple-radio-button-max';
import ModalQuit1 from '../components/ModalQuit1';
//import ModalMaps1 from '../components/ModalMaps1';
import ModalInfoEtude from '../components/ModalInfoEtude';
import moment from 'moment';
import 'moment/locale/fr';
import * as Calendar from 'expo-calendar';
import * as WebBrowser from 'expo-web-browser';

const Rdv1Screen = props => {

  const {iduser, flat, PointsUser, ShieldUser, Idetude, Points, Shield, etude, num_Q, Q, Q_1, Q_2, Q_3, Q_4, Q_5, Q_6, Q_7, Q_8, Q_9, Q_10, Q_type, Img_Packaging, Q_description, Duree} = props.route.params;

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalmapsVisible, setModalMapsVisible] = useState(false);
  //const totalPoints = Number(PointsUser) + Number(Points);
  //const totalShield = Number(ShieldUser) + Number(Shield);
   
  useEffect(() => {
    
    (async () => {
      const { status } = await Calendar.requestCalendarPermissionsAsync();
      if (status === 'granted') {
        const calendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);
        console.log('Here are all your calendars:');
        console.log({ calendars });
      }
    })();
  }, []);

  var width = Dimensions.get('window').width;

  

  var defaultCal //the calendar ID you are passing to createEventAsync()


  async function addTestEvent() {
    const long = (Q_3.length) - 1
     const heure = (Q_3.substring(0,long)) - 2
     console.log(long)


    if( Platform.OS == 'ios' ) {
     var defaultCalendar = await Calendar.getDefaultCalendarAsync();
      } else {
      const calendars = await Calendar.getCalendarsAsync();
      var defaultCalendar = calendars.find(({accessLevel})=>"owner");
      console.log('calendarSelect',defaultCalendar)
      
    }

    const eventDetails = {
      title: `Useradventure  ${Q}`,
      startDate: `${Q_2}T${heure}:00:00.000Z`,
      endDate: `${Q_2}T${heure + 1}:00:00.000Z`,
      timeZone: "Europe/Paris",
    };
  
    await Calendar.createEventAsync(defaultCalendar.id, eventDetails).then(() =>Alert.alert("Useradventure",`${Q} a été ajouté à l'agenda`));
  }

  const handleOpenWithLinking = () => {
    Linking.openURL('https://www.google.com/maps/dir//260+Rue+Salvador+Allende,+59120+Loos');
     
  };


   

        return (
          
          <View style={{ flex:1}}>
         <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center' ,borderBottomColor:'#00808B',borderBottomWidth:0, backgroundColor: "#00343B", elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color='white' size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color='white' size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
        
          <View style={styles.Container}>
                      <Text style={styles.text1}>{Q}</Text>
                      <Text style={styles.text333}>{Q_1}</Text>
                      <View style={{  paddingHorizontal:10,justifyContent: 'center', alignItems:'center' }}>
                      <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 130,
                        width:'100%',
                        resizeMode: 'contain',
                      }} source={{ uri: Img_Packaging }} />
                       <Text style={styles.textT1}>{Q_3} le {moment(Q_2).format('Do MMMM')}</Text>
                       { Platform.OS === 'web' ? undefined :
                       <TouchableOpacity onPress={addTestEvent} style={{justifyContent: 'center',  backgroundColor: 'white', borderRadius:5, marginBottom:10}}>
                       <Text style={styles.text3}>Ajouter à mon agenda</Text>
                       </TouchableOpacity>}
                      <Text style={styles.text3}>au 260 rue Salvador Allende 59120 LOOS Société UseConcept</Text>
                      <Avatar
                        rounded
                        size="large"
                        source={{  uri:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqRweRQ-g8XwkvidVbPGeYr3UBCz250D1JKg&usqp=CAU' }}
                      />
                      <Text style={styles.text3}>Laetitia (votre contact sur place)</Text>
                      <Text style={styles.text33}>09 72 61 05 13</Text>
                      </View>  
             <TouchableOpacity  onPress={() => handleOpenWithLinking()} style={{justifyContent: 'center',  backgroundColor: 'white', borderRadius:5, marginBottom:10}}>
                       <Text style={styles.text3}>Comment s'y rendre ?</Text>
                       </TouchableOpacity>
                      <Text style={styles.text1}>Durée :</Text>
                      <View style={{ marginVertical:5}}>
                      <Text style={styles.text11}>Environ {Duree} minutes</Text>
                      </View>
                      <Text style={styles.text1}>Votre récompense après le test :</Text>
                      <View style={{ flexDirection: "row", marginTop:5}}>
                    <Text style={styles.text11}>+{Shield} </Text>
                    <Image style={{height:35, width:35, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                      <Text style={styles.text11}>   +{Points} pts</Text>
                  </View>
                      </View>
                {/* <ModalMaps1 isVisible={modalmapsVisible} onQuit={() => {setModalMapsVisible(false)}}/> */}
              <ModalInfoEtude isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points} shield1={Shield} Duree={Duree} etude={etude}/>
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center',borderTopColor:'#00808B',borderTopWidth:0, height:80, backgroundColor:"#00343B"}}>
        <View style={{ flexDirection: "row",justifyContent: 'center',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.navigate('Rdv2',{Idetude: Idetude, flat: flat, iduser: iduser, etude:etude, Points:Points, Shield: Shield, Img_Packaging: Img_Packaging, rdv: Q_2, heurerdv: Q_3, Duree: Duree})} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Modifier</Text>
        </TouchableOpacity>
        </View>
        </View> 
        </View> 
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 22,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textT1: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:0
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 22,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text11: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text111: {
        fontSize: 34,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text22: {
        fontSize: 20,
        color: 'black',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:7,
        marginHorizontal:35,
        textAlign:'center',
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
        color: '#00808B',
        paddingBottom:10,
       backgroundColor:'transparent',
       textDecorationLine: 'underline',
      },
      text333: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text4: {
        fontSize: 26,
        color: 'green',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      inputgreen: {
        flex:8,
        height: 50,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      rondgreen: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      section1: {
        fontSize: 16,
        color: 'black',
        fontWeight:'normal',
        marginBottom:10,
        textAlign:'center'
      },
      section2: {
        fontSize: 16,
        color: 'black',
        fontWeight:'bold',
        marginVertical:20,
        textAlign:'center'
      },
      section3: {
        fontSize: 16,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section4: {
        fontSize: 16,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },

    });

export default Rdv1Screen;
