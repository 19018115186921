import React, { useState, useEffect, Component } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalInfo1 from '../components/ModalInfo1';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar } from 'react-native-paper';
import BoutonEtude from '../components/BoutonEtude';

const FinalCompte14Screen = props => {
  
  const {iduser, Points, Shield, etude, classe, Pseudo, body, visage, slip, cheveux, casque, arme, manualite, langue, enfants, situation, Profession, Poste, Autre_info, NumRue, Rue, CodePostal, Ville} = props.route.params;

  console.log(props.route.params)

  const URL = 'https://www.useradventure.net/'

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const message = `
j'espère que vous allez bien !
Bienvenue sur l'application UserAdventure.
Je suis Pigeon Voyageur et je suis à votre disposition pour vous aider. N'hésitez pas à me poser des questions ou à activer le logo pour profiter de mes connaissances.
Je serai aussi là pour vous rappeler des informations importantes ou vos rendez-vous.
Bonne journée,`

  const valide = () => {
 
  fetch(`${URL}json_get_react_input_cadeau.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({iduser: iduser, message: message, type: 'text', qui: 'P'})
      })
        .then((response) => response.json())
        .catch((error) => console.error(error))
        .finally(() => 
    fetch(`${URL}json_get_react_input_classe.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser, classe: classe, points: Points, pseudo: Pseudo})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() => 
    
      fetch(`${URL}json_get_react_level.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({pointsuser: Points})
      })
        .then((response) => response.json())
        .then(response => {props.navigation.navigate('Home', { token : '0',item1: body, item2:visage, item3: slip, item4: cheveux, item5: casque, item6: arme, Points: Points, Shield: Shield, Classe: classe, Pseudo: Pseudo, level:response[0].Level, pointsmax: response[0].pts_max, MSG:1})})))

  };
 
  
        return (
          <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        <ProgressBar progress={1} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
            <View style={styles.Container}>
            <View style={{ justifyContent: 'center', alignItems:'center'}}>
                <Text style={styles.textT1}>Félicitation</Text>
                <Text style={styles.text3}>Vous venez de gagner</Text>
                <View style={{ width:250, height:250, borderWidth:3, borderColor:'#FFB217', borderRadius:125, marginBottom:-65, backgroundColor:'white'}}>
                  <View style={{ height:170,justifyContent: 'center', alignItems:'center'}}>
                    <Text style={styles.sectionTitle}>+ {Points} pts</Text>
                  </View>
                </View>
                <Image style={{ height:90,width:'100%', resizeMode: 'contain'}} source={require("../assets/coffre.png")} />
              </View>      
              <View style={{ alignItems:'center', marginTop:30}}>
              <Text style={styles.section3}>Valider <Text style={styles.section1}>pour finaliser le questionnaire et revenir au tableau de bord</Text></Text>
              <Text style={styles.section1}>ou</Text>
              <Text style={styles.section4}>Précédent <Text style={styles.section1}>pour modifier vos réponses</Text></Text>
              <Text style={styles.section2}>Ces réponses sont modifiables à tout moment sur votre profil utilisateur</Text>
              </View>
               </View> 
              <ModalInfo1 isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} Point1={Points} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
          <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
          <BoutonEtude onPress={() => props.navigation.goBack()} text={'Précédent'} textColor={'#FFB217'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={valide}   text={'Valider'} textColor={'white'} boutonColor={'#00808B'}></BoutonEtude>
        </View>
        </View>
        </View>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      levelUser: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       
      },
      text2: {
        fontSize: 14,
        color: '#00808B',
        paddingBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:15,
       backgroundColor:'transparent'
      },
      textjaune: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFB217',
        marginLeft:10,       
       backgroundColor:'transparent'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 22,
        color: 'black',
       marginVertical:10,
        marginTop:5
      },
      section1: {
        fontSize: 16,
        color: 'black',
        fontWeight:'normal',
        marginBottom:10,
        textAlign:'center'
      },
      section2: {
        fontSize: 16,
        color: 'black',
        fontWeight:'bold',
        marginVertical:20,
        textAlign:'center'
      },
      section3: {
        fontSize: 16,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section4: {
        fontSize: 16,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        margin: 10,
      },
      textGrid: {
        fontSize: 50,
        color: 'transparent',
        
      }

    });

export default FinalCompte14Screen;
