import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox} from 'react-native-elements';

const ModalFilterHome =  ({ isVisible, onSubmit, onCancel, ischecked1, onCheck1, ischecked2, onCheck2, ischecked3, onCheck3, ischecked4, onCheck4, ischecked5, onCheck5, props }) => { 

  return(
 
  <Modal
animationType="fade"
transparent={true}
visible={isVisible}
>
<View style={styles.centeredView}>
<View style={styles.modalView}>
<View style={{ flexDirection: "row", alignItems:'center', paddingHorizontal:10}}>
<MaterialCommunityIcons  name='filter' color={'#00808B'} size={44}  />
<Text style={{  paddingHorizontal: 5, fontWeight:'bold', color:'#00808B',fontSize:24}}>Filtrer</Text>
</View>
  <View style={{ flexDirection: "row", marginBottom:20}}>
  <View>
  <Text style={styles.modalText}>Etape :</Text>
  <CheckBox
    title='en cours'
    textStyle={{fontSize:18, fontWeight:'normal'}}
    containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
    checked={ischecked3}
    checkedColor='#00808B'
    uncheckedColor='#00808B'
    size={36}
    onPress={(value) => onCheck3(value)}
    />
    <CheckBox
    title='à réaliser'
    textStyle={{fontSize:18, fontWeight:'normal'}}
    containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
    checked={ischecked4}
    checkedColor='#00808B'
    uncheckedColor='#00808B'
    size={36}
    onPress={(value) => onCheck4(value)}
    />

<CheckBox
    title='à postuler'
    textStyle={{fontSize:18, fontWeight:'normal'}}
    containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
    checked={ischecked5}
    checkedColor='#00808B'
    uncheckedColor='#00808B'
    size={36}
    onPress={(value) => onCheck5(value)}
    />
    </View>
    <View style={{width:'1%', backgroundColor: '#FFB217' }} />
  <View>
  <Text style={styles.modalText}>Type :</Text>
  <CheckBox
    title='en ligne'
    textStyle={{fontSize:18, fontWeight:'normal'}}
    containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
    checked={ischecked1}
    checkedColor='#00808B'
    uncheckedColor='#00808B'
    size={36}
    onPress={(value) => onCheck1(value)}
    />
    <CheckBox
    title='Présentiel'
    textStyle={{fontSize:18, fontWeight:'normal'}}
    containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
    checked={ischecked2}
    checkedColor='#00808B'
    uncheckedColor='#00808B'
    size={36}
    onPress={(value) => onCheck2(value)}
    />
    </View>
    </View>
  <View style={{flexDirection: "row", justifyContent:'flex-end'}}>
  <TouchableOpacity
    style={{ backgroundColor: "transparent", marginHorizontal:20 }}
    onPress={(value) => onCancel(value)}
  >
    <Text style={styles.textStyle}>ANNULER</Text>
  </TouchableOpacity>
  <TouchableOpacity
    style={{ backgroundColor: "transparent" }}
    onPress={(value) => onSubmit(value)}
  >
    <Text style={styles.textStyle}>VALIDER</Text>
  </TouchableOpacity>
  </View>
</View>
</View>
</Modal>


            );




}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modalView: {
    margin: 0,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 25,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
  textStyle: {
  color: '#00808B',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
   margin: 10,
   fontWeight: "bold",
   fontSize: 20
   },
  
   

  });

  export default ModalFilterHome;