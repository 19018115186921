import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect, Component } from 'react';
import { StyleSheet, Text, View, Button, TouchableOpacity, Dimensions, ScrollView, TextInput, Modal, FlatList, Image } from 'react-native';
import { NavigationContainer, useRoute, useFocusEffect } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets, CardStyleInterpolators } from '@react-navigation/stack';
import {Input, CheckBox, Divider, Overlay} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MainScreen from './screens/MainScreen';
import RegisterScreen from './screens/RegisterScreen';
import TabScreen from './screens/TabScreen';
import * as Linking from 'expo-linking';

export default function App() {
  
  const { Navigator, Screen } = createStackNavigator();
  const [data, setData] = useState(null); 
  const prefix = Linking.createURL("/");
  const [isLoading, setLoading] = useState(false);
  
  const linking = {
    prefixe : [prefix],
    config: {
      screens: {
        Main: "Main",
        Tab: "Tab",
        Register: "Register"
      }
    }

  }

  function handleDeepLink(event) {
      let data = Linking.parse(event.url);
      setData(data)
      
  }

  useEffect(() => {
     async function getInitialURL(){
      const initialURL = await Linking.getInitialURL();
      if(initialURL) {setData(Linking.parse(initialURL))}else{setLoading(true)}
   }
    Linking.addEventListener("url", handleDeepLink);
    if(!data){
      getInitialURL()
    }
    return () => {
      Linking.removeEventListener("url")
    }

  }, []);

  return ((data || isLoading) &&
    <NavigationContainer linking={linking}>
      <Navigator initialRouteName="Main" screenOptions={{cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS}}>
        <Screen name="Main" component={MainScreen}   options={{headerShown: false}} initialParams={{ circuit: '', deeplink:data ? data.queryParams.data: undefined }}/>
        <Screen name="Tab" component={TabScreen} options={{headerShown: false}}/>
        <Screen name="Register" component={RegisterScreen} options={{headerShown: false}} />
      </Navigator>
      <StatusBar translucent style="light" />
    </NavigationContainer>
  );

 
}
