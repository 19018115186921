import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, Alert, RefreshControl} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import ModalMsgP from '../components/ModalMsgP';
import ModalQueryP from '../components/ModalQueryP';

const PigeonScreen = props => {

  const {iduser, Pseudo, Classe, item1, item2, item3, item4, item5, item6, flat , Points, Shield} = props.route.params;
  console.log(props.route.params)

  const [body, setbody] = useState(item1);
  const [visage, setvisage] = useState(item2);
  const [slip, setslip] = useState(item3);
  const [cheveux, setcheveux] = useState(item4);
  const [casque, setcasque] = useState(item5);
  const [arme, setarme] = useState(item6);
  const [Images, setImages] = useState('');
  const [Item, setItem] = useState('');
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalVisible3, setModalVisible3] = useState(false);
  const [Question, setQuestion] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const URL = 'https://www.useradventure.net/'
  const URL_items = 'https://www.useradventure.net/Items/'
  const [disabledcolor, setdisabledcolor] = useState("#00808B");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
  fetch(`${URL}json_get_react_data_pigeon.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iduser: iduser})
  })
    .then((response) => response.json())
    .then(response => {setFilteredDataSource(response);
      console.log(response)
           })
    .catch((error) => console.error(error))
    .finally(() => setLoading(false))

    fetch(`${URL}json_get_react_data_pigeon_vu.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
      .then((response) => response.json())
      .then(response => {

             {response.map((item, index) => { 

 fetch(`${URL}json_get_react_update_vu_pigeon.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: item.ID})
    })

              })}})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
            
    
}, []);

  var width = Dimensions.get('window').width;
  var height = Dimensions.get('window').height;

  const cadeau = (text, image) => {
    switch (image.substring(0, 4)) {
      case "slip":
        setslip(image)
        setImages(`${URL_items}${image}.png`)
        break;
      case 'body':
        setbody(image)
        setImages(`${URL_items}${image}.png`)
        break;
      case 'visa':
        setvisage(image)
        setImages(`${URL_items}${image}.png`)
         break;
      case 'chev':
        setcheveux(image)
        setImages(`${URL_items}${image}.png`)
        break;
      case 'casq':
        var x = image.substring(6)
        var img =`casque_${Classe}${x}`
        setcasque(img)
        setImages(`${URL_items}${img}.png`)
        break; 
      case 'arme':
        var x = image.substring(4)
        var img =`casque_${Classe}${x}`
        setarme(img)
        setImages(`${URL_items}${img}.png`)
        break; 
    }
  
fetch(`${URL}json_get_react_update_vu_pigeon.php`, {
  method: 'post',
  headers: {
    'Accept': 'application/json',  // It can be used to overcome cors errors
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({id: text})
})
  .then((response) => response.json())
  .catch((error) => console.error(error))
  .finally(() => setModalVisible2(true))
  }

  const question = () => {
    setModalVisible3(true)
    
      }


const MAJ = () => {
  fetch(`${URL}json_get_react_data_pigeon.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iduser: iduser})
  })
    .then((response) => response.json())
    .then(response => {setFilteredDataSource(response);
      console.log(response)
           })
    .catch((error) => console.error(error))
    .finally(() => setLoading(false))
  
}

const onRefresh = React.useCallback(() => {
  setRefreshing(true);
  setTimeout(() => {
    MAJ()
    setRefreshing(false)}, 2000)
    
}, []);


  const equiper = () => { 
    setModalVisible2(false)
    fetch(`${URL}json_get_react_input_avatar.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iduser: iduser, item1: body, item2: visage, item3: slip, item4: cheveux, item5: casque, item6: arme})
  })
    .then((response) => response.json())
    .catch((error) => console.error(error))
    .finally(() =>  
    props.navigation.navigate('Home', { Cadeau: "0", item1: body, item2: visage, item3: slip, item4: cheveux, item5: casque, item6: arme})) ,
    props.navigation.navigate('Profil', { params: { item1: body, item2: visage, item3: slip, item4: cheveux, item5: casque, item6: arme}, screen : "Compte"})
}

const send = (text) => { 

fetch(`${URL}json_get_react_input_cadeau.php`, {
  method: 'post',
  headers: {
    'Accept': 'application/json',  // It can be used to overcome cors errors
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({iduser: iduser, message: text, type: 'text', qui: 'U'})
})
  .then((response) => response.json())
  .catch((error) => console.error(error))
  .finally(() =>  MAJ(),setModalVisible3(false))
}
  
        return (
             <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{  backgroundColor: '#00808B', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.3,  shadowRadius: 5,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } }}>
          <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
        <View style={styles.button}>
        <Button onPress={() => props.navigation.goBack()} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="chevron-left" color="white" size={46} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>Pigeon Voyageur</Text>
        
        </View>
        <View style={{ justifyContent: 'flex-start', alignItems: 'flex-end',marginTop:Platform.OS === 'web' ? 0 : 30, marginRight: 10}}>
                    <TouchableOpacity  style={{ height:50, width: 50, borderRadius:30, backgroundColor:'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } , elevation: 10}}>
                      <View style={{ flex:1, justifyContent: 'center',alignItems: 'center',marginTop:15}}>

                        <Image style={{height:55, width:40, resizeMode: 'stretch'}} source={require("../assets/pigeon_bulle.png")} />
 
                      </View>
                   </TouchableOpacity>
                  </View>
        </LinearGradient>
        </View>
        </View>
        <FlatList
        style={{marginBottom:70}}
          data={filteredDataSource}
          refreshControl={<RefreshControl refreshing={refreshing}  onRefresh={onRefresh} /> }  
          getItemLayout = {(data, index) => (
            {length: width, offset: width * index + 150, index}
          )}
          backgroundColor='transparent'
          initialScrollIndex={filteredDataSource.length-1}
          renderItem={({ item, index }) => (
            
          <View style={styles.Container}>
                  <View style={{flex:5, flexDirection: "row",justifyContent: 'center',}}>
               {item.Qui  == 'P' ?    <View style={{ flex:1,alignItems: 'center'}}>
                  <View  style={{ height:50, width: 50, borderRadius:30, backgroundColor:'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } , elevation: 10}}>
                      <View style={{  justifyContent: 'center',alignItems: 'center',marginTop:10}}>
                        <Image style={{height:55, width:40, resizeMode: 'stretch'}} source={require("../assets/pigeon_bulle.png")} />
                      </View>
                   </View>
                   </View>:   <View style={{ flex:1}}>
                  
                  </View>}
                   {(() => {
                   switch (item.Type) {
                    case "text":   return (<>
                    {item.Qui  == 'P' ?
                    <View style={{ flex:3,backgroundColor: "white",padding:10 , borderRadius: 5}}>
                    <Text style={{ fontSize:16}}>Bonjour {Pseudo},</Text>
                     <Text style={{ fontSize:16}}>{item.Message}</Text>
                     <Text style={{ fontSize:16, textAlign:'right',marginTop:20}}>Pigeon Voyageur</Text>
                     </View>:<View style={{ backgroundColor: "#00808B", padding:10 , borderRadius: 5,marginHorizontal:10}}>
                     <Text style={{color:'white', fontSize:16,justifyContent: 'center',}}>{item.Message}</Text>
                     </View>}
                     </>);
                    case "cadeau": return  ( <>
                      <View style={{ flex:3,backgroundColor: "white",padding:10 , borderRadius: 5}}>
                      <Text style={{ fontSize:16}}>{Pseudo},</Text>
                       <Text style={{ fontSize:16}}>{item.Message}</Text>
                       <TouchableOpacity onPress={()=> cadeau(item.ID, item.Image)} style={{ height:40,margin:15,  borderRadius:5,borderWidth:2,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
                        <Text style={{ color:'#00808B', fontSize:16, fontWeight:'bold' }}>Ouvrir mon cadeau</Text>
                      </TouchableOpacity>
                      <Text style={{ fontSize:16, textAlign:'right'}}>Pigeon Voyageur</Text>
                       </View>
                       </>
                      );
                      case "etude":   return (<>
                        <View style={{ flex:3,backgroundColor: "white",padding:10 , borderRadius: 5}}>
                        <Text style={{ fontSize:16}}>Bonjour {Pseudo},</Text>
                         <Text style={{ fontSize:16}}>{item.Message}</Text>
                         <TouchableOpacity style={{ height:40,margin:15,  borderRadius:5,borderWidth:2,borderColor:disabledcolor,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} onPress={() => 
            fetch(`${URL}json_get_react_idetude.php`, {
              method: 'post',
              headers: {
                'Accept': 'application/json',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({idetude: item.Etude, iduser: iduser})
            })
              .then((response) => response.json())
              .then(response => {
                
                console.log('etude',response)
                if(response[0].Type_Etude === "en presentiel"){
                  props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : item.ID_Etude, etude : item.Nom_Etude,Points : item.Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Presentiel1"})
                } else if(response[0].Type_Etude === "postule"){
                  props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : item.ID_Etude, etude : item.Nom_Etude,Points : item.Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Postule1"})
                } else if(response[0].Type_Etude === "Rdv"){
                  props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : item.ID_Etude, etude : item.Nom_Etude,Points : item.Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Q_3: response[0].Q_3,Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Rdv1"})
                } else {
                 props.navigation.navigate("Accueil", { params: {flat: flat, iduser: iduser, PointsUser: Points, ShieldUser:Shield, Idetude : item.Etude, etude : response[0].Nom_Etude, Points : response[0].Points,  num_Q: 0, Q: response[0].Q, Q_type: response[0].Q_type, Q_1: response[0].Q_1, Q_2: response[0].Q_2, Img_Packaging: response[0].Img_Packaging, Shield: response[0].Shield, Q_description: response[0].Q_description, Duree: response[0].Duree}, screen : "Etude"})
            }  })
            .catch((error) => alert("Etude déjà réalisée")
            )}>
                        <Text style={{ color:'#00808B', fontSize:16, fontWeight:'bold' }}>Accéder à l'étude</Text>
                      </TouchableOpacity>
                         </View>
                         </>);
                    default:      return ;
                  }
                })()}
        
        {item.Qui  == 'P' ? <View style={{ flex:1,}}>
                  
                   </View>: undefined}
                  </View>
                
                  </View> 
                 
          )}
          //Setting the number of column
       
          keyExtractor={(item, index) => index}
         
          /> 
           <TouchableOpacity onPress={() => question()} style={{backgroundColor:'#FFB217', width:50, height:50, position:'absolute',borderRadius:25, bottom:20, right:20, justifyContent: 'center',alignItems: 'center'}}>
           <MaterialCommunityIcons  name='help' color={'white'} size={25}  />
                  </TouchableOpacity>
       
                  <ModalMsgP isVisible={modalVisible2} onSubmit={() => equiper()}  onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Home',{Cadeau: "0"})}} isImage={Images} props={props}/>
             <ModalQueryP isVisible={modalVisible3} onSubmit={() => send(Question)} onQuery={(text) => setQuestion(text)} onQuit={() => setModalVisible3(false)} isQuestion={Question} props={props}/>
             </View> 
             );
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10,
       paddingHorizontal:10
      },
      textPigeon: {
        fontSize: 18,
        fontWeight: 'bold',
       color: '#FFB217',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
        
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center'
      },
      Container: {
        marginVertical: 10,
      },
     

    });

export default PigeonScreen;
