import React, { useState, useEffect, Component, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import { Rating, AirbnbRating } from 'react-native-elements';
import { ProgressBar } from 'react-native-paper';
import ModalInfo1 from '../components/ModalInfo1';
import ModalQuit1 from '../components/ModalQuit1';
import ModalCamera from '../components/ModalCamera';
import firebase from '../firebase';
import * as ImagePicker from 'expo-image-picker';
import "firebase/firestore";
import moment from 'moment';
import 'moment/locale/fr';

const Identity1Screen = props => {
  const {Code, PointsUser, iduser, flat} = props.route.params;
  const URL = 'https://www.useradventure.net/'
  const [photo, setphoto] = useState(`${URL}appareil.png`);
  console.log('id1',props.route.params)

  var width = Dimensions.get('window').width;
  
  const [initValue, setinitValue] = useState('Selectionner')
  const [rating, setrating] = useState(3.5);
  const [checked, setchecked] = useState(false)
  const [checked2, setchecked2] = useState(false)

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalVisible3, setModalVisible3] = useState(false);
  const [modalVisible4, setModalVisible4] = useState(false);
  
  let index = 0;
  const data = [
      { key: index++, section: true, label: 'Type ?' },
      { key: index++, label: `Aliment` },
      { key: index++, label: 'Médicament' },
      { key: index++, label: 'Produit' },
  ];

const Points = 150;
const [Nom, setNom] = useState('');
const [Fabricant, setFabricant] = useState('');
const [Remarque, setRemarque] = useState('');
const [Genre, setGenre] = useState('');

const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
const [disabled, setdisabled] = useState(false);

const valid = async () => {

  
  if(disabled){
    
    firebase.storage().ref(`${Code}.jpg`).getDownloadURL()
    .then((url) => {


    fetch(`${URL}json_get_react_delete_produit.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: Code })
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() =>
    fetch(`${URL}json_get_react_input_produit.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: Code, name: Nom, quantity: '', image: url, type: Genre, marque: Fabricant, remarque: Remarque})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() => props.navigation.navigate('Avis1',{Code: Code, Name: Nom, PointsUser: PointsUser, iduser: iduser, flat: flat, circuit:'1' })));

  
});
 
}
}

const ChangeNom = (text) => {
  setNom(text)
  if(text != '' && Fabricant !='' && photo !=`${URL}appareil.png` && Genre !=''){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
  console.log(text)
}


const ChangeGenre = (option) => {
  setGenre(option.label)
  setinitValue(option.label)  
  setModalVisible4(false)
  if(option.label != '' && Nom != '' && Fabricant !='' && photo !=`${URL}appareil.png` ){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
  
}



const ChangeFabricant = (text) => {
  setFabricant(text)
  if(text != '' && Nom !='' && photo !=`${URL}appareil.png` && Genre !=''){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
  console.log(text)
   
}



useEffect(() => {
  (async () => {
    if (Platform.OS !== 'web') {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    }
  })();
}, []);

const saveImage = async(photo) => {
var metadata = {
    contentType: 'image/jpg',
  };
  const response = await fetch(photo);
  const blob = await response.blob();
  
    var ref = firebase.storage().ref().child(`${Code}.jpg`);
  
  ref.put(blob, metadata);
  if(Fabricant != '' && Nom !='' && photo !=`${URL}appareil.png` && Genre !=''){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
}

const pickImage = async () => {
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    quality: 0.5,
  });

  console.log(result);

  if (!result.cancelled) {
    setphoto(result.uri);
    var metadata = {
      contentType: 'image/jpg',
    };
    const response = await fetch(result.uri);
    const blob = await response.blob();
   
      var ref = firebase.storage().ref().child(`${Code}.jpg`);
    
    ref.put(blob, metadata);

    if(Fabricant != '' && Nom !='' && photo !=`${URL}appareil.png` && Genre !=''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
  }
};

const ref_input1 = useRef();
const ref_input2 = useRef();
const ref_input3 = useRef();

  
        return (
            <>
              <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
            <View  style={{ height: 115, }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>Identifier le produit</Text>
        <ProgressBar progress={0.1} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
          <View style={styles.Container}>
          
            <View style={styles.sectionContainer}>
            <Text style={styles.text33}>(<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text>) champs obligatoires</Text>
                <Text style={styles.sectionTitle}>Nom du produit<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
                <TextInput style={styles.inputLight} placeholder="" placeholderTextColor="grey" value={Nom} onChangeText={(text)=> ChangeNom(text)}  returnKeyType = { "next" } onSubmitEditing={()=>setModalVisible4(true)} ref={ref_input1}/>
              </View>   
      <Text style={styles.sectionTitle}>Type<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
      <View style={styles.ViewInput}>
                {/* <ModalSelector
                    data={data}
                    initValue={initValue}
                    visible={modalVisible4}
                    initValueTextStyle={{color:'black',textAlign:'left'}}
                    selectTextStyle={{color:'black',textAlign:'left'}}
                    optionContainerStyle={{backgroundColor:'white'}}
                    cancelContainerStyle={{backgroundColor:'white', borderRadius:5}}
                    cancelText="Annuler"
                    overlayStyle={{ flex: 1, padding: '5%', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}
                    onChange={option => ChangeGenre(option) }
                   /> */}
                 </View>   
                 <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Marque / Fabricant<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
                <TextInput style={styles.inputLight} placeholder="" placeholderTextColor="grey" value={Fabricant} onChangeText={(text)=> ChangeFabricant(text)} returnKeyType = { "next" }  ref={ref_input2} />
              </View> 

<Image style={{ backgroundColor:'transparent',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 150,
                        width:'100%',
                        resizeMode: 'contain',
                      }} source={{ uri: photo }} />
         <TouchableOpacity style={styles.button2} onPress={() => { setModalVisible3(true) }}>
              <Text style={{  fontSize: 24,color:'white',fontWeight:'bold'}}>Prendre une photo</Text>
            </TouchableOpacity>
            <Text style={styles.text22}>ou</Text>
            <TouchableOpacity style={styles.button2} onPress={pickImage}>
              <Text style={{  fontSize: 24,color:'white',fontWeight:'bold'}}>Ajouter une photo</Text>
            </TouchableOpacity>



                 <Text style={styles.sectionTitle}>Remarque / Détail</Text>
                 <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Remarque} onChangeText={(text)=> setRemarque(text)}  multiline={true} />
       </View>
       <ModalInfo1 isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
       <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Scan')}} props={props}/>
       <ModalCamera isVisible={modalVisible3} onSubmit={(data) => {setModalVisible3(false), setphoto(data.uri),saveImage(data.uri), ref_input1.current.focus() }} onCancel={() => {setModalVisible3(false) }} />
       {Platform.OS === 'ios' ?<View style={{  height:300 }}></View>: undefined}
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.navigate('Scan')} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#FFB217', fontSize:20, fontWeight:'bold' }} >Annuler</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={valid} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: disabledcolor,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Suivant</Text>
        </TouchableOpacity>
        </View>
</View>
</KeyboardAvoidingView>
            </>
        );
        
      
        }

    const styles = StyleSheet.create({
      button2: {
        height:50,
        borderRadius:5,
        justifyContent: 'center',
      alignItems: "center",
      backgroundColor: '#FFB217',
       padding: 10,
       marginVertical:10,
       marginBottom:20,
       elevation: 20  ,
       shadowColor: 'black', 
       shadowOpacity: 0.3, 
    shadowOffset: { height: 10 }
      },
      Title: {
        paddingTop: 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 30,
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text2: {
        fontSize: 18,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text22: {
        fontSize: 18,
        color: 'black',
        textAlign:'center',
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
        color: '#00808B',
        paddingVertical:0,
       backgroundColor:'transparent',
       textAlign:'right'
      },
      button:{
          marginTop: 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 120,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      inputLight: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center',
        backgroundColor:'white'
      },

    });

export default Identity1Screen;
