import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal, ImageBackground} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const ModalRecompense =  ({ isVisible, onSubmit, onQuit,isImage, props }) => { 

return(
  <Modal
  animationType="fade"
  transparent={true}
  visible={isVisible}
 
>
<View style={styles.centeredView}>
<View style={styles.modalView}>
  <View style={{flexDirection: "row", alignItems:'center', marginBottom:10}}>
  <Text style={styles.textStyle1}>Bravo !</Text>
  </View>
  <Text style={styles.modalText}>Vous avez gagné <Text style={styles.modalTextbold}>un cadeau</Text>.</Text>
  <View style={{alignItems:'center', marginBottom:20,}}>
    <ImageBackground style={{ height:150, width:'100%'}} imageStyle={{resizeMode: 'contain'}} source={{uri:isImage}} />
  </View>
  <View style={{flexDirection: "row", justifyContent:'center'}}>
  {/* <TouchableOpacity
    style={{ height:40,margin:10,padding:5, borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} 
    onPress={(value) => onQuit(value)}
  >
    <Text style={styles.textStyle}>QUITTER</Text>
  </TouchableOpacity> */}
  <TouchableOpacity
    style={{ height:40,width: 60, margin:10,padding:5,  borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} 
    onPress={(value) => onSubmit(value)}
  >
    <Text style={styles.textStyle}>OK</Text>
  </TouchableOpacity>
  </View>
</View>
</View>
</Modal>


            );




}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
  textStyle: {
  color: 'white',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:0,
    fontSize: 24
     },
   modalText: {
   marginBottom: 15
   },
   modalTextbold: {
    marginBottom: 15,
    fontWeight:'bold'
    },
   

  });

  export default ModalRecompense;