import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Animated, ActivityIndicator, Platform, ImageBackground, RefreshControl} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox, Slider} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import { Rating, AirbnbRating } from 'react-native-elements';
import { ProgressBar } from 'react-native-paper';
import ModalInfoAvis from '../components/ModalInfoAvis';
import ModalQuitAvis from '../components/ModalQuitAvis';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from '../components/react-native-simple-radio-button-max';

const Avis7Screen = props => {

  const {Code, flat, Name, Quantity, Points, PointsUser, iduser, Pseudo, person, rating, Temps, Frequence, Remarque, value1, value2, value3, P1, P2, P3 , N1, N2, N3} = props.route.params;

  const URL = 'https://www.useradventure.net/'
  console.log('a7',props.route.params)

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  
  const [level, setlevel] = useState('');
  const [levelold, setlevelold] = useState('');
  const [pointsmax, setPointsmax] = useState('');
  const [Cadeau, setCadeau] = useState("0");
  const [ImageCadeau, setImageCadeau] = useState('');

  var width = Dimensions.get('window').width;


const total = Number(PointsUser) + Number(Points);

fetch(`${URL}json_get_react_level.php`, {
  method: 'post',
  headers: {
    'Accept': 'application/json',  // It can be used to overcome cors errors
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({pointsuser: PointsUser})
})
  .then((response2) => response2.json())
  .then(response2 => {setlevelold(response2[0].Level)
                 

fetch(`${URL}json_get_react_level.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({pointsuser: total})
  })
    .then((response) => response.json())
    .then(response => {setlevel(response[0].Level)
                       setPointsmax(response[0].pts_max)
                       setImageCadeau(response[0].Cadeau)
                       if(response2[0].Level != response[0].Level) {
                         if (response[0].Cadeau != ''){
                         setCadeau(1)
                      
                            }
                         }  })  })
                      
  
const onValide = () =>  { 

if(Cadeau == 1){
  const message = `
  J'ai un nouveau cadeau pour vous.
  Encore merci pour votre aide`

  fetch(`${URL}json_get_react_input_cadeau.php`, {
                          method: 'post',
                          headers: {
                            'Accept': 'application/json',  // It can be used to overcome cors errors
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({iduser: iduser, message: message, type: 'cadeau', image: ImageCadeau, qui: 'P'})
                        })
                          .then((response) => response.json())
                          .catch((error) => console.error(error))
}



  fetch(`${URL}json_get_react_input_avis.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iduser: iduser, scan: Code, Note: rating, Person: person, howling: Temps, frequence: Frequence, remarque: Remarque, Facilite_utilisation: value1, Efficacite_produit: value2, Lisibilite_notice: value3, positif1: P1, positif2: P2, positif3: P3, negatif1: N1, negatif2: N2, negatif3: N3})
  })
    .then((response) => response.json())
    .catch((error) => console.error(error))
    .finally(() =>
  fetch(`${URL}json_get_react_input_fin_avis.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iduser: iduser, points: total})
  })
    .then((response) => response.json())
    .catch((error) => console.error(error))
    .finally(() => props.navigation.navigate('Scanner', { params: { Points : total, level: level, pointsmax: pointsmax}, screen : "Scan"})));
                  
}


  
        return (
            <>
          <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title} numberOfLines={1}>Avis {Name} {Quantity}</Text>
        <ProgressBar progress={1} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
          <View style={styles.Container}>
            <View style={{ justifyContent: 'center', alignItems:'center'}}>
                <Text style={styles.textT1}>Merci pour votre avis consommateur</Text>
                <View style={{ width:250, height:250, borderWidth:3, borderColor:'#FFB217', borderRadius:125, marginBottom:-65, backgroundColor:'white'}}>
                  <View style={{ height:200,justifyContent: 'center', alignItems:'center'}}>
                    <Text style={styles.sectionTitle}>+ {Points} pts</Text>
                  </View>
                </View>
                <Image style={{ height:90,width:'100%', resizeMode: 'contain'}} source={require("../assets/coffre.png")} />
              </View>      
              <View style={{ alignItems:'center', marginTop:30}}>
              <Text style={styles.section3}>Valider <Text style={styles.section1}>pour finaliser le questionnaire et revenir au tableau de bord</Text></Text>
              <Text style={styles.section1}>ou</Text>
              <Text style={styles.section4}>Précedent <Text style={styles.section1}>pour modifier vos réponses</Text></Text>
              <Text style={styles.section2}>Ces réponses sont modifiables à tout moment sur votre profil utilisateur</Text>
              </View>
               </View> 
       <ModalInfoAvis isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
       <ModalQuitAvis isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Scan')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.goBack()} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#FFB217', fontSize:20, fontWeight:'bold' }} >Précédent</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onValide} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Valider</Text>
        </TouchableOpacity>
        </View>
</View>
</View>
            </>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10,
       textAlign:'center',
       marginBottom:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 26,
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text2: {
        fontSize: 18,
        color: 'black',
        paddingVertical:10,
        marginBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:15,
       backgroundColor:'transparent'
      },
      textjaune: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFB217',
        marginLeft:10,       
       backgroundColor:'transparent'
      },
      text4: {
        fontSize: 26,
        color: 'green',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        flex:8,
        height: 50,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      rond: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center'
      },
      section1: {
        fontSize: 16,
        color: 'black',
        fontWeight:'normal',
        marginBottom:10,
        textAlign:'center'
      },
      section2: {
        fontSize: 16,
        color: 'black',
        fontWeight:'bold',
        marginVertical:20,
        textAlign:'center'
      },
      section3: {
        fontSize: 16,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section4: {
        fontSize: 16,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      sectionTitle: {
        fontSize: 22,
        color: 'black',
       marginVertical:10,
        marginTop:5
      },
    });

export default Avis7Screen;
