import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Alert, Platform} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { List } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import ModalCodeBar from '../components/ModalCodeBar';
import axios from 'axios';
import firebase from '../firebase';
import "firebase/firestore";
import "firebase/storage";
import ModalScan from '../components/ModalScan';
import ModalScanNo from '../components/ModalScanNo';
import ModalImageNo from '../components/ModalImageNo';
import ModalCamera from '../components/ModalCamera';
import ModalPhoto from '../components/ModalPhoto';
import { Camera } from 'expo-camera';

const ScanScreen = props => {

    const { iduser, token, flat, Points, Shield, Classe, Pseudo, item1, item2, item3, item4, item5, item6 } = props.route.params;

  console.log(props.route.params)
  const [Etat, setEtat] = useState(0);
  const [barcode, setbarcode] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [isLoading1, setLoading1] = useState(true);
  const [modalVisible, setModalVisible] = useState(false); 
  const [modalVisible2, setModalVisible2] = useState(false); 
  const [modalVisible3, setModalVisible3] = useState(false); 
  const [modalVisible4, setModalVisible4] = useState(false); 
  const [modalVisible5, setModalVisible5] = useState(false); 
  const [modalVisible6, setModalVisible6] = useState(false); 
  const backgroundColor = Etat  == 0 ? '#DCDCDC' :  '#FFB217';
  const [Name, setName] = useState(''); 
  const [Code, setCode] = useState(''); 
  const [Images, setImage] = useState('');
  const [quantity, setquantity] = useState('');
  const [brands, setbrands] = useState('');
  const [categories, setcategories] = useState('');
  const [types, settypes] = useState('Aliment');
  const [scanned, setScanned] = useState(false);
  const [hasPermission, setHasPermission] = useState(null);
  const URL = 'https://www.useradventure.net/'
  const URL1 =  "https://world.openfoodfacts.org/api/v0/product/"
  const URL2 =  "https://world.openproductsfacts.org/api/v0/product/"
  const EXT = ".json"

  useEffect(() => {
   
    const unsubscribe = props.navigation.addListener('focus', () => {
       
      fetch(`${URL}json_get_react_saveID.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
    .then((response) => response.json())
    .then((response) =>{
      props.navigation.setParams({Shield: response[0].Shield, Points: response[0].Points, item1: response[0].Item_1, item2: response[0].Item_2, item3: response[0].Item_3, item4: response[0].Item_4, item5: response[0].Item_5, item6: response[0].Item_6, Manualite: response[0].Manualite, Langue_maternelle: response[0].Langue_maternelle, Enfants: response[0].Enfants, Situation: response[0].Situation, Profession: response[0].Profession, Poste: response[0].Poste, Autre_info: response[0].Autre_info, Num_rue: response[0].Num_rue, Rue: response[0].Rue, Code_postal: response[0].Code_postal, Ville: response[0].Ville })
    console.log('DataUser',response) 
    fetch(`${URL}json_get_react_level.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({pointsuser: response[0].Points})
    })
      .then((response2) => response2.json())
      .then(response2 => { props.navigation.setParams({level: response2[0].Level})
      setLoading1(false)
        console.log('Level',response2[0].Level)})
    })
    });

  
    return () => {
      unsubscribe;
    };
  
  }, [props.navigation]);

 

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  
  if (Points > 0) {
    if(Etat==0){
      setEtat(1)
    }          
  }


  const handleBarCodeScanned = ( data ) => {
    console.log('code',data)
    setScanned(true)
    if(data.length > 13){
      var codebar = data.substring(4, 17);
       } else {
        var codebar = data;
       }
       console.log(codebar)
      fetch(`${URL}json_get_react_data_medic.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codebarre: codebar})
      })
        .then((response) => response.json())
        .then(response => {
            setCode(codebar)
            setName(response[0].NOM_COURT)
            setbrands(response[0].PRODUIT)
            if(response[0].Image){
            setImage(response[0].Image)
              }else{
                setImage(`${URL}appareil.png`)
              }
            setcategories('Médicament')
            setScanned(false)
            setModalVisible2(true)  
            setLoading(false)

          } )
          .catch(function (error) {
    fetch(`${URL}json_get_react_produits.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: codebar})
    })
      .then((response) => response.json())
      .then(response => {
          setCode(codebar)
          setName(response[0].product_name)
          if(response[0].image_url){
            setImage(response[0].image_url)
              }else{
                setImage(`${URL}appareil.png`)
              }
          setquantity(response[0].quantity)
          setbrands(response[0].Marque)
          setcategories(response[0].Type)
          //setScanned(false)
          setModalVisible2(true)  
          setLoading(false)
        } )
        .catch(function (error) {
      axios.get(`${URL1}${codebar}${EXT}`).then(function(response){
        console.log('data',response)
    setCode(codebar)
    setName(response.data.product.product_name_fr)
    setImage(response.data.product.image_front_small_url)
    setquantity(response.data.product.quantity)
    setbrands(response.data.product.brands)
    setcategories(response.data.product.categories)
    //setScanned(false)
    setModalVisible2(true)  
    setLoading(false)
    inputImages(response.data.product.image_front_small_url,codebar)
     })
    .catch(function (error) {
      axios.get(`${URL2}${codebar}${EXT}`).then(function(response1){
        setCode(codebar)
        settypes('Produit')
        setName(response1.data.product.product_name_fr)
        setImage(response1.data.product.image_front_small_url)
        setquantity(response1.data.product.quantity)
        setbrands(response1.data.product.brands)
        setcategories(response1.data.product.categories)
        //setScanned(false)
        setModalVisible2(true)  
        setLoading(false)
        inputImages(response1.data.product.image_front_small_url,codebar)
         })
        .catch(function (error) {
      console.log(error);
     // setflashmode(Camera.Constants.FlashMode.off)
       setModalVisible4(true) 
       setLoading(false) 
      //setScanned(false)
    })
    })
        })
    
     
      
   //alert(`Bar code with type ${type} and data ${data} has been scanned!`);  props.navigation.navigate('Avis1',{Code: Code, Name: Name, Quantity: quantity, Points: Points, PointsUser: PointsUser, iduser: iduser })
  })
  }

  const inputImages = async(uri,data) => {

    var metadata = {
      contentType: 'image/jpg',
    };
  const response = await fetch(uri);
  const blob = await response.blob();
  var ref = firebase.storage().ref().child(`${data}.jpg`);
  ref.put(blob, metadata);
  }

  const input = () => {
    firebase.storage().ref(`${Code}.jpg`).getDownloadURL()
    .then((urltoken) => {

      fetch(`${URL}json_get_react_data_medic.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codebarre: Code})
      })
        .then((response) => response.json())
        .then(response => {console.log(response[0].code)})
        .catch((error) => { 

    fetch(`${URL}json_get_react_produits.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: Code})
    })
      .then((response) => response.json())
      .then(response => {console.log(response[0].code)})
      .catch((error) => { 
             
       
 fetch(`${URL}json_get_react_input_produit.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: Code, name: Name, quantity: quantity, image: urltoken, type: types, marque: brands, remarque: ''})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
  })
    })
  })
  }


  const inputMissing = () => {
    firebase.storage().ref(`${Code}.jpg`).getDownloadURL()
    .then((urltoken) => {
if(categories == 'Médicament'){
      fetch(`${URL}json_get_react_update_img_medic.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({codebarre: Code,image: urltoken })
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
     } else {
      fetch(`${URL}json_get_react_update_img_produit.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codebarre: Code,image: urltoken })
      })
        .then((response) => response.json())
        .catch((error) => console.error(error))


     }
  })
  }

 
    return (isLoading1 ? <ActivityIndicator/> :
      <View style={{ flex:1}}>
        <View style={{  height: Platform.OS === 'web' ? 70 : 110 , backgroundColor: '#00808B', elevation: 10  ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 }}}>
        <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
      <View style={{flex:2 }}>
      <Text style={styles.Title}>Avis consommateur</Text>
      </View>
      </LinearGradient>
      </View>
          <ScrollView>
           <View style={{flex:1, marginHorizontal:24}}>
                <View style={styles.imageContainer}>
                    <Image style={styles.stretch} source={require("../assets/pigeon_scan.png")} />
                </View>
                <Text>Donner votre avis consommateur après l'utilisation d'un produit ou d'un serivce permettra d'aider les concepteurs à l'améliorer.</Text>
                <Text style={styles.soustitre}>Durée :</Text>
                <Text>Moins de 5 minutes</Text>
                <Text style={styles.soustitre}>Votre récompense :</Text>
                <Text>+ 100 pts</Text>
                <View style={styles.ContainerButton}>
               { Platform.OS === 'web' ? <>
               <TextInput style={styles.input}  placeholder="Numero de Code Barre" placeholderTextColor="grey" value={barcode} onChangeText={(text)=>{setbarcode(text)}}/>
               <TouchableOpacity style={{height:50, borderRadius:5, justifyContent: 'center', alignItems: "center", backgroundColor: backgroundColor, padding: 10, elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }} onPress={() => {if(Etat  == 0) {Alert.alert("Information","Merci de réaliser votre première mission afin de débloquer les études disponibles et l'ensemble des fonctionnalités de l'application")} else {handleBarCodeScanned(barcode)}}}>
              <Text style={{  fontSize: 24,color:'white',fontWeight:'bold'}}>Valider</Text>
            </TouchableOpacity></> :
                <TouchableOpacity style={{height:50, borderRadius:5, justifyContent: 'center', alignItems: "center", backgroundColor: backgroundColor, padding: 10, elevation: 20  ,shadowColor: 'black', shadowOpacity: 0.3,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }} onPress={() => {if(Etat  == 0) {Alert.alert("Information","Merci de réaliser votre première mission afin de débloquer les études disponibles et l'ensemble des fonctionnalités de l'application")} else {setModalVisible(true)}}}>
              <Text style={{  fontSize: 24,color:'white',fontWeight:'bold'}}>Scanner le produit</Text>
            </TouchableOpacity>}
              </View>
              <View style={styles.ContainerLien}>
              <TouchableOpacity onPress={() => props.navigation.navigate('Identity1',{Code: getRandomInt(999999999999999999), PointsUser: Points, iduser: iduser })}>
              <Text style={styles.lien}>Le produit n'a pas de code barre</Text>
              </TouchableOpacity>
              </View>
           </View>
           <ModalCodeBar isVisible={modalVisible} onSubmitCB={(Name, Code, Images, quantity, brands, categories) => {setModalVisible(false),props.navigation.navigate('Avis0',{Code: Code, Name: Name, Quantity: quantity, PointsUser: Points, iduser: iduser, Pseudo: Pseudo , flat: flat})}} onSubmitCBno={(Code) => {setModalVisible(false),props.navigation.navigate('Identity1',{Code: Code, PointsUser: Points, iduser: iduser, Pseudo: Pseudo ,flat: flat})}} onCancelCB={() => {setModalVisible(false) }} props={props}/>
           {isLoading ? undefined : (<ModalScan isVisible={modalVisible2} onSubmit={() => { if(Images == `${URL}appareil.png`){setModalVisible2(false), setModalVisible5(true)  } else { setModalVisible2(false), props.navigation.navigate('Avis0',{Code: Code, Name: Name, Quantity: quantity, PointsUser: Points, iduser: iduser, Pseudo: Pseudo , flat: flat}), input() }}} onCancel={() => { setModalVisible2(false),setScanned(false) }} 
             isName={Name} isImage={Images} isquantity={quantity} isbrands={brands} iscategories={categories} />)}

              {isLoading ? undefined : (<ModalScanNo isVisible={modalVisible4} onSubmit={() => {  setModalVisible4(false), onSubmitCBno(Code) }} onCancel={() => { setModalVisible4(false),setScanned(false) }} />
            
             )}
               {isLoading ? undefined : (<ModalImageNo isVisible={modalVisible5} onSubmit={() => {  setModalVisible5(false), setModalVisible3(true)}} onCancel={() => { setModalVisible5(false),onSubmitCB(Name, Code, Images, quantity, brands, categories ), input() }} />
            
            )}
                {isLoading ? undefined : (  <ModalCamera isVisible={modalVisible3} onSubmit={(data) => {setModalVisible3(false),setModalVisible6(true), setImage(data.uri), inputImages(data.uri,Code) }} onCancel={() => {setModalVisible3(false) }} props={props}/>)}
                {isLoading ? undefined : (  <ModalPhoto isVisible={modalVisible6} onSubmit={(data) => { setModalVisible6(false), onSubmitCB(Name, Code, Images, quantity, brands, categories ),inputMissing() }} onCancel={() => {setModalVisible6(false), setModalVisible3(true)}} isImage={Images} props={props}/>)}
          </ScrollView>
       </View>
      );
      
}

const styles = StyleSheet.create({
   
    stretch: {
        width: '100%',
        height: 300,
        resizeMode: 'contain',
        },
    imageContainer: {
        paddingTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        },
    soustitre: {
        color:'#00808B',
        fontWeight:'bold',
        fontSize:26,
        marginVertical:10
    },
    lien: {
        color:'#00808B',
        fontSize:20,
        marginVertical:10,
        textDecorationLine: 'underline'
    },
    ContainerLien: {
        marginVertical: 10,
        justifyContent: 'center',
        alignItems: 'center',
      },
    buttonDelete1: {
        backgroundColor: '#FFB217',
        height:50,
      },
      buttonDelete: {
        backgroundColor:'transparent'
      },
      buttonsize: {
          fontSize: 24, 
          fontWeight:'bold'
        },
      ContainerButton: {
        marginVertical: 10,
      },
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 24,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      button:{
          marginTop: 40,
          marginLeft:10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button2: {
        height:50,
        borderRadius:5,
        justifyContent: 'center',
      alignItems: "center",
      backgroundColor: 'white',
       padding: 10,
       elevation: 20  ,
       shadowColor: 'black', 
       shadowOpacity: 0.3, 
    shadowOffset: { height: 10 }
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
  });


export default ScanScreen
