import React, { useState, useEffect, Component, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Animated, ActivityIndicator, Platform, KeyboardAvoidingView, ImageBackground, Picker} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox, Slider} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import { Rating, AirbnbRating } from 'react-native-elements';
import { ProgressBar } from 'react-native-paper';
import ModalInfoAvis from '../components/ModalInfoAvis';
import ModalQuitAvis from '../components/ModalQuitAvis';
import ModalCamera from '../components/ModalCamera';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from '../components/react-native-simple-radio-button-max';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import RNPickerSelect from '../components/react-native-picker-select-max';

const Avis5Screen = props => {

  const {Code, flat, Name, Quantity, Points, PointsUser, iduser, Pseudo, person, rating, Temps, Frequence, Remarque, value1, value2, value3, P1, P2, P3, N1, N2, N3, PB} = props.route.params;

  console.log('a5', props.route.params);

  const URL = 'https://www.useradventure.net/'

  let index = 0;
  const data = [
      { value: index++, label: `Une seule fois` },
      { value: index++, label: 'Une fois sur 1000' },
      { value: index++, label: `Une fois sur 100` },
      { value: index++, label: `Une fois sur 10` },
      { value: index++, label: `A chaque utilisation` },
    
  ];
  const [photo, setphoto] = useState(`${URL}appareil.png`);
  const [initValue, setinitValue] = useState('Selectionner')
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalVisible3, setModalVisible3] = useState(false);
  const [Remarque1, setRemarque1] = useState('');
  const [Solution, setSolution] = useState('');
  const [nbfois, setnbfois] = useState('');
  const [pb, setpb] = useState(PB + 1);
  const [selectedImage, setSelectedImage] = useState(false);
  var width = Dimensions.get('window').width;


  useEffect(() => {

    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      quality: 1,
    });

    console.log(result);

    if (!result.cancelled) {
      setphoto(result.uri);
      //setSelectedImage(result);
      uploadimage(result.uri)
      ref_input4.current.focus()
    }
  };

  const uploadimage = (uri) => {

    let uriParts = uri.split('.');
    let fileType = uriParts[uriParts.length - 1];
   
    const payload = new FormData();
    payload.append('fileToUpload', {
      uri: uri,
      type: `fileToUpload.${fileType}`,
      name: `image/${fileType}`,
    });

    console.log('payload',payload)

  
    fetch(`${URL}upload.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json', 
        'Content-Type': 'multipart/form-data'
      },
      body: payload
    })
    .then((response) => {response.json()
      console.log('upload',response)});

  }

  const ref_input4 = useRef();

  const ChangeGenre = (option) => {
    setnbfois(option.label)
    setinitValue(option.label)
    /*if(Nom != '' && Prenom != '' && option.key !='' && National !='' && date !=''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }*/
    
  }

  const valid = () =>{
    fetch(`${URL}json_get_react_delete_pb_produit.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: Code , numPB: pb})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() =>
    fetch(`${URL}json_get_react_input_pb_produit.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({scan: Code, probleme: Remarque1, frequence: nbfois, solution: Solution, iduser: iduser, numPB: 0})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() => props.navigation.push('Avis6',{Code: Code, flat: flat, Name: Name, Quantity: Quantity, Points: Points, PointsUser: PointsUser, iduser: iduser, Pseudo: Pseudo, person:person, rating:rating, Temps:Temps, Frequence:Frequence, Remarque:Remarque, value1:value1, value2:value2, value3:value3, P1:P1, P2:P2, P3:P3 , N1:N1, N2:N2, N3:N3, PB: pb })))

  }

        return (
            <>
              <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
           <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20  ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title} numberOfLines={1}>Avis {Name} {Quantity}</Text>
        <ProgressBar progress={0.5} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
          <View style={styles.Container}>
          <Text style={styles.text1}>Décrivez votre problème</Text>
             <Text style={styles.text2}>1. Votre problème *</Text>
             <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Remarque1} onChangeText={(text)=> setRemarque1(text)} multiline={true}/>
             <Text style={styles.text2}>2. Combien de fois avez-vous rencontré le problème ? *</Text>
      <View style={styles.ViewInput}>
      {Platform.OS == "ios" ?
               
               <RNPickerSelect
                style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
           onValueChange={(itemValue, itemIndex) => ChangeGenre(itemValue)}
           selectedValue={initValue}
           items={data}
       /> : 
               <Picker
       selectedValue={initValue}
       style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
       onValueChange={(itemValue, itemIndex) => ChangeGenre(itemValue)}
     >
       {data.map((item, i) =>{ return <Picker.Item label={item.label} value={item.value} />})}
        
     </Picker>}
                 </View>   
                 <Text style={styles.text2}>3. Charger une photo pour illustrer le problème (si vous le souhaitez)</Text>
                 <Image style={{ backgroundColor:'transparent',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 150,
                        width:'100%',
                        resizeMode: 'contain',
                      }} source={{ uri: photo }} />
         <TouchableOpacity style={styles.button2} onPress={() => { setModalVisible3(true) }}>
              <Text style={{  fontSize: 24,color:'white',fontWeight:'bold'}}>Prendre une photo</Text>
            </TouchableOpacity>
            <Text style={styles.text22}>ou</Text>
            <TouchableOpacity style={styles.button2} onPress={pickImage}>
              <Text style={{  fontSize: 24,color:'white',fontWeight:'bold'}}>Ajouter une photo</Text>
            </TouchableOpacity>
            <Text style={styles.text2}>4. Votre solution pour résoudre le problème (si vous en avez une)</Text>
             <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Solution} onChangeText={(text)=> setSolution(text)} multiline={true} ref={ref_input4} />
       </View>
       <ModalCamera isVisible={modalVisible3} onSubmit={(data) => {setModalVisible3(false), setphoto(data.uri) }} onCancel={() => {setModalVisible3(false) }} props={props}/>
       <ModalInfoAvis isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
       <ModalQuitAvis isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Scan')}} props={props}/>
              {Platform.OS === 'ios' ?<View style={{  height:300 }}></View>: undefined}
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.goBack()} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#FFB217', fontSize:20, fontWeight:'bold' }} >Précédent</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={valid} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Suivant</Text>
        </TouchableOpacity>
        </View>
</View>
 </View>
</KeyboardAvoidingView>
            </>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button2: {
        height:50,
        borderRadius:5,
        justifyContent: 'center',
      alignItems: "center",
      backgroundColor: '#FFB217',
       padding: 10,
       marginVertical:10,
      
       elevation: 20  ,
       shadowColor: 'black', 
       shadowOpacity: 0.3, 
    shadowOffset: { height: 10 }
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 26,
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text2: {
        fontSize: 18,
        color: 'black',
       
       backgroundColor:'transparent'
      },
      text22: {
        fontSize: 18,
        color: 'black',
        textAlign:'center',
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 18,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4: {
        fontSize: 26,
        color: 'green',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 120,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      rond: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center',
        backgroundColor:'white'
      },

    });

export default Avis5Screen;
