import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const ModalSharedPref =  ({ isVisible, onSubmit, onQuit, props }) => { 

return(
 
  <Modal
animationType="slide"
transparent={true}
visible={isVisible}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<View style={styles.centeredView}>
<View style={styles.modalView}>
  <View style={{flexDirection: "row", alignItems:'center', marginBottom:10}}>
  <MaterialCommunityIcons name="lock" color='#00808B' size={46} />
  <Text style={styles.textStyle1}>Connexion et sécurité</Text>
  </View>
  <Text style={styles.modalText}>Souhaitez vous enregistrer votre identifiant et mot de passe pour les prochaines connexions ?</Text>
  <View style={{flexDirection: "row", justifyContent:'flex-end'}}>
  <TouchableOpacity
    style={{ backgroundColor: "transparent", marginHorizontal:20 }}
    onPress={(value) => onQuit(value)}
  >
    <Text style={styles.textStyle}>NON</Text>
  </TouchableOpacity>
  <TouchableOpacity
    style={{ backgroundColor: "transparent" }}
    onPress={(value) => onSubmit(value)}
  >
    <Text style={styles.textStyle}>OUI</Text>
  </TouchableOpacity>
  </View>
</View>
</View>
</Modal>


            );




}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
   
    backgroundColor: "white",
    borderRadius: 5,
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
  textStyle: {
  color: '#00808B',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
   marginBottom: 15
   },
  
   

  });

  export default ModalSharedPref;