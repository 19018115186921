import React, { useState, useEffect, Component, useRef  } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity,Pressable, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView, Alert} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import firebase from '../firebase';
import "firebase/auth";
import ModalInfoShield from '../components/ModalInfoShield';
import BoutonEtude from '../components/BoutonEtude';

const ModifPasswordScreen = props  => {

  const { iduser } = props.route.params;

  const URL = 'https://www.useradventure.net/'

  console.log(props.route.params)

  var iChars = "~`!#$%^&*+=-[]\\';,./{}|\":<>?";
  var width = Dimensions.get('window').width;
  
  const [Mail, setMail] = useState('');
  const [icon1, seticon1] = useState('close-circle');
  const [colorIcon1, setcolorIcon1] = useState('transparent');
  const [icon2, seticon2] = useState('close-circle');
  const [colorIcon2, setcolorIcon2] = useState('transparent');
  const [icon3, seticon3] = useState('close-circle');
  const [colorIcon3, setcolorIcon3] = useState('transparent');
  const [icon4, seticon4] = useState('close-circle');
  const [colorIcon4, setcolorIcon4] = useState('transparent');
  const [CMail, setCMail] = useState('');
  const [oldMdp, setoldMdp] = useState('');
  const [Mdp, setMdp] = useState('');
  const [CMdp, setCMdp] = useState('');
  const [securityold, setsecurityold] = useState(true);
  const [security, setsecurity] = useState(true);
  const [securityC, setsecurityC] = useState(true);
  const [dataUser, setDataUser] = useState([]);
  const [car8, set8car] = useState(false);
  const [Scar, setScar] = useState(false);
  const [Maj, setMaj] = useState(false);
  const [Minu, setMinu] = useState(false);
  const [Chif, setChif] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [iconPassold, seticonPassold] = useState('eye-off-outline');
  const [iconPass, seticonPass] = useState('eye-off-outline');
  const [iconCPass, seticonCPass] = useState('eye-off-outline');
  const [Password, setPassword] = useState('');
  const [findMail, setfindMail] = useState('');
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetch(`${URL}json_get_react_data_user.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
      .then((response) => response.json())
      .then(response => {setDataUser(response);   
     setPassword(response[0].Password); 
     console.log(response[0].Password)
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);


  const ChangeoldMdp = () => {
    
   if(oldMdp == Password){
    seticon2('check-circle');
   setcolorIcon2('green');
   if(icon4 == 'check-circle'  && icon3 == 'check-circle'){
    setdisabledcolor('#FFB217')
    setdisabled(true)
  }
  } 
  else {
    seticon2('close-circle');
   setcolorIcon2('red');
   setdisabledcolor('#DCDCDC')
      setdisabled(false)
  }
  };

 
  const verifMdp = () => {
   
    let Maj = false;
    let car8 = false;
    let Scar =false;
    let Minu = false;
    let Chif = false;
    var long = Mdp.length ;

    if (long >= 8){car8 = true}else{car8 = false}

    for (var i = 0; i < long; i++)
    {if (iChars.indexOf(Mdp.charAt(i)) != -1 )
      {Scar = true
      break;}
      else {
      Scar = false
      }
    }
    
    for (var i = 0; i < long; i++)
    {if (Mdp.charAt(i) == Mdp.charAt(i).toUpperCase() )
      {  Maj = true
      break;}
      else {
         Maj = false
      }

    }

      for (var i = 0; i < long; i++)
    {if (Mdp.charAt(i) == Mdp.charAt(i).toLowerCase() )
      {Minu = true
      break;}
      else {
        Minu = false
       
      }
    }

      for (var i = 0; i < long; i++)
      { if (!isNaN(Mdp.charAt(i) * 1))
        {Chif = true
        break;}
        else {
        Chif = false
        }
      }
  
  if(car8 && Scar && Maj && Minu && Chif) 
  {  setsecurity(true)
  seticon3('check-circle');
  setcolorIcon3('green');
  seticonPass('eye-off-outline')
  if(icon2 == 'check-circle'  && icon4 == 'check-circle'){
    setdisabledcolor('#FFB217')
    setdisabled(true)
  }
} else {
seticon3('close-circle');
setcolorIcon3('red');
setdisabledcolor('#DCDCDC')
      setdisabled(false)
}
console.log(car8 , Scar, Maj ,Minu ,Chif)

  };

  const verifCMdp = () => {
   
    if(CMdp === Mdp){
      seticon4('check-circle');
     setcolorIcon4('green');
     if(icon2 == 'check-circle'  && icon3 == 'check-circle'){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    }
    } else {
      seticon4('close-circle');
      setcolorIcon4('red');
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  };

  const visiblePassold = () => {
    if(iconPassold === 'eye-off-outline'){
      seticonPassold('eye-outline')
      setsecurityold(false)
    } else {
      seticonPassold('eye-off-outline')
      setsecurityold(true)
    }
    
      }
  
  const visiblePass = () => {
if(iconPass === 'eye-off-outline'){
  seticonPass('eye-outline')
  setsecurity(false)
} else {
  seticonPass('eye-off-outline')
  setsecurity(true)
}

  }

  const visibleCPass = () => {
    if(iconCPass === 'eye-off-outline'){
      seticonCPass('eye-outline')
      setsecurityC(false)
    } else {
      seticonCPass('eye-off-outline')
      setsecurityC(true)
    }
    
      }
  
  const valide = () => {
    if(disabled){

fetch(`${URL}json_get_react_update_mdp_user.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser:iduser,
                            password:Mdp
                          })
    })
      .then((response) => response.json())
      .then(response => {alert(response); 
        console.log(response);
    
      })
      .catch((error) => console.error(error))
      .finally(() => props.navigation.navigate('Compte'));

    }
  
  };

  const ref_input2 = useRef();
const ref_input3 = useRef();

        return (
          <View style={{ flex:1}}>
         
              <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
          <View style={{ height: Platform.OS === 'web' ? 70 : 110  , backgroundColor: '#00808B', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
          <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>Modifier mon mot de passe</Text>
        </View>
        </LinearGradient>
        </View>
      
          <ScrollView>
          {isLoading ? <ActivityIndicator/> : 
          <>
            <View style={styles.Container}>
             <Text style={styles.text2}>Toutes les informations sont obligatoires</Text>
             
             <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Mot de passe actuel</Text>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', marginVertical: 10}}>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', height: 50,borderColor: '#00808B', borderWidth: 2, borderRadius:10, flex:9, paddingRight:5, backgroundColor:'white'}}>
                    <TextInput style={{paddingHorizontal: 10,fontSize:18, flex:8, paddingVertical:10}} placeholder="" placeholderTextColor="grey" value={oldMdp} onChangeText={text => setoldMdp(text)} onBlur={text => ChangeoldMdp(text)} secureTextEntry={securityold} returnKeyType = { "next" } onSubmitEditing={() => ref_input2.current.focus()} />
                    <MaterialCommunityIcons onPress={visiblePassold}  name={iconPassold} size={30} color='darkgrey'/>
                    </View>
                    <MaterialCommunityIcons   name={icon2} size={40} color={colorIcon2}/>
                  </View>
              </View>   
              <Text style={styles.text3}>Votre mot de passe</Text>
              <Text style={styles.text2}>Votre mot de passe doit contenir au minimum <Text style={styles.text4}>8 caractères</Text> et contenir au minimum : <Text style={styles.text4}>1 majuscule, 1 minuscule, 1 chiffre et 1 caractère special</Text></Text>
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Nouveau mot de passe</Text>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', marginVertical: 10}}>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', height: 50,borderColor: '#00808B', borderWidth: 2, borderRadius:10, flex:9, paddingRight:5, backgroundColor:'white'}}>
                    <TextInput style={{paddingHorizontal: 10,fontSize:18, flex:8, paddingVertical:10}} placeholder="" placeholderTextColor="grey" value={Mdp} onChangeText={text =>  setMdp(text)} onBlur={text => verifMdp(text)} secureTextEntry={security} returnKeyType = { "next" } onSubmitEditing={() => ref_input3.current.focus()}   ref={ref_input2} />
                    <MaterialCommunityIcons onPress={visiblePass}  name={iconPass} size={30} color='darkgrey'/>
                    </View>
                    <MaterialCommunityIcons   name={icon3} size={40} color={colorIcon3}/>
                  </View>
              </View>   
              <View style={styles.sectionContainer}>
                <Text style={styles.sectionTitle}>Confimer votre nouveau mot de passe</Text>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', marginVertical: 10}}>
                <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', height: 50,borderColor: '#00808B', borderWidth: 2, borderRadius:10, flex:9, paddingRight:5, backgroundColor:'white'}}>
                    <TextInput style={{paddingHorizontal: 10,fontSize:18, flex:8, paddingVertical:10}} placeholder="" placeholderTextColor="grey" value={CMdp} onChangeText={text =>  setCMdp(text)} onBlur={text => verifCMdp(text)} secureTextEntry={securityC}  ref={ref_input3}/>
                    <MaterialCommunityIcons onPress={visibleCPass}  name={iconCPass} size={30} color='darkgrey'/>
                    </View>
                    <MaterialCommunityIcons   name={icon4} size={40} color={colorIcon4}/>
                  </View>
              </View>
            </View> 
            <ModalInfoShield isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }}  />
            </>}
          </ScrollView>
          <View style={{ flexDirection: "column-reverse", height:80, backgroundColor:'#00808B'}}>
   <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,justifyContent: 'center'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
          <BoutonEtude onPress={() => props.navigation.navigate('Compte')} text={'Annuler'} textColor={'#00808B'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={valide} text={'Valider'} textColor={'white'} boutonColor={disabledcolor}></BoutonEtude>
        </View>
</LinearGradient>
</View>
</KeyboardAvoidingView>
           </View>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 14,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text4: {
        fontSize: 14,
        color: '#00808B',
        fontWeight: 'bold',
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      button:{
          marginTop: 40,
          marginLeft:10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      sectionContainer: {
      marginVertical:10
      },
      input: {
        flex:9,
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20,
        marginTop:25
      },
     

    });

export default ModifPasswordScreen;
