import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, Alert} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import { List } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import { useFonts } from 'expo-font';
import AppLoading from 'expo-app-loading';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const CompteScreen =  props  => { 

  const { token, iduser, level, Points, Shield, Classe, Pseudo, item1, item2, item3, item4, item5, item6, Nom, Prenom, Sexe, Nationalite, Date_de_naissance, Num_portable, Email, Num_rue, Rue, Code_postal, Ville, Manualite, Langue_maternelle, Situation, Enfants, Poste, Autre_info, Profession} = props.route.params;

console.log('compte',props.route.params)


const [loaded] = useFonts({
  Rubik: require('../assets/fonts/Rubik-Regular.ttf'),
  RubikSemiBold: require('../assets/fonts/Rubik-SemiBold.ttf'),
  RubikBold: require('../assets/fonts/Rubik-Bold.ttf')
});

  var width = Dimensions.get('window').width;
  var height = Dimensions.get('window').height;

  const [Etat, setEtat] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [body, setbody] = useState('');
  const [visage, setvisage] = useState('');
  const [slip, setslip] = useState('');
  const [cheveux, setcheveux] = useState('');
  const [casque, setcasque] = useState('');
  const [arme, setarme] = useState('');
  const [dataUser, setDataUser] = useState([]);
  const URL = 'https://www.useradventure.net/'
  const URL_items = 'https://www.useradventure.net/Items/'

  if (token == '0') {
    if(Etat==0){
      setEtat(1)
    }          
  }

  

  useEffect(() => {
     
    const unsubscribe = props.navigation.addListener('focus', () => {
      
      fetch(`${URL}json_get_react_saveID.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
    .then((response) => response.json())
    .then((response) =>{setDataUser(response),
      props.navigation.setParams({Shield: response[0].Shield, Points: response[0].Points, item1: response[0].Item_1, item2: response[0].Item_2, item3: response[0].Item_3, item4: response[0].Item_4, item5: response[0].Item_5, item6: response[0].Item_6, Manualite: response[0].Manualite, Langue_maternelle: response[0].Langue_maternelle, Enfants: response[0].Enfants, Situation: response[0].Situation, Profession: response[0].Profession, Poste: response[0].Poste, Autre_info: response[0].Autre_info, Num_rue: response[0].Num_rue, Rue: response[0].Rue, Code_postal: response[0].Code_postal, Ville: response[0].Ville })
    console.log('DataUser',response) 
    fetch(`${URL}json_get_react_level.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({pointsuser: response[0].Points})
    })
      .then((response2) => response2.json())
      .then(response2 => { props.navigation.setParams({level: response2[0].Level})
        console.log('Level',response2[0].Level)})
    })
    });

    return () => {
     
      unsubscribe;
    };

}, [props.navigation]);

  const createTwoButtonAlert = () => {
    props.navigation.navigate('Main',{circuit:'2'}) }


    if (!loaded) {
      return null;
    } else {
          return ( 
            <View style={{ flex:1}}>
          <View style={{ height: Platform.OS === 'web' ? 70 : 110 , backgroundColor: '#00808B', elevation: 10  ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
         <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
     
        <View style={{flex:2 }}>
        <Text style={styles.Title}>Profil</Text>
        </View>
        
        <View style={{flex:2 }}>
          <TouchableOpacity  onPress={createTwoButtonAlert}>
        <Text style={styles.out}>Se déconnecter</Text>
         </TouchableOpacity>
        </View>
      
        </LinearGradient>
      
        </View>
          <ScrollView>
         
            <List.AccordionGroup >
            <List.Accordion title="Informations personnelles" id="1" theme={{colors: {placeholder: 'white', primary: '#00808B', underlineColor: 'transparent', background: 'transparent' } }}  left = { props  =>  <Image
        source={require('../assets/icon_user.png')}
        fadeDuration={0}
        style={{ width: 30, height: 30, marginLeft:15, tintColor:'#00808B'}}
        
      />  } >
              <Text style={styles.itemStyle}>Nom : {Nom}</Text>  
              <Text style={styles.itemStyle}>Prenom : {Prenom}</Text> 
              {(() => {
                   switch (Sexe) {
                    case "1":   return <Text style={styles.itemStyle}>Genre : Homme</Text>;
                    case "2": return <Text style={styles.itemStyle}>Genre : Femme</Text>;
                    case "3":  return <Text style={styles.itemStyle}>Genre : Je m'identifie comme un homme</Text>;
                    case "4":  return <Text style={styles.itemStyle}>Genre : Je m'identifie comme une femme</Text>;
                    case "5":  return <Text style={styles.itemStyle}>Genre : Je ne m'identifie à aucun genre</Text>;
                    default:      return <Text style={styles.itemStyle}>Genre : </Text>;
                  }
                })()}
              <Text style={styles.itemStyle}>Nationalité : {Nationalite}</Text> 
              <Text style={styles.itemStyle}>Date de naissance : {Date_de_naissance}</Text> 
              <Text style={styles.itemStyle}>Téléphone portable : {Num_portable}</Text> 
              <Text style={styles.itemStyle}>Mail : {Email}</Text> 
              <Text style={styles.itemStyle}>Adresse : {Num_rue} {Rue}</Text> 
              <Text style={styles.itemStyle}>                 {Code_postal}</Text> 
              <Text style={styles.itemStyle}>                 {Ville}</Text> 
              {Etat  == 0 ? undefined : 
              <TouchableOpacity
                  style={styles.buttonStyle}
                  onPress={() => props.navigation.navigate('ModifInfo1',{iduser})}>
                  <Text style={styles.buttontext}>Modifier</Text>
              </TouchableOpacity>}
            </List.Accordion>
            <Divider style={{ backgroundColor: '#00808B' }} />
            <List.Accordion title="Informations complémentaires" id="2" theme={{colors: {placeholder: 'white', primary: '#00808B', underlineColor: 'transparent', background: 'transparent' } }} left={props => <Image
        source={require('../assets/icon_info.png')}
        fadeDuration={0}
        style={{ width: 30, height: 30, marginLeft:15,tintColor:'#00808B'}}
        
      /> } >
              {(() => {
                   switch (Manualite) {
                    case "0":   return <Text style={styles.itemStyle}>Latéralisation : Droitier</Text>;
                    case "1": return <Text style={styles.itemStyle}>Latéralisation : Gaucher</Text>;
                    case "2":  return <Text style={styles.itemStyle}>Latéralisation : Ambidextre</Text>;
                    default:      return <Text style={styles.itemStyle}>Latéralisation : </Text>;
                  }
                })()}
              <Text style={styles.itemStyle}>Langue maternelle : {Langue_maternelle}</Text>  
              {(() => {
                   switch (Situation) {
                    case "0":   return <Text style={styles.itemStyle}>Situation familiale : Célibataire</Text>;
                    case "1": return <Text style={styles.itemStyle}>Situation familiale : En couple</Text>;
                    case "2":  return <Text style={styles.itemStyle}>Situation familiale : Marié(e)</Text>;
                    case "3":  return <Text style={styles.itemStyle}>Situation familiale : Veuf/veuve</Text>;
                    default:      return <Text style={styles.itemStyle}>Situation familiale : </Text>;
                  }
                })()}
              <Text style={styles.itemStyle}>Enfants : {Enfants}</Text>  
              <Text style={styles.itemStyle}>Profession : {Profession} {Poste}</Text>  
              <Text style={styles.itemStyle}>Autre élément : {Autre_info}</Text>  
              {Etat  == 0 ? undefined : 
              <TouchableOpacity
                  style={styles.buttonStyle}
                  onPress={() => props.navigation.navigate('ModifInfo3',{iduser})}>
                  <Text style={styles.buttontext}>Modifier</Text>
              </TouchableOpacity>}
            </List.Accordion>
            <Divider style={{ backgroundColor: '#00808B' }} />
              <List.Accordion title="Votre aventurier" id="3" theme={{colors: {placeholder: 'white', primary: '#00808B', underlineColor: 'transparent', background: 'transparent' } }} left={props => <Image
        source={require('../assets/icon_epee.png')}
        fadeDuration={0}
        style={{ width: 30, height: 30, marginLeft:15,tintColor:'#00808B'}}
        
      /> } >
                <View style={{ height: 200, backgroundColor:'transparent',alignItems: 'center',marginRight:60}}>
                  <View style={{flex:1, width:200, justifyContent: 'center',alignItems: 'center', }}>
                    <ImageBackground style={{height:'100%',width:'100%', resizeMode: 'stretch'}} source={{ uri: `${URL_items}${item1}.png` }}>
                    <ImageBackground style={{height:'100%',width:'100%', resizeMode: 'stretch'}} source={{ uri: `${URL_items}${item2}.png` }} >
                    <ImageBackground style={{height:'100%',width:'100%', resizeMode: 'stretch'}} source={{ uri: `${URL_items}${item3}.png` }} >
                    <ImageBackground style={{height:'100%',width:'100%', resizeMode: 'stretch'}} source={{ uri: `${URL_items}${item4}.png` }} >
                    <ImageBackground style={{height:'100%',width:'100%', resizeMode: 'stretch'}} source={{ uri: `${URL_items}${item5}.png` }} >
                    <ImageBackground style={{height:'100%',width:'100%', resizeMode: 'stretch'}} source={{ uri: `${URL_items}${item6}.png` }} />
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                  </View>
                </View>
                <Text style={styles.itemStyle}>Classe : {Classe}</Text>  
                <Text style={styles.itemStyle}>Pseudo : {Pseudo}</Text>  
                {Etat  == 0 ? undefined : 
                <TouchableOpacity
                  style={styles.buttonStyle}
                  onPress={() => props.navigation.navigate('Avatar', {iduser,classe:Classe, level: level, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6 })}>
                  <Text style={styles.buttontext}>Modifier</Text>
                </TouchableOpacity>}
              </List.Accordion>
            <Divider style={{ backgroundColor: '#00808B' }} />
              <List.Accordion title="Connexion et sécurité" id="4" theme={{colors: {placeholder: 'white', primary: '#00808B', underlineColor: 'transparent', background: 'transparent' } }} left={props => <Image
        source={require('../assets/icon_cadenas.png')}
        fadeDuration={0}
        style={{ width: 30, height: 30, marginLeft:15,tintColor:'#00808B'}}
        
      /> } >
                <List.Item title="Changer le mot de passe" />
                <Text style={{ marginLeft:10, marginRight:40 }}>Nous vous conseillons d’utiliser un mot de passe sûr que vous n’utilisez nulle part ailleurs</Text>
                {Etat  == 0 ? undefined : 
              <TouchableOpacity
                  style={styles.buttonStyle}
                  onPress={() => props.navigation.navigate('ModifPassword',{iduser})}>
                  <Text style={styles.buttontext}>Modifier</Text>
              </TouchableOpacity>}
              </List.Accordion>
              <Divider style={{ backgroundColor: '#00808B' }} />
              <List.Accordion title="Informations de paiement" id="5" theme={{colors: {placeholder: 'white', primary: '#00808B', underlineColor: 'transparent', background: 'transparent' } }} left={props => <Image
        source={require('../assets/icon_cards.png')}
        fadeDuration={0}
        style={{ width: 30, height: 30, marginLeft:15,tintColor:'#00808B'}}
        
      /> } >
                <Text style={{ marginBottom:10, marginRight:40 }}>Le paiement de vos shields est actuellement effectué à chaque début du mois suivant par chèque postale à votre domicile.</Text>
                <Text style={styles.itemStyle}>Votre adresse actuellement renseignée</Text> 
                <Text style={styles.itemStyle}>Adresse : {Num_rue} {Rue}</Text> 
                <Text style={styles.itemStyle}>                 {Code_postal}</Text> 
                <Text style={styles.itemStyle}>                 {Ville}</Text> 
                {Etat  == 0 ? undefined : 
              <TouchableOpacity
                  style={styles.buttonStyle}
                  onPress={() => props.navigation.navigate('ModifInfo2',{iduser})}>
                  <Text style={styles.buttontext}>Modifier</Text>
              </TouchableOpacity>} 
              {Etat  == 0 ? undefined : 
          <TouchableOpacity
          style={{backgroundColor:'transparent', alignItems: 'center', marginLeft:0,  marginBottom:20}}
          onPress={() => props.navigation.navigate('Tab')}>
          <Text style={{  fontSize: 16, color:'#00808B',marginLeft:-60, textDecorationLine: 'underline'}}>Télécharger le récapitulatif des paiements</Text>
              </TouchableOpacity> }               
              </List.Accordion>
          </List.AccordionGroup>
           
          </ScrollView>
          
            </View>

          )}
        
        
      
}

const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingLeft: 24,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      out: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingRight: 24,
        fontSize: 16,
        textAlign:'right',
        color: 'white',
       backgroundColor:'transparent'
      },
      button:{
          marginTop: 40,
          marginLeft:10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
      buttonStyle: {
        backgroundColor: '#003333',
        height:50,
        margin:20,
        width:Dimensions.get('window').width > 600 ? Dimensions.get('window').width/4 : undefined ,
        alignSelf: Dimensions.get('window').width > 600 ? 'center' : undefined ,
        elevation: 15  ,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: 'black', 
        shadowOpacity: 0.3, 
        borderRadius:5,
        shadowRadius: 5, 
        shadowOffset: { height: 8, width:1 }
      },
      buttonsize: {fontSize: 24, },
      buttontext: {
        marginLeft:-60,
        fontSize: 24,
        fontFamily: 'RubikSemiBold',
        color:'white' 
      },
      itemStyle: {
          paddingVertical:5
      }

  });

  export default CompteScreen;

