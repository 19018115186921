import React, { useState, useEffect, Component, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView, Picker} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox,Rating, AirbnbRating} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { ProgressBar } from 'react-native-paper';
import ModalInfoAvis from '../components/ModalInfoAvis';
import ModalQuitAvis from '../components/ModalQuitAvis';
import RNPickerSelect from '../components/react-native-picker-select-max';

const Avis1Screen = props => {

  const {Code, Name, Quantity, PointsUser, iduser, circuit, Pseudo, flat} = props.route.params;

  console.log('a1',props.route.params)

  var width = Dimensions.get('window').width;
  const [Remarque, setRemarque] = useState('');
  const [initValue, setinitValue] = useState('Selectionner')
  const [initValue2, setinitValue2] = useState('Selectionner')
  const [rating, setrating] = useState(3);
  const [checked, setchecked] = useState(false)
  const [checked2, setchecked2] = useState(false)
  const [Temps, setTemps] = useState('Selectionner');
  const [Frequence, setFrequence] = useState('Selectionner');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);

  const retour = circuit  === '1' ? "Précédent" : "Annuler";
  const Pointscircuit = circuit  === '1' ? 150 : 100;
  
  let index = 0;
  const data = [
   
      {  label: `Aujourd'hui ou seulement quelques jours`,value: index++ },
      {  label: 'Ce mois-ci', value:  index++},
      {  label: `Cette année` , value:  index++},
      {  label: `Plus d'un an` , value:  index++},
      {  label: `Plus de 5 ans`, value:  index++ },
      {  label: `Plus de 10 ans` , value:  index++},
  ];
  let index2 = 0;
  const data2 = [
    { value: index2++, label: `Tous les jours` },
    { value: index2++, label: 'Une ou plusieurs fois par semaine' },
    { value: index2++, label: `Une ou quelques fois par mois` },
    { value: index2++, label: `Une ou quelques fois par an` },
    { value: index2++, label: `Qu'une seule fois` },
];

const ChangeGenre = (option) => {
  console.log('Temps',option)
  setTemps(option)
  setinitValue(option)
  if(Frequence != 'Selectionner' && person != -1 && option != 'Selectionner' ){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }

}

const ChangeGenre1 = (option) => {
  setFrequence(option)
  setinitValue2(option)
  if(Temps != 'Selectionner' && person != -1 && option != 'Selectionner' ){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
  
}

const changechecked = (text) => {
  setchecked(text)
  const person = checked && checked2  ? 3 : checked ? 1 : checked2 ? 2 : -1;
  if(Temps != 'Selectionner' && (checked2 == true || text == true) && Frequence !='Selectionner' ){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
  
}

const changechecked2 = (text) => {
  setchecked2(text)
  if(Temps != 'Selectionner' && (checked == true || text == true) && Frequence !='Selectionner' ){
    setdisabledcolor('#00808B')
    setdisabled(true)
  } else {
    setdisabledcolor('#DCDCDC')
    setdisabled(false)
  }
  
}

const ref_input2 = useRef();

const person = checked && checked2  ? 3 : checked ? 1 : checked2 ? 2 : -1;

const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);


  const onValid = () => {
    if(disabled){
    props.navigation.navigate('Avis2',{Code: Code,flat: flat, Name: Name, Quantity: Quantity, Points: Pointscircuit, PointsUser: PointsUser, iduser: iduser, Pseudo: Pseudo, person:person, rating:rating, Temps:Temps, Frequence:Frequence, Remarque:Remarque })

    }
  }

        return (
            <>
               <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{flex:1}}
    >
     <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title} numberOfLines={1}>Avis {Name} {Quantity}</Text>
        <ProgressBar progress={0.1} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
          <View style={styles.Container}>
          <Text style={styles.text1}>Avis général sur le produit</Text>
          <Text style={styles.text33}>(*) champs obligatoires</Text>
             <Text style={styles.text2}>1. Note globale<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
          <AirbnbRating
  count={5}
  showRating={false}
  size={50}
  startingValue={rating}
  onFinishRating={(rating) => setrating(rating)}
/>
           <Text style={styles.text2}>2. Qui a utilisé ce produit ?<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
        <CheckBox
        title='Moi'
        textStyle={{fontSize:22, fontWeight:'normal'}}
        containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
        checked={checked}
        checkedColor='#00808B'
        uncheckedColor='#00808B'
        size={36}
        onPress={() => { if(!checked) {changechecked(true)} else {changechecked(false)} }}
      />
      <CheckBox
        title='Une personne de mon entourage'
        textStyle={{fontSize:22, fontWeight:'normal'}}
        containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
        checked={checked2}
        checkedColor='#00808B'
        uncheckedColor='#00808B'
        size={36}
        onPress={() => { if(!checked2) {changechecked2(true)} else {changechecked2(false)} }}
      />
      <Text style={styles.text2}>3. Depuis combien de temps utilisez vous ce produit ?<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
      <View style={styles.ViewInput}>
      {Platform.OS == "ios" ?
               
               <RNPickerSelect
                style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
           onValueChange={(itemValue) => ChangeGenre(itemValue)}
           selectedValue={initValue}
           items={data}
       /> : 
               <Picker
       selectedValue={initValue}
       style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
       onValueChange={(itemValue) => ChangeGenre(itemValue)}
     >
     <Picker.Item label="Selectionner" value={null} />
       {data.map((item, i) =>{ return <Picker.Item label={item.label} value={item.value} />})}
     </Picker>}
                 </View>   
       
        <Text style={styles.text2}>4. A quelle fréquence utilisez vous ce produit ?<Text style={{color: '#00808B',fontWeight: 'bold'}}>*</Text></Text>
      <View style={styles.ViewInput}>
      {Platform.OS == "ios" ?
               
               <RNPickerSelect
                style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
           onValueChange={(itemValue) => ChangeGenre1(itemValue)}
           selectedValue={initValue2}
           items={data2}
       /> : 
               <Picker
       selectedValue={initValue2}
       style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
       onValueChange={(itemValue) => ChangeGenre1(itemValue)}
     >
      <Picker.Item label="Selectionner" value={null} />
       {data2.map((item, i) =>{ return <Picker.Item label={item.label} value={item.value} />})}
        
     </Picker>}
                {/* <ModalSelector
                    data={data2}
                    initValue={initValue2}
                    style={{ paddingVertical:10}}
                    initValueTextStyle={{color:'black',textAlign:'left'}}
                    selectTextStyle={{color:'black',textAlign:'left'}}
                    optionContainerStyle={{backgroundColor:'white'}}
                    cancelContainerStyle={{backgroundColor:'white', borderRadius:5}}
                    cancelText="Annuler"
                    overlayStyle={{ flex: 1, padding: '5%', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}
                    onChange={ option => ChangeGenre1(option) }/> */}
                 </View>   
                 <Text style={styles.text2}>5. Remarque général</Text>
                 <TextInput style={styles.input} placeholder="" placeholderTextColor="grey" value={Remarque} onChangeText={(text)=> setRemarque(text)} multiline={true} returnKeyType = { "next" }  blurOnSubmit={false}/>
       </View>
              <ModalInfoAvis isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Pointscircuit}  />
              <ModalQuitAvis isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Scan')}} props={props}/>
       {Platform.OS === 'ios' ?<View style={{  height:300 }}></View>: undefined}
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => circuit  === '1' ? props.navigation.navigate('Identity1') : props.navigation.navigate('Scan')} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#FFB217', fontSize:20, fontWeight:'bold' }} >{retour}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onValid} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: disabledcolor,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Suivant</Text>
        </TouchableOpacity>
        </View>
</View>
</View>
</KeyboardAvoidingView>
            </>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center',
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text2: {
        fontSize: 18,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
        color: '#00808B',
        paddingVertical:0,
       backgroundColor:'transparent',
       textAlign:'right'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 150,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        backgroundColor:'white'
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center',
        backgroundColor:'white'
      },

    });

export default Avis1Screen;
