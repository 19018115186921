import React, { useState, useEffect, Component } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView, Alert} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox, Slider, Image as Img} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
//import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from 'react-native-simple-radio-button';
import ModalQuit1 from '../components/ModalQuit1';
import { ProgressBar } from 'react-native-paper';
import ModalInfoEtude from '../components/ModalInfoEtude';

const Postule1Screen = props => {

  const {flat, iduser, PointsUser, ShieldUser, Idetude, Points, Shield, etude, num_Q, Q, Q_1, Q_2, Q_3, Q_4, Q_5, Q_6, Q_7, Q_8, Q_9, Q_10, Q_type, Img_Packaging, Q_description, Duree, Ecomplet, R} = props.route.params;
  console.log(num_Q,props);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
  const [Etat, setEtat] = useState(0);
  const flatlist = Number(flat) + 1;
  const totalPoints = Number(PointsUser) + Number(Points);
  const totalShield = Number(ShieldUser) + Number(Shield);
  const [level, setlevel] = useState('');
  const [levelold, setlevelold] = useState('');
  const [pointsmax, setPointsmax] = useState('');
  const [Cadeau, setCadeau] = useState("0");
  const [ImageCadeau, setImageCadeau] = useState('');
  const [H, setH] = useState(1);
  const [I, setI] = useState(250);
  const [Load, setLoad] = useState(true);
  const [value, setValue] = useState(-1);
  const [valueBplus, setValueBplus] = useState(-1);
  const [value11, setValue11] = useState(50);

  const URL = 'https://www.useradventure.net/'

  var width = Dimensions.get('window').width;

  if(num_Q  == Ecomplet.length - 1 ){

  fetch(`${URL}json_get_react_level.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({pointsuser: PointsUser})
  })
    .then((response2) => response2.json())
    .then(response2 => {setlevelold(response2[0].Level)
                   
  
  fetch(`${URL}json_get_react_level.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({pointsuser: totalPoints})
    })
      .then((response) => response.json())
      .then(response => {setlevel(response[0].Level)
                         setPointsmax(response[0].pts_max)
                         setImageCadeau(response[0].Cadeau)
                         if(response2[0].Level != response[0].Level) {
                           if (response[0].Cadeau != ''){
                           setCadeau(1)
                        
                              }
                           }  })  })

                          }           



  var radio_propsBplus = [];
  for (let i = 1; i < 10; i++) {
    var x = 'Q_'+(i);
    if(eval(x) != ""){
    radio_propsBplus.push(Object.fromEntries( new Map([['label',eval(x)] , ['value' , i ]])));
    }
  }
  console.log('radio',radio_propsBplus)
  console.log('radioprops',radio_propsBplus)

  
  /*let indexB = 0;
  const radio_propsBplus = 
  [
    {label: Q_1, value: indexB++ },
    {label: Q_2, value: indexB++ },
  ];*/

  //variables multicheckbox
  const [check, setcheck] = useState(0)
  const [checked1, setchecked1] = useState(0)
  const [checked2, setchecked2] = useState(0)
  const [checked3, setchecked3] = useState(0)
  const [checked4, setchecked4] = useState(0)
  const [checked5, setchecked5] = useState(0)
  const [checked6, setchecked6] = useState(0)
  const [checked7, setchecked7] = useState(0)
  const [checked8, setchecked8] = useState(0)
  const [checked9, setchecked9] = useState(0)
  const [checked10, setchecked10] = useState(0)

  const changeC1 = (text) =>{
    setchecked1(text)
    if(text+checked2+checked3+checked4+checked5+checked6+checked7+checked8+checked9+checked10 != 0){
        setdisabledcolor('#00808B')
        setdisabled(true)
      } else {
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      }
}

  const changeC2 = (text) =>{
    setchecked2(text)
    if(text+checked1+checked3+checked4+checked5+checked6+checked7+checked8+checked9+checked10 != 0){
        setdisabledcolor('#00808B')
        setdisabled(true)
      } else {
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      }
}

const changeC3 = (text) =>{
  setchecked3(text)
  if(text+checked2+checked1+checked4+checked5+checked6+checked7+checked8+checked9+checked10 != 0){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
}

const changeC4 = (text) =>{
  setchecked4(text)
  if(text+checked2+checked1+checked3+checked5+checked6+checked7+checked8+checked9+checked10 != 0){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
}

const changeC5 = (text) =>{
  setchecked5(text)
  if(text+checked2+checked1+checked3+checked4+checked6+checked7+checked8+checked9+checked10 != 0){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
}

const changeC6 = (text) =>{
  setchecked6(text)
  if(text+checked2+checked1+checked3+checked4+checked5+checked7+checked8+checked9+checked10 != 0){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
}

const changeC7 = (text) =>{
  setchecked7(text)
  if(text+checked2+checked1+checked3+checked4+checked5+checked6+checked8+checked9+checked10 != 0){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
}

const changeC8 = (text) =>{
  setchecked8(text)
  if(text+checked2+checked1+checked3+checked4+checked5+checked6+checked7+checked9+checked10 != 0){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
}

const changeC9 = (text) =>{
  setchecked9(text)
  if(text+checked2+checked1+checked3+checked4+checked5+checked6+checked7+checked8+checked10 != 0){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
}

const changeC10 = (text) =>{
  setchecked10(text)
  if(text+checked2+checked1+checked3+checked4+checked5+checked6+checked7+checked8+checked9 != 0){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
}


//variables slider
const changeMoins = () => {
  if(value11 > 0){
setValue11(value11 - 1)
  }
  setdisabledcolor('#00808B')
    setdisabled(true)
}

const changePlus = () => {
  if(value11 < 100){
    setValue11(value11 + 1)
  }
  setdisabledcolor('#00808B')
    setdisabled(true)
  }


   //variables damier
   const [damier, setdamier] = useState('');
   var radio_props1 = [
    {label: `Pas du tout d'accord`, value: 1 },
    {label: `Plutôt pas d'accord`, value: 2 },
    {label: 'Neutre', value: 3 },
    {label: `Plutôt d'accord`, value: 4 },
    {label: `Tout à fait d'accord`, value: 5 }
  ];

  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [value4, setValue4] = useState('');
  const [value5, setValue5] = useState('');
  const [value6, setValue6] = useState('');
  const [value7, setValue7] = useState('');
  const [value8, setValue8] = useState('');
  const [value9, setValue9] = useState('');
  const [value10, setValue10] = useState('');
  const [damier1, setdamier1] = useState(0);
  const [damier2, setdamier2] = useState(0);
  const [damier3, setdamier3] = useState(0);
  const [damier4, setdamier4] = useState(0);
  const [damier5, setdamier5] = useState(0);
  const [damier6, setdamier6] = useState(0);
  const [damier7, setdamier7] = useState(0);
  const [damier8, setdamier8] = useState(0);
  const [damier9, setdamier9] = useState(0);
  const [damier10, setdamier10] = useState(0);
  /*const changeValue1 = (text) => {
    
       

   }*/
   const changeValue1 = (text) => {
var x = 0;
setdamier1(1);
    if(Q_2 == '')
    { x = 1;
     } 
    else if(Q_3 == '')
    { x = 2 
      }
     else if(Q_4 == '')
     { x = 3 
       } 
      else if(Q_5 == '')
      { x = 4 
    }  
       else if(Q_6 == '')
       { x = 5 
        } 
        else if(Q_7 == '')
        { x = 6 
        }
         else if(Q_8 == '')
         { x = 7 
          }
          else if(Q_9 == '')
          { x = 8 
         }
           else if(Q_10 == '')
           { x = 9 
          } else { x = 10}
            // alert(x)
    setValue1(text)
    if(1 + damier2 + damier3  + damier4 + damier5 + damier6 + damier7 + damier8 + damier9 + damier10== x){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }

   }

   const changeValue2 = (text) => {
    var x = 0;
    setdamier2(1);
        if(Q_2 == '')
        { x = 1;
         } 
        else if(Q_3 == '')
        { x = 2 
          }
         else if(Q_4 == '')
         { x = 3 
           } 
          else if(Q_5 == '')
          { x = 4 
        }  
           else if(Q_6 == '')
           { x = 5 
            } 
            else if(Q_7 == '')
            { x = 6 
            }
             else if(Q_8 == '')
             { x = 7 
              }
              else if(Q_9 == '')
              { x = 8 
             }
               else if(Q_10 == '')
               { x = 9 
              } else { x = 10}
                // alert(x)
        setValue2(text)
        if(1 + damier1 + damier3  + damier4 + damier5 + damier6 + damier7 + damier8 + damier9 + damier10== x){
          setdisabledcolor('#00808B')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
    
       }

       const changeValue3 = (text) => {
        var x = 0;
        setdamier3(1);
            if(Q_2 == '')
            { x = 1;
             } 
            else if(Q_3 == '')
            { x = 2 
              }
             else if(Q_4 == '')
             { x = 3 
               } 
              else if(Q_5 == '')
              { x = 4 
            }  
               else if(Q_6 == '')
               { x = 5 
                } 
                else if(Q_7 == '')
                { x = 6 
                }
                 else if(Q_8 == '')
                 { x = 7 
                  }
                  else if(Q_9 == '')
                  { x = 8 
                 }
                   else if(Q_10 == '')
                   { x = 9 
                  } else { x = 10}
                    // alert(x)
            setValue3(text)
            if(1 + damier2 + damier1  + damier4 + damier5 + damier6 + damier7 + damier8 + damier9 + damier10== x){
              setdisabledcolor('#00808B')
              setdisabled(true)
            } else {
              setdisabledcolor('#DCDCDC')
              setdisabled(false)
            }
        
           }

           const changeValue4 = (text) => {
            var x = 0;
            setdamier4(1);
                if(Q_2 == '')
                { x = 1;
                 } 
                else if(Q_3 == '')
                { x = 2 
                  }
                 else if(Q_4 == '')
                 { x = 3 
                   } 
                  else if(Q_5 == '')
                  { x = 4 
                }  
                   else if(Q_6 == '')
                   { x = 5 
                    } 
                    else if(Q_7 == '')
                    { x = 6 
                    }
                     else if(Q_8 == '')
                     { x = 7 
                      }
                      else if(Q_9 == '')
                      { x = 8 
                     }
                       else if(Q_10 == '')
                       { x = 9 
                      } else { x = 10}
                        // alert(x)
                setValue4(text)
                if(1 + damier2 + damier3  + damier1 + damier5 + damier6 + damier7 + damier8 + damier9 + damier10== x){
                  setdisabledcolor('#00808B')
                  setdisabled(true)
                } else {
                  setdisabledcolor('#DCDCDC')
                  setdisabled(false)
                }
            
               }

               const changeValue5 = (text) => {
                var x = 0;
                setdamier5(1);
                    if(Q_2 == '')
                    { x = 1;
                     } 
                    else if(Q_3 == '')
                    { x = 2 
                      }
                     else if(Q_4 == '')
                     { x = 3 
                       } 
                      else if(Q_5 == '')
                      { x = 4 
                    }  
                       else if(Q_6 == '')
                       { x = 5 
                        } 
                        else if(Q_7 == '')
                        { x = 6 
                        }
                         else if(Q_8 == '')
                         { x = 7 
                          }
                          else if(Q_9 == '')
                          { x = 8 
                         }
                           else if(Q_10 == '')
                           { x = 9 
                          } else { x = 10}
                            // alert(x)
                    setValue5(text)
                    if(1 + damier2 + damier3  + damier1 + damier4 + damier6 + damier7 + damier8 + damier9 + damier10== x){
                      setdisabledcolor('#00808B')
                      setdisabled(true)
                    } else {
                      setdisabledcolor('#DCDCDC')
                      setdisabled(false)
                    }
                
                   }

                   const changeValue6 = (text) => {
                    var x = 0;
                    setdamier6(1);
                        if(Q_2 == '')
                        { x = 1;
                         } 
                        else if(Q_3 == '')
                        { x = 2 
                          }
                         else if(Q_4 == '')
                         { x = 3 
                           } 
                          else if(Q_5 == '')
                          { x = 4 
                        }  
                           else if(Q_6 == '')
                           { x = 5 
                            } 
                            else if(Q_7 == '')
                            { x = 6 
                            }
                             else if(Q_8 == '')
                             { x = 7 
                              }
                              else if(Q_9 == '')
                              { x = 8 
                             }
                               else if(Q_10 == '')
                               { x = 9 
                              } else { x = 10}
                                // alert(x)
                        setValue6(text)
                        if(damier1 + damier2 + damier3 + damier4 + damier5 + 1  + damier7 + damier8 + damier9 + damier10== x){
                          setdisabledcolor('#00808B')
                          setdisabled(true)
                        } else {
                          setdisabledcolor('#DCDCDC')
                          setdisabled(false)
                        }
                    
                       }
        
                       const changeValue7 = (text) => {
                        var x = 0;
                        setdamier7(1);
                            if(Q_2 == '')
                            { x = 1;
                             } 
                            else if(Q_3 == '')
                            { x = 2 
                              }
                             else if(Q_4 == '')
                             { x = 3 
                               } 
                              else if(Q_5 == '')
                              { x = 4 
                            }  
                               else if(Q_6 == '')
                               { x = 5 
                                } 
                                else if(Q_7 == '')
                                { x = 6 
                                }
                                 else if(Q_8 == '')
                                 { x = 7 
                                  }
                                  else if(Q_9 == '')
                                  { x = 8 
                                 }
                                   else if(Q_10 == '')
                                   { x = 9 
                                  } else { x = 10}
                                    // alert(x)
                            setValue7(text)
                            if(damier1 + damier2 + damier3 + damier4 + damier5 + damier6 + 1   + damier8 + damier9 + damier10== x){
                              setdisabledcolor('#00808B')
                              setdisabled(true)
                            } else {
                              setdisabledcolor('#DCDCDC')
                              setdisabled(false)
                            }
                        
                           }

                           const changeValue8 = (text) => {
                            var x = 0;
                            setdamier8(1);
                                if(Q_2 == '')
                                { x = 1;
                                 } 
                                else if(Q_3 == '')
                                { x = 2 
                                  }
                                 else if(Q_4 == '')
                                 { x = 3 
                                   } 
                                  else if(Q_5 == '')
                                  { x = 4 
                                }  
                                   else if(Q_6 == '')
                                   { x = 5 
                                    } 
                                    else if(Q_7 == '')
                                    { x = 6 
                                    }
                                     else if(Q_8 == '')
                                     { x = 7 
                                      }
                                      else if(Q_9 == '')
                                      { x = 8 
                                     }
                                       else if(Q_10 == '')
                                       { x = 9 
                                      } else { x = 10}
                                        // alert(x)
                                setValue8(text)
                                if(damier1 + damier2 + damier3 + damier4 + damier5 + damier6 + damier7 + 1 + damier9 + damier10== x){
                                  setdisabledcolor('#00808B')
                                  setdisabled(true)
                                } else {
                                  setdisabledcolor('#DCDCDC')
                                  setdisabled(false)
                                }
                            
                               }
                
            
                               const changeValue9 = (text) => {
                                var x = 0;
                                setdamier9(1);
                                    if(Q_2 == '')
                                    { x = 1;
                                     } 
                                    else if(Q_3 == '')
                                    { x = 2 
                                      }
                                     else if(Q_4 == '')
                                     { x = 3 
                                       } 
                                      else if(Q_5 == '')
                                      { x = 4 
                                    }  
                                       else if(Q_6 == '')
                                       { x = 5 
                                        } 
                                        else if(Q_7 == '')
                                        { x = 6 
                                        }
                                         else if(Q_8 == '')
                                         { x = 7 
                                          }
                                          else if(Q_9 == '')
                                          { x = 8 
                                         }
                                           else if(Q_10 == '')
                                           { x = 9 
                                          } else { x = 10}
                                            // alert(x)
                                    setValue9(text)
                                    if(damier1 + damier2 + damier3 + damier4 + damier5 + damier6 + damier7 + damier8 + 1  + damier10== x){
                                      setdisabledcolor('#00808B')
                                      setdisabled(true)
                                    } else {
                                      setdisabledcolor('#DCDCDC')
                                      setdisabled(false)
                                    }
                                
                                   }
                
                                   
                                   const changeValue10 = (text) => {
                                    var x = 0;
                                    setdamier10(1);
                                        if(Q_2 == '')
                                        { x = 1;
                                         } 
                                        else if(Q_3 == '')
                                        { x = 2 
                                          }
                                         else if(Q_4 == '')
                                         { x = 3 
                                           } 
                                          else if(Q_5 == '')
                                          { x = 4 
                                        }  
                                           else if(Q_6 == '')
                                           { x = 5 
                                            } 
                                            else if(Q_7 == '')
                                            { x = 6 
                                            }
                                             else if(Q_8 == '')
                                             { x = 7 
                                              }
                                              else if(Q_9 == '')
                                              { x = 8 
                                             }
                                               else if(Q_10 == '')
                                               { x = 9 
                                              } else { x = 10}
                                                // alert(x)
                                        setValue10(text)
                                        if(damier1 + damier2 + damier3 + damier4 + damier5 + damier6 + damier7  + damier8 + damier9 + 1== x){
                                          setdisabledcolor('#00808B')
                                          setdisabled(true)
                                        } else {
                                          setdisabledcolor('#DCDCDC')
                                          setdisabled(false)
                                        }
                                    
                                       }
                        

//variables positif
  const [P1, setP1] = useState('');
  const [P2, setP2] = useState('');
  const [P3, setP3] = useState('');

  const [OpenP2, setOpenP2] = useState(false);
  const [OpenP3, setOpenP3] = useState(false);

  const changeP1 = (text) => {
    setP1(text)
    setOpenP2(true)
    if(Number(text.length)+Number(P2.length)+Number(P3.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

  const changeP2 = (text) => {
    setP2(text)
    setOpenP3(true)
    if(Number(text.length)+Number(P1.length)+Number(P3.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

  const changeP3 = (text) => {
    setP3(text)
    if(Number(text.length)+Number(P2.length)+Number(P1.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

//variables négatif
  const [N1, setN1] = useState('');
  const [N2, setN2] = useState('');
  const [N3, setN3] = useState('');

  const [OpenN2, setOpenN2] = useState(false);
  const [OpenN3, setOpenN3] = useState(false);

  const changeN1 = (text) => {
    setN1(text)
    setOpenN2(true)
    if(Number(text.length)+Number(N2.length)+Number(N3.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

  const changeN2 = (text) => {
    setN2(text)
    setOpenN3(true)
    if(Number(text.length)+Number(N1.length)+Number(N3.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}

  const changeN3 = (text) => {
    setN3(text)
    if(Number(text.length)+Number(N2.length)+Number(N1.length) != '0'){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)

  }}
  
  //variables bascule
 

  const ChangeBascule = (text) => {
    setValue(text)
    console.log('value',text)
   if(text != -1){
     setdisabledcolor('#00808B')
     setdisabled(true)
   } else {
     setdisabledcolor('#DCDCDC')
     setdisabled(false)
   }
 }

 const ChangeBasculeplus = (text) => {
  setValueBplus(text)
  console.log('value',text)
 if(text != -1){
   setdisabledcolor('#00808B')
   setdisabled(true)
 } else {
   setdisabledcolor('#DCDCDC')
   setdisabled(false)
 }
}

  

  const onValide = () => {
    if(disabled){
      setdisabled(false)
      setdisabledcolor('#DCDCDC')
    var data =''
    if (Q_type == "Question_ouverte") {
      var payload = {
       "iduser": iduser,
     "idetude": Idetude,
     "numR": num_Q,
     "etape": 0,
     "r1": Nom,
     "r2": '',
     "r3": '',
     "r4": '',
     "r5": '', 
     "r6": '', 
     "r7": '', 
     "r8": '', 
     "r9": '',
     "r10": ''
      };
      data =  payload  ;
      }
     else if(Q_type == "MultiCheckbox")  {
       var payload1 = {
         "iduser": iduser,
         "idetude": Idetude,
         "numR": num_Q,
         "etape": 0,
         "r1": checked1,
         "r2": checked2,
         "r3": checked3,
         "r4": checked4,
         "r5": checked5,
         "r6": checked6, 
         "r7": checked7, 
         "r8": checked8, 
         "r9": checked9,
         "r10": checked10
        };
        data = payload1  ;
      }else if(Q_type == "Bascule")  {
       var payload2 = {
         "iduser": iduser,
         "idetude": Idetude,
         "numR": num_Q,
         "etape": 0,
         "r1": valueBplus,
         "r2": '',
         "r3": '',
         "r4": '',
         "r5": '', 
         "r6": '', 
         "r7": '', 
         "r8": '', 
         "r9": '',
         "r10": ''
        };
        data =  payload2  ;
      }else if(Q_type == "Damier")  {
       var payload3 = {
         "iduser": iduser,
         "idetude": Idetude,
         "numR": num_Q,
         "etape": 0,
         "r1": value1,
         "r2": value2,
         "r3": value3,
         "r4": value4,
         "r5": value5, 
         "r6": value6, 
         "r7": value7, 
         "r8": value8, 
         "r9": value9,
         "r10": value10
        };
        data =  payload3 ;
      }else if(Q_type == "Positif")  {
       var payload4 = {
         "iduser": iduser,
         "idetude": Idetude,
         "numR": num_Q,
         "etape": 0,
         "r1": P1,
         "r2": P2,
         "r3": P3,
         "r4": '',
         "r5": '', 
         "r6": '', 
         "r7": '', 
         "r8": '', 
         "r9": '',
         "r10": ''
        };
        data =  payload4;
      }else if(Q_type == "Negatif")  {
       var payload5 = {
         "iduser": iduser,
         "idetude": Idetude,
         "numR": num_Q,
         "etape": 0,
         "r1": N1,
         "r2": N2,
         "r3": N3,
         "r4": '',
         "r5": '', 
         "r6": '', 
         "r7": '', 
         "r8": '', 
         "r9": '',
         "r10": ''
        };
        data =  payload5 ;
      }else if(Q_type == "Invitation")  {
       var payload6 = {
         "iduser": iduser,
         "idetude": Idetude,
         "numR": num_Q,
         "etape": 0,
         "r1": Mail1,
         "r2": Mail2,
         "r3": Mail3,
         "r4": '',
         "r5": '', 
         "r6": '', 
         "r7": '', 
         "r8": '', 
         "r9": '',
         "r10": ''
        };
        data =  payload6;
      }else if(Q_type == "Slider")  {
       var payload6 = {
         "iduser": iduser,
         "idetude": Idetude,
         "numR": num_Q,
         "etape": 0,
         "r1": value11,
         "r2": '',
         "r3": '',
         "r4": '',
         "r5": '', 
         "r6": '', 
         "r7": '', 
         "r8": '', 
         "r9": '',
         "r10": ''
        };
        data =  payload6 ;
      }
      if(num_Q  != Ecomplet.length  ){
       
        var parse_obj = R
        parse_obj.splice(num_Q-1, 1, data);
      
       console.log('str',parse_obj)
        }      
   
        if(num_Q  == Ecomplet.length - 1 ){
   
         setdisabledcolor('#00808B')
         setdisabled(true)
        }
     if (bouton == "Valider"){
      
      fetch(`${URL}json_get_react_input_resultat_jsonformat.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(R)
      })
        .then((response) => response.json())
        .catch((error) => console.error('R',error))
        .finally(() =>
        fetch(`${URL}json_get_react_input_fin_etude.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({iduser: iduser, points: totalPoints, shield: totalShield })
      })
        .then((response) => response.json())
        .catch((error) => console.error(error))
        .finally(() => props.navigation.navigate('Home',{flat: flatlist, Points : totalPoints, Shield: totalShield, level: level, pointsmax: pointsmax, ImageCadeau: ImageCadeau, Cadeau: Cadeau}),props.navigation.navigate('Don',{ params: { Shield: totalShield, level: level, pointsmax: pointsmax}, screen : "Cash"}),props.navigation.navigate('Scanner',{ params: { Points : totalPoints,Shield: totalShield, level: level, pointsmax: pointsmax}, screen : "Scan"}),props.navigation.navigate('Profil', {params:{ level: level },screen:'Compte'}))
          
        )} else {
          
  
         num_Q  == Ecomplet.length -1 ? props.navigation.setParams({num_Q : num_Q+1 , Q_type:'Positif',R:parse_obj}) : props.navigation.setParams({num_Q : num_Q + 1, Q:Ecomplet[num_Q+1].Q, Q_1:Ecomplet[num_Q+1].Q_1, Q_2:Ecomplet[num_Q+1].Q_2, Q_3:Ecomplet[num_Q+1].Q_3, Q_4:Ecomplet[num_Q+1].Q_4, Q_5:Ecomplet[num_Q+1].Q_5, Q_6:Ecomplet[num_Q+1].Q_6, Q_7:Ecomplet[num_Q+1].Q_7, Q_8:Ecomplet[num_Q+1].Q_8, Q_9:Ecomplet[num_Q+1].Q_9, Q_10:Ecomplet[num_Q+1].Q_10, Q_type: Ecomplet[num_Q+1].Q_type, Img_Packaging: Ecomplet[num_Q+1].Img_Packaging,R:parse_obj, r1:R[num_Q-1].r1});
        if(R.length > num_Q){
          setValue11( R[num_Q].r1)
          setNom( R[num_Q].r1)
          setP1( R[num_Q].r1)
          setP2( R[num_Q].r2)
          setP3( R[num_Q].r3)
          setN1( R[num_Q].r1)
          setN2( R[num_Q].r2)
          setN3( R[num_Q].r3)
          setValueBplus( R[num_Q].r1)
          setchecked1( R[num_Q].r1)
          setchecked2( R[num_Q].r2)
          setchecked3( R[num_Q].r3)
          setchecked4( R[num_Q].r4)
          setchecked5( R[num_Q].r5)
          setchecked6( R[num_Q].r6)
          setchecked7( R[num_Q].r7)
          setchecked8( R[num_Q].r8)
          setchecked9( R[num_Q].r9)
          setchecked10( R[num_Q].r10)
          setValue1( R[num_Q].r1)
          setValue2( R[num_Q].r2)
          setValue3( R[num_Q].r3)
          setValue4( R[num_Q].r4)
          setValue5( R[num_Q].r5)
          setValue6( R[num_Q].r6)
          setValue7( R[num_Q].r7)
          setValue8( R[num_Q].r8)
          setValue9( R[num_Q].r9)
          setValue10( R[num_Q].r10) 
          setdisabledcolor('#00808B')
          setdisabled(true)
        }else{
          setNom("")
          setValue11(50)
          setP1("")
          setP2("")
          setP3("")
          setN1("")
          setN2("")
          setN3("")
          setValueBplus(-1)
          setchecked1(0)
          setchecked2(0)
          setchecked3(0)
          setchecked4(0)
          setchecked5(0)
          setchecked6(0)
          setchecked7(0)
          setchecked8(0)
          setchecked9(0)
          setchecked10(0)
          setValue1("")
          setValue2("")
          setValue3("")
          setValue4("")
          setValue5("")
          setValue6("")
          setValue7("")
          setValue8("")
          setValue9("")
          setValue10("")
         
            
         
          
          
        }
      }
    }
  }

  const onPrevious = () => {

    props.navigation.setParams({num_Q : num_Q - 1, Q:Ecomplet[num_Q-1].Q, Q_1:Ecomplet[num_Q-1].Q_1, Q_2:Ecomplet[num_Q-1].Q_2, Q_3:Ecomplet[num_Q-1].Q_3, Q_4:Ecomplet[num_Q-1].Q_4, Q_5:Ecomplet[num_Q-1].Q_5, Q_6:Ecomplet[num_Q-1].Q_6, Q_7:Ecomplet[num_Q-1].Q_7, Q_8:Ecomplet[num_Q-1].Q_8, Q_9:Ecomplet[num_Q-1].Q_9, Q_10:Ecomplet[num_Q-1].Q_10, Q_type: Ecomplet[num_Q-1].Q_type, Img_Packaging: Ecomplet[num_Q-1].Img_Packaging, r1:num_Q === 1 ? "": R[num_Q-2].r1}) 
    setValue11(num_Q === 1 ? 50 : R[num_Q-2].r1)
    setNom(num_Q === 1 ? "": R[num_Q-2].r1)
    setP1(num_Q === 1 ? "": R[num_Q-2].r1)
    setP2(num_Q === 1 ? "": R[num_Q-2].r2)
    setP3(num_Q === 1 ? "": R[num_Q-2].r3)
    setN1(num_Q === 1 ? "": R[num_Q-2].r1)
    setN2(num_Q === 1 ? "": R[num_Q-2].r2)
    setN3(num_Q === 1 ? "": R[num_Q-2].r3)
    setValueBplus(num_Q === 1 ? 0 : R[num_Q-2].r1)
    setchecked1(num_Q === 1 ? 0 : R[num_Q-2].r1)
    setchecked2(num_Q === 1 ? 0 : R[num_Q-2].r2)
    setchecked3(num_Q === 1 ? 0 : R[num_Q-2].r3)
    setchecked4(num_Q === 1 ? 0 : R[num_Q-2].r4)
    setchecked5(num_Q === 1 ? 0 : R[num_Q-2].r5)
    setchecked6(num_Q === 1 ? 0 : R[num_Q-2].r6)
    setchecked7(num_Q === 1 ? 0 : R[num_Q-2].r7)
    setchecked8(num_Q === 1 ? 0 : R[num_Q-2].r8)
    setchecked9(num_Q === 1 ? 0 : R[num_Q-2].r9)
    setchecked10(num_Q === 1 ? 0 : R[num_Q-2].r10)
    setValue1(num_Q === 1 ? 0 : R[num_Q-2].r1)
    setValue2(num_Q === 1 ? 0 : R[num_Q-2].r2)
    setValue3(num_Q === 1 ? 0 : R[num_Q-2].r3)
    setValue4(num_Q === 1 ? 0 : R[num_Q-2].r4)
    setValue5(num_Q === 1 ? 0 : R[num_Q-2].r5)
    setValue6(num_Q === 1 ? 0 : R[num_Q-2].r6)
    setValue7(num_Q === 1 ? 0 : R[num_Q-2].r7)
    setValue8(num_Q === 1 ? 0 : R[num_Q-2].r8)
    setValue9(num_Q === 1 ? 0 : R[num_Q-2].r9)
    setValue10(num_Q === 1 ? 0 : R[num_Q-2].r10)
    setdisabledcolor('#00808B')
            setdisabled(true)
  }
  
  const onStart = () => {
    if(Ecomplet.length == 1)
    {  fetch(`${URL}json_get_react_input_resultat.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser, idetude: Idetude, numR: 1 , etape: 0})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() =>props.navigation.navigate('Home',{ flat: flatlist}))} 
    else {
    props.navigation.setParams({num_Q : num_Q + 1, Q:Ecomplet[num_Q+1].Q, Q_1:Ecomplet[num_Q+1].Q_1, Q_2:Ecomplet[num_Q+1].Q_2, Q_3:Ecomplet[num_Q+1].Q_3, Q_4:Ecomplet[num_Q+1].Q_4, Q_5:Ecomplet[num_Q+1].Q_5, Q_6:Ecomplet[num_Q+1].Q_6, Q_7:Ecomplet[num_Q+1].Q_7, Q_8:Ecomplet[num_Q+1].Q_8, Q_9:Ecomplet[num_Q+1].Q_9, Q_10:Ecomplet[num_Q+1].Q_10, Q_type: Ecomplet[num_Q+1].Q_type, Img_Packaging: Ecomplet[num_Q+1].Img_Packaging})
    if(R.length > num_Q){
      setValue11( R[num_Q].r1)
      setNom( R[num_Q].r1)
      setP1( R[num_Q].r1)
      setP2( R[num_Q].r2)
      setP3( R[num_Q].r3)
      setN1( R[num_Q].r1)
      setN2( R[num_Q].r2)
      setN3( R[num_Q].r3)
      setValueBplus( R[num_Q].r1)
      setchecked1( R[num_Q].r1)
      setchecked2( R[num_Q].r2)
      setchecked3( R[num_Q].r3)
      setchecked4( R[num_Q].r4)
      setchecked5( R[num_Q].r5)
      setchecked6( R[num_Q].r6)
      setchecked7( R[num_Q].r7)
      setchecked8( R[num_Q].r8)
      setchecked9( R[num_Q].r9)
      setchecked10( R[num_Q].r10)
      setValue1( R[num_Q].r1)
      setValue2( R[num_Q].r2)
      setValue3( R[num_Q].r3)
      setValue4( R[num_Q].r4)
      setValue5( R[num_Q].r5)
      setValue6( R[num_Q].r6)
      setValue7( R[num_Q].r7)
      setValue8( R[num_Q].r8)
      setValue9( R[num_Q].r9)
      setValue10( R[num_Q].r10) 
      setdisabledcolor('#00808B')
      setdisabled(true)}
  }}

  const [Mail1, setMail1] = useState('');
  const [Mail2, setMail2] = useState('');
  const [Mail3, setMail3] = useState('');

  const [Nom, setNom] = useState('');
  const ChangeNom = (text) => {
   setNom(text)
    if(text != ''){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
  }

  var radio_propsBplus = [];
  for (let i = 1; i < 10; i++) {
    var x = 'Q_'+(i);
    if(eval(x) != ""){
    radio_propsBplus.push(Object.fromEntries( new Map([['label',eval(x)] , ['value' , i ]])));
    }
  }


  const barprog = (num_Q/Ecomplet.length).toFixed(2);
  const bouton = num_Q  == Ecomplet.length ? "Valider" : "Suivant";

        return (
          
            <>
              <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      style={{flex:1}}
    >
         <View  style={{ height: 115, }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 ,borderBottomColor:'#00808B',borderBottomWidth:1, backgroundColor: 'white', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color='#00808B' size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>{etude}</Text>
        {Q_type == "Introduction" ? undefined : 
        <ProgressBar progress={barprog} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginRight:10, marginTop:5 }} />
          }
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color='#00808B' size={36} />  } />
        </View>
        </View>
        </View>
        <ScrollView>
         {
          num_Q  == Ecomplet.length  ?   
          <View style={styles.Container}>
          <View style={{ justifyContent: 'center', alignItems:'center'}}>
              <Text style={styles.textT1}>Merci</Text>
              <Text style={styles.text3}>d'avoir postulé !</Text>
              <View style={{ width:250, height:250, borderWidth:3, borderColor:'#FFB217', borderRadius:125, marginBottom:-65, backgroundColor:'white'}}>
                <View style={{ height:190,justifyContent: 'center', alignItems:'center'}}>
                {Shield == 0 ? undefined : 
                <View style={{ flexDirection: "row", marginVertical:5}}>
                    <Text style={styles.text111}>+{Shield} </Text>
                    <Image style={{height:50, width:50, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                  </View>}
                  <Text style={styles.text111}>+ {Points} pts</Text>
                </View>
              </View>
              <Image style={{ height:90,width:'100%', resizeMode: 'contain'}} source={require("../assets/coffre.png")} />
            </View>      
            <View style={{ alignItems:'center', marginTop:30}}>
            <Text style={styles.section3}>Valider <Text style={styles.section1}>pour confirmer</Text></Text>
            <Text style={styles.section1}>ou</Text>
            <Text style={styles.section4}>Précedent <Text style={styles.section1}>pour modifier vos réponses</Text></Text>
            <Text style={styles.section2}>Votre participation à la mission vous sera confirmée au plus vite !</Text>
            </View>
             </View> 
             :
          (() => {  
            switch (Q_type) {
                    case "Introduction":   return (  <>
                     <View style={{ margin:10}}>
                      <Text style={styles.text1}>{Q}</Text>
                      <Text style={styles.text3}>{Q_1}</Text>
                      <View style={{  paddingHorizontal:10}}>
                      <Img style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 250,
                        width:'100%',
                        resizeMode: 'contain',
                      }} source={{ uri: Img_Packaging }} 
                      PlaceholderContent={<ActivityIndicator size="large" color='#FFB217' />}/>
                      </View>  
                      <Text style={styles.text33}>{Q_description}</Text>
                      <Text style={styles.text1}>Durée :</Text>
                      <View style={{ marginVertical:5}}>
                      <Text style={styles.text11}>Environ {Duree} minutes</Text>
                      </View>
                      <Text style={styles.text1}>Votre récompense :</Text>
                      <View style={{ flexDirection: "row", marginTop:5}}>
                    <Text style={styles.text11}>+{Shield} </Text>
                    <Image style={{height:35, width:35, resizeMode: 'stretch'}} source={require("../assets/bouclier_or.png")} /> 
                      <Text style={styles.text11}>   +{Points} pts</Text>
                  </View>
                    
                      </View>
                      </>
                   );
                    case "Question_ouverte": return (  <>
                    <View style={{ margin:10}}>
                      <Text style={styles.text1}>{num_Q}. {Q}</Text>
                      { Q_1  ? <Text style={styles.text2}>{Q_1}</Text> : undefined }
                      { Img_Packaging =="" ? undefined:
                      <>
                       <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: H,
                        width:'100%',
                        resizeMode: 'contain',
                      }} 
                      source={{ uri: Img_Packaging }}
                      onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                      />  
                      <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: I}}>
                          <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                        </View>
                      </>
                      }
                      <TextInput style={styles.input} placeholder="Entrez votre réponse" placeholderTextColor="grey" value={Nom} onChangeText={(text) => ChangeNom(text)} />
                     </View> 
                     </>
);
                    case "Bascule":  return (  <>
                      <View style={{ margin:10}}>
                      <Text style={styles.text1}>{num_Q}. {Q}</Text>
                      { Q_1  ? <Text style={styles.text2}>{Q_1}</Text> : undefined }
                        { Img_Packaging =="" ? undefined:
                        <>
                        <Image style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: H,
                          width:'100%',
                          resizeMode: 'contain',
                          marginBottom:20
                        }} 
                        source={{ uri: Img_Packaging }}
                        onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                        />  
                        <View style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: I}}>
                            <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                          </View>
                        </>
                        }
                        
                      {/* <RadioForm
                      formHorizontal={false}
                      animation={true}
                    >
                    
                      {
                        radio_propsBplus.map((obj, i) => (
                          <RadioButton labelHorizontal={true} key={i} >
                           
                            <RadioButtonInput
                              obj={obj}
                              index={i}
                              isSelected={valueBplus === i+1}
                              onPress={(text)=>{ChangeBasculeplus(text)}}
                              borderWidth={1}
                              buttonInnerColor={'#00808B'}
                              buttonOuterColor={'#00808B'}
                              buttonSize={20}
                              buttonOuterSize={30}
                              buttonStyle={{}}
                              buttonWrapStyle={{marginLeft: 0, paddingVertical:3}}
                            />
                            <RadioButtonLabel
                              obj={obj}
                              index={i}
                              labelHorizontal={true}
                              onPress={(text)=>{ChangeBasculeplus(text)}}
                              labelStyle={{fontSize: 20, color: '#000'}}
                              labelWrapStyle={{}}
                            />
                          </RadioButton>
                        ))
                      }  
                    </RadioForm> */}
                      </View>
                        </>
                    );
                    case "Slider": return (  <>
                      <View style={{ margin:10}}>
                        <Text style={styles.text1}>{num_Q}. {Q}</Text>
                        { Q_1  ? <Text style={styles.text2}>{Q_1}</Text> : undefined }
                      
                        { Img_Packaging =="" ? undefined:
                        <>
                        <Image style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: H,
                          width:'100%',
                          resizeMode: 'contain',
                        }} 
                        source={{ uri: Img_Packaging }}
                        onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                        />  
                        <View style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: I}}>
                            <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                          </View>
                        </>
                        }
                         <Text style={styles.section33}>{value11}</Text>
                         <View style={{ flexDirection: "row",justifyContent: 'space-between',marginBottom:20}}> 
              <TouchableOpacity onPress={()=>{changeMoins()}}  style={{width: 50, height: 50, borderRadius:5,borderWidth:3,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:32, fontWeight:'bold' }}>-</Text>
        </TouchableOpacity>
        <View style={{ flex:1, justifyContent: 'center', paddingHorizontal:10 }}>
          <Slider
          maximumValue={100}
          minimumValue={0}
            value={value11}
            onValueChange={(text) => { setValue11( Math.round(text)) ,setdisabledcolor('#00808B'),
            setdisabled(true)}}
            trackStyle={{ height: 3, backgroundColor: '#00808B' }}
            thumbStyle={{ height: 40, width: 40, backgroundColor: 'white', borderWidth: 3, borderColor: '#00808B' }}
            minimumTrackTintColor='#00808B'
          />
        </View>
        <TouchableOpacity onPress={()=>{changePlus()}} style={{width: 50, height:50, borderRadius:5,borderWidth:3,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:32, fontWeight:'bold' }}>+</Text>
        </TouchableOpacity>
              </View>
                      </View> 
                      </>
                    );
                    case "MultiCheckbox": return (  <>
                      <View style={{ margin:10}}>
                        <Text style={styles.text1}>{num_Q}. {Q}</Text>
                        {Q_1 =='' ? undefined : 
                         <CheckBox
                          title={Q_1}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked1}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked1) {changeC1(1)} else {changeC1(0)}}}
                        />}
                         {Q_2 =='' ? undefined : 
                         <CheckBox
                          title={Q_2}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked2}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked2) {changeC2(1)} else {changeC2(0)}}}
                        />}
                         {Q_3 =='' ? undefined : 
                         <CheckBox
                          title={Q_3}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked3}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked3) {changeC3(1)} else {changeC3(0)} }}
                        />}
                         {Q_4 =='' ? undefined : 
                         <CheckBox
                          title={Q_4}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked4}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked4) {changeC4(1)} else {changeC4(0)} }}
                        />}
                        {Q_5 =='' ? undefined : 
                         <CheckBox
                          title={Q_5}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked5}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked5) {changeC5(1)} else {changeC5(0)} }}
                        />}
                        {Q_6 =='' ? undefined : 
                         <CheckBox
                          title={Q_6}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked6}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked6) {changeC6(1)} else {changeC6(0)} }}
                        />}
                        {Q_7 =='' ? undefined : 
                         <CheckBox
                          title={Q_7}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked7}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked7) {changeC7(1)} else {changeC7(0)} }}
                        />}
                        {Q_8 =='' ? undefined : 
                         <CheckBox
                          title={Q_8}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked8}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked8) {changeC8(1)} else {changeC8(0)} }}
                        />}
                        {Q_9 =='' ? undefined : 
                         <CheckBox
                          title={Q_9}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked9}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked9) {changeC9(1)} else {changeC9(0)} }}
                        />}
                        {Q_10 =='' ? undefined : 
                         <CheckBox
                          title={Q_10}
                          textStyle={{fontSize:22, fontWeight:'normal'}}
                          containerStyle={{backgroundColor:'transparent', borderWidth:0, padding:0}}
                          checked={checked10}
                          checkedColor='#00808B'
                          uncheckedColor='#00808B'
                          size={36}
                          onPress={() => { if(!checked10) {changeC10(1)} else {changeC10(0)} }}
                        />}
                      </View> 
                      </>
);
                    case "Damier":  return (
                      
                      <>
                    
                      <View style={{ margin:10}}>
                      <Text style={styles.text1}>{num_Q}. {Q}</Text>
                      {Q_1 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_1}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value1}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value1) => {changeValue1(value1)}}
                    />
                    </View>
                    </View>}
                    {Q_2 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_2}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value2}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value2) => {changeValue2(value2)}}
                    />
                    </View>
                    </View>}
                    {Q_3 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_3}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value3}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value3) => {changeValue3(value3)}}
                    />
                    </View>
                    </View>}
                    {Q_4 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_4}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value4}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value4) => {changeValue4(value4)}}
                    />
                    </View>
                    </View>}
                    {Q_5 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_5}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value5}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value5) => {changeValue5(value5)}}
                    />
                    </View>
                    </View>}
                    {Q_6 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_6}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value6}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value6) => {changeValue6(value6)}}
                    />
                    </View>
                    </View>}
                    {Q_7 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_7}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value7}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value7) => {changeValue7(value7)}}
                    />
                    </View>
                    </View>}
                    {Q_8 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_8}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value8}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value8) => {changeValue8(value8)}}
                    />
                    </View>
                    </View>}
                    {Q_9 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_9}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value9}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value9) => {changeValue9(value9)}}
                    />
                    </View>
                    </View>}
                    {Q_10 =='' ? undefined : 
                      <View>
                      <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="circle-medium" color='#FFB217' size={46} /> 
                      <Text style={styles.text22}>{Q_10}</Text>
                      </View>
                      <View style={{ marginLeft:35}}>
                      <RadioForm
                      radio_props={radio_props1}
                      initial={value10}
                      buttonColor={'#00808B'}
                      selectedButtonColor={'#00808B'}
                      animation={true}
                      labelStyle={{fontSize:20, paddingVertical:10}}
                      onPress={(value10) => {changeValue10(value10)}}
                    />
                    </View>
                    </View>}

                    </View>
                      </>
);
                    case "Positif":  return (  <>
                    <View style={{ margin:10}}>
                    <Text style={styles.text1}>{num_Q}. {Q}</Text>
                    { Q_1  ? <Text style={styles.text1}>{Q_1}</Text> : undefined }
                      { Img_Packaging =="" ? undefined:
                      <>
                       <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: H,
                        width:'100%',
                        resizeMode: 'contain',
                      }} 
                      source={{ uri: Img_Packaging }}
                      onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                      />  
                      <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: I}}>
                          <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                        </View>
                      </>
                      }
                      <View style={{flex:10, flexDirection: "row",marginVertical:10}}> 
                      <View style={styles.rondgreen}>
                      <Text style={styles.text4}>1</Text>
                      </View>
                      <TextInput style={styles.inputgreen} placeholder="Premier point positif, précisez..." placeholderTextColor="black" value={P1} onChangeText={(text) => changeP1(text)} />
                        </View>
                       { OpenP2 == false ? undefined : <View style={{flex:10, flexDirection: "row",marginVertical:10}}> 
                      <View style={styles.rondgreen}>
                      <Text style={styles.text4}>2</Text>
                      </View>
                      <TextInput style={styles.inputgreen} placeholder="Second point positif, précisez..." placeholderTextColor="black" value={P2} onChangeText={(text) => changeP2(text)} />
                        </View>}
                        { OpenP3 == false ? undefined : <View style={{flex:10, flexDirection: "row",marginVertical:10}}> 
                      <View style={styles.rondgreen}>
                      <Text style={styles.text4}>3</Text>
                      </View>
                      <TextInput style={styles.inputgreen} placeholder="Troisième point positif, précisez..." placeholderTextColor="black" value={P3} onChangeText={(text) => changeP3(text)} />
                        </View>}
                        </View>
                      </>
);
                    case "Negatif":  return (  <>
                       <View style={{ margin:10}}>
                    <Text style={styles.text1}>{num_Q}. {Q}</Text>
                    { Q_1  ? <Text style={styles.text1}>{Q_1}</Text> : undefined }
                      { Img_Packaging =="" ? undefined:
                      <>
                       <Image style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: H,
                        width:'100%',
                        resizeMode: 'contain',
                      }} 
                      source={{ uri: Img_Packaging }}
                      onLoadEnd={()=> {setLoad(false),setH(250),setI(0)}}
                      />  
                      <View style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: I}}>
                          <ActivityIndicator size="large" color='#FFB217' animating={Load} /> 
                        </View>
                      </>
                      }
                      <View style={{flex:10, flexDirection: "row",marginVertical:10}}> 
                      <View style={styles.rondred}>
                      <Text style={styles.text4red}>1</Text>
                      </View>
                      <TextInput style={styles.inputred} placeholder="Premier point négatif, précisez..." placeholderTextColor="black" value={N1} onChangeText={(text) => changeN1(text)} />
                        </View>
                        { OpenN2 == false ? undefined :<View style={{flex:10, flexDirection: "row",marginVertical:10}}> 
                      <View style={styles.rondred}>
                      <Text style={styles.text4red}>2</Text>
                      </View>
                      <TextInput style={styles.inputred} placeholder="Second point négatif, précisez..." placeholderTextColor="black" value={N2} onChangeText={(text) => changeN2(text)} />
                        </View>}
                        { OpenN3 == false ? undefined :<View style={{flex:10, flexDirection: "row",marginVertical:10}}> 
                      <View style={styles.rondred}>
                      <Text style={styles.text4red}>3</Text>
                      </View>
                      <TextInput style={styles.inputred} placeholder="Troisième point négatif, précisez..." placeholderTextColor="black" value={N3} onChangeText={(text) => changeN3(text)} />
                        </View>}
                        </View>
                      </>
);
                      case "Invitation":  return (  <>
                     
                        <View style={{ margin:10}}>
                      <Text style={styles.text1}>Invitation 1</Text>
                      <Text style={styles.text222}>{Q_1}</Text>
                      <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                      <TextInput style={styles.input_invit} placeholder="Mail1@pigeon.com" placeholderTextColor="grey" value={Mail1} keyboardType={'email-address'} onChangeText={(text) => verifMail1(text)} returnKeyType = { "next" } onSubmitEditing={() => ref_input2.current.focus()} blurOnSubmit={false}/>
                      <MaterialCommunityIcons   name={icon1} size={40} color={colorIcon1}/>
                      </View>
                      <Text style={styles.text1}>Invitation 2</Text>
                      <Text style={styles.text222}>{Q_2}</Text>
                      <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                      <TextInput style={styles.input_invit} placeholder="Mail2@pigeon.com" placeholderTextColor="grey" value={Mail2} keyboardType={'email-address'} onChangeText={(text) => verifMail2(text)} returnKeyType = { "next" } onSubmitEditing={() => ref_input3.current.focus()} ref={ref_input2}/>
                      <MaterialCommunityIcons   name={icon2} size={40} color={colorIcon2}/>
                      </View>
                      <Text style={styles.text1}>Invitation 3</Text>
                      <Text style={styles.text222}>{Q_3}</Text>
                      <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                      <TextInput style={styles.input_invit} placeholder="Mail3@pigeon.com" placeholderTextColor="grey" value={Mail3} keyboardType={'email-address'} onChangeText={(text) => verifMail3(text)}     ref={ref_input3}/>
                      <MaterialCommunityIcons   name={icon3} size={40} color={colorIcon3}/>
                      </View>
                     </View> 
                     
                      </>
                      );
                    default:      return <Text style={styles.itemStyle}>error</Text>;
                  }
                })()


}
           
              <ModalInfoEtude isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points} shield1={Shield} Duree={Duree} etude={etude}/>
              <ModalQuit1 isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {props.navigation.navigate('Home')}} props={props}/>
          </ScrollView>
          {Q_type == "Introduction" ?   
         <View style={{ flexDirection: "column-reverse",justifyContent: 'center',borderTopColor:'#00808B',borderTopWidth:1, height:80, backgroundColor:'white'}}>
         <View style={{ flexDirection: "row",justifyContent: 'center',marginHorizontal:10, }}>
         <TouchableOpacity onPress={() => onStart()} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#00808B',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
         <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Postuler</Text>
         </TouchableOpacity>
        </View>
        </View> 
        : 
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center',borderTopColor:'#00808B',borderTopWidth:1, height:80, backgroundColor:'white'}}>
        <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => {onPrevious()}} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }} >Précédent</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=> {onValide()}} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: disabledcolor,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>{bouton}</Text>
        </TouchableOpacity>
        </View>
        </View>
        }
         </KeyboardAvoidingView>
            </>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: 40,
        paddingHorizontal: 0,
        fontSize: 22,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 22,
        marginTop: 10,
        marginBottom: 5,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text11: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text111: {
        fontSize: 34,
        fontWeight: 'bold',
        color: 'black',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text22: {
        fontSize: 20,
        color: 'black',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text33: {
        fontSize: 16,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      text4: {
        fontSize: 26,
        color: 'green',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
          marginTop: 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      inputgreen: {
        flex:8,
        height: 50,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      rondgreen: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        justifyContent: 'center',
        alignItems: "center",
       backgroundColor:'transparent'
      },
      section1: {
        fontSize: 16,
        color: 'black',
        fontWeight:'normal',
        marginBottom:10,
        textAlign:'center'
      },
      section2: {
        fontSize: 16,
        color: 'black',
        fontWeight:'bold',
        marginVertical:20,
        textAlign:'center'
      },
      section3: {
        fontSize: 16,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section4: {
        fontSize: 16,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section33: {
        fontSize: 22,
        color: '#00808B',
        fontWeight:'bold',
        marginBottom:10,
        textAlign:'center'
      },
      section44: {
        fontSize: 24,
        color: '#FFB217',
        fontWeight:'bold',
        marginBottom:10,
        marginTop:30,
      },
    });

export default Postule1Screen;
