import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Alert, Platform, Modal} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, ListItem} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import ModalInfoShield from '../components/ModalInfoShield';

const Shields2Screen =  props => {

  const {majData, iduser, items, Don, Shield } = props.route.params;

  console.log(items)
  const majDatalist = Number(majData) + 1;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);

  const URL = 'https://www.useradventure.net/'

  const valide = () => {
    setModalVisible1(true)
  }

  const Go =() => {
    items.map((item, index) => {
      if(item.quantity != 0){
    fetch(`${URL}json_get_react_input_don_shields.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser,idassoc:item.ID, shield: item.quantity})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
    }})
      
      fetch(`${URL}json_get_react_input_Historique_shields.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser,cash:0, don: Don[0]})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() => fetch(`${URL}json_get_react_input_fin_shields.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser, shield: Shield[0]})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
      .finally(() => props.navigation.navigate('Cash', {majData: majDatalist, Shield : Shield[0]}),
                    props.navigation.navigate('Home', { Shield : Shield[0]}))
      ) 
     

  }
   

  const Item = ({ title, quantity }) => (
    <View style={{flexDirection: 'row'}}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.title}> ({quantity})</Text>
    </View>
  );

  const renderItem = ({ item }) => 

   (
     <>
    {item.quantity != '0'?  <Item title={item.Nom_Association} quantity={item.quantity} />: null }
    </>
  );



 
  return (
    <>
    <View  style={{ height: 110, }}>
   <View style={{ height: 110 , backgroundColor: '#00808B', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
   <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
 <View style={styles.button}>
 <Button onPress={() => props.navigation.navigate('Cash')} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="chevron-left" color="white" size={46} />  } />
 </View>
 <View style={{flex:2 }}>
 <Text style={styles.Title}>Faire un don</Text>
 </View>
 <View style={styles.button}>
 <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
 </View>
 </LinearGradient>
 </View>
 </View>
   <ScrollView>
      <View style={styles.Container}>
            <View style={{ justifyContent: 'center', alignItems:'center'}}>
                <Text style={styles.textT1}>Félicitations!</Text>
                <Text style={styles.text3}>Vous venez de faire un don</Text>

                 <View style={{ flex:1,flexDirection: "row", backgroundColor:'transparent'}}>
                 <View style={{  padding:10 }}>
                  <View style={{ width:150, height:150, borderWidth:3, borderColor:'red', borderRadius:75, marginBottom:-55, backgroundColor:'white'}}>
                  <View style={{ height:130,justifyContent: 'center', alignItems:'center'}}>
                  <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center'}}>
                  <Text style={styles.sectionT3}>{Don}</Text>
                    <Image style={{height:30, width:30, resizeMode: 'contain'}} source={require("../assets/bouclier_or.png")} /> 
                  </View>
                 </View>
                 </View>
                 <View style={{height:100, justifyContent: 'center',alignItems: 'center', padding:5 }}>
                  <Image style={{height:'100%',width:'100%', resizeMode: 'contain'}} source={require("../assets/boite-coeur.png")}/>
                </View>
                </View>
                <View style={{flex:1, justifyContent: 'center',alignItems: 'flex-start', padding:10 }}>
                <Text style={styles.text3}>Détails:</Text>
                <View style={{flex:1}}>
                      <FlatList
                      data={items}
                      renderItem={renderItem}
                      keyExtractor={item => item.id}
                      />
                       </View>
                       </View>
              </View>
                
            

                       
              </View>      
              <View style={{ justifyContent: 'center', alignItems:'center',flexDirection: "row" , textAlign:'center'}}>
              <Text style={styles.sectionT4}>Il vous reste <Text style={styles.sectionT3}>{Shield} </Text></Text>
                    <Image style={{height:35, width:35, resizeMode: 'contain'}} source={require("../assets/bouclier_or.png")} /> 
                    <Text style={styles.sectionT4}> en cagnotte</Text>
                  </View>
              <View style={{ alignItems:'center', marginTop:30}}>
              <Text style={styles.section4}>Valider <Text style={styles.section1}>pour finaliser l'attribution des shields</Text></Text>
              <Text style={styles.section1}>ou</Text>
              <Text style={styles.section3}>Modifier <Text style={styles.section1}>pour modifier l'attribution</Text></Text>
              <Text style={styles.section2bis}>Attention</Text>
              <Text style={styles.section2}>Une fois validé, vous ne pourrez plus modifier votre don.</Text>
              </View>
               </View> 
               <ModalInfoShield isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }}  />
   </ScrollView>
   <View style={{ flexDirection: "column-reverse", height:80, backgroundColor:'#00808B'}}>
<LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,justifyContent: 'center'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
 <TouchableOpacity onPress={() => props.navigation.navigate('Shields1')} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
 <Text style={{ color:'#00808B', fontSize:20, fontWeight:'bold' }} >Modifier</Text>
 </TouchableOpacity>
 <TouchableOpacity onPress={valide} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
 <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Valider</Text>
 </TouchableOpacity>
 </View>
</LinearGradient>
<Modal
animationType="slide"
transparent={true}
visible={modalVisible1}
onRequestClose={() => {
alert("Modal has been closed.");
}}
>
<View style={styles.centeredView}>
<View style={styles.modalView}>
  <Text style={styles.modalText}>Attention</Text>
  <Text style={styles.modalTextbold}>Une fois validé, vous ne pourrez plus modifier votre don.</Text>
  <View style={{flexDirection: "row", justifyContent:'flex-end'}}>
  <TouchableOpacity
    style={{ backgroundColor: "transparent", marginHorizontal:20 }}
    onPress={() => setModalVisible1(false)}
  >
    <Text style={styles.textStyle}>Annuler</Text>
  </TouchableOpacity>
  <TouchableOpacity
    style={{ backgroundColor: "transparent" }}
    onPress={(value) => Go(value)}
  >
    <Text style={styles.textStyle}>OK</Text>
  </TouchableOpacity>
  </View>
</View>
</View>
</Modal>
</View>
     </>

);
        
      
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
   textStyle: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    fontSize: 20
     },
     textStyle1: {
      color: '#00808B',
      fontWeight: "bold",
      textAlign: "right",
      marginHorizontal:10,
      fontSize: 24
       },
     modalText: {
     marginBottom: 15
     },
     modalTextbold: {
      marginBottom: 15,
      fontWeight:'bold'
      },
     
Title: {
textAlign: 'center',
paddingTop: 40,
paddingHorizontal: 0,
fontSize: 24,
fontWeight: 'bold',
color: 'white',
backgroundColor:'transparent'
},
textT1: {
fontSize: 24,
fontWeight: 'bold',
color: '#00808B',
backgroundColor:'transparent',
paddingVertical:10
},
textPigeon: {
fontSize: 20,
fontWeight: 'bold',
color: '#00808B',
backgroundColor:'transparent'
},
levelUser: {
fontSize: 12,
fontWeight: 'bold',
color: '#00808B',
backgroundColor:'transparent'
},
text1: {
fontSize: 16,
fontWeight: 'bold',
color: '#00808B',
backgroundColor:'transparent',

},
text2: {
fontSize: 14,
color: '#00808B',
paddingBottom:10,
backgroundColor:'transparent'
},
text3: {
fontSize: 20,
fontWeight: 'bold',
color: '#00808B',
paddingVertical:15,
backgroundColor:'transparent'
},
textjaune: {
fontSize: 14,
fontWeight: 'bold',
color: '#FFB217',
marginLeft:10,       
backgroundColor:'transparent'
},
button:{
  marginTop: 40,
justifyContent: 'center',
alignItems: 'center',
backgroundColor:'transparent'
},
button1: {
  height:50,
  margin:10,
  borderRadius:5,
  justifyContent: 'center',
alignItems: "center",
backgroundColor: '#003333',
 padding: 10,
 elevation: 10  ,
 shadowColor: 'black', 
 shadowOpacity: 0.3, 
shadowOffset: { height: 10 }
},
buttonDelete1: {
backgroundColor:'transparent',
},

buttonsize: {fontSize: 24, },
sectionTitle: {
fontSize: 22,
color: 'black',
marginVertical:10,
marginTop:5
},
section1: {
fontSize: 16,
color: 'black',
fontWeight:'normal',
marginBottom:10,
textAlign:'center'
},
section2: {
fontSize: 16,
color: 'black',
fontWeight:'bold',
marginBottom:20,
textAlign:'center'
},
section2bis: {
  fontSize: 16,
  color: 'black',
  fontWeight:'bold',
  marginTop:20,
  textAlign:'center'
  },
section3: {
fontSize: 16,
color: '#00808B',
fontWeight:'bold',
marginBottom:10,
textAlign:'center'
},
section4: {
fontSize: 16,
color: '#FFB217',
fontWeight:'bold',
marginBottom:10,
textAlign:'center'
},
sectionT3: {
  fontSize: 36,
  color: '#00808B',
  fontWeight:'bold',
  marginBottom:0,
  textAlign:'center',
  paddingEnd:3
  },
  sectionT4: {
  fontSize: 24,
  color: '#FFB217',
  fontWeight:'bold',
  marginBottom:0,
  textAlign:'center'
  },
input: {
height: 50,
borderColor: '#00808B',
borderWidth: 2,
borderRadius:10,
paddingHorizontal: 10,
marginVertical: 10,
},
Container: {
margin: 10,
},
textGrid: {
fontSize: 50,
color: 'transparent',

}

});

  export default Shields2Screen;
