import React, { useState, useEffect, Component } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView,Modal, ActivityIndicator, Alert} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { List } from 'react-native-paper';
import { BarCodeScanner } from 'expo-barcode-scanner';
import ModalScan from '../components/ModalScan';
import ModalScanNo from '../components/ModalScanNo';
import ModalImageNo from '../components/ModalImageNo';
import ModalCamera from '../components/ModalCamera';
import ModalPhoto from '../components/ModalPhoto';
import axios from 'axios';
import { Camera } from 'expo-camera';
import firebase from '../firebase';
import "firebase/firestore";
import "firebase/storage";


const ModalCodeBar =  ({ isVisible, onSubmitCB, onSubmitCBno, onCancelCB,  isName, isImage, isquantity, isbrands, iscategories, props }) => { 

  
  const URL = 'https://www.useradventure.net/'
  const URL1 =  "https://world.openfoodfacts.org/api/v0/product/"
  const URL2 =  "https://world.openproductsfacts.org/api/v0/product/"
  const EXT = ".json"

  var width = Dimensions.get('window').width;
  var height = Dimensions.get('window').height;

  const Points = 100;
  const [isLoading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false); 
  const [modalVisible3, setModalVisible3] = useState(false); 
  const [modalVisible4, setModalVisible4] = useState(false); 
  const [modalVisible5, setModalVisible5] = useState(false); 
  const [modalVisible6, setModalVisible6] = useState(false); 
  const [Name, setName] = useState(''); 
  const [Code, setCode] = useState(''); 
  const [Images, setImage] = useState('');
  const [quantity, setquantity] = useState('');
  const [brands, setbrands] = useState('');
  const [categories, setcategories] = useState('');
  const [types, settypes] = useState('Aliment');

  const [Flash, setFlash] = useState("flashlight-off");
  const [flashmode, setflashmode] = useState(Camera.Constants.FlashMode.off);
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true)
    if(data.length > 13){
      var codebar = data.substring(4, 17);
       } else {
        var codebar = data;
       }
       console.log(codebar)
      fetch(`${URL}json_get_react_data_medic.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codebarre: codebar})
      })
        .then((response) => response.json())
        .then(response => {
            setCode(codebar)
            setName(response[0].NOM_COURT)
            setbrands(response[0].PRODUIT)
            if(response[0].Image){
            setImage(response[0].Image)
              }else{
                setImage(`${URL}appareil.png`)
              }
            setcategories('Médicament')
            setScanned(false)
            setModalVisible2(true)  
            setLoading(false)

          } )
          .catch(function (error) {
    fetch(`${URL}json_get_react_produits.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: codebar})
    })
      .then((response) => response.json())
      .then(response => {
          setCode(codebar)
          setName(response[0].product_name)
          if(response[0].image_url){
            setImage(response[0].image_url)
              }else{
                setImage(`${URL}appareil.png`)
              }
          setquantity(response[0].quantity)
          setbrands(response[0].Marque)
          setcategories(response[0].Type)
          //setScanned(false)
          setModalVisible2(true)  
          setLoading(false)
        } )
        .catch(function (error) {
      axios.get(`${URL1}${codebar}${EXT}`).then(function(response){
    setCode(codebar)
    setName(response.data.product.product_name_fr)
    setImage(response.data.product.image_front_small_url)
    setquantity(response.data.product.quantity)
    setbrands(response.data.product.brands)
    setcategories(response.data.product.categories)
    //setScanned(false)
    setModalVisible2(true)  
    setLoading(false)
    inputImages(response.data.product.image_front_small_url,codebar)
     })
    .catch(function (error) {
      axios.get(`${URL2}${codebar}${EXT}`).then(function(response1){
        setCode(codebar)
        settypes('Produit')
        setName(response1.data.product.product_name_fr)
        setImage(response1.data.product.image_front_small_url)
        setquantity(response1.data.product.quantity)
        setbrands(response1.data.product.brands)
        setcategories(response1.data.product.categories)
        //setScanned(false)
        setModalVisible2(true)  
        setLoading(false)
        inputImages(response1.data.product.image_front_small_url,codebar)
         })
        .catch(function (error) {
      console.log(error);
      setflashmode(Camera.Constants.FlashMode.off)
       setModalVisible4(true) 
       setLoading(false) 
      //setScanned(false)
    })
    })
        })
    
     
      
   //alert(`Bar code with type ${type} and data ${data} has been scanned!`);  props.navigation.navigate('Avis1',{Code: Code, Name: Name, Quantity: quantity, Points: Points, PointsUser: PointsUser, iduser: iduser })
  })
  }


  const inputImages = async(uri,data) => {

    var metadata = {
      contentType: 'image/jpg',
    };
  const response = await fetch(uri);
  const blob = await response.blob();
  var ref = firebase.storage().ref().child(`${data}.jpg`);
  ref.put(blob, metadata);
  }

  const input = () => {
    firebase.storage().ref(`${Code}.jpg`).getDownloadURL()
    .then((urltoken) => {

      fetch(`${URL}json_get_react_data_medic.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codebarre: Code})
      })
        .then((response) => response.json())
        .then(response => {console.log(response[0].code)})
        .catch((error) => { 

    fetch(`${URL}json_get_react_produits.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: Code})
    })
      .then((response) => response.json())
      .then(response => {console.log(response[0].code)})
      .catch((error) => { 
             
       
 fetch(`${URL}json_get_react_input_produit.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({code: Code, name: Name, quantity: quantity, image: urltoken, type: types, marque: brands, remarque: ''})
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
  })
    })
  })
  }


  const inputMissing = () => {
    firebase.storage().ref(`${Code}.jpg`).getDownloadURL()
    .then((urltoken) => {
if(categories == 'Médicament'){
      fetch(`${URL}json_get_react_update_img_medic.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({codebarre: Code,image: urltoken })
    })
      .then((response) => response.json())
      .catch((error) => console.error(error))
     } else {
      fetch(`${URL}json_get_react_update_img_produit.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codebarre: Code,image: urltoken })
      })
        .then((response) => response.json())
        .catch((error) => console.error(error))


     }
  })
  }
      

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

return(
 
  <Modal
animationType="slide"
transparent={false}
visible={isVisible}
>
<View style={styles.container}>
             
             <Camera
               onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
               style={StyleSheet.absoluteFillObject} flashMode={flashmode}
             >
                <View style={styles.button}>
                     <Button onPress={() => onCancelCB()} buttonStyle={styles.buttonDelete1}
                     icon={<MaterialCommunityIcons name="chevron-left" color="white" size={46} /> }   />
                     <TouchableOpacity
                   style={styles.button50}
                   onPress={() => {
                     setflashmode(
                       flashmode === Camera.Constants.FlashMode.torch
                         ? Camera.Constants.FlashMode.off
                         : Camera.Constants.FlashMode.torch
                     );
                     setFlash(
                       Flash === "flashlight"
                         ? "flashlight-off"
                         : "flashlight"
                     );
       
                   }}>
                   <MaterialCommunityIcons name={Flash} color="white" size={40} />
                 </TouchableOpacity>
               </View>
               <View style={{flex:1, justifyContent: 'center', alignItems: 'center',}}>
                 
                 <View style={{ width:width-50, height:height/3, borderColor:'white', borderWidth:0.5, borderRadius:5,  marginTop: -40,}}></View>
               </View>
              
             </Camera>
               {isLoading ? undefined : (<ModalScan isVisible={modalVisible2} onSubmit={() => { if(Images == `${URL}appareil.png`){ setflashmode(Camera.Constants.FlashMode.off),setModalVisible2(false), setModalVisible5(true)  } else { setflashmode(Camera.Constants.FlashMode.off), setModalVisible2(false), onSubmitCB(Name, Code, Images, quantity, brands, categories ), input() }}} onCancel={() => { setModalVisible2(false),setScanned(false) }} 
             isName={Name} isImage={Images} isquantity={quantity} isbrands={brands} iscategories={categories} />)}

              {isLoading ? undefined : (<ModalScanNo isVisible={modalVisible4} onSubmit={() => { setflashmode(Camera.Constants.FlashMode.off), setModalVisible4(false), onSubmitCBno(Code) }} onCancel={() => { setModalVisible4(false),setScanned(false) }} />
            
             )}
               {isLoading ? undefined : (<ModalImageNo isVisible={modalVisible5} onSubmit={() => {  setModalVisible5(false), setModalVisible3(true)}} onCancel={() => { setModalVisible5(false),onSubmitCB(Name, Code, Images, quantity, brands, categories ), input() }} />
            
            )}
                {isLoading ? undefined : (  <ModalCamera isVisible={modalVisible3} onSubmit={(data) => {setModalVisible3(false),setModalVisible6(true), setImage(data.uri), inputImages(data.uri,Code) }} onCancel={() => {setModalVisible3(false) }} props={props}/>)}
                {isLoading ? undefined : (  <ModalPhoto isVisible={modalVisible6} onSubmit={(data) => { setModalVisible6(false), onSubmitCB(Name, Code, Images, quantity, brands, categories ),inputMissing() }} onCancel={() => {setModalVisible6(false), setModalVisible3(true)}} isImage={Images} props={props}/>)}
           </View>
</Modal>


            );




}

const styles = StyleSheet.create({
  container:{
      flex:1,
      backgroundColor:'black',
    
  },
  button:{
    flexDirection: 'row',
    marginTop: 40,
    marginHorizontal:10,
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor:'transparent'
},
buttonDelete1: {
  backgroundColor:'transparent'
},
button50: {
  marginTop: 10,
  marginRight: 10
},

});

  export default ModalCodeBar;