import React, { useState, useEffect, Component, useRef, useLayoutEffect } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, TouchableHighlight, Dimensions, ScrollView, Alert, Platform} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalSharedPref from '../components/ModalSharedPref';
import ModalActivityIndicator from '../components/ModalActivityIndicator';
import * as SecureStore from 'expo-secure-store';

const MainScreen = props => {

    const { circuit, deeplink} = props.route.params;
    const [Mail, setMail] = useState('');
    const [Password, setPassword] = useState('');
    const [Id, setId]= useState('');
    const [dataUser, setDataUser] = useState([]);
    const [Nom, setNom]= useState('');
    const [Prenom, setPrenom]= useState('');
    const [Sexe, setSexe]= useState('');
    const [Nationalite, setNationalite]= useState('');
    const [Date_de_naissance, setDate_de_naissance]= useState('');
    const [Num_portable, setNum_portable]= useState('');
    const [Email, setEmail] = useState('');
    const [CEmail, setCEmail] = useState('');
    const [Num_rue, setNum_rue] = useState('');
    const [Rue, setRue] = useState('');
    const [Code_postal, setCode_postal] = useState('');
    const [Ville, setVille] = useState('');
    const [Item1, setItem1] = useState('');
    const [Item2, setItem2] = useState('');
    const [Item3, setItem3] = useState('');
    const [Item4, setItem4] = useState('');
    const [Item5, setItem5] = useState('');
    const [Item6, setItem6] = useState('');
    const [Points, setPoints] = useState('');
    const [Shield, setShield] = useState('');
    const [Classe, setClasse] = useState('');
    const [Pseudo, setPseudo] = useState('');
    const [Situation, setSituation] = useState('');
    const [Manualite, setManualite] = useState('');
    const [Langue_maternelle, setLangue_maternelle] = useState('');
    const [Enfants, setEnfants] = useState('');
    const [Profession, setProfession] = useState('');
    const [Autre_info, setAutre_info] = useState('');
    const [Poste, setPoste] = useState('');
    const [level, setlevel] = useState('');
    const [pointsmax, setPointsmax] = useState('');
    const [Cadeau, setCadeau] = useState('');
    const [ImageCadeau, setImageCadeau] = useState('');
    const [tt, settt] = useState('');
    const [t2, sett2] = useState('');
    const [MSG, setMSG] = useState('');
    const URL = 'https://www.useradventure.net/';
    const [security, setsecurity] = useState(true);
    const [iconPass, seticonPass] = useState('eye-off-outline');
    const [isMail, setisMail] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisible1, setModalVisible1] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);
    const [key, onChangeKey] = useState(null);
    const [value, onChangeValue] = useState(null);
    const [key1, onChangeKey1] = useState(null);
    const [value1, onChangeValue1] = useState(null);
    const [height_origin, setheight_origin] = useState();

    var x = Dimensions.get('window').height - 410;

    useEffect(() => {
      if(Platform.OS === 'web'){
      if(localStorage.getItem("PasswordUA")){
        setMail(localStorage.getItem("IdUA"))
        setPassword(localStorage.getItem("PasswordUA"))
        find(localStorage.getItem("IdUA"),localStorage.getItem("PasswordUA"))}
      }else{
           getValueFor('mdp')
      }
       
      }, []);

    const Valid = (data, T) => {
      console.log('id', Id)
      if(data[0].CEmail != '' && data[0].ID != '')
      {
  
        if(data[0].Classe!= ''){
          var token = '0'
        } else {
          var token = '1'
        }
        if(Platform.OS === 'web'){
        if(!localStorage.getItem("PasswordUA")){
          setModalVisible2(true)
        }}else{
          if(!T){
            setModalVisible2(true)
          }
          
        }
          fetch(`${URL}json_get_react_Total_vu_pigeon.php`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',  // It can be used to overcome cors errors
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({iduser: data[0].ID, type: "text"})
          })
            .then((response3) => response3.json())
            .then(response3=> { console.log('r3',response3)
            if (response3[0].TotalVu == 0 || response3[0].TotalVu == null) {
              var TotalMSG = 0
             }else {
              var TotalMSG = response3[0].TotalVu
            }
            
          fetch(`${URL}json_get_react_Total_vu_pigeon.php`, {
            method: 'post',
            headers: {
              'Accept': 'application/json',  // It can be used to overcome cors errors
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({iduser:data[0].ID, type: "cadeau"})
          })
            .then((response2) => response2.json())
            .then(response2=> { console.log('r2',response2)
            if (response2[0].TotalVu == 0 || response2[0].TotalVu == null) {
             var TotalCadeau = 0
            } else {
              var TotalCadeau = response2[0].TotalVu
            }
      fetch(`${URL}json_get_react_level.php`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',  // It can be used to overcome cors errors
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({pointsuser: data[0].Points})
        })
          .then((response) => response.json())
          .then(response => {setlevel(response[0].Level)
                             setPointsmax(response[0].pts_max) 
                             setCadeau(response[0].Cadeau)
         props.navigation.navigate('Tab', {deeplink, iduser: data[0].ID, token: token, Points: 0, Shield:0,level: response[0].Level,levelold: response[0].Level, pointsmax: response[0].pts_max, Classe:data[0].Classe, Pseudo:data[0].Pseudo, item1: data[0].Item_1, item2: data[0].Item_2, item3: data[0].Item_3, item4: data[0].Item_4, item5: data[0].Item_5, item6: data[0].Item_6, Nom: data[0].Nom, Prenom: data[0].Prenom, Sexe: data[0].Sexe, Nationalite: data[0].Nationalite, Date_de_naissance: data[0].Date_de_naissance, Num_portable: data[0].Num_portable, Email: data[0].Email, Num_rue: data[0].Num_rue, Rue: data[0].Rue, Code_postal: data[0].Code_postal, Ville: data[0].Ville, Situation: data[0].Situation, Manualite: data[0].Manualite, Langue_maternelle: data[0].Langue_maternelle, Enfants: data[0].Enfants, Profession: data[0].Profession, Autre_info: data[0].Autre_info, Poste: data[0].Poste, Cadeau: TotalCadeau, ImageCadeau: ImageCadeau, MSG:TotalMSG, flat:'0'})})})})
        // setId('')
        // setPassword('')
        // setMail('')
        } else {
         if(data[0].CEmail == "" && data[0].Email != ""){
          alert(`Avant de vous connecter, valider le mail d'inscription que nous vous avons envoyé`)
         }else{
           alert('Identifient ou mot de passe incorrect')
         }
       
      }
  
  }

    const ValidMail = (text) => {
      setMail(text)
      if(Platform.OS != 'web'){
      onChangeKey1('email')
     onChangeValue1(text)}
      console.log(text)
      find(text,Password)
      }

      const ValidPass = (text) => {
        setPassword(text)
        if(Platform.OS != 'web'){
        onChangeKey('mdp')
       onChangeValue(text)}
       console.log(text)
        find(Mail,text)
      }

    const visiblePass = () => {
      if(iconPass === 'eye-off-outline'){
        seticonPass('eye-outline')
        setsecurity(false)
      } else {
        seticonPass('eye-off-outline')
        setsecurity(true)
      }
      
        }

        const find = (M, P, F) => {
          fetch(`${URL}json_get_react_save.php`, {
              method: 'post',
              headers: {
                'Accept': 'application/json',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({user_name: M, password:P})
            })
            .then((response) => response.json())
                .then(response => {
                  if(response.length != 0){
                  setDataUser(response); 
                  if(Platform.OS === 'web'){
                  if(localStorage.getItem("PasswordUA")){
                   Valid(response)
                  }}else{
                    if(F){
                      Valid(response, true)
                     }
                  }
                  }
                })
          
          }

          if(circuit == '2'){
            if(Platform.OS === 'web'){
            localStorage.removeItem('IdUA'),
            localStorage.removeItem('PasswordUA')
             }else{
              SecureStore.deleteItemAsync('mdp')
              SecureStore.deleteItemAsync('email')
             }
            }


            async function getValueFor(key) {
              let mdp = await SecureStore.getItemAsync('mdp');
              let email = await SecureStore.getItemAsync('email');
              if (mdp && email) {
              setModalVisible(true)
                setMail(email)
                setPassword(mdp)
                find(email,mdp,true)
             } }

            async function save(key, value) {
              await SecureStore.setItemAsync(key, value);
            }

    const ref_input2 = useRef();

    return (
      <View style={{  flex:1 }}>
         <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{ flex:1 ,justifyContent: 'center'}}>
        <View style={{justifyContent: 'center', alignItems: 'center', marginHorizontal:24, marginTop:10}}>
               <Image style={{ width: '100%', resizeMode: 'contain',height : (x)/2 }} source={require("../assets/logo_useradv.jpg")} />
             </View>
   <View style={styles.sectionContainer}>
               <Text style={styles.sectionTitle}>Mail</Text>
               <TextInput style={styles.input}  placeholder="" placeholderTextColor="grey" value={Mail} onChangeText={(text)=>{ValidMail(text)}} keyboardType={'email-address'} returnKeyType = { "next" } onSubmitEditing={() => ref_input2.current.focus()} blurOnSubmit={false}/>
             </View>
   <View style={styles.sectionContainer}>
              <Text style={styles.sectionTitle}>Mot de passe</Text>
               <View style={{ flexDirection: "row",justifyContent: 'center', alignItems:'center', backgroundColor:'white', height: 50,borderColor: '#00808B', borderWidth: 0, marginVertical: 10,borderRadius:10, paddingRight:5}}>
               <TextInput style={{paddingHorizontal: 10,fontSize:18, height: 50, flex:8}}  placeholder="" placeholderTextColor="grey" secureTextEntry={security} value={Password} onChangeText={(text) => {ValidPass(text)}} ref={ref_input2}/>
               <MaterialCommunityIcons onPress={() => {visiblePass()}}  name={iconPass} size={30} color='darkgrey'/>
             </View>
             </View>
             <View style={styles.sectionContainer}>
             <TouchableOpacity style={styles.button1} onPress={()=>{Valid(dataUser)}}>
               <Text style={{  fontSize: 24,color:'white',fontWeight:'bold'}}>Connexion</Text>
             </TouchableOpacity>
             <TouchableOpacity>
              <Text style={styles.out}>J'ai oublié mon mot de passe</Text>
             </TouchableOpacity>
               </View>
              
             <View style={styles.middle}>
               <Text style={styles.sectionDescription}></Text>
             </View> 
 
             <View style={styles.sectionContainerBas}>
           
               <View style={{justifyContent: 'center', alignItems: 'center', marginBottom:10}}>
               <Image style={{ width: '100%', resizeMode: 'contain',height : (x)/2 }} source={require("../assets/pigeon_right.png")} />
             </View>
                
               <View style={styles.sectionContainer}>
               <TouchableOpacity style={styles.button2} onPress={() => props.navigation.navigate('Register')}>
               <Text style={{  fontSize: 24,color:'white',fontWeight:'bold'}}>Créer un compte</Text>
             </TouchableOpacity>
               </View>
             </View>
             <ModalSharedPref isVisible={modalVisible2} onSubmit={() => { if(Platform.OS === 'web'){localStorage.setItem("IdUA", Mail),localStorage.setItem("PasswordUA", Password), setModalVisible2(false)}else{save(key, value),save(key1, value1), setModalVisible2(false)} }} onQuit={() => {setModalVisible2(false) }} />
             <ModalActivityIndicator isVisible={modalVisible1}  />
             </LinearGradient>   
             </View>
    )
}

const styles = StyleSheet.create({
    sectionContainer: {
        marginTop: 0,
        paddingHorizontal: 24,
      },
      button1: {
        height:50,
        borderRadius:5,
        justifyContent: 'center',
      alignItems: "center",
      backgroundColor: '#003333',
       padding: 10,
       marginTop:5,
       elevation: 20  ,
       shadowColor: 'black', 
       shadowOpacity: 0.3, 
       shadowRadius: 5,
       shadowOffset: { height: 8, width:5 }
      },
      button2: {
        height:50,
        borderRadius:5,
        justifyContent: 'center',
      alignItems: "center",
      backgroundColor: '#FFB217',
       padding: 10,
       elevation: 20  ,
       shadowColor: 'black', 
       shadowOpacity: 0.3, 
       shadowRadius: 5,
       shadowOffset: { height: 8, width:5 }
      },
      out: {
        paddingTop: 15,
        paddingHorizontal: 24,
        fontSize: 14,
        textAlign:'center',
        color: 'white',
       backgroundColor:'transparent',
       textDecorationLine: 'underline',
       fontWeight:'bold'
      },
      sectionDescription: {
        marginTop: 8,
        fontSize: 18,
        fontWeight: '400',
        color: 'black',
      },
      sectionContainerBas: {
        marginTop: 5,
       
      },
      sectionTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
      },
      input: {
        height: 50,
        borderRadius:10,
        backgroundColor:'white',
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:20
      },
      middle: {
        alignItems: 'center',
      },
     

})

export default MainScreen
