import React from 'react'
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform,KeyboardAvoidingView, ImageBackground, Picker} from 'react-native';

const BoutonEtude = ({onPress, text, textColor, boutonColor}) => {
  return (
    <TouchableOpacity onPress={onPress} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: boutonColor,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 10, width:5 }}} >
    <Text style={{ color:textColor, fontSize:20, fontWeight:'bold' }} >{text}</Text>
    </TouchableOpacity>
  )
}

export default BoutonEtude