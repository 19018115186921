import firebase from 'firebase';

// Optionally import the services that you want to use
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyAhUoDBtFWwAoT4PqQtoDh1Pw8TU2mUN2I",
    authDomain: "lighthouse-7ab93.firebaseapp.com",
    databaseURL: "https://lighthouse-7ab93-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lighthouse-7ab93",
    storageBucket: "lighthouse-7ab93.appspot.com",
    messagingSenderId: "40503121445",
    appId: "1:40503121445:web:569e08cc1e2b486b9551cc"
  };

  firebase.initializeApp(firebaseConfig);


  export default firebase;