import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal, ImageBackground, ActivityIndicator, Image} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
//import { Image } from 'react-native-elements';

const ModalScan =  ({ isVisible, isName, isImage, isquantity, isbrands, iscategories, onSubmit, onCancel, props }) => { 

  var width = Dimensions.get('window').width;

return(
  
  <Modal
animationType="slide"
transparent={true}
visible={isVisible}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<View style={styles.centeredView}>
  
<View style={styles.modalView}>
  <View style={{alignItems:'center', marginBottom:20}}>
  {/* <Image
  source={{ uri: isImage }}
  style={{ height:350, width:'100%'}}
  containerStyle={{ height:350, width:'100%'}}
  PlaceholderContent={<ActivityIndicator />}
/> */}
<Image
        style={{
    width: width-100,
    height: 200,
    resizeMode: 'contain',
  }}
        source={{ uri: isImage }}
        loadingIndicatorSource={<ActivityIndicator />}
      />
  </View>
  <View style={styles.viewText}>
  <Text style={styles.modalText}>{isName} {isquantity}</Text>
  <Text style={styles.modalText}>Catégorie : {iscategories}</Text>
  <Text style={styles.modalText}>Fabricant : {isbrands}</Text>
  <Text style={styles.modalTextbold}>C'est bien le bon produit?</Text>
  </View>
  <View style={{flexDirection: "row", justifyContent:'flex-end'}}>
  <TouchableOpacity
    style={{ backgroundColor: "transparent", marginHorizontal:20 }}
    onPress={(value) => maybe(value)}
  >
    <Text style={styles.textStyle}>PEUT-ÊTRE</Text>
  </TouchableOpacity>
  <TouchableOpacity
    style={{ backgroundColor: "transparent", marginHorizontal:20 }}
    onPress={(value) => onCancel(value)}
  >
    <Text style={styles.textStyle}>NON</Text>
  </TouchableOpacity>
  <TouchableOpacity
    style={{ backgroundColor: "transparent", marginHorizontal:10 }}
    onPress={(value) => onSubmit(value)}
  >
    <Text style={styles.textStyle}>OUI</Text>
  </TouchableOpacity>
  </View>
</View>
</View>
</Modal>


            );




}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
  textStyle: {
  color: '#00808B',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
   marginBottom: 15
   },
   modalTextbold: {
    marginBottom: 15,
    fontWeight:'bold',
    },
    viewText: {
      marginHorizontal:10,
      },

  });

  export default ModalScan;