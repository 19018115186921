import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const ModalInfoShield =  ({ isVisible, onSubmit, }) => { 

return(

            <Modal
        animationType="fade"
        transparent={true}
        visible={isVisible}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={{flexDirection: "row", alignItems:'center', marginBottom:10}}>
            <MaterialCommunityIcons name="information-outline" color='#00808B' size={46} />
            <Text style={styles.textStyle1}>Information</Text>
            </View>
            <Text style={styles.modalText}>Vous pouvez attribuer une partie ou l'ensemble de vos shields. Décidez de les garder pour vous ou de faire un don à une initiative de votre choix.</Text>
            <Text style={styles.modalText}>Le paiement de votre cagnotte est réalisé à partir de 15 euros par voie postale. Si ce n'est pas les cas, le montant est conservé pour le mois suivant.</Text>
            <TouchableOpacity
              style={{ backgroundColor: "transparent" }}
              onPress={(value) => onSubmit(value)}
            >
              <Text style={styles.textStyle}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
     

            );




}

const styles = StyleSheet.create({
    
    centeredView: {
      flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    backgroundColor: 'rgba(0,0,0,0.5)'
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 5,
      padding: 35,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5
     },
    textStyle: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    fontSize: 20
     },
     textStyle1: {
      color: '#00808B',
      fontWeight: "bold",
      textAlign: "right",
      marginHorizontal:10,
      fontSize: 24
       },
     modalText: {
     marginBottom: 15
     },
    
  

  });

  export default ModalInfoShield;