import React, { useState, useEffect, Component, useRef, useLayoutEffect } from 'react';
import { BackHandler } from 'react-native';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity,Pressable, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, KeyboardAvoidingView, Alert, Animated, useWindowDimensions} from 'react-native';
import { NavigationContainer, useRoute, useFocusEffect } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets, CardStyleInterpolators } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import HomeScreen from './HomeScreen';
import CompteScreen from './CompteScreen';
import ScanScreen from './ScanScreen';
import Avis0Screen from './Avis0Screen';
import Avis1Screen from './Avis1Screen';
import Avis2Screen from './Avis2Screen';
import Avis3Screen from './Avis3Screen';
import Avis4Screen from './Avis4Screen';
import Avis5Screen from './Avis5Screen';
import Avis6Screen from './Avis6Screen';
import Avis7Screen from './Avis7Screen';
import Identity1Screen from './Identity1Screen';
import CashScreen from './CashScreen';
import Shields1Screen from './Shields1Screen';
import Shields2Screen from './Shields2Screen';
import AvatarScreen from './AvatarScreen';
import ModifPasswordScreen from './ModifPasswordScreen';
import ModifInfo1Screen from './ModifInfo1Screen';
import ModifInfo2Screen from './ModifInfo2Screen';
import ModifInfo3Screen from './ModifInfo3Screen';
import ModifInfo4Screen from './ModifInfo4Screen';
import EtudeScreen from './EtudeScreen';
import FinalCompte1Screen from './FinalCompte1Screen';
import FinalCompte2Screen from './FinalCompte2Screen';
import FinalCompte3Screen from './FinalCompte3Screen';
import FinalCompte4Screen from './FinalCompte4Screen';
import FinalCompte5Screen from './FinalCompte5Screen';
import FinalCompte6Screen from './FinalCompte6Screen';
import FinalCompte7Screen from './FinalCompte7Screen';
import FinalCompte8Screen from './FinalCompte8Screen';
import FinalCompte9Screen from './FinalCompte9Screen';
import FinalCompte10Screen from './FinalCompte10Screen';
import FinalCompte11Screen from './FinalCompte11Screen';
import FinalCompte12Screen from './FinalCompte12Screen';
import FinalCompte13Screen from './FinalCompte13Screen';
import FinalCompte14Screen from './FinalCompte14Screen';
import Postule1Screen from './Postule1Screen';
import Presentiel1Screen from './Presentiel1Screen';
import Presentiel2Screen from './Presentiel2Screen';
import Presentiel3Screen from './Presentiel3Screen';
import Rdv1Screen from './Rdv1Screen';
import Rdv2Screen from './Rdv2Screen';
import Rdv3Screen from './Rdv3Screen';
import Rdv4Screen from './Rdv4Screen';
import Rdv5Screen from './Rdv5Screen';
import PigeonScreen from './PigeonScreen';
import { LinearGradient } from 'expo-linear-gradient';
import { BottomTabBar } from '@react-navigation/bottom-tabs';




const Accueil = props  =>  {

    const {deeplink, iduser, flat, token, level, levelold, Cadeau,MSG, pointsmax, Points, Shield, Classe, Pseudo, item1, item2, item3, item4, item5, item6  } = props.route.params;
    
    const route = useRoute();


    console.log('compteprops',props)
   
  if(route.state){
    console.log(props.navigation.setOptions())
    props.navigation.setOptions({
      tabBarVisible: route.state.index > 0? false : true,
     
    });
  }
  
  
  return(
      <Navigator screenOptions={{cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS}}>
        <Screen name = "Home" component ={HomeScreen} options={{headerShown: false}} initialParams={{deeplink:deeplink, token: token, flat: flat, iduser:iduser,level: level, levelold:levelold, Cadeau: Cadeau,MSG:MSG, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe:Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6}}/>   
        <Screen name= "Etude" component={EtudeScreen} options={{headerShown: false}} initialParams={{iduser:iduser}}/>
        <Screen name= "FinalCompte1" component={FinalCompte1Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte2" component={FinalCompte2Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte3" component={FinalCompte3Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte4" component={FinalCompte4Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte5" component={FinalCompte5Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte6" component={FinalCompte6Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte7" component={FinalCompte7Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte8" component={FinalCompte8Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte9" component={FinalCompte9Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte10" component={FinalCompte10Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte11" component={FinalCompte11Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte12" component={FinalCompte12Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte13" component={FinalCompte13Screen} options={{headerShown: false}}/>
        <Screen name= "FinalCompte14" component={FinalCompte14Screen} options={{headerShown: false}}/>
        <Screen name= "Postule1" component={Postule1Screen} options={{headerShown: false}}/>
        <Screen name= "Presentiel1" component={Presentiel1Screen} options={{headerShown: false}}/>
        <Screen name= "Presentiel2" component={Presentiel2Screen} options={{headerShown: false}}/>
        <Screen name= "Presentiel3" component={Presentiel3Screen} options={{headerShown: false}}/>
        <Screen name= "Rdv1" component={Rdv1Screen} options={{headerShown: false}}/>
        <Screen name= "Rdv2" component={Rdv2Screen} options={{headerShown: false}}/>
        <Screen name= "Rdv3" component={Rdv3Screen} options={{headerShown: false}}/>
        <Screen name= "Rdv4" component={Rdv4Screen} options={{headerShown: false}}/>
        <Screen name= "Rdv5" component={Rdv5Screen} options={{headerShown: false}}/>
        <Screen name= "Pigeon" component={PigeonScreen} options={{headerShown: false}}/>
      </Navigator>
    );
  }

  const Profil = props =>  {



    const { iduser, token, level, pointsmax, Points, Shield , Classe, Pseudo, item1, item2, item3, item4, item5, item6, Nom, Prenom, Sexe, Nationalite, Date_de_naissance, Num_portable, Email, Num_rue, Rue, Ville, Code_postal, Situation,  Manualite,  Langue_maternelle,  Enfants,  Profession,  Autre_info, Poste } = props.route.params;
  
    const route = useRoute();
  
    console.log('compteprops',props)
   
  if(route.state){
    console.log(props.navigation.setOptions())
    props.navigation.setOptions({
      tabBarVisible: route.state.index > 0? false : true,
     
    });
  }
  
  return(
      <Navigator screenOptions={{cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS}}>
         <Screen name= "Compte" component ={CompteScreen} options={{headerShown: false}} initialParams={{ token: token, iduser:iduser,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, Nom: Nom, Prenom: Prenom, Sexe: Sexe, Nationalite: Nationalite, Date_de_naissance: Date_de_naissance, Num_portable: Num_portable, Email: Email, Num_rue: Num_rue, Rue: Rue, Ville: Ville, Code_postal: Code_postal, Situation: Situation, Manualite: Manualite, Langue_maternelle: Langue_maternelle, Enfants: Enfants, Profession: Profession, Autre_info: Autre_info, Poste: Poste}}/>      
        <Screen name= "Avatar" component={AvatarScreen} options={{headerShown: false}}/>
        <Screen name= "ModifPassword" component={ModifPasswordScreen} options={{headerShown: false}}/>
        <Screen name= "ModifInfo1" component={ModifInfo1Screen} options={{headerShown: false}}/>
        <Screen name= "ModifInfo2" component={ModifInfo2Screen} options={{headerShown: false}}/>
        <Screen name= "ModifInfo3" component={ModifInfo3Screen} options={{headerShown: false}}/>
        <Screen name= "ModifInfo4" component={ModifInfo4Screen} options={{headerShown: false}}/>
      </Navigator>
    );
  }

  const Scanner = props =>  {

     const { iduser, flat, token, level, pointsmax, Points, Shield, Classe, Pseudo, item1, item2, item3, item4, item5, item6 } = props.route.params;
    
      const route = useRoute();
       
    if(route.state){
      props.navigation.setOptions({
        tabBarVisible: route.state.index > 0? false : true,
        
      });
    }
    
    return(
        <Navigator screenOptions={{cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS}}>
        <Screen name = "Scan" component ={ScanScreen} options={{headerShown: false}} initialParams={{ token: token,flat: flat, iduser:iduser,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6}}/>        
           {/*  <Screen name= "Cam" component={CamScreen} options={{headerShown: false}}/> */}
          <Screen name= "Avis0" component={Avis0Screen} options={{headerShown: false}}/>
          <Screen name= "Avis1" component={Avis1Screen} options={{headerShown: false}}/>
          <Screen name= "Avis2" component={Avis2Screen} options={{headerShown: false}}/>
          <Screen name= "Avis3" component={Avis3Screen} options={{headerShown: false}}/>
          <Screen name= "Avis4" component={Avis4Screen} options={{headerShown: false}}/>
          <Screen name= "Avis5" component={Avis5Screen} options={{headerShown: false}}/>
          <Screen name= "Avis6" component={Avis6Screen} options={{headerShown: false}}/>
          <Screen name= "Avis7" component={Avis7Screen} options={{headerShown: false}}/>
          <Screen name= "Identity1" component={Identity1Screen} options={{headerShown: false}}/>
        </Navigator>
      );
    }

  const Don = props =>  {

    const { iduser, token, level, pointsmax, Points, Shield, Classe, Pseudo, item1, item2, item3, item4, item5, item6 } = props.route.params;
  
    const route = useRoute();
    
  if(route.state){
   
    props.navigation.setOptions({
      tabBarVisible: route.state.index > 0? false : true,
      
    });
  }
  
  return(
      <Navigator screenOptions={{cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS}}>
        <Screen name = "Cash" component ={CashScreen} options={{headerShown: false}} initialParams={{majData: '0', token: token, iduser:iduser,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6}}/>      
        <Screen name= "Shields1" component={Shields1Screen} options={{headerShown: false}}/>
        <Screen name= "Shields2" component={Shields2Screen} options={{headerShown: false}}/>
      </Navigator>
    );
  }

  

  

  const { Navigator, Screen } = createStackNavigator();

const TabScreen = props => {

  useEffect(() => {
    setheight_origin(Dimensions.get('window').height)
  }, []);
 
const {deeplink, iduser, flat, token, level, levelold, Cadeau, MSG, pointsmax, Points, Shield, Classe, Pseudo, item1, item2, item3, item4, item5, item6, Nom, Prenom, Sexe, Nationalite, Date_de_naissance, Num_portable, Email, Num_rue, Rue, Code_postal, Ville, Situation,  Manualite,  Langue_maternelle,  Enfants,  Profession,  Autre_info, Poste } = props.route.params;
 const { height, scale, width } = useWindowDimensions();
 const W =  width <900 ? 50 : 260
  const H = width >900 ? undefined : 80
  const icon1 = "home-outline";
  const icon2 = "account-outline";
  const icon3 = "barcode-scan";
  const icon4 = "currency-eur";
  const [icon5, seticon5] = useState("arrow-right");
  const [iconcolor1, seticoncolor1] = useState('#FFB217');
  const [textcolor1, settextcolor1] = useState('rgb(0, 97, 108)');
  const [bgcolor1, setbgcolor1] = useState("white");
  const [iconcolor2, seticoncolor2] = useState("white");
  const [textcolor2, settextcolor2] = useState("white");
  const [bgcolor2, setbgcolor2] = useState('transparent');
  const [iconcolor3, seticoncolor3] = useState("white");
  const [textcolor3, settextcolor3] = useState("white");
  const [bgcolor3, setbgcolor3] = useState('transparent');
  const [iconcolor4, seticoncolor4] = useState("white");
  const [textcolor4, settextcolor4] = useState("white");
  const [bgcolor4, setbgcolor4] = useState('transparent');
  const [iconcolor5, seticoncolor5] = useState("white");
  const [textcolor5, settextcolor5] = useState("white");
  const [bgcolor5, setbgcolor5] = useState('transparent');
  const [volet, setvolet] = useState(false);
  const [height_origin, setheight_origin] = useState();
  const [translateValue] = useState(new Animated.Value(0));
  const totalWidth = Dimensions.get("window").width;
  const tabWidth = totalWidth / 4;
  
   const animateSlider = (index) => {
       Animated.spring(translateValue, {
         toValue: index * tabWidth,
         velocity: 10,
         useNativeDriver: true,
       }).start();
     };

     const animatecolor = (index) => {
      if(index == 0){
        seticoncolor1('#FFB217')
        seticoncolor2("white")
        seticoncolor3("white")
        seticoncolor4("white")
        settextcolor1('rgb(0, 97, 108)')
        settextcolor2("white")
        settextcolor3("white")
        settextcolor4("white")
        setbgcolor1("white")
        setbgcolor2('transparent')
        setbgcolor3('transparent')
        setbgcolor4('transparent')
      }else if(index == 1){
        seticoncolor1("white")
        seticoncolor2('#FFB217')
        seticoncolor3("white")
        seticoncolor4("white")
        settextcolor1("white")
        settextcolor2('rgb(0, 97, 108)')
        settextcolor3("white")
        settextcolor4("white")
        setbgcolor1('transparent')
        setbgcolor2("white")
        setbgcolor3('transparent')
        setbgcolor4('transparent')
      }else if(index == 2){
        seticoncolor1("white")
        seticoncolor2("white")
        seticoncolor3('#FFB217')
        seticoncolor4("white")
        settextcolor1("white")
        settextcolor2("white")
        settextcolor3('rgb(0, 97, 108)')
        settextcolor4("white")
        setbgcolor1('transparent')
        setbgcolor2('transparent')
        setbgcolor3("white")
        setbgcolor4('transparent')
      }else if(index == 3){
        seticoncolor1("white")
        seticoncolor2("white")
        seticoncolor3("white")
        seticoncolor4('#FFB217')
        settextcolor1("white")
        settextcolor2("white")
        settextcolor3("white")
        settextcolor4('rgb(0, 97, 108)')
        setbgcolor1('transparent')
        setbgcolor2('transparent')
        setbgcolor3('transparent')
        setbgcolor4("white")
      }
    };

    const T = () =>{
      console.log(volet)
      console.log(W)
      if(volet) {
        setW(50) 
        setvolet(!volet)
        seticon5("arrow-right")
      }else{
        setW(150) 
      setvolet(!volet)
      seticon5("arrow-left")
      }
    }

  
  
     const Tab = createBottomTabNavigator();
     


  return (
<>
{Platform.OS === 'web' ? 
    <View style={{ flex:1}}>
    <View style={{flexDirection: "row",flex:1}}>
   
    {!H && (   <View style={{ width:W, paddingHorizontal:10, backgroundColor:'rgb(0, 97, 108)'}}> 
     <View style={{ flex:1, justifyContent:'space-between'}}>
    
          <View style={{flex : 3, flexDirection:'row',justifyContent: 'center', alignItems: 'center'}}>
          <Image style={{ width: '100%', resizeMode: 'contain',height :90 }} source={require("../assets/logo_useradv.jpg")} />
        </View>
        <View style={{ flex : 7, paddingHorizontal:10}}> 
        <TouchableOpacity onPress={() => {animatecolor(0), props.navigation.navigate('Accueil',{ deeplink:deeplink,iduser: iduser, token: token,level: level, levelold:levelold, Cadeau:Cadeau, MSG:MSG, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat})}} style={{ height: 50, paddingHorizontal:10,justifyContent: 'center',  borderRadius:5,elevation: 15, backgroundColor: bgcolor1,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <View style={{flexDirection:'row',justifyContent: 'left', alignItems: 'center'}}>
          <Image 
        source={require('../assets/icon_home.png')}
        fadeDuration={0}
        style={{ width: 30, height: 30, tintColor:iconcolor1, color:'white'}}
      />
            <Text style={{ color:textcolor1, fontSize:18, fontWeight:'none', paddingStart:15, fontWeight:'bold' }} >Missions</Text>
        </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {animatecolor(1), props.navigation.navigate('Profil',{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, Nom: Nom, Prenom: Prenom, Sexe: Sexe, Nationalite: Nationalite, Date_de_naissance: Date_de_naissance, Num_portable: Num_portable, Email: Email, Num_rue: Num_rue, Rue: Rue, Code_postal: Code_postal, Ville: Ville, Situation: Situation, Manualite: Manualite, Langue_maternelle: Langue_maternelle, Enfants: Enfants, Profession: Profession, Autre_info: Autre_info, Poste: Poste})}} style={{ height: 50, paddingHorizontal:10,justifyContent: 'center',  borderRadius:5,elevation: 15, backgroundColor: bgcolor2,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <View style={{flexDirection:'row',justifyContent: 'left', alignItems: 'center'}}>
          <Image
           source={require('../assets/icon_user.png')}
           fadeDuration={0}
           style={{ width: 30, height: 30, tintColor:iconcolor2, color:'white'}}
         />
            <Text style={{ color:textcolor2, fontSize:16, fontWeight:'none', paddingStart:15, fontWeight:'bold' }} >Profil</Text>
        </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {animatecolor(2), props.navigation.navigate('Scanner',{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat})}} style={{ height: 50, paddingHorizontal:10,justifyContent: 'center',  borderRadius:5,elevation: 15, backgroundColor: bgcolor3,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <View style={{flexDirection:'row',justifyContent: 'left', alignItems: 'center'}}>
          <Image
           source={require('../assets/icon_scan.png')}
           fadeDuration={0}
           style={{ width: 30, height: 30, tintColor:iconcolor3, color:'white'}}
         />
            <Text style={{ color:textcolor3, fontSize:16, fontWeight:'none', paddingStart:15 , fontWeight:'bold'}} >Avis consommateur</Text>
        </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {animatecolor(3),props.navigation.navigate('Don',{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6})}} style={{ height: 50, paddingHorizontal:10,justifyContent: 'center', borderRadius:5,elevation: 15, backgroundColor: bgcolor4,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
          <View style={{flexDirection:'row',justifyContent: 'left', alignItems: 'center'}}>
          <Image
           source={require('../assets/icon_cash.png')}
           fadeDuration={0}
           style={{ width: 30, height: 30, tintColor:iconcolor4, color:'white'}}
         />
            <Text style={{ color:textcolor4, fontSize:16, fontWeight:'none', paddingStart:15 , fontWeight:'bold'}} >Récompenses</Text>
        </View>
        </TouchableOpacity>
        </View>
        
       
      </View>
</View>)}
      <View style={{flex:9}}>
    
     
      <Navigator initialRouteName="Accueil">
        <Screen name= "Accueil" component={Accueil} options={{ unmountOnBlur: true, headerShown: false}} initialParams={{deeplink:deeplink, iduser: iduser, token: token,level: level, levelold:levelold, Cadeau:Cadeau, MSG:MSG, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat}} />
        <Screen name= "Profil" component={Profil} options={{headerShown: false}} initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, Nom: Nom, Prenom: Prenom, Sexe: Sexe, Nationalite: Nationalite, Date_de_naissance: Date_de_naissance, Num_portable: Num_portable, Email: Email, Num_rue: Num_rue, Rue: Rue, Code_postal: Code_postal, Ville: Ville, Situation: Situation, Manualite: Manualite, Langue_maternelle: Langue_maternelle, Enfants: Enfants, Profession: Profession, Autre_info: Autre_info, Poste: Poste}}/>
        <Screen name= "Scanner" component={Scanner} options={{headerShown: false}} initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat}}/>
        <Screen name= "Don" component={Don} options={{headerShown: false}} initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6}}/>
      </Navigator>
  
      </View>
      
      
    </View>
       {H && (<View style={{ flexDirection: "column-reverse", height:H, backgroundColor:'#00808B',borderTopColor:"#FFB217",borderTopWidth:2}}>
       <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{ height: H ,justifyContent: 'center'}}> 
       <View style={{ flexDirection: "row" }}>
       <Animated.View style={[{height: 5,position: "absolute",top: 0, left: 0, backgroundColor: '#FFB217', borderRadius: 0}, { transform: [{ translateX: translateValue }], width: tabWidth - 0,}, ]}/>
       </View>
     <View style={{flex:1,justifyContent: 'center'}}>
        <View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => {animatecolor(0),animateSlider(0),props.navigation.navigate('Accueil',{ deeplink:deeplink,iduser: iduser, token: token,level: level, levelold:levelold, Cadeau:Cadeau, MSG:MSG, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat})}} style={{flex:1, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5, backgroundColor: 'transparent',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Image 
           source={require('../assets/icon_home.png')}
           fadeDuration={0}
           style={{ width: 25, height: 25, tintColor:iconcolor1, color:'white'}}
         />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {animatecolor(1),animateSlider(1), props.navigation.navigate('Profil',{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, Nom: Nom, Prenom: Prenom, Sexe: Sexe, Nationalite: Nationalite, Date_de_naissance: Date_de_naissance, Num_portable: Num_portable, Email: Email, Num_rue: Num_rue, Rue: Rue, Code_postal: Code_postal, Ville: Ville, Situation: Situation, Manualite: Manualite, Langue_maternelle: Langue_maternelle, Enfants: Enfants, Profession: Profession, Autre_info: Autre_info, Poste: Poste})}} style={{flex:1,  height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5, backgroundColor: 'transparent',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Image
           source={require('../assets/icon_user.png')}
           fadeDuration={0}
           style={{ width: 25, height: 25, tintColor:iconcolor2, color:'white'}}
         />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {animatecolor(2),animateSlider(2), props.navigation.navigate('Scanner',{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat})}} style={{flex:1,  height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5, backgroundColor: 'transparent',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Image
           source={require('../assets/icon_scan.png')}
           fadeDuration={0}
           style={{ width: 25, height: 25, tintColor:iconcolor3, color:'white'}}
         />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {animatecolor(3),animateSlider(3),props.navigation.navigate('Don',{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6})}} style={{flex:1,  height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5, backgroundColor: 'transparent',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Image
           source={require('../assets/icon_cash.png')}
           fadeDuration={0}
           style={{ width: 25, height: 25, tintColor:iconcolor4, color:'white'}}
         />
        </TouchableOpacity>
        </View>
        </View>
        </LinearGradient>
      </View>)} 
    </View>:  <Tab.Navigator 
    initialRouteName="Accueil"
    tabBar={(props) => {

return(
    <LinearGradient
    colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]}
    >
      <View style={{ flexDirection: "row" }}>
    <Animated.View
      style={[
        {
          height: 5,
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: '#FFB217',
          borderRadius: 0,
        },
        {
          transform: [{ translateX: translateValue }],
          width: tabWidth - 0,
        },
      ]}
    />
    </View>

      <BottomTabBar
        {...props}
        style={{  
        elevation: 0, // <- - c'est la solution
        backgroundColor: 'transparent',
        borderTopWidth: 0,
        marginTop:0,
      }}
      />
    </LinearGradient>
);
  
        }}
   tabBarOptions={{
      activeTintColor: '#FFB217',
      inactiveTintColor: 'white', 
      
      style: {
        position: 'absolute',
        elevation: 0, // <- - c'est la solution
        backgroundColor: 'transparent',
        borderTopWidth: 0,
         
        },
        labelStyle:{
          height:0
        },
    }}
 
  >
   <Tab.Screen
     name="Accueil"
     component={Accueil}
     initialParams={{deeplink:deeplink, iduser: iduser, token: token,level: level, levelold:levelold, Cadeau:Cadeau, MSG:MSG, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat}}
    options={{
       tabBarLabel: '',
       tabBarIcon: ({ color }) => (
        <Image 
        source={require('../assets/icon_home.png')}
        fadeDuration={0}
        style={{ width: 25, height: 25, tintColor:color, color:'white'}}
      />
       ),
     }}
     listeners={({ navigation, route }) => ({
      tabPress: e => {
        animateSlider(0)
      }
  })}
   >
   </Tab.Screen>
   <Tab.Screen
     name="Profil"
     component={Profil}
     initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, Nom: Nom, Prenom: Prenom, Sexe: Sexe, Nationalite: Nationalite, Date_de_naissance: Date_de_naissance, Num_portable: Num_portable, Email: Email, Num_rue: Num_rue, Rue: Rue, Code_postal: Code_postal, Ville: Ville, Situation: Situation, Manualite: Manualite, Langue_maternelle: Langue_maternelle, Enfants: Enfants, Profession: Profession, Autre_info: Autre_info, Poste: Poste}}
     options={{
       tabBarLabel: '',
       tabBarIcon: ({ color }) => (
        <Image
        source={require('../assets/icon_user.png')}
        fadeDuration={0}
        style={{ width: 25, height: 25, tintColor:color}}
      />
       ),
     }}
     listeners={({ navigation, route }) => ({
      tabPress: e => {
        animateSlider(1)
      }
  })}
   />
    <Tab.Screen
     name="Scanner"
     component={Scanner}
     initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat}}
     options={{
       tabBarLabel: '',
       tabBarIcon: ({ color }) => (
        <Image
        source={require('../assets/icon_scan.png')}
        fadeDuration={0}
        style={{ width: 25, height: 25, tintColor:color}}
        
      />
       ),
     }}
     listeners={({ navigation, route }) => ({
      tabPress: e => {
        animateSlider(2)
      }
  })}
   />
    <Tab.Screen
     name="Don"
     component={Don}
     initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6}}
     options={{
       tabBarLabel: '',
       tabBarIcon: ({ color }) => (
        <Image
        source={require('../assets/icon_cash.png')}
        fadeDuration={0}
        style={{ width: 25, height: 25, tintColor:color}}
        
      />
       ),
     }}
     listeners={({ navigation, route }) => ({
      tabPress: e => {
        animateSlider(3)
      }
  })}
   />
 </Tab.Navigator>}
</>  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
})



//     const onBackPress = () => {return true}
  
//     useEffect(() => {
     
//       BackHandler.addEventListener('hardwareBackPress', onBackPress);
     
//     }, []);
  
//   const Tab = createBottomTabNavigator();
//   const { iduser, flat, token, level, levelold, Cadeau, MSG, pointsmax, Points, Shield, Classe, Pseudo, item1, item2, item3, item4, item5, item6, Nom, Prenom, Sexe, Nationalite, Date_de_naissance, Num_portable, Email, Num_rue, Rue, Code_postal, Ville, Situation,  Manualite,  Langue_maternelle,  Enfants,  Profession,  Autre_info, Poste } = props.route.params;
  
//   const [translateValue] = useState(new Animated.Value(0));
//   const totalWidth = Dimensions.get("window").width;
//   const tabWidth = totalWidth / 4;
  
//   const animateSlider = (index) => {
//       Animated.spring(translateValue, {
//         toValue: index * tabWidth,
//         velocity: 10,
//         useNativeDriver: true,
//       }).start();
//     };
  
//     return (
     
//      <Tab.Navigator 
//       initialRouteName="Accueil"
//       tabBar={(props) => {
  
//   return(
//       <LinearGradient
//       colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]}
//       >
//         <View style={{ flexDirection: "row" }}>
//       <Animated.View
//         style={[
//           {
//             height: 5,
//             position: "absolute",
//             top: 0,
//             left: 0,
//             backgroundColor: '#FFB217',
//             borderRadius: 0,
//           },
//           {
//             transform: [{ translateX: translateValue }],
//             width: tabWidth - 0,
//           },
//         ]}
//       />
//       </View>
  
//         <BottomTabBar
//           {...props}
//           style={{  
//           elevation: 0, // <- - c'est la solution
//           backgroundColor: 'transparent',
//           borderTopWidth: 0,
//           marginBottom: 10,
//           marginTop: 10
//         }}
//         />
//       </LinearGradient>
//   );
    
//           }}
//      tabBarOptions={{
//         activeTintColor: '#FFB217',
//         inactiveTintColor: 'white', 
        
//         style: {
//           position: 'absolute',
//           elevation: 0, // <- - c'est la solution
//           backgroundColor: 'transparent',
//           borderTopWidth: 0,
           
//           },
//           labelStyle:{
//             height:0
//           },
//       }}
   
//     >
//      <Tab.Screen
//        name="Accueil"
//        component={Accueil}
//        initialParams={{ iduser: iduser, token: token,level: level, levelold:levelold, Cadeau:Cadeau, MSG:MSG, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat}}
//       options={{ unmountOnBlur: true,
//          tabBarLabel: '',
//          tabBarIcon: ({ color }) => (
//           <Image 
//           source={require('../assets/icon_home.png')}
//           fadeDuration={0}
//           style={{ width: 25, height: 25, tintColor:color, color:'white'}}
//         />
//          ),
//        }}
//        listeners={({ navigation, route }) => ({
//       tabPress: e => {
//         animateSlider(0)
//       }
//   })}
//      >
//      </Tab.Screen>
//      <Tab.Screen
//        name="Profil"
//        component={Profil}
//      initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, Nom: Nom, Prenom: Prenom, Sexe: Sexe, Nationalite: Nationalite, Date_de_naissance: Date_de_naissance, Num_portable: Num_portable, Email: Email, Num_rue: Num_rue, Rue: Rue, Code_postal: Code_postal, Ville: Ville, Situation: Situation, Manualite: Manualite, Langue_maternelle: Langue_maternelle, Enfants: Enfants, Profession: Profession, Autre_info: Autre_info, Poste: Poste}}
//        options={{
//          tabBarLabel: '',
//          tabBarIcon: ({ color }) => (
//           <Image
//           source={require('../assets/icon_user.png')}
//           fadeDuration={0}
//           style={{ width: 25, height: 25, tintColor:color}}
//         />
//          ),
//        }}
//        listeners={({ navigation, route }) => ({
//       tabPress: e => {
//         animateSlider(1)
//       }
//   })}
//      />
//       <Tab.Screen
//        name="Scanner"
//        component={Scanner}
//        initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6, flat: flat}}
//        options={{
//          tabBarLabel: '',
//          tabBarIcon: ({ color }) => (
//           <Image
//           source={require('../assets/icon_scan.png')}
//           fadeDuration={0}
//           style={{ width: 25, height: 25, tintColor:color}}
          
//         />
//          ),
//        }}
//        listeners={({ navigation, route }) => ({
//       tabPress: e => {
//         animateSlider(2)
//       }
//   })}
//      />
//       <Tab.Screen
//        name="Don"
//        component={Don}
//        initialParams={{ iduser: iduser, token: token,level: level, pointsmax: pointsmax, Points: Points, Shield: Shield, Classe: Classe, Pseudo:Pseudo, item1: item1, item2: item2, item3: item3, item4: item4, item5: item5, item6: item6}}
//        options={{
//          tabBarLabel: '',
//          tabBarIcon: ({ color }) => (
//           <Image
//           source={require('../assets/icon_cash.png')}
//           fadeDuration={0}
//           style={{ width: 25, height: 25, tintColor:color}}
          
//         />
//          ),
//        }}
//        listeners={({ navigation, route }) => ({
//       tabPress: e => {
//         animateSlider(3)
//       }
//   })}
//      />
//    </Tab.Navigator>
//     );
// }
  
export default TabScreen