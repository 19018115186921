import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Animated, ActivityIndicator, Platform, ImageBackground} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar, CheckBox, Slider} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import { Rating, AirbnbRating } from 'react-native-elements';
import { ProgressBar } from 'react-native-paper';
import ModalInfoAvis from '../components/ModalInfoAvis';
import ModalQuitAvis from '../components/ModalQuitAvis';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from '../components/react-native-simple-radio-button-max';
const Avis4Screen = props => {

  const {Code, flat, Name, Quantity, Points, PointsUser, iduser, Pseudo, person, rating, Temps, Frequence, Remarque, value1, value2, value3, P1, P2, P3, N1, N2, N3} = props.route.params;

  console.log('a4',props.route.params)

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);

  var width = Dimensions.get('window').width;

  var radio_props = [
    {label: 'Oui', value: 0 },
    {label: 'Non', value: 1 }
  ];

  const [value, setValue] = useState(-1);
  console.log(value)

  const ChangeValue = (value) => {
    //setGenre(option.key)
    setValue(value)
    if(value != '-1' ){
      setdisabledcolor('#00808B')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  }

const onValide = () => {
if(disabled){
  if(value == 1){
props.navigation.navigate('Avis7',{Code: Code, flat: flat,  Name: Name, Quantity: Quantity, Points: Points, PointsUser: PointsUser, iduser: iduser, Pseudo: Pseudo, person:person, rating:rating, Temps:Temps, Frequence:Frequence, Remarque:Remarque, value1:value1, value2:value2, value3:value3, P1:P1, P2:P2, P3:P3 , N1:N1, N2:N2, N3:N3 })
} else {
props.navigation.push('Avis5',{Code: Code, flat: flat, Name: Name, Quantity: Quantity, Points: Points, PointsUser: PointsUser, iduser: iduser, Pseudo: Pseudo, person:person, rating:rating, Temps:Temps, Frequence:Frequence, Remarque:Remarque, value1:value1, value2:value2, value3:value3, P1:P1, P2:P2, P3:P3 , N1:N1, N2:N2, N3:N3, PB: 0 })
}
}
}
  
        return (
            <>
          <View style={{ flex:1}}>
          <View  style={{ height: Platform.OS === 'web' ? 70 : 110 }}>
          <View style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',  height: 110 , backgroundColor: '#FFB217', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }}>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="information-outline" color="white" size={36} />  } />
        </View>
        <View style={{flex:2 }}>
        <Text style={styles.Title} numberOfLines={1}>Avis {Name} {Quantity}</Text>
        <ProgressBar progress={0.4} color={'#00343B'} style={{   height: 18, borderRadius:8, backgroundColor:'white',marginHorizontal:10, marginVertical:3 }} />
        </View>
        <View style={styles.button}>
        <Button onPress={() => { setModalVisible2(true) }} buttonStyle={styles.buttonDelete1}  icon={  <MaterialCommunityIcons name="close-circle-outline" color="white" size={36} />  } />
        </View>
        </View>
        </View>
          <ScrollView>
          <View style={styles.Container}>
          <Text style={styles.text1}>Difficultés rencontrés</Text>
             <Text style={styles.text2}>Avez-vous rencontré une ou plusieurs difficultés lors de l'utilisation de ce produit ? *</Text>
             <RadioForm
            radio_props={radio_props}
            initial={value}
            buttonColor={'#00808B'}
            selectedButtonColor={'#00808B'}
            animation={true}
            labelStyle={{fontSize:20, paddingVertical:10}}
            onPress={(value) => ChangeValue(value)}
          />
       </View>
       <ModalInfoAvis isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} Point1={Points}  />
       <ModalQuitAvis isVisible={modalVisible2} onSubmit={() => { setModalVisible2(false) }} onQuit={() => {setModalVisible2(false) ,props.navigation.navigate('Scan')}} props={props}/>
          </ScrollView>
          <View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#FFB217'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
        <TouchableOpacity onPress={() => props.navigation.navigate('Avis3')} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#FFB217', fontSize:20, fontWeight:'bold' }} >Précédent</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onValide} style={{width: 130, height: 50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: disabledcolor,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Suivant</Text>
        </TouchableOpacity>
        </View>
</View>
</View>
            </>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      text2: {
        fontSize: 18,
        color: 'black',
        paddingVertical:10,
        marginBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 18,
        color: 'black',
        paddingVertical:10,
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4: {
        fontSize: 26,
        color: 'green',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      text4red: {
        fontSize: 26,
        color: 'red',
        fontWeight: 'bold',
       backgroundColor:'transparent',
       textAlign:'center'
      },
      button:{
        paddingTop: Platform.OS === 'web' ? 0 : 40,
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        flex:8,
        height: 50,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      inputred: {
        flex:8,
        height: 50,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        fontSize:18
      },
      rond: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'green',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      rondred: {
        height: 50,
        width:50,
        fontSize: 18,
        borderColor: 'red',
        borderWidth: 2,
        borderRadius:25,
        color: 'black',
        marginRight:10,
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      Container: {
        marginVertical: 10,
        marginHorizontal:20
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center'
      },

    });

export default Avis4Screen;
