import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal, ImageBackground} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';


const ModalScanNo =  ({ isVisible, onSubmit, onCancel }) => { 

return(
  
  <Modal
animationType="slide"
transparent={true}
visible={isVisible}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<View style={styles.centeredView}>
  
<View style={styles.modalView}>
  <View style={{alignItems:'center', marginBottom:20}}>
    <ImageBackground style={{ height:200, width:'100%'}} imageStyle={{resizeMode: 'contain'}} source={require("../assets/pigeon_no.png")} />
  </View>
  <Text style={styles.modalText}>Nous ne connaissons pas encore le produit que vous venez de scanner, aidez-nous à l'identifier et gagnez 50 pts supplémentaires.</Text>
  <Text style={styles.modalText}>Nous aider à identifier le produit ?</Text>
  <View style={{flexDirection: "row", justifyContent:'flex-end'}}>
  <TouchableOpacity
    style={{ backgroundColor: "transparent", marginHorizontal:20 }}
    onPress={(value) => onCancel(value)}
  >
    <Text style={styles.textStyle}>NON</Text>
  </TouchableOpacity>
  <TouchableOpacity
    style={{ backgroundColor: "transparent" }}
    onPress={(value) => onSubmit(value)}
  >
    <Text style={styles.textStyle}>OUI</Text>
  </TouchableOpacity>
  </View>
</View>
</View>
</Modal>


            );




}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
   },
  textStyle: {
  color: '#00808B',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
   marginBottom: 15
   },
   modalTextbold: {
    marginBottom: 15,
    fontWeight:'bold'
    },
   

  });

  export default ModalScanNo;