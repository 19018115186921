import React, { useState, useEffect, Component, useRef } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, Picker} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ModalInfoRegister from '../components/ModalInfoRegister';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from '../components/react-native-simple-radio-button-max';
import { LinearGradient } from 'expo-linear-gradient';
import RNPickerSelect from '../components/react-native-picker-select-max';
import BoutonEtude from '../components/BoutonEtude';

const ModifInfo3Screen = props => {

  const {iduser} = props.route.params;

  const URL = 'https://www.useradventure.net/'

  const radioButtonRef = useRef(null);
  const radioButtonRef2 = useRef(null);

  useEffect(() => {
    fetch(`${URL}json_get_react_data_user.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser})
    })
      .then((response) => response.json())
      .then(response => {console.log(response)
         setValue(Number(response[0].Manualite));
        setValue2(Number(response[0].Situation));
        setinitValue(response[0].Langue_maternelle);
        setinitValue2(Number(response[0].Enfants));
        if(response[0].Manualite != '' && response[0].Situation != '' && response[0].Langue_maternelle != '' && response[0].Enfants != '' ){
          setdisabledcolor('#FFB217')
          setdisabled(true)
          }
      })
      .catch((error) => console.error(error))
     
  }, []);


  const [modalVisible, setModalVisible] = useState(false);
  
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
  const [value, setValue] = useState(-1);
  const [value2, setValue2] = useState(-1);
  const [initValue, setinitValue] = useState('Selectionner')
  const [initValue2, setinitValue2] = useState('Selectionner')

  var radio_props = [
    {label: 'Droitier', value: "0" },
    {label: 'Gaucher', value: "1" },
    {label: 'Ambidextre', value: "2" }
  ];


  var radio2_props = [
    {label: 'Célibataire', value: "0" },
    {label: 'En couple', value: "1" },
    {label: 'Marié(e)', value: "2" },
    {label: 'Veuf/veuve', value: "3" },
  ];


  //let index = 0;
  const data = [
    { value: 'Français', label: 'Français'},
    { value: 'Mandarin', label: 'Mandarin' },
    { value: `Espagnol`, label: `Espagnol` },
    { value: `Anglais`, label: `Anglais` },
    { value: `Arabe`, label: `Arabe` },
    { value: `Bengali`, label: `Bengali` },
    { value: `Hindi`, label: `Hindi` },
    { value: `Portugais`, label: `Portugais` },
    { value: `Russe`, label: `Russe` },
    { value: `Japonais`, label: `Japonais` },
    { value: `Lahnda/Pendjabi`, label: `Lahnda/Pendjabi` },
    { value: `Javanais`, label: `Javanais` },
    { value: `Allemand`, label: `Allemand` },
    { value: `Vietnamien`, label: `Vietnamien` },
    { value: `Télougou`, label: `Télougou` },
    { value: `Coréen`, label: `Coréen` },
    { value: `Turc`, label: `Turc` },
    { value: `Tamoul`, label: `Tamoul` },
    { value: `Marathi`, label: `Marathi` },
    { value: `Ourdou`, label: `Ourdou` },
    { value: `Italien`, label: `Italien` },
];


  let index2 = 0;
  const data2 = [
      { value: index2++, label: "0"},
      { value: index2++, label: "1"},
      { value: index2++, label: "2" },
      { value: index2++, label: "3" },
      { value: index2++, label: "4" },
      { value: index2++, label: "5" },
      { value: index2++, label: "6" },
      { value: index2++, label: "7" },
      { value: index2++, label: "8" },
      { value: index2++, label: "9" },
      { value: index2++, label: "10 et plus" },
     
  ];

  const ChangeGenre = (option) => {
    //setGenre(option.key)
    setinitValue(option)
    if(value != '-1' && value2 != '-1' && option !='Selectionner' && initValue2 !='Selectionner' ){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  }

  const ChangeGenre2 = (option) => {
    //setGenre(option.key)
    setinitValue2(option)
    if(value != '-1' && value2 != '-1' && option !='Selectionner' && initValue !='Selectionner' ){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  }

  const ChangeValue = (value) => {
    //setGenre(option.key)
    setValue(value)
    if(value != '-1' && value2 != '-1' && initValue2!='Selectionner' && initValue !='Selectionner' ){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  }

  const ChangeValue2 = (value2) => {
    //setGenre(option.key)
    setValue2(value2)
    if(value != '-1' && value2 != '-1' && initValue2 !='Selectionner' && initValue !='Selectionner' ){
      setdisabledcolor('#FFB217')
      setdisabled(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    }
    
  }

  const valid = () => {
    if(disabled) {
      props.navigation.navigate('ModifInfo4', {iduser , manualite: value, situation: value2, langue: initValue, enfants: initValue2})}
    }

    console.log(value)
    console.log(value2)

        return (
          <View style={{ flex:1}}>
          <View style={{ height: Platform.OS === 'web' ? 70 : 110  , backgroundColor: '#00808B', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
          <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
      
        <View style={{flex:2 }}>
        <Text style={styles.Title}>Modifier mes informations complémentaires</Text>
        </View>
       
        </LinearGradient>
       
        </View>
          <ScrollView>
            <View style={styles.Container}>
             <Text style={styles.textT1}>Un peu plus sur vous 1/2</Text>
             <Text style={styles.text3}>Vous êtes*</Text>
                <View style={styles.sectionContainer}>
                {
                  radio_props.map((item, i) => (
                          <TouchableOpacity onPress={()=>{ChangeValue(i)}}>
                          <View style={{flexDirection:'row', alignItems:'center', height:50, marginHorizontal:0}}>
            
                          <RadioButton labelHorizontal={true} key={i} >
                          
                            <RadioButtonInput
                              obj={item}
                              index={i}
                              isSelected={value === i}
                              onPress={()=>{ChangeValue(i)}}
                              borderWidth={1}
                              buttonInnerColor={'#00808B'}
                              buttonOuterColor={'#00808B'}
                              buttonSize={20}
                              buttonOuterSize={30}
                              buttonStyle={{}}
                              buttonWrapStyle={{marginLeft: 0, paddingVertical:0}}
                            />
                           
                          </RadioButton>
                          <Text style={{ fontSize: 22, color:'#303030', marginBottom:5, marginStart:10}}>{item.label}</Text>
                            </View>
                          </TouchableOpacity>
                        ))
                      }    
                        </View>
                        <View style={styles.sectionContainer}>
                        <Text style={styles.text3}>Votre langue maternelle*</Text>
                        <View style={styles.ViewInput}>
                        {Platform.OS == "ios" ?
               
               <RNPickerSelect
                style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
           onValueChange={(itemValue) => ChangeGenre(itemValue)}
           value={initValue}
           items={data}
       /> : 
               <Picker
       selectedValue={initValue}
       style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
       onValueChange={(itemValue) => ChangeGenre(itemValue)}
     >
     <Picker.Item label="Selectionner" value={null} />
       {data.map((item, i) =>{ return <Picker.Item label={item.label} value={item.value} />})}
     </Picker>}
                 </View>   
                        </View>
                        <View style={styles.sectionContainer}>
                        <Text style={styles.text3}>Votre situation familiale*</Text>
                        </View>
                       
                        <View style={styles.sectionContainer}>
                        {
                          radio2_props.map((item, i) => (
                          <TouchableOpacity onPress={()=>{ChangeValue2(i)}}>
                          <View style={{flexDirection:'row', alignItems:'center', height:50, marginHorizontal:0}}>
            
                          <RadioButton labelHorizontal={true} key={i} >
                          
                            <RadioButtonInput
                              obj={item}
                              index={i}
                              isSelected={value2 === i}
                              onPress={()=>{ChangeValue2(i)}}
                              borderWidth={1}
                              buttonInnerColor={'#00808B'}
                              buttonOuterColor={'#00808B'}
                              buttonSize={20}
                              buttonOuterSize={30}
                              buttonStyle={{}}
                              buttonWrapStyle={{marginLeft: 0, paddingVertical:0}}
                            />
                           
                          </RadioButton>
                          <Text style={{ fontSize: 22, color:'#303030', marginBottom:5, marginStart:10}}>{item.label}</Text>
                            </View>
                          </TouchableOpacity>
                        ))}
                         
                      </View>
                       <View style={styles.sectionContainer}>
                        <Text style={styles.text3}>Combien avez-vous des enfants?*</Text>
                        <View style={styles.ViewInput}>
                        {Platform.OS == "ios" ?
               
               <RNPickerSelect
                style={{  backgroundColor:'transparent' ,marginHorizontal:0, color:'black', borderWidth:0 }}
           onValueChange={(itemValue2) => ChangeGenre2(itemValue2)}
           value={initValue2}
           items={data2}
       /> : 
               <Picker
       selectedValue={initValue2}
       style={{  backgroundColor:'transparent' ,marginHorizontal:-5, color:'black', borderWidth:0 }}
       onValueChange={(itemValue2) => ChangeGenre2(itemValue2)}
     >
     <Picker.Item label="Selectionner" value={null} />
       {data2.map((item, i) =>{ return <Picker.Item label={item.label} value={item.value} />})}
     </Picker>}
                 </View>   
                        </View>
               </View> 
               <ModalInfoRegister isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }} />
          </ScrollView>
          <View style={{ flexDirection: "column-reverse", height:80, backgroundColor:'#00808B'}}>
   <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,justifyContent: 'center'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
          <BoutonEtude onPress={() => props.navigation.goBack()} text={'Annuler'} textColor={'#00808B'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={valid} text={'Valider'} textColor={'white'} boutonColor={'#FFB217'}></BoutonEtude>
        </View>
</LinearGradient>
</View>
        </View>  
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      levelUser: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       
      },
      text2: {
        fontSize: 14,
        color: '#00808B',
        paddingBottom:10,
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      textjaune: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFB217',
        marginLeft:10,       
       backgroundColor:'transparent'
      },
      button:{
          marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        backgroundColor:'white'
      },
      Container: {
        margin: 10,
      },
      textGrid: {
        fontSize: 50,
        color: 'transparent',
        
      }, 
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center',
        backgroundColor:'white'
      },

    });

export default ModifInfo3Screen;
