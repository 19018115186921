import React, { useState, useEffect, Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity,TouchableWithoutFeedback, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform} from 'react-native';
import {Button, Icon, Divider, SearchBar, Avatar} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import ModalInfoShield from '../components/ModalInfoShield';

const Shields1Screen =  props => {

  const {majData, iduser, Shield } = props.route.params;

    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [refresh, setrefresh] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [items, setItems] = useState([]);
    const [Shield1, setShield] = useState([Shield]);
    const [Don, setDon] = useState(['0']);
    const [masterDataSource, setMasterDataSource] = useState([]);
    const animals = [];
    const [modalVisible, setModalVisible] = useState(false);

    const URL = 'https://www.useradventure.net/'
    
    if(Dimensions.get('window').width > 1200){
      var width = ((Dimensions.get('window').width))/3;
      var height = Dimensions.get('window').height
    }else{
      var width = Dimensions.get('window').width;
      var height = Dimensions.get('window').height
    }

    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
      fetch(`${URL}json_get_react_assocations.php`)
        .then((response) => response.json())
        .then(response => {setDataSource(response); 
                          setItems(response);
                          console.log('asso',response); 
      
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }, []);

    const Increase = (index) => {
     const newItems = [...items];
     const newShield = [...Shield1];
     const newDon = [...Don];
     if(newShield[0] != '0') {
       newShield[0]--;
       newDon[0]++;
     newItems[index].quantity++;
     setDon(newDon);
     setShield(newShield);
     setItems(newItems);
     }
         
    }

    const Decrease = (index) => {
      const newItems = [...items];
      const newShield = [...Shield1];
      const newDon = [...Don];
      if(newItems[index].quantity != '0') {
         newItems[index].quantity--;
         newDon[0]--;
      newShield[0]++;
      setShield(newShield);
      setItems(newItems);
      setDon(newDon);
    }
     }
 

     var width_origin = Dimensions.get('window').width;
     var height_origin = Dimensions.get('window').height
     

    const renderItem = ({ item, index }) => {
     const backgroundColor = item.quantity > 0 ? "#FFB217" : "white";
     const marginTop = Platform.OS === 'ios' ? 6 : 10;
     const marginBottom = Platform.OS === 'ios' ? 5 : 4;
  
      return (
         <View style={{ flex: 1, backgroundColor:backgroundColor, flexDirection: 'column', margin: 6, marginTop:marginTop, borderColor: 'pink', borderWidth: 0, borderRadius: 5, marginBottom: marginBottom,elevation: 12,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 } }} >
          <View style={{  paddingTop: 15, alignItems:'center'}}>
          <Text style={styles.titleGrid}>{item.Nom_Association}</Text>
        </View>
        <View style={{  paddingHorizontal:10}}>
        <Image style={{
    justifyContent: 'center',
    alignItems: 'center',
    height: width/5.5,
    width:'100%',
    resizeMode: 'contain',
  }} source={{ uri: item.Logo_Association }} />
   </View>  
   <View style={{ flexDirection: "row",justifyContent: 'space-between',margin:10}}> 
          <TouchableOpacity onPress={()=> Decrease(index)} style={{width: width/8, height:width/8, borderRadius:5,borderWidth:2,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
    <Text style={{ color:'#00808B', fontSize:24, fontWeight:'bold' }}>-</Text>
    </TouchableOpacity>
          <Text style={styles.textShield2}>{item.quantity}</Text>
    <TouchableOpacity onPress={()=> Increase(index)} style={{width: width/8, height:width/8, borderRadius:5,borderWidth:2,borderColor:'#00808B',justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
    <Text style={{ color:'#00808B', fontSize:24, fontWeight:'bold' }}>+</Text>
    </TouchableOpacity>
          </View>
      </View>
       
      );
    };
  
  
  


  // const searchFilterFunction = (text) => {
  //   // Check if searched text is not blank
  //   if (text) {
  //     // Inserted text is not blank
  //     // Filter the masterDataSource
  //     // Update FilteredDataSource
  //     const newData = masterDataSource.filter(function (item) {
  //       const itemData = item.lieu
  //         ? item.lieu.toUpperCase()
  //         : ''.toUpperCase();
  //       const textData = text.toUpperCase();
  //       return itemData.indexOf(textData) > -1;
  //     });
  //     setFilteredDataSource(newData);
  //     setSearch(text);
  //   } else {
  //     // Inserted text is blank
  //     // Update FilteredDataSource with masterDataSource
  //     setFilteredDataSource(masterDataSource);
  //     setSearch(text);
  //   }
  // };

 
  const marginBottom2 = Platform.OS === 'ios'? 10 : 0;
 
    return (

      <View style={[Platform.OS === 'ios' ? styles.containerIOS : styles.container]}>
     <View style={{flex:0.6, backgroundColor: 'white',marginBottom:marginBottom2}} >
            <View style={{flex:1, backgroundColor: '#00808B', elevation: 20  ,shadowColor: 'black', shadowOpacity: 0.4,  shadowRadius: 5, shadowOffset: { height: 8, width:1 } }}>
             <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,  paddingTop:0 }}>
              <View style={{flex:2, flexDirection: "row", marginTop:10}}>
                <View style={styles.button}>
                  <Button onPress={() => props.navigation.navigate('Cash')} buttonStyle={styles.buttonDelete1}
                  icon={ <MaterialCommunityIcons name="chevron-left" color="white" size={46} /> }/>
                </View>
                <View style={{flex:1 , justifyContent:'center'}}>
                  <Text style={styles.Title}>Faire un don</Text>
                </View>
                <View style={styles.button}>
                  <Button onPress={() => { setModalVisible(true) }} buttonStyle={styles.buttonDelete1}
                  icon={ <MaterialCommunityIcons name="information-outline" color="white" size={36} /> }/>
                </View>
              </View>
              <View style={{flex:3,flexDirection: "row", backgroundColor:'transparent', marginHorizontal:10}}>
                <View style={{flex:1, justifyContent: 'center',alignItems: 'center' }}>
                  <Image style={{height:'100%',width:'100%', resizeMode: 'contain'}} source={require("../assets/porte-feuille.png")}/>
                </View>
                <View style={{flex:1,flexDirection: "row", justifyContent: 'center',alignItems: 'center', }}>
                    <Text style={styles.textShield}>{Shield1}</Text>
                    <Image style={{height:35, width:35, resizeMode: 'contain'}} source={require("../assets/bouclier_or.png")} /> 
                 </View>
                 <View style={{flex:1, justifyContent: 'center',alignItems: 'center' }}>
                  <Image style={{height:'100%',width:'100%', resizeMode: 'contain'}} source={require("../assets/boite-coeur.png")}/>
                </View>
                 <View style={{flex:1,flexDirection: "row", justifyContent: 'center',alignItems: 'center'}}>
                    <Text style={styles.textShield}>{Don}</Text>
                    <Image style={{height:35, width:35, resizeMode: 'contain'}} source={require("../assets/bouclier_or.png")} /> 
                 </View>
              </View>
              <View style={{flex:1, flexDirection: "row",justifyContent:'space-between', alignItems: "flex-end",marginHorizontal:10}}>
                  <Text style={{ color:'white', fontWeight:'bold', fontSize:26 }}>BENEFICIAIRES</Text>
                <TouchableOpacity  onPress={() => onPress('Légumes')} style={{ justifyContent: 'center', height: 30, borderColor:'white', borderWidth:1, borderRadius:5,marginBottom:3}}> 
                    <View style={{ flexDirection: "row", alignItems:'center', paddingHorizontal:10}}>
                       <Text style={{ paddingHorizontal: 5, fontWeight:'bold', color:'white'}}>Filtrer</Text>
                       <MaterialCommunityIcons  name='filter' color={'white'} size={24}  />
                     </View>
                  </TouchableOpacity>
                
              </View>
            </LinearGradient>
            </View>
        </View>
        {isLoading ? <ActivityIndicator/> : (
      <FlatList
      style={{flex:1, paddingHorizontal : width_origin > 1200? 120 :0}}
      backgroundColor='#DCDCDC'
        extraData={selectedId}
        data={items}
        renderItem={renderItem}
        numColumns={'2'}
        keyExtractor={(item) => item.ID}
      />
      )}
    <ModalInfoShield isVisible={modalVisible} onSubmit={() => { setModalVisible(false) }}  />
<View style={{ flexDirection: "column-reverse",justifyContent: 'center', height:80, backgroundColor:'#00808B'}}>
<LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,justifyContent: 'center'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10}}>
        <TouchableOpacity onPress={() => props.navigation.navigate('Cash')} style={{width: 130, height: 50, borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: 'white',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'#00808B', fontSize:20, fontWeight:'bold' }}>Annuler</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => props.navigation.navigate('Shields2', {majData: majData, iduser: iduser, items: items, Don: Don, Shield: Shield1})} style={{width: 130, height: 50, borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >
        <Text style={{ color:'white', fontSize:20, fontWeight:'bold' }}>Valider</Text>
        </TouchableOpacity>
        </View>
        </LinearGradient>
</View>
    </View>
    
    );
        }

  const styles = StyleSheet.create({
    container: {
      flex: 3,
      justifyContent: 'center',
      backgroundColor:'#DCDCDC'
    },
    containerIOS: {
      flex: 3,
      justifyContent: 'center',
      backgroundColor: 'white',
    },
      textGrid: {
       paddingVertical: 5,
        marginHorizontal:4,
      },
      titleGrid: {
        height: 30,
         marginHorizontal:4,
         justifyContent: 'center',
         alignItems: 'center',
       },
       tinyLogo: {
        width: '100%',
        height: 300,
      },
      Title: {
        textAlign: 'center',
       
        paddingHorizontal: 0,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
        backgroundColor:'transparent'
        },
      button:{
        
          marginLeft:0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      buttonDelete1: {
        backgroundColor:'transparent'
      },
      textShield: {
        paddingHorizontal:5,
        color: 'white',
        fontWeight:'bold',
        fontSize:30
       },
       textShield2: {
        paddingHorizontal:10,
        color: '#00808B',
        fontWeight:'bold',
        fontSize:30
       },

  });



  export default Shields1Screen;
