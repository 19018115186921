import React, { useState, useEffect, Component } from 'react';
import { StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import BoutonEtude from '../components/BoutonEtude';

const AvatarScreen = props => {

  const {iduser, classe, level, item1, item2, item3, item4, item5, item6 } = props.route.params;
  var width = Dimensions.get('window').width;

  const [body, setbody] = useState(item1);
  const [visage, setvisage] = useState(item2);
  const [slip, setslip] = useState(item3);
  const [cheveux, setcheveux] = useState(item4);
  const [casque, setcasque] = useState(item5);
  const [arme, setarme] = useState(item6);
  const [text, settext] = useState('Couleur de peau');
  const [dataSource, setDataSource] = useState([]);
  const [Item, setItem] = useState(item1);
  const [categorie, setcategorie] = useState('body');

  const URL_items = 'https://www.useradventure.net/Items/'
  const URL = 'https://www.useradventure.net/'

  useEffect(() => {
    fetch(`${URL}json_get_react_items.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({categorie: 'body',niv: level, iduser: iduser })
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
                      
    
      })
      .catch((error) => console.error(error))
      .finally(() => settext('Couleur de peau'));
  }, []);

  const onValid =()=>{
    fetch(`${URL}json_get_react_input_avatar.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({iduser: iduser, item1: body, item2: visage, item3: slip, item4: cheveux, item5: casque, item6: arme})
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
                      
    
      })
      .catch((error) => console.error(error))
      .finally(() => props.navigation.navigate('Compte', { token : '2', item1: body, item2: visage, item3: slip, item4: cheveux, item5: casque, item6: arme}),
     
      ) 
    }

  const Body = () =>{
    setItem(body)
    fetch(`${URL}json_get_react_items.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({categorie: 'body',niv: level, iduser: iduser })
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
      })
      .catch((error) => console.error(error))
      .finally(() => settext('Couleur de peau'));
  }

  const Visage = () =>{
    setItem(visage)
    fetch(`${URL}json_get_react_items.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({categorie: 'visage',niv: level, iduser: iduser })
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
      })
      .catch((error) => console.error(error))
      .finally(() => settext('Visages'));
  }

  const Slip = () =>{
    setItem(slip)
    fetch(`${URL}json_get_react_items.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({categorie: 'slip',niv: level, iduser: iduser })
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
      })
      .catch((error) => console.error(error))
      .finally(() => settext('Vêtements') );
  }

  const Cheveux = () =>{
    setItem(cheveux)
    fetch(`${URL}json_get_react_items.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({categorie: 'cheveux',niv: level, iduser: iduser })
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
      })
      .catch((error) => console.error(error))
      .finally(() => settext('Coiffures') );
  }

  const Arme = () =>{
    setItem(arme)
    fetch(`${URL}json_get_react_items.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({categorie: `arme_${classe}`,niv: level, iduser: iduser })
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
      })
      .catch((error) => console.error(error))
      .finally(() => settext('Armes') );
  }

  const Casque = () =>{
    setItem(casque)
    fetch(`${URL}json_get_react_items.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({categorie: `casque_${classe}`,niv: level, iduser: iduser })
    })
      .then((response) => response.json())
      .then(response => {setDataSource(response); 
      })
      .catch((error) => console.error(error))
      .finally(() => settext('Casques') );
  }
  
 //console.log(dataSource)
  
        return (
          <View style={{ flex:1}}>
          <View style={{ height: Platform.OS === 'web' ? 70 : 110 , backgroundColor: '#00808B', elevation: 20 ,shadowColor: 'black', shadowOpacity: 0.3,  shadowRadius: 5, shadowOffset: { height: 8, width:1 }  }}>
          <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:3, flexDirection: "row",justifyContent: 'center', alignItems:'center',}}>
        <View style={{flex:2 }}>
        <Text style={styles.Title}>Modifier mon aventurier</Text>
        </View>
        </LinearGradient>
        </View>
          <ScrollView>
            <View style={styles.Container}>
            <View style={{ height: 320, backgroundColor:'transparent',alignItems: 'center'}}>
                    <ImageBackground style={{height:'100%',width:320}} imageStyle={{resizeMode: 'contain'}} source={{ uri:  `${URL_items}${body}.png` }}>
                    <ImageBackground style={{height:'100%',width:320}} imageStyle={{resizeMode: 'contain'}} source={{ uri:  `${URL_items}${visage}.png` }}>
                    <ImageBackground style={{height:'100%',width:320}} imageStyle={{resizeMode: 'contain'}} source={{ uri:  `${URL_items}${slip}.png` }}>
                    <ImageBackground style={{height:'100%',width:320}} imageStyle={{resizeMode: 'contain'}} source={{ uri:  `${URL_items}${cheveux}.png` }}>
                    <ImageBackground style={{height:'100%',width:320}} imageStyle={{resizeMode: 'contain'}} source={{ uri:  `${URL_items}${casque}.png` }}>
                    <ImageBackground style={{height:'100%',width:320}} imageStyle={{resizeMode: 'contain'}} source={{ uri:  `${URL_items}${arme}.png` }}>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                    </ImageBackground>
                  </View>
                    <View style={{flex:6, flexDirection: "row",justifyContent: 'space-between', alignItems:'center', borderBottomColor:'#00808B',borderBottomWidth:6, marginTop:10}}>
                    <TouchableOpacity onPress={Body} style={[ text === 'Couleur de peau' ? {  backgroundColor:'transparent', borderTopRightRadius:5, borderTopLeftRadius:5, borderWidth:5, borderColor:'#00808B'} : { backgroundColor:'transparent', borderTopRightRadius:5,borderTopLeftRadius:5,borderWidth:5, borderColor:'transparent'}]}  >
                    <Image  source={require('../assets/goutte.png')}   style={[ text === 'Couleur de peau' ? { width: 50, height: 50, tintColor:'white', backgroundColor:'#00808B', color:'white'} : { width: 50, height: 50, tintColor:'#00808B',  color:'white'}]}  /> 
                    </TouchableOpacity>
                    <TouchableOpacity onPress={Visage} style={[ text === 'Visages' ? {  backgroundColor:'transparent', borderTopRightRadius:5, borderTopLeftRadius:5, borderWidth:5, borderColor:'#00808B'} : { backgroundColor:'transparent', borderTopRightRadius:5,borderTopLeftRadius:5,borderWidth:5, borderColor:'transparent'}]}  >
                    <Image  source={require('../assets/smile1.png')}  style={[ text === 'Visages' ? { width: 50, height: 50, tintColor:'white', backgroundColor:'#00808B', color:'#00808B'} : { width: 50, height: 50, tintColor:'#00808B', color:'white'}]}  /> 
                    </TouchableOpacity>
                    <TouchableOpacity onPress={Slip} style={[ text === 'Vêtements' ? {  backgroundColor:'transparent', borderTopRightRadius:5, borderTopLeftRadius:5, borderWidth:5, borderColor:'#00808B'} : { backgroundColor:'transparent', borderTopRightRadius:5,borderTopLeftRadius:5,borderWidth:5, borderColor:'transparent'}]}  >
                    <Image  source={require('../assets/tshirt.png')}   style={[ text === 'Vêtements' ? { width: 50, height: 50, tintColor:'white', backgroundColor:'#00808B', color:'white'} : { width: 50, height: 50, tintColor:'#00808B',  color:'white'}]}  /> 
                    </TouchableOpacity>
                    <TouchableOpacity onPress={Cheveux} style={[ text === 'Coiffures' ? {  backgroundColor:'transparent', borderTopRightRadius:5, borderTopLeftRadius:5, borderWidth:5, borderColor:'#00808B'} : { backgroundColor:'transparent', borderTopRightRadius:5,borderTopLeftRadius:5,borderWidth:5, borderColor:'transparent'}]}  >
                    <Image  source={require('../assets/cheveux.png')}  style={[ text === 'Coiffures' ? { width: 50, height: 50, tintColor:'white', backgroundColor:'#00808B', color:'white'} : { width: 50, height: 50, tintColor:'#00808B',  color:'white'}]}  /> 
                    </TouchableOpacity>
                    <TouchableOpacity onPress={Arme} style={[ text === 'Armes' ? {  backgroundColor:'transparent', borderTopRightRadius:5, borderTopLeftRadius:5, borderWidth:5, borderColor:'#00808B'} : { backgroundColor:'transparent', borderTopRightRadius:5,borderTopLeftRadius:5,borderWidth:5, borderColor:'transparent'}]}  >
                    <Image  source={require('../assets/epee1.png')}    style={[ text === 'Armes' ? { width: 50, height: 50, tintColor:'white', backgroundColor:'#00808B', color:'white'} : { width: 50, height: 50, tintColor:'#00808B',  color:'white'}]}  />  
                    </TouchableOpacity>
                    <TouchableOpacity onPress={Casque} style={[ text === 'Casques' ? {  backgroundColor:'transparent', borderTopRightRadius:5, borderTopLeftRadius:5, borderWidth:5, borderColor:'#00808B'} : { backgroundColor:'transparent', borderTopRightRadius:5,borderTopLeftRadius:5,borderWidth:5, borderColor:'transparent'}]}  >
                    <Image  source={require('../assets/casque.png')}   style={[ text === 'Casques' ? { width: 50, height: 50, tintColor:'white', backgroundColor:'#00808B', color:'white'} : { width: 50, height: 50, tintColor:'#00808B',  color:'white'}]}  />  
                    </TouchableOpacity>
                    </View> 
                    <Text style={styles.textT1}>{text}</Text>
                    <FlatList
          data={dataSource}
          backgroundColor='transparent'
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => {if(item.Categorie == 'slip'){ setslip(item.Item), setItem(item.Item)}else if(item.Categorie == `arme_${classe}`){setarme(item.Item), setItem(item.Item)}else if(item.Categorie == `casque_${classe}`){setcasque(item.Item), setItem(item.Item)}
            else if(item.Categorie == 'body'){setbody(item.Item), setItem(item.Item)}else if(item.Categorie == 'visage'){setvisage(item.Item), setItem(item.Item)}else if(item.Categorie == 'cheveux'){setcheveux(item.Item), setItem(item.Item)}}}  style={[ item.Item === Item ? { flex: 1, flexDirection: 'column', margin: 3, borderColor: '#00808B', borderWidth: 3, borderRadius: 8, marginBottom: 7,elevation: 12, backgroundColor: item.color_icone ,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 }} : { flex: 1, flexDirection: 'column', margin: 6, borderColor: '#00808B', borderWidth: 0, borderRadius: 8, marginBottom: 10,elevation: 12, backgroundColor: item.color_icone,shadowColor: 'black', shadowOpacity: 0.2,  shadowRadius: 5, shadowOffset: { height: 8, width:5 } }]} >
              <View style={{ alignItems: 'center'}}>
                <Image style={{height:Platform.OS === 'web' ? (((width-80)/5)/2) : (width-80)/5,width:(width-150)/5, resizeMode: 'contain'}} source={{ uri: item.icone_item }}/>
              </View>
          </TouchableOpacity>
          )}
          //Setting the number of column
          numColumns={5}
          keyExtractor={(item, index) => index}
          /> 
            </View> 
          </ScrollView>
          <View style={{ flexDirection: "column-reverse", height:80, backgroundColor:'#00808B'}}>
   <LinearGradient colors={['#00808B','#003333']} start={[0.0, 1.4]} end={[1.4, 1.4]} style={{flex:1,justifyContent: 'center'}}>
<View style={{ flexDirection: "row",justifyContent: 'space-between',marginHorizontal:10, }}>
          <BoutonEtude onPress={() => props.navigation.navigate('Compte')} text={'Annuler'} textColor={'#00808B'} boutonColor={'white'}></BoutonEtude>
          <BoutonEtude onPress={onValid} text={'Valider'} textColor={'white'} boutonColor={'#FFB217'}></BoutonEtude>
        </View>
</LinearGradient>
</View>
          </View>
        );
        
      
        }

    const styles = StyleSheet.create({
    
      Title: {
        textAlign: 'center',
        paddingTop: Platform.OS === 'web' ? 0 : 40,
        paddingHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
       backgroundColor:'transparent'
      },
      textT1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent',
       paddingVertical:10,
       paddingHorizontal:10
      },
      textPigeon: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text1: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text2: {
        fontSize: 16,
        color: '#00808B',
       backgroundColor:'transparent'
      },
      text3: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#00808B',
        paddingVertical:10,
       backgroundColor:'transparent'
      },
      button:{
          marginTop: 40,
          marginLeft:10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'transparent'
      },
      button1: {
          height:50,
          margin:10,
          borderRadius:5,
          justifyContent: 'center',
        alignItems: "center",
        backgroundColor: '#003333',
         padding: 10,
         elevation: 10  ,
         shadowColor: 'black', 
         shadowOpacity: 0.3, 
      shadowOffset: { height: 10 }
        },
      buttonDelete1: {
        backgroundColor:'transparent',
        
      },
     
      buttonsize: {fontSize: 24, },
      sectionTitle: {
        fontSize: 18,
        color: 'black',
        marginTop:5
      },
      input: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18
      },
      ViewInput: {
        height: 50,
        borderColor: '#00808B',
        borderWidth: 2,
        borderRadius:10,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize:18,
        justifyContent:'center'
      },
      Container: {
        margin: 10,
      },
     

    });

export default AvatarScreen;
